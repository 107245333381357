import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getStockReservation,
  getStockReservationById,
  exportStockReservationPDF,
  exportStockReservationExcel
} from '../../../services/stockReservation';
import {
  SET_STATE,
  // SET_ERROR_FORM,
  GET_DATA_STOCK_RESERVATION,
  GET_DATA_STOCK_RESERVATION_BY_ID,
  GET_DATA_STOCK_RESERVATION_NEXT_OR_PREV,
  DOWNLOAD_DATA_STOCK_RESERVATION_PDF,
  DOWNLOAD_DATA_STOCK_RESERVATION_EXCEL
} from './actions';
import { handleDownload, logMatches } from '../../../utils';

export function* GET_STOCK_RESERVATION(action) {
  yield put({ type: SET_STATE, payload: { data: null, loading: true } });
  try {
    const { data } = yield call(getStockReservation, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_STOCK_RESERVATION_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { data: null, loading: true } });
  try {
    const { data } = yield call(getStockReservationById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_STOCK_RESERVATION_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DOWNLOAD_STOCK_RESERVATION_PDF(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(exportStockReservationPDF, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DOWNLOAD_STOCK_RESERVATION_EXCEL(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(exportStockReservationExcel, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_STOCK_RESERVATION, GET_STOCK_RESERVATION),
  takeLatest(GET_DATA_STOCK_RESERVATION_BY_ID, GET_STOCK_RESERVATION_BY_ID),
  takeLatest(
    GET_DATA_STOCK_RESERVATION_NEXT_OR_PREV,
    GET_STOCK_RESERVATION_NEXT_OR_PREV
  ),
  takeLatest(
    DOWNLOAD_DATA_STOCK_RESERVATION_PDF,
    DOWNLOAD_STOCK_RESERVATION_PDF
  ),
  takeLatest(
    DOWNLOAD_DATA_STOCK_RESERVATION_EXCEL,
    DOWNLOAD_STOCK_RESERVATION_EXCEL
  )
]);
