import service from './service';
import endpoint from './constantUrl';

// StockInventory
export const addStockInventory = (data) => {
  return service.post(`${endpoint.POS_URL}/inventory/stock-inventory/`, data);
};
export const getStockInventory = (param) => {
  return service.get(`${endpoint.POS_URL}/inventory/stock-inventory/`, param);
};

export const getStockInventoryProduct = (param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-inventory/product/`,
    { ...param, available_stock_only: true }
  );
};

export const getStockInventorySku = (param) => {
  return service.post(`${endpoint.POS_URL}/inventory/stock-inventory/sku/`, {
    ...param,
    available_stock_only: true
  });
};

export const getStockInventoryById = (id, param) => {
  return service.get(
    `${endpoint.POS_URL}/inventory/stock-inventory/${id}/`,
    param
  );
};

export const updateStockInventory = (data, id) => {
  return service.put(
    `${endpoint.POS_URL}/inventory/stock-inventory/${id}/`,
    data
  );
};

export const deleteStockInventory = (id) => {
  return service.delete(`${endpoint.POS_URL}/inventory/stock-inventory/${id}/`);
};

export const downloadTemplateStockInventory = () => {
  return service.getBlob(
    `${endpoint.POS_URL}/inventory/stock-inventory/csv-template/`
  );
};

export const downloadDataStockInventory = (param) => {
  return service.postArrayBuffer(
    `${endpoint.POS_URL}/inventory/stock-inventory/export-excel/`,
    param
  );
};

export const uploadStockInventory = (data) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-inventory/import-csv/`,
    data
  );
};

export const getHistoryTransaksi = (history_id62) => {
  return service.getPublic(
    `${endpoint.POS_URL}/inventory/history/${history_id62}/`
  );
};

export const transferToB2b = (id) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-inventory/${id}/submit-b2b/`,
    {}
  );
};

export const getStockInventoryHistories = (id, param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-inventory/${id}/histories/`,
    param
  );
};

export const getStockInventoryBatchs = (id, param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-inventory/${id}/batchs/`,
    param
  );
};

export const getStockInventoryDistributions = (id, param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-inventory/${id}/distributions/v2/`,
    param
  );
};

export const getStockInventoryDistributionsV1 = (id, param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-inventory/${id}/distributions/`,
    param
  );
};

export const getStockDistributionsByBatch = (batch, isAuth = false) => {
  return service.postPublic(
    `${endpoint.POS_URL}/inventory/stock-distributions/batch-number/${batch}/`,
    isAuth
  );
};

export const changeInventory = (data) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-batch/change-inventory/`,
    data
  );
};

export const getStockInventoryDistributionByHistory = (param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-batch/detail-distributions-by-history/`,
    param
  );
};

export const exportStockInventoryExcel = (param) => {
  return service.postDownloadService(
    `${endpoint.DOWNLOAD}/stock-inventory/excel`,
    param
  );
};
