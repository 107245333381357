import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'TransaksiPembelian/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'TransaksiPembelian/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_TRANSAKSI_PEMBELI = 'GET_DATA_TRANSAKSI_PEMBELI';
export const getDataTransaksiPembeli = defaultActionCreator(
  GET_DATA_TRANSAKSI_PEMBELI,
  'param'
);

export const ADD_DATA_TRANSAKSI_PEMBELI = 'ADD_DATA_TRANSAKSI_PEMBELI';
export const addDataTransaksiPembeli = defaultActionCreator(
  ADD_DATA_TRANSAKSI_PEMBELI,
  'data',
  'log'
);
export const APPROVE_DATA_TRANSAKSI_PEMBELI = 'APPROVE_DATA_TRANSAKSI_PEMBELI';
export const approveDataTransaksiPembeli = defaultActionCreator(
  APPROVE_DATA_TRANSAKSI_PEMBELI,
  'data',
  'id'
);

export const DELETE_DATA_TRANSAKSI_PEMBELIAN =
  'DELETE_DATA_TRANSAKSI_PEMBELIAN';
export const deleteDataTransaksiPembelian = defaultActionCreator(
  DELETE_DATA_TRANSAKSI_PEMBELIAN,
  'id'
);

export const GET_DATA_TRANSAKSI_PEMBELIAN_BY_ID =
  'GET_DATA_TRANSAKSI_PEMBELIAN_BY_ID';
export const getDataTransaksiPembelianById = defaultActionCreator(
  GET_DATA_TRANSAKSI_PEMBELIAN_BY_ID,
  'id'
);

export const DOWNLOAD_TEMPLATE_TRANSAKSI_PEMBELIAN =
  'DOWNLOAD_TEMPLATE_TRANSAKSI_PEMBELIAN';
export const downloadTemplateTransaksiPembelian = defaultActionCreator(
  DOWNLOAD_TEMPLATE_TRANSAKSI_PEMBELIAN,
  'id'
);
export const UPDATE_DATA_TRANSAKSI_PEMBELIAN =
  'UPDATE_DATA_TRANSAKSI_PEMBELIAN';
export const updateDataTransaksiPembelian = defaultActionCreator(
  UPDATE_DATA_TRANSAKSI_PEMBELIAN,
  'data',
  'id',
  'log'
);

export const UPDATE_DATA_TRANSAKSI_PEMBELIAN_CONFIRMED =
  'UPDATE_DATA_TRANSAKSI_PEMBELIAN_CONFIRMED';
export const updateDataTransaksiPembelianConfirmed = defaultActionCreator(
  UPDATE_DATA_TRANSAKSI_PEMBELIAN_CONFIRMED,
  'data',
  'id',
  'log'
);
export const DOWNLOAD_DATA_TRANSAKSI_PEMBELIAN =
  'DOWNLOAD_DATA_TRANSAKSI_PEMBELIAN';
export const downloadDataTransaksiPembelian = defaultActionCreator(
  DOWNLOAD_DATA_TRANSAKSI_PEMBELIAN,
  'param'
);

export const UPLOAD_DATA_TRANSAKSI_PEMBELIAN =
  'UPLOAD_DATA_TRANSAKSI_PEMBELIAN';
export const uploadDataTransaksiPembelian = defaultActionCreator(
  UPLOAD_DATA_TRANSAKSI_PEMBELIAN,
  'data'
);

export const DOWNLOAD_DATA_TRANSAKSI_PEMBELIAN_EXCEL =
  'DOWNLOAD_DATA_TRANSAKSI_PEMBELIAN_EXCEL';
export const downloadDataTransaksiPembelianExcel = defaultActionCreator(
  DOWNLOAD_DATA_TRANSAKSI_PEMBELIAN_EXCEL,
  'param'
);
