import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeTag = [
  {
    path: '/tag',
    parent: '/tag',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(/* webpackChunkName: "tag" */ '../Pages/MasterData/Tag')
      )
    )
  },
  {
    path: '/tag/:id',
    parent: '/tag',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "tagDetail" */ '../Pages/MasterData/Tag/detail'
        )
      )
    )
  },
  {
    path: '/tag/add',
    parent: '/tag',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(/* webpackChunkName: "tagAdd" */ '../Pages/MasterData/Tag/add')
      )
    )
  }
];
