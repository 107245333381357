import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'stokIn/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'stokIn/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_STOK_IN = 'GET_DATA_STOK_IN';
export const getDataStokIn = defaultActionCreator(GET_DATA_STOK_IN, 'param');

export const GENERATE_NO_STOK_IN = 'GENERATE_NO_STOK_IN';
export const generateNoStokIn = defaultActionCreator(
  GENERATE_NO_STOK_IN,
  'data'
);

export const CREATE_DATA_STOCK_IN = 'CREATE_DATA_STOCK_IN';
export const createDataStockIn = defaultActionCreator(
  CREATE_DATA_STOCK_IN,
  'data'
);

export const DELETE_DATA_STOCK_IN = 'DELETE_DATA_STOCK_IN';
export const deleteDataStockIn = defaultActionCreator(
  DELETE_DATA_STOCK_IN,
  'id'
);

export const GET_STOCK_IN_BY_ID = 'GET_STOCK_IN_BY_ID';
export const getDataStockInById = defaultActionCreator(
  GET_STOCK_IN_BY_ID,
  'id'
);

export const UPDATE_STATUS_KALKULASI_IN = 'UPDATE_STATUS_KALKULASI_IN';
export const updateStatusKalkulasi = defaultActionCreator(
  UPDATE_STATUS_KALKULASI_IN,
  'id',
  'data'
);

export const UPDATE_STOCK_IN = 'UPDATE_STOCK_IN';
export const updateStokIn = defaultActionCreator(UPDATE_STOCK_IN, 'id', 'data');

export const UPLOAD_STOCK_IN = 'UPLOAD_DATA_STOCK_IN';
export const uploadDataStockIn = defaultActionCreator(UPLOAD_STOCK_IN, 'data');

export const DOWNLOAD_EXCEL = 'DOWNLOAD_EXCEL';
export const downloadExcel = defaultActionCreator(DOWNLOAD_EXCEL, 'data');

export const TEMPLATE_CSV = 'TEMPLATE_CSV';
export const templateCsv = defaultActionCreator(TEMPLATE_CSV, 'data');
