import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'invetoryType/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'invetoryType/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_INVENTORY_TYPE = 'GET_DATA_INVENTORY_TYPE';
export const getDataInventoryType = defaultActionCreator(
  GET_DATA_INVENTORY_TYPE,
  'param'
);

export const GET_DATA_INVENTORY_TYPE_NEXT_OR_PREV =
  'GET_DATA_INVENTORY_TYPE_NEXT_OR_PREV';
export const getDataInventoryTypeNextOrPrev = defaultActionCreator(
  GET_DATA_INVENTORY_TYPE_NEXT_OR_PREV,
  'link'
);

export const GET_DATA_INVENTORY_TYPE_BY_ID = 'GET_DATA_INVENTORY_TYPE_BY_ID';
export const getDataInventoryTypeById = defaultActionCreator(
  GET_DATA_INVENTORY_TYPE_BY_ID,
  'id'
);

export const ADD_DATA_INVENTORY_TYPE = 'ADD_DATA_INVENTORY_TYPE';
export const addDataInventoryType = defaultActionCreator(
  ADD_DATA_INVENTORY_TYPE,
  'data'
);

export const UPDATE_DATA_INVENTORY_TYPE = 'UPDATE_DATA_INVENTORY_TYPE';
export const updateDataInventoryType = defaultActionCreator(
  UPDATE_DATA_INVENTORY_TYPE,
  'data',
  'id'
);

export const DELETE_DATA_INVENTORY_TYPE = 'DELETE_DATA_INVENTORY_TYPE';
export const deleteDataInventoryType = defaultActionCreator(
  DELETE_DATA_INVENTORY_TYPE,
  'id'
);
