import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getKomoditi,
  getKomoditiById,
  addKomoditi,
  updateKomoditi,
  deleteKomoditi,
  downloadKomoditi,
  exportCommodityExcel
} from '../../../services/komoditi';
import { handleDownload, logMatches } from '../../../utils';
import {
  SET_STATE,
  GET_DATA_KOMODITI,
  GET_DATA_KOMODITI_BY_ID,
  ADD_DATA_KOMODITI,
  UPDATE_DATA_KOMODITI,
  DELETE_DATA_KOMODITI,
  GET_DATA_KOMODITI_NEXT_OR_PREV,
  SET_ERROR_FORM,
  DOWNLOAD_DATA_KOMODITI,
  DOWNLOAD_DATA_KOMODITI_EXCEL
} from './actions';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_KOMODITI(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getKomoditi, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_KOMODITI_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getKomoditiById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_KOMODITI_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_KOMODITI(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addKomoditi, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Commodity', 'Create Commodity');

    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* UPDATE_KOMODITI(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateKomoditi, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Commodity', 'Edit Commodity');

    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DELETE_KOMODITI(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteKomoditi, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Commodity', 'Delete Commodity');

    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loading: false }
    });
  }
}

export function* DOWNLOAD_KOMODITI(action) {
  yield put({ type: SET_STATE, payload: { loadingDownload: true } });
  try {
    const { data, headers } = yield call(downloadKomoditi, action.param);
    const filename = yield logMatches(headers['content-disposition']);
    yield handleDownload(data, filename[0]);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDownload: false } });
  }
}

export function* DOWNLOAD_KOMODITI_EXCEL(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(exportCommodityExcel, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_KOMODITI, GET_KOMODITI),
  takeLatest(GET_DATA_KOMODITI_BY_ID, GET_KOMODITI_BY_ID),
  takeLatest(GET_DATA_KOMODITI_NEXT_OR_PREV, GET_KOMODITI_NEXT_OR_PREV),
  takeLatest(ADD_DATA_KOMODITI, ADD_KOMODITI),
  takeLatest(UPDATE_DATA_KOMODITI, UPDATE_KOMODITI),
  takeLatest(DELETE_DATA_KOMODITI, DELETE_KOMODITI),
  takeLatest(DOWNLOAD_DATA_KOMODITI, DOWNLOAD_KOMODITI),
  takeLatest(DOWNLOAD_DATA_KOMODITI_EXCEL, DOWNLOAD_KOMODITI_EXCEL)
]);
