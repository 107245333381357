import { all, put, takeLatest, call } from 'redux-saga/effects';
import {
  getTagihanPembelian,
  getTagihanPembelianById,
  addTagihanPembelian,
  updateTagihanPembelian,
  deleteTagihanPembelian,
  downloadTagihanPembelian
} from '../../../services/tagihanPembelian';
import {
  SET_ERROR_FORM,
  SET_STATE,
  GET_DATA_TAGIHAN_PEMBELIAN,
  GET_DATA_TAGIHAN_PEMBELIAN_BY_ID,
  ADD_DATA_TAGIHAN_PEMBELIAN,
  UPDATE_DATA_TAGIHAN_PEMBELIAN,
  DELETE_DATA_TAGIHAN_PEMBELIAN,
  DOWNLOAD_TAGIHAN_PEMBELIAN
} from './actions';
import { handleDownload, logMatches } from '../../../utils';

import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_TAGIHAN_PEMBELIAN(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getTagihanPembelian, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_TAGIHANN_PEMBELIAN_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getTagihanPembelianById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_TAGIHAN_PEMBELIAN(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addTagihanPembelian, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Pembelian', 'Create Tagihan Pembelian');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* UPDATE_TAGIHAN_PEMBELIAN(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateTagihanPembelian, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Pembelian', 'Edit Tagihan Pembelian');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    console.log(response);
    yield put({ type: SET_ERROR_FORM, error });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DELETE_TAGIHAN_PEMBELIAN(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteTagihanPembelian, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Pembelian', 'Delete Tagihan Pembelian');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DOWNLOAD_TAGIHAN(action) {
  try {
    const { data, headers } = yield call(
      downloadTagihanPembelian,
      action.param
    );
    const filename = yield logMatches(headers['content-disposition']);
    yield handleDownload(data, filename[0]);
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export default all([
  takeLatest(GET_DATA_TAGIHAN_PEMBELIAN, GET_TAGIHAN_PEMBELIAN),
  takeLatest(GET_DATA_TAGIHAN_PEMBELIAN_BY_ID, GET_TAGIHANN_PEMBELIAN_BY_ID),
  takeLatest(ADD_DATA_TAGIHAN_PEMBELIAN, ADD_TAGIHAN_PEMBELIAN),
  takeLatest(UPDATE_DATA_TAGIHAN_PEMBELIAN, UPDATE_TAGIHAN_PEMBELIAN),
  takeLatest(DELETE_DATA_TAGIHAN_PEMBELIAN, DELETE_TAGIHAN_PEMBELIAN),
  takeLatest(DOWNLOAD_TAGIHAN_PEMBELIAN, DOWNLOAD_TAGIHAN)
]);
