import service from './service';
import endpoint from './constantUrl';

export const addReturPembelian = (data) => {
  return service.post(`${endpoint.POS_URL}/purchase/retur/`, data);
};
export const getReturPembelian = (param) => {
  return service.post(`${endpoint.POS_URL}/purchase/retur/filter/`, param);
};
export const getReturPembelianById = (id) => {
  return service.get(`${endpoint.POS_URL}/purchase/retur/${id}/`);
};

export const updateDataReturPembelian = (data, id) => {
  return service.put(`${endpoint.POS_URL}/purchase/retur/${id}/`, data);
};

export const deleteReturPembelian = (id) => {
  return service.delete(`${endpoint.POS_URL}/purchase/retur/${id}/`);
};

export const downloadReturnPembelian = (param) => {
  return service.postArrayBuffer(
    `${endpoint.POS_URL}/purchase/retur/export-excel/`,
    param
  );
};
