import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeDashboardCommercial = [
  {
    path: '/commercial-dashboard',
    isParent: true,
    action: 'view',
    parent: '/commercial-dashboard',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "dashboard commercial" */ '../Pages/DashboardCommercial/'
        )
      )
    )
  },
  {
    path: '/commercial-dashboard/:id',
    action: 'view',
    parent: '/commercial-dashboard',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "dashboard commercial" */ '../Pages/DashboardCommercial/'
        )
      )
    )
  }
];
