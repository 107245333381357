import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'prospectWarehouse/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'prospectWarehouse/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_PROSPECT_WAREHOUSE = 'GET_DATA_PROSPECT_WAREHOUSE';
export const getDataProspectWarehouse = defaultActionCreator(
  GET_DATA_PROSPECT_WAREHOUSE,
  'param'
);

export const GET_DATA_PROSPECT_WAREHOUSE_BY_ID =
  'GET_DATA_PROSPECT_WAREHOUSE_BY_ID';
export const getDataProspectWarehouseById = defaultActionCreator(
  GET_DATA_PROSPECT_WAREHOUSE_BY_ID,
  'id'
);

export const ADD_DATA_PROSPECT_WAREHOUSE = 'ADD_DATA_PROSPECT_WAREHOUSE';
export const addDataProspectWarehouse = defaultActionCreator(
  ADD_DATA_PROSPECT_WAREHOUSE,
  'data'
);

export const UPDATE_DATA_PROSPECT_WAREHOUSE = 'UPDATE_DATA_PROSPECT_WAREHOUSE';
export const updateDataProspectWarehouse = defaultActionCreator(
  UPDATE_DATA_PROSPECT_WAREHOUSE,
  'id',
  'data'
);

export const DELETE_DATA_PROSPECT_WAREHOUSE = 'DELETE_DATA_PROSPECT_WAREHOUSE';
export const deleteDataProspectWarehouse = defaultActionCreator(
  DELETE_DATA_PROSPECT_WAREHOUSE,
  'id'
);

export const DOWNLOAD_DATA_PROSPECT_WAREHOUSE =
  'DOWNLOAD_DATA_PROSPECT_WAREHOUSE';
export const downloadDataProspectWarehouse = defaultActionCreator(
  DOWNLOAD_DATA_PROSPECT_WAREHOUSE,
  'param'
);
