import service from './service';
import endpoint from './constantUrl';

// Inventory Type
export const createInventoryChanges = (data) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-batch/change-inventory/`,
    data
  );
};

export const getInventoryChanges = (param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-batch/change-inventory-list/`,
    param
  );
};

export const getInventoryChangesDetail = (id62) => {
  return service.get(
    `${endpoint.POS_URL}/inventory/stock-batch/${id62}/change-inventory/`
  );
};

export const deleteInventoryChanges = (id62) => {
  return service.delete(
    `${endpoint.POS_URL}/inventory/stock-batch/${id62}/change-inventory-delete/`
  );
};

export const downloadExcelInventoryChanges = (data) => {
  return service.postArrayBuffer(
    `${endpoint.POS_URL}/inventory/stock-batch/change-inventory-excel/`,
    data
  );
};
