import service from './service';
import endpoint from './constantUrl';

// NetworkType
export const addNetworkType = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/network/network_type/`, data);
};

export const getNetworkType = (param) => {
  const { search, limit, offset } = param;
  return service.get(
    `${endpoint.MASTERDATA_URL}/network/network_type/?search=${search}&limit=${limit}&offset=${offset}`
  );
};

export const getNetworkTypeById = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/network/network_type/${id}/`);
};

export const updateNetworkType = (data, id) => {
  return service.put(
    `${endpoint.MASTERDATA_URL}/network/network_type/${id}/`,
    data
  );
};

export const deleteNetworkType = (id) => {
  return service.delete(
    `${endpoint.MASTERDATA_URL}/network/network_type/${id}/`
  );
};
