import { all, put, takeLatest, call } from 'redux-saga/effects';
import {
  getProspectWarehouse,
  getProspectWarehouseDetail,
  createProspectWarehouse,
  updateProspectWarehouse,
  deleteProspectWarehouse,
  downloadExcelProspectWarehouse
} from '../../../services/prospectWarehouse';
import { handleDownload, logMatches } from '../../../utils';
import {
  SET_STATE,
  GET_DATA_PROSPECT_WAREHOUSE,
  GET_DATA_PROSPECT_WAREHOUSE_BY_ID,
  ADD_DATA_PROSPECT_WAREHOUSE,
  UPDATE_DATA_PROSPECT_WAREHOUSE,
  DELETE_DATA_PROSPECT_WAREHOUSE,
  SET_ERROR_FORM,
  DOWNLOAD_DATA_PROSPECT_WAREHOUSE
} from './actions';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_PROSPECT_WAREHOUSE(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getProspectWarehouse, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_PROSPECT_WAREHOUSE_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getProspectWarehouseDetail, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_PROSPECT_WAREHOUSE(action) {
  yield put({ type: SET_STATE, payload: { loadingForm: true } });
  try {
    yield call(createProspectWarehouse, action.data);
    yield setIpUser();
    const body = bodyLog(
      'create',
      'Prospect Warehouse',
      'Create Prospect Warehouse'
    );
    let message = 'Your Data Has Been Submitted';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  }
}

export function* UPDATE_PROSPECT_WAREHOUSE(action) {
  yield put({ type: SET_STATE, payload: { loadingForm: true } });
  try {
    yield call(updateProspectWarehouse, action.id, action.data);
    yield setIpUser();
    const body = bodyLog(
      'edit',
      'Prospect Warehouse',
      'Edit Prospect Warehouse'
    );
    let message = 'Your Data Has Been Submitted';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  }
}

export function* DELETE_PROSPECT_WAREHOUSE(action) {
  yield put({ type: SET_STATE, payload: { loadingDelete: true } });
  try {
    yield call(deleteProspectWarehouse, action.id);
    yield setIpUser();
    const body = bodyLog(
      'delete',
      'Prospect Warehouse',
      'Delete Prospect Warehouse'
    );
    let message = 'Successfully deleting the Data';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isDelete: true, message } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
    yield put({
      type: SET_STATE,
      payload: { loadingDelete: false }
    });
  }
}

export function* DOWNLOAD_PROSPECT_WAREHOUSE(action) {
  yield put({ type: SET_STATE, payload: { loadingDownload: true } });
  try {
    const { data, headers } = yield call(
      downloadExcelProspectWarehouse,
      action.param
    );
    const filename = yield logMatches(headers['content-disposition']);
    yield handleDownload(data, filename[0]);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDownload: false } });
  }
}

export default all([
  takeLatest(GET_DATA_PROSPECT_WAREHOUSE, GET_PROSPECT_WAREHOUSE),
  takeLatest(GET_DATA_PROSPECT_WAREHOUSE_BY_ID, GET_PROSPECT_WAREHOUSE_BY_ID),
  takeLatest(ADD_DATA_PROSPECT_WAREHOUSE, ADD_PROSPECT_WAREHOUSE),
  takeLatest(UPDATE_DATA_PROSPECT_WAREHOUSE, UPDATE_PROSPECT_WAREHOUSE),
  takeLatest(DELETE_DATA_PROSPECT_WAREHOUSE, DELETE_PROSPECT_WAREHOUSE),
  takeLatest(DOWNLOAD_DATA_PROSPECT_WAREHOUSE, DOWNLOAD_PROSPECT_WAREHOUSE)
]);
