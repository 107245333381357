import service from './service';
import endpoint from './constantUrl';

export const createProspectWarehouse = (data) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/warehouse/prospect-warehouse/`,
    data
  );
};

export const getProspectWarehouse = (param) => {
  return service.get(
    `${endpoint.MASTERDATA_URL}/warehouse/prospect-warehouse/`,
    param
  );
};

export const getProspectWarehouseDetail = (id62) => {
  return service.get(
    `${endpoint.MASTERDATA_URL}/warehouse/prospect-warehouse/${id62}/`
  );
};

export const deleteProspectWarehouse = (id62) => {
  return service.delete(
    `${endpoint.MASTERDATA_URL}/warehouse/prospect-warehouse/${id62}/`
  );
};
export const updateProspectWarehouse = (id62, data) => {
  return service.put(
    `${endpoint.MASTERDATA_URL}/warehouse/prospect-warehouse/${id62}/`,
    data
  );
};

export const downloadExcelProspectWarehouse = (data) => {
  return service.postArrayBuffer(
    `${endpoint.MASTERDATA_URL}/warehouse/prospect-warehouse/export-excel/`,
    data
  );
};
