import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeHsCode = [
  {
    path: '/hs-code',
    parent: '/hs-code',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(/* webpackChunkName: "HsCode" */ '../Pages/MasterData/HsCode')
      )
    )
  },
  {
    path: '/hs-code/add',
    parent: '/hs-code',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "AddDataHsCode" */ '../Pages/MasterData/HsCode/add'
        )
      )
    )
  },
  {
    path: '/hs-code/detail/:id',
    parent: '/hs-code',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "DetailDataHsCode" */ '../Pages/MasterData/HsCode/add'
        )
      )
    )
  },
  {
    path: '/hs-code/edit/:idEdit',
    parent: '/hs-code',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "EditDataHsCode" */ '../Pages/MasterData/HsCode/add'
        )
      )
    )
  }
];
