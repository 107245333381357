import service from './service';
import endpoint from './constantUrl';

export const getDataPenyewa = (param) => {
  return service.get(
    `${endpoint.MASTERDATA_URL}/user/user/?user_type=penyewa-gudang`,
    param
  );
};

export const getDataPenyewaById = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/user/user/${id}/`);
};

export const getPenyewaByIdGudang = (id) => {
  return service.get(
    `${endpoint.MASTERDATA_URL}/warehouse/warehouse/${id}/tenant/`
  );
};

export const downloadCsvPenyewa = () => {
  return service.post(`${endpoint.MASTERDATA_URL}/user/user/csv-template/
  `);
};
