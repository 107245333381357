import service from './service';
import endpoint from './constantUrl';

//fishfin v2
//fishfin changes
export const createFishfinChanges = (data) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-inventory/change-fishfin/v2/`,
    data
  );
};

export const getFishfinChanges = (param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-inventory/change-fishfin-list/`,
    param
  );
};

export const getFishfinChangesDetail = (id62) => {
  return service.get(
    `${endpoint.POS_URL}/inventory/stock-inventory/${id62}/change-fishfin/`
  );
};

export const deleteFishfinChanges = (id62) => {
  return service.delete(
    `${endpoint.POS_URL}/inventory/stock-inventory/${id62}/change-fishfin-delete/`
  );
};

export const downloadExcelFishfinChanges = (data) => {
  return service.postArrayBuffer(
    `${endpoint.POS_URL}/inventory/stock-inventory/change-fishfin-excel/`,
    data
  );
};

//fishfin return
export const createFishfinReturn = (data) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-inventory/change-fishfin-return/v2/`,
    data
  );
};

export const getFishfinReturn = (param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-inventory/change-fishfin-return-list/`,
    param
  );
};

export const getFishfinReturnDetail = (id62) => {
  return service.get(
    `${endpoint.POS_URL}/inventory/stock-inventory/${id62}/change-fishfin-return/`
  );
};

export const deleteFishfinReturn = (id62) => {
  return service.delete(
    `${endpoint.POS_URL}/inventory/stock-inventory/${id62}/change-fishfin-return-delete/`
  );
};

export const downloadExcelFishfinReturn = (data) => {
  return service.postArrayBuffer(
    `${endpoint.POS_URL}/inventory/stock-inventory/change-fishfin-return-excel/`,
    data
  );
};
