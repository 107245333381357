import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeCompany = [
  {
    path: '/contact',
    parent: '/contact',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ManageContact" */ '../Pages/MasterData/Perusahaan/Perusahaan'
        )
      )
    )
  },
  {
    path: '/contact/add',
    parent: '/contact',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ManageContactAdd" */ '../Pages/MasterData/Perusahaan/AddData'
        )
      )
    )
  },
  {
    path: '/contact/view/:id',
    parent: '/contact',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ManageContactDetail" */ '../Pages/MasterData/Perusahaan/ViewData'
        )
      )
    )
  },
  {
    path: '/contact/update/:idEdit',
    parent: '/contact',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ManageContactUpdate" */ '../Pages/MasterData/Perusahaan/AddData'
        )
      )
    )
  },
  {
    path: '/business-type',
    parent: '/business-type',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "JenisUsaha" */ '../Pages/MasterData/JenisUsaha'
        )
      )
    )
  },
  {
    path: '/business-type/:id',
    parent: '/business-type',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "JenisUsahaView" */ '../Pages/MasterData/JenisUsaha/View'
        )
      )
    )
  },
  {
    path: '/business-type/add',
    parent: '/business-type',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "JenisUsahaAdd" */ '../Pages/MasterData/JenisUsaha/Add'
        )
      )
    )
  },
  {
    path: '/business-type/update/:id',
    parent: '/business-type',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "JenisUsahaUpdate" */ '../Pages/MasterData/JenisUsaha/Update'
        )
      )
    )
  }
];
