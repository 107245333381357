import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeStockReservation = [
  {
    path: '/stock-reservation',
    parent: '/stock-reservation',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockReservation" */ '../Pages/StockReservation'
        )
      )
    )
  },
  {
    path: '/stock-reservation/:id',
    parent: '/stock-reservation',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockReservationDetail" */ '../Pages/StockReservation/detail'
        )
      )
    )
  }
];
