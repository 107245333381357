import { all, put, takeLatest, call } from 'redux-saga/effects';
import {
  SET_STATE,
  GET_DATA_TRANSAKSI_PENJUALAN,
  SET_ERROR_FORM,
  ADD_DATA_TRANSAKSI_PENJUALAN,
  DELETE_DATA_TRANSAKSI_PENJUALAN,
  GET_DATA_TRANSAKSI_PENJUALAN_BY_ID,
  DOWNLOAD_DATA_TRANSAKSI_PENJUALAN,
  DOWNLOAD_TEMPLATE_TRANSAKSI_PENJUALAN,
  UPLOAD_DATA_TRANSAKSI_PENJUALAN,
  UPDATE_DATA_TRANSAKSI_PENJUALAN,
  UPDATE_DATA_TRANSAKSI_PENJUALAN_CONFIRMED,
  DOWNLOAD_DATA_PENJUALAN_EXCEL
} from './action';
import {
  getDataTransaksiPenjualan,
  postDataTransaksiPenjualan,
  putDataTransaksiPenjualan,
  deleteDataTransaksiPenjualan,
  getDetailDataPenjualan,
  getTemplateTransaksiPenjualan,
  downloadDataTransaksiPenjualan,
  uploadDataTransaksiPenjualan,
  approveSalesTransaction,
  putDataTransaksiPenjualanConfirmed,
  exportPenjualanExcel
} from '../../../services/penjualan';
import { handleDownload } from '../../../utils';

import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_TRANSAKSI_PENJUALAN(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getDataTransaksiPenjualan, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_TRANSAKSI_PENJUALAN(action) {
  yield put({ type: SET_STATE, payload: { loadingDraft: true } });
  try {
    yield call(postDataTransaksiPenjualan, action.data);
    yield setIpUser();
    const body = bodyLog(action.log.log_type, 'Penjualan', action.log.desc);
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDraft: false } });
  }
}

export function* APPROVE_SALES(action) {
  yield put({ type: SET_STATE, payload: { loadingApprove: true } });
  try {
    yield call(approveSalesTransaction, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('create', 'Penjualan', 'Create Sales Transaction');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingApprove: false } });
  }
}
export function* DELETE_TRANSAKSI_PENJUALAN(action) {
  yield put({ type: SET_STATE, payload: { loadingDelete: true } });
  try {
    yield call(deleteDataTransaksiPenjualan, action.id);
    yield setIpUser();
    const body = bodyLog(
      'delete',
      'Penjualan',
      'Delete Draft Sales Transaction'
    );
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDelete: false } });
  }
}

export function* GET_TRANSAKSI_PENJUALAN_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getDetailDataPenjualan, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* UPDATE_TRANSAKSI_PENJUALAN(action) {
  yield put({ type: SET_STATE, payload: { loadingDraft: true } });
  try {
    yield call(putDataTransaksiPenjualan, action.data, action.id);
    yield setIpUser();
    const body = bodyLog(action.log.log_type, 'Penjualan', action.log.desc);
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDraft: false } });
  }
}
export function* UPDATE_TRANSAKSI_PENJUALAN_CONFIRMED(action) {
  yield put({ type: SET_STATE, payload: { loadingDraft: true } });
  try {
    yield call(putDataTransaksiPenjualanConfirmed, action.data, action.id);
    yield setIpUser();
    const body = bodyLog(action.log.log_type, 'Penjualan', action.log.desc);
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDraft: false } });
  }
}

export function* DOWNLOAD_TEMPLATE_TRANSAKSI_PENJUALAN_F() {
  // yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getTemplateTransaksiPenjualan);
    yield handleDownload(data, 'transaksi_penjualan_template.csv');
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* DOWNLOAD_TRANSAKSI_PENJUALAN(action) {
  try {
    // const response = yield call(downloadDataTransaksiPenjualan, action.param);
    // const filename = yield logMatches(response.headers['content-disposition']);
    // yield handleDownload(response.data, filename[0]);
    const { data } = yield call(downloadDataTransaksiPenjualan, action.param);
    yield handleDownload(data, 'transaksi_penjualan.csv');
  } catch ({ message: error }) {
    console.log(error);
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* UPLOAD_TRANSAKSI_PENJUALAN(action) {
  yield put({ type: SET_STATE, payload: { loadingUpload: true } });
  try {
    yield call(uploadDataTransaksiPenjualan, action.data);
    yield put({
      type: SET_STATE,
      payload: { isUpload: true, isUpdate: true }
    });
  } catch ({ response }) {
    yield put({
      type: SET_STATE,
      payload: { errorUpload: response?.data, isUpload: false }
    });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loadingUpload: false }
    });
  }
}

export function* DOWNLOAD_PENJUALAN_EXCEL(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(exportPenjualanExcel, action.param);
    // const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, 'sales transaction.xlsx');
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(ADD_DATA_TRANSAKSI_PENJUALAN, ADD_TRANSAKSI_PENJUALAN),
  takeLatest(GET_DATA_TRANSAKSI_PENJUALAN, GET_TRANSAKSI_PENJUALAN),
  takeLatest(DELETE_DATA_TRANSAKSI_PENJUALAN, DELETE_TRANSAKSI_PENJUALAN),
  takeLatest(GET_DATA_TRANSAKSI_PENJUALAN_BY_ID, GET_TRANSAKSI_PENJUALAN_BY_ID),
  takeLatest(DOWNLOAD_DATA_TRANSAKSI_PENJUALAN, DOWNLOAD_TRANSAKSI_PENJUALAN),
  takeLatest(UPDATE_DATA_TRANSAKSI_PENJUALAN, UPDATE_TRANSAKSI_PENJUALAN),
  takeLatest(
    UPDATE_DATA_TRANSAKSI_PENJUALAN_CONFIRMED,
    UPDATE_TRANSAKSI_PENJUALAN_CONFIRMED
  ),
  takeLatest(
    DOWNLOAD_TEMPLATE_TRANSAKSI_PENJUALAN,
    DOWNLOAD_TEMPLATE_TRANSAKSI_PENJUALAN_F
  ),
  takeLatest(UPLOAD_DATA_TRANSAKSI_PENJUALAN, UPLOAD_TRANSAKSI_PENJUALAN),
  takeLatest(DOWNLOAD_DATA_PENJUALAN_EXCEL, DOWNLOAD_PENJUALAN_EXCEL)
]);
