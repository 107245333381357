import { SET_ERROR_FORM, SET_STATE } from './actions';

const initialState = {
  loading: false,
  error: null,
  filter: {
    product_id62: null,
    is_active: null,
    offset: 0,
    search: '',
    limit: 10
  },
  form: {},
  formValidation: {}
};

export default function assessmentReducer(state = initialState, action) {
  switch (action.type) {
    case SET_STATE:
      return { ...state, ...action.payload };
    case SET_ERROR_FORM:
      return { ...state, formValidation: { ...action.error } };
    default:
      return state;
  }
}
