import service from './service';
import endpoint from './constantUrl';

// Container
export const addContainer = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/product/container/`, data);
};

export const getContainer = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/product/container/`, param);
};

export const getContainerById = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/product/container/${id}/`);
};

export const updateContainer = (data, id) => {
  return service.put(
    `${endpoint.MASTERDATA_URL}/product/container/${id}/`,
    data
  );
};

export const deleteContainer = (id) => {
  return service.delete(`${endpoint.MASTERDATA_URL}/product/container/${id}/`);
};
