import service from './service';
import endpoint from './constantUrl';

export const geteDataModule = (param) => {
  const { search, limit, offset } = param;
  return service.get(
    `${endpoint.ACCOUNT_URL}/module/module/?search=${search}&limit=${limit}&offset=${offset}`
  );
};

export const getDataModuleById = (id) => {
  return service.get(`${endpoint.ACCOUNT_URL}/module/module/${id}/`);
};

export const addDataModule = (data) => {
  return service.post(`${endpoint.ACCOUNT_URL}/module/module/`, data);
};

export const updateDataModule = (data, id) => {
  return service.put(`${endpoint.ACCOUNT_URL}/module/module/${id}/`, data);
};

export const deleteDataModule = (id) => {
  return service.delete(`${endpoint.ACCOUNT_URL}/module/module/${id}/`);
};
