import service from './service';
import endpoint from './constantUrl';

export const addContact = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/contact/`, data);
};

export const getContact = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/contact/`, param);
};

export const getContactById = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/contact/${id}/`);
};

export const updateContact = (data, id) => {
  return service.put(`${endpoint.MASTERDATA_URL}/contact/${id}/`, data);
};

export const deleteContact = (id) => {
  return service.delete(`${endpoint.MASTERDATA_URL}/contact/${id}/`);
};

export const updateContactStatus = (id) => {
  return service.post(`${endpoint.MASTERDATA_URL}/contact/${id}/activation/`);
};

export const downloadDataContact = (param) => {
  return service.postDownloadService(
    `${endpoint.DOWNLOAD}/contact/excel`,
    param
  );
};
