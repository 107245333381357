import { all, put, takeLatest, call } from 'redux-saga/effects';

import {
  GET_DATA_USER_ROLE,
  GETD_DATA_USER_BY_ID,
  ADD_DATA_USER,
  DELETE_DATA_USER,
  SET_STATE,
  SET_ERROR_FORM,
  GET_USER_TYPE_INTERNAL,
  UPDATE_DATA_USER,
  DOWNLOAD_DATA_USER_ROLE_EXCEL
} from './actions';
import {
  getDataUser,
  getDataUserById,
  addDataUser,
  deleteDataUser,
  updateDataUser,
  getUserInternal,
  exportUserRoleExcel
} from '../../../services/user';

import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog, logMatches, handleDownload } from '../../../utils';

export function* GET_USER(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataUser, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_USER_INTERNAL(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getUserInternal, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_USER_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataUserById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_USER(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(addDataUser, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'string', 'Create User Role');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { data, isAdd: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DELETE_USER(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteDataUser, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'string', 'Delete User Role');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* UPDATE_USER(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateDataUser, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'string', 'Edit User Role');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DOWNLOAD_USER_ROLE_EXCEL(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(exportUserRoleExcel, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_USER_ROLE, GET_USER),
  takeLatest(GET_USER_TYPE_INTERNAL, GET_USER_INTERNAL),
  takeLatest(GETD_DATA_USER_BY_ID, GET_USER_BY_ID),
  takeLatest(ADD_DATA_USER, ADD_USER),
  takeLatest(DELETE_DATA_USER, DELETE_USER),
  takeLatest(UPDATE_DATA_USER, UPDATE_USER),
  takeLatest(DOWNLOAD_DATA_USER_ROLE_EXCEL, DOWNLOAD_USER_ROLE_EXCEL)
]);
