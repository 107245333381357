import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'rolePernmission/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'rolePernmission/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_ROLE_PERMISSION = 'GET_DATA_ROLE_PERMISSION';
export const getDataRolePermission = defaultActionCreator(
  GET_DATA_ROLE_PERMISSION,
  'param'
);
export const GET_DATA_ROLE_PERMISSION_BY_ID = 'GET_DATA_ROLE_PERMISSION_BY_ID';
export const getDataRolePermissionById = defaultActionCreator(
  GET_DATA_ROLE_PERMISSION_BY_ID,
  'id'
);
export const GET_DATA_ROLE_PERMISSION_NEXT_OR_PREV =
  'GET_DATA_ROLE_PERMISSION_NEXT_OR_PREV';
export const getDataRolePermissionNextOrPrev = defaultActionCreator(
  GET_DATA_ROLE_PERMISSION_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_ROLE_PERMISSION = 'ADD_DATA_ROLE_PERMISSION';
export const addDataRolePermission = defaultActionCreator(
  ADD_DATA_ROLE_PERMISSION,
  'data'
);
