import service from './service';
import endpoint from './constantUrl';

// Product
export const addProduct = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/product/product/`, data);
};

export const getProduct = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/product/product/`, param);
};

export const getProductById = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/product/product/${id}/`);
};

export const updateProduct = (data, id) => {
  return service.put(`${endpoint.MASTERDATA_URL}/product/product/${id}/`, data);
};

export const deleteProduct = (id) => {
  return service.delete(`${endpoint.MASTERDATA_URL}/product/product/${id}/`);
};

export const exportProductExcel = (param) => {
  return service.postDownloadService(
    `${endpoint.DOWNLOAD}/product/excel`,
    param
  );
};

export const downloadTemplateProduct = () => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/product/product/csv-template/`
  );
};

export const downloadDataProduct = (param) => {
  return service.getArrayBuffer(
    `${endpoint.MASTERDATA_URL}/product/product/export-excel/`,
    param
  );
};

export const uploadProduct = (data) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/product/product/csv_import/`,
    data
  );
};

export const activationProduct = (id62) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/product/product/${id62}/product-activation/`
  );
};

export const getProductByIdGudang = (id) => {
  return service.get(
    `${endpoint.MASTERDATA_URL}/product/product/?warehouse_id62=${id}`
  );
};

export const getProductPurchaseSupplier = (param) => {
  return service.get(
    `${endpoint.MASTERDATA_URL}/product/purchase-supplier/`,
    param
  );
};

export const getProductPurchaseSupplierById = (id) => {
  return service.get(
    `${endpoint.MASTERDATA_URL}/product/purchase-supplier/${id}/`
  );
};

export const addProductPurchaseSupplier = (param) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/product/purchase-supplier/`,
    param
  );
};

export const updateProductPurchaseSupplier = (data, id) => {
  return service.put(
    `${endpoint.MASTERDATA_URL}/product/purchase-supplier/${id}/`,
    data
  );
};

export const deleteProductPurchaseSupplier = (id) => {
  return service.delete(
    `${endpoint.MASTERDATA_URL}/product/purchase-supplier/${id}/`
  );
};

export const getProductCertBySupplier = (data) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/product/product/cert-by-supplier/`,
    data
  );
};
