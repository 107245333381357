import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getTransfer,
  getTransferById,
  addTransfer,
  updateTransfer,
  deleteTransfer,
  approvalTransfer,
  downloadExcelTransfer,
  deliveryTransfer,
  updateTransferNotReceived,
  updateTransferReceived,
  getTransferReceived,
  downloadExcelTransferReceived
} from '../../../services/transferManagement';
import {
  SET_STATE,
  SET_ERROR_FORM,
  GET_DATA_TRANSFER_OUT,
  GET_DATA_TRANSFER_OUT_BY_ID,
  GET_DATA_TRANSFER_OUT_NEXT_OR_PREV,
  ADD_DATA_TRANSFER_OUT,
  UPDATE_DATA_TRANSFER_OUT,
  DELETE_DATA_TRANSFER_OUT,
  APPROVAL_DATA_TRANSFER_OUT,
  DOWNLOAD_DATA_TRANSFER_OUT,
  DELIVERY_DATA_TRANSFER_OUT,
  UPDATE_DATA_TRANSFER_NOT_RECEIVED,
  UPDATE_DATA_TRANSFER_RECEIVED,
  GET_DATA_TRANSFER_RECEIVED,
  DOWNLOAD_DATA_TRANSFER_RECEIVED
} from './actions';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog, handleDownload, logMatches } from '../../../utils';

export function* GET_TRANSFER_OUT(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getTransfer, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_TRANSFER_OUT_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { data: null, loading: true } });
  try {
    const { data } = yield call(getTransferById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_TRANSFER_OUT_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_TRANSFER_OUT(action) {
  yield put({ type: SET_STATE, payload: { loadingDraft: true } });
  try {
    yield call(addTransfer, action.data);
    yield setIpUser();
    const body = bodyLog(
      action.log.log_type,
      'Stock Transfer',
      action.log.desc
    );
    let message = 'Your Data Has Been Submitted';
    if (action?.data?.is_draft) {
      message = 'Successfully Save as Draft';
    }
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDraft: false } });
  }
}

export function* UPDATE_TRANSFER_OUT(action) {
  yield put({ type: SET_STATE, payload: { loadingDraft: true } });
  try {
    yield call(updateTransfer, action.data, action.id);
    yield setIpUser();
    const body = bodyLog(
      action.log.log_type,
      'Stock Transfer',
      action.log.desc
    );

    let message = 'Your Data Has Been Submitted';
    if (action?.data?.is_draft) {
      message = 'Successfully Save as Draft';
    }
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDraft: false } });
  }
}

export function* DELETE_TRANSFER_OUT(action) {
  yield put({ type: SET_STATE, payload: { loadingDelete: true } });
  try {
    yield call(deleteTransfer, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Stock Transfer', 'Delete Stock Transfer');
    let message = 'Successfully deleting the Draft';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isDelete: true, message } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loadingDelete: false }
    });
  }
}

export function* APPROVAL_TRANSFER_OUT(action) {
  yield put({ type: SET_STATE, payload: { loadingApproval: true } });
  try {
    yield call(approvalTransfer, action.id, action.data);
    yield setIpUser();
    const body = bodyLog('edit', 'Stock Transfer', 'Approval Stock Transfer');
    let message = 'Transaction Has Been Approved';
    if (!action?.data?.is_approved) {
      message = 'Transaction Has Been Rejected';
    }
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loadingApproval: false }
    });
  }
}
export function* DELIVERY_TRANSFER_OUT(action) {
  yield put({ type: SET_STATE, payload: { loadingDelivery: true } });
  try {
    yield call(deliveryTransfer, action.id, action.data);
    yield setIpUser();
    const body = bodyLog('edit', 'Stock Transfer', 'Delivery Stock Transfer');
    let message = 'Transactions On The Go';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loadingDelivery: false }
    });
  }
}

export function* DOWNLOAD_TRANSFER_OUT(action) {
  try {
    const response = yield call(downloadExcelTransfer, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    console.log(error);
    // yield put({ type: SET_STATE, payload: { error } });
  }
}
export function* DOWNLOAD_TRANSFER_RECEIVED(action) {
  try {
    const response = yield call(downloadExcelTransferReceived, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    console.log(error);
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* GET_TRANSFER_RECEIVED(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getTransferReceived, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* UPDATE_NOT_RECEIVED(action) {
  yield put({ type: SET_STATE, payload: { loadingNotRe: true } });
  try {
    yield call(updateTransferNotReceived, action.id, action.data);
    yield setIpUser();
    const body = bodyLog(
      'edit',
      'Stock Received',
      'Stock Received - Data Not Received'
    );
    yield call(createLog, body);
    yield put({
      type: SET_STATE,
      payload: { isUpdateNot: true, message: 'Transaction Not Receive' }
    });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingNotRe: false } });
  }
}

export function* UPDATE_RECEIVED(action) {
  yield put({ type: SET_STATE, payload: { loadingRe: true } });
  try {
    yield call(updateTransferReceived, action.id, action.data);
    yield setIpUser();
    const body = bodyLog(
      'edit',
      'Stock Received',
      'Stock Received - Data Received'
    );
    yield call(createLog, body);
    yield put({
      type: SET_STATE,
      payload: { isUpdate: true, message: 'Transaction Has Been Received' }
    });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingRe: false } });
  }
}

export default all([
  takeLatest(GET_DATA_TRANSFER_OUT, GET_TRANSFER_OUT),
  takeLatest(GET_DATA_TRANSFER_RECEIVED, GET_TRANSFER_RECEIVED),
  takeLatest(GET_DATA_TRANSFER_OUT_BY_ID, GET_TRANSFER_OUT_BY_ID),
  takeLatest(
    GET_DATA_TRANSFER_OUT_NEXT_OR_PREV,
    GET_DATA_TRANSFER_OUT_NEXT_OR_PREV
  ),
  takeLatest(ADD_DATA_TRANSFER_OUT, ADD_TRANSFER_OUT),
  takeLatest(DOWNLOAD_DATA_TRANSFER_OUT, DOWNLOAD_TRANSFER_OUT),
  takeLatest(DOWNLOAD_DATA_TRANSFER_RECEIVED, DOWNLOAD_TRANSFER_RECEIVED),
  takeLatest(UPDATE_DATA_TRANSFER_OUT, UPDATE_TRANSFER_OUT),
  takeLatest(DELETE_DATA_TRANSFER_OUT, DELETE_TRANSFER_OUT),
  takeLatest(APPROVAL_DATA_TRANSFER_OUT, APPROVAL_TRANSFER_OUT),
  takeLatest(DELIVERY_DATA_TRANSFER_OUT, DELIVERY_TRANSFER_OUT),
  takeLatest(UPDATE_DATA_TRANSFER_NOT_RECEIVED, UPDATE_NOT_RECEIVED),
  takeLatest(UPDATE_DATA_TRANSFER_RECEIVED, UPDATE_RECEIVED)
]);
