import { SET_ERROR_FORM, SET_STATE } from './action';

const initialState = {
  filter: {
    search: null,
    limit: 10,
    offset: 0,
    batch_number: null,
    created_at: null,
    sku_id62: null,
    warehouse_id62: null,
    inventory_type: null,
    movement_type: null
  },
  currentPage: 0,
  loading: false,
  error: null,
  form: {},
  formValidation: {}
};

export default function assessmentReducer(state = initialState, action) {
  switch (action.type) {
    case SET_STATE:
      return { ...state, ...action.payload };
    case SET_ERROR_FORM:
      return { ...state, formValidation: { ...action.error } };
    default:
      return state;
  }
}
