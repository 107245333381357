import service from './service';
import endpoint from './constantUrl';

//transaksi penjualan
export const getDataTransaksiPenjualan = (bodyParam) => {
  return service.post(
    `${endpoint.POS_URL}/sales/transaction/filter/`,
    bodyParam
  );
};

export const postDataTransaksiPenjualan = (body) => {
  return service.post(`${endpoint.POS_URL}/sales/transaction/`, body);
};

export const putDataTransaksiPenjualan = (body, id) => {
  return service.put(`${endpoint.POS_URL}/sales/transaction/${id}/`, body);
};
export const putDataTransaksiPenjualanConfirmed = (body, id) => {
  return service.put(
    `${endpoint.POS_URL}/sales/transaction/${id}/edit-confirmed/`,
    body
  );
};

export const deleteDataTransaksiPenjualan = (id62) => {
  return service.delete(`${endpoint.POS_URL}/sales/transaction/${id62}/`);
};

export const getDetailDataPenjualan = (id) => {
  return service.get(`${endpoint.POS_URL}/sales/transaction/${id}/`);
};

export const getTemplateTransaksiPenjualan = () => {
  return service.get(`${endpoint.POS_URL}/sales/transaction/csv-template/`);
};

export const downloadDataTransaksiPenjualan = (body) => {
  return service.postArrayBuffer(
    `${endpoint.POS_URL}/sales/transaction/export-csv/`,
    body
  );
};

export const uploadDataTransaksiPenjualan = (body) => {
  return service.post(
    `${endpoint.POS_URL}/sales/transaction/import-csv/`,
    body
  );
};

export const getProdukTransaksiPenjualan = (id) => {
  return service.get(`${endpoint.POS_URL}/sales/transaction/${id}/products/`);
};

export const approveSalesTransaction = (data, id) => {
  return service.put(
    `${endpoint.POS_URL}/sales/transaction/${id}/approve/`,
    data
  );
};

//tagihan penjualan
export const getDataTagihanPenjualan = (bodyParam) => {
  return service.post(`${endpoint.POS_URL}/sales/invoice/filter/`, bodyParam);
};

export const getDetailDataTagihanPenjualan = (id62) => {
  return service.get(`${endpoint.POS_URL}/sales/invoice/${id62}/`);
};

export const putDataTagihan = (id62, body) => {
  return service.put(`${endpoint.POS_URL}/sales/invoice/${id62}/`, body);
};

export const postDataTagihanPenjualan = (body) => {
  return service.post(`${endpoint.POS_URL}/sales/invoice/`, body);
};

export const deleteDataTagihanPenjualan = (id62) => {
  return service.delete(`${endpoint.POS_URL}/sales/invoice/${id62}/`);
};

//return penjualan
export const getDataReturPenjualan = (bodyParam) => {
  return service.post(`${endpoint.POS_URL}/sales/return/filter/`, bodyParam);
};

export const getDetailDataReturPenjualan = (id62) => {
  return service.get(`${endpoint.POS_URL}/sales/return/${id62}/`);
};

export const deleteDataReturPenjualan = (id62) => {
  return service.delete(`${endpoint.POS_URL}/sales/return/${id62}/`);
};

export const postDataReturPenjualan = (body) => {
  return service.post(`${endpoint.POS_URL}/sales/return/`, body);
};

export const putDataReturPenjualan = (body, id62) => {
  return service.put(`${endpoint.POS_URL}/sales/return/${id62}/`, body);
};

export const exportPenjualanExcel = (param) => {
  return service.postDownloadService(`${endpoint.DOWNLOAD}/sales/excel`, param);
};
