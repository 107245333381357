const PRODUCT_TYPE_OPTIONS = [
  { id: 1, name: 'Storable Product', value: 'storable' },
  { id: 2, name: 'Consumable', value: 'consumable', isDisabled: true }
];

const PRODUCT_NATURE_CHOICES = [
  { value: 'raw-materials', name: 'Raw Materials' },
  { value: 'finished-goods', name: 'Finished Goods' },
  { value: 'work-in-progress', name: 'Work in Progress' },
  { value: 'mro', name: 'Maintenance, Repair and Operations (MRO)' }
];

const COMODITY_TYPE_OPTIONS = [
  { id: 1, name: 'Seafood/Fishery', value: 'seafood_fishery' },
  { id: 2, name: 'Non Seafood/Fishery', value: 'non-seafood_fishery' }
];

const PREPARATION_OPTIONS = [
  { id: 1, name: 'Peeled', value: 'peeled' },
  { id: 2, name: 'Preparation', value: 'preparation' },
  { id: 3, name: 'Whole', value: 'whole' },
  { id: 4, name: 'Cubed', value: 'cubed' },
  { id: 5, name: 'Scale', value: 'scale' }
];

const TREATMENT_OPTIONS = [
  { id: 1, name: 'Pasteurized', value: 'pasteurized' },
  { id: 2, name: 'Carbon Monoxide (CO)', value: 'carbon-monoxide' },
  { id: 3, name: 'Frozen', value: 'frozen' },
  { id: 4, name: 'Treatment', value: 'treatment' },
  { id: 5, name: 'Temperature', value: 'temperature' }
];

const LOCATION_TYPE_OPTIONS = [
  {
    id: 1,
    name: 'Internal Location',
    value: 'internal-location',
    category: {
      name: 'Physical Locations',
      value: 'physical-locations'
    }
  },
  {
    id: 2,
    name: 'Transit Location',
    value: 'transit-location',
    category: {
      name: 'Virtual Locations',
      value: 'virtual-locations'
    }
  },
  {
    id: 3,
    name: 'Vendor Location',
    value: 'vendor-location',
    category: {
      name: 'Partner Locations',
      value: 'partner-locations'
    }
  },
  {
    id: 4,
    name: 'Customer Location',
    value: 'customer-location',
    category: {
      name: 'Partner Locations',
      value: 'partner-locations'
    }
  },
  {
    id: 5,
    name: 'Production',
    value: 'production',
    category: {
      name: 'Physical Locations',
      value: 'physical-locations'
    }
  },
  {
    id: 6,
    name: 'Inventory Lost',
    value: 'inventory-lost',
    category: {
      name: 'Virtual Locations',
      value: 'virtual-locations'
    }
  }
];

export default {
  menuSuperUser: process.env.REACT_APP_MODULE_SUPER_USER,
  whatsApp: process.env.REACT_APP_WHATSAPP_FISHLOG,
  unit_id62: process.env.REACT_APP_MODULE_UNIT_ID_DEFAULT,
  statusMenu: process.env.REACT_APP_STATUS_MENU,
  storage_url: process.env.REACT_APP_STORAGE_URL,
  key_map: process.env.REACT_APP_KEY_GOOGLEMAPS,
  PRODUCT_TYPE_OPTIONS,
  PRODUCT_NATURE_CHOICES,
  PREPARATION_OPTIONS,
  TREATMENT_OPTIONS,
  COMODITY_TYPE_OPTIONS,
  LOCATION_TYPE_OPTIONS,

  // const error
  invalidPassword: 'invalid password format',

  //const regex
  regexEmail: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  regexOnlyNumber: /^\d*$/,
  regexNumberDotDashes: /^[0-9.-]*$/,
  regexParenthesisNumberPlusMinusSpace: /^[()0-9+-\s]*$/,
  regexFormatMoney: /\B(?=(\d{3})+(?!\d))/g,
  regexFilename: /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
  regexDialCode:
    /(\+|00)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)\d{1,20}/
};
