import { lazy } from 'react';
import { retry } from '../utils/index';
export const routeGroup = [
  {
    path: '/group',
    parent: '/group',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(/* webpackChunkName: "Group" */ '../Pages/MasterData/Group')
      )
    )
  },
  {
    path: '/group/add',
    parent: '/group',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "GroupAdd" */ '../Pages/MasterData/Group/add'
        )
      )
    )
  },
  {
    path: '/group/detail/:id',
    parent: '/group',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "GroupDetail" */ '../Pages/MasterData/Group/add'
        )
      )
    )
  },
  {
    path: '/group/edit/:idEdit',
    parent: '/group',
    action: 'approve',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "GroupEdit" */ '../Pages/MasterData/Group/add'
        )
      )
    )
  }
];
