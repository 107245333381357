import { all, put, takeLatest, call } from 'redux-saga/effects';
import {
  getDataRolePermission,
  getDataRolePermissionById,
  addDataRolePermission
} from '../../../services/rolePermission';
import {
  SET_STATE,
  GET_DATA_ROLE_PERMISSION,
  GET_DATA_ROLE_PERMISSION_BY_ID,
  ADD_DATA_ROLE_PERMISSION,
  SET_ERROR_FORM
} from './actions';

export function* GET_ROLE_PERMISSION(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataRolePermission, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_ROLE_PERMISSION_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataRolePermissionById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_ROLE_PERMISSION(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addDataRolePermission, action.data);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_ROLE_PERMISSION, GET_ROLE_PERMISSION),
  takeLatest(GET_DATA_ROLE_PERMISSION_BY_ID, GET_ROLE_PERMISSION_BY_ID),
  takeLatest(ADD_DATA_ROLE_PERMISSION, ADD_ROLE_PERMISSION)
]);
