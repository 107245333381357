import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'stockBatch/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'stockBatch/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_STOCK_BATCH = 'GET_DATA_STOCK_BATCH';
export const getDataStockBatch = defaultActionCreator(
  GET_DATA_STOCK_BATCH,
  'param'
);
export const GET_DATA_STOCK_BATCH_SEARCH = 'GET_DATA_STOCK_BATCH_SEARCH';
export const getDataStockBatchSearch = defaultActionCreator(
  GET_DATA_STOCK_BATCH_SEARCH,
  'param'
);
export const GET_DATA_STOCK_BATCH_BY_ID = 'GET_DATA_STOCK_BATCH_BY_ID';
export const getDataStockBatchById = defaultActionCreator(
  GET_DATA_STOCK_BATCH_BY_ID,
  'id',
  'param'
);

export const GET_DATA_STOCK_BATCH_BY_BATCH_NUMBER =
  'GET_DATA_STOCK_BATCH_BY_BATCH_NUMBER';
export const getDataStockBatchByBatchNUmber = defaultActionCreator(
  GET_DATA_STOCK_BATCH_BY_BATCH_NUMBER,
  'id',
  'param'
);

export const GET_DATA_STOCK_DISTRIBUTION_BY_BATCH_NUMBER =
  'GET_DATA_STOCK_DISTRIBUTION_BY_BATCH_NUMBER';
export const getDataStockDistributionByBatchNUmber = defaultActionCreator(
  GET_DATA_STOCK_DISTRIBUTION_BY_BATCH_NUMBER,
  'id',
  'param'
);

export const STOCK_TRANSFER = 'STOCK_TRANSFER';
export const stockTransfer = defaultActionCreator(STOCK_TRANSFER, 'data');

export const STOCK_BATCH_B2B_SYNC = 'STOCK_BATCH_B2B_SYNC';
export const stockBatchB2BSync = defaultActionCreator(
  STOCK_BATCH_B2B_SYNC,
  'data'
);

export const GET_DATA_STOCK_BATCH_DISTRIBUTION =
  'GET_DATA_STOCK_BATCH_DISTRIBUTION';
export const getDataStockBatchDistribution = defaultActionCreator(
  GET_DATA_STOCK_BATCH_DISTRIBUTION,
  'param'
);

export const GET_DATA_STOCK_BATCH_DETIAL_DISTRIBUTION =
  'GET_DATA_STOCK_BATCH_DETIAL_DISTRIBUTION';
export const getDataStockBatchDetailDistribution = defaultActionCreator(
  GET_DATA_STOCK_BATCH_DETIAL_DISTRIBUTION,
  'param'
);

export const GET_DATA_STOCK_BATCH_PHOTOS = 'GET_DATA_STOCK_BATCH_PHOTOS';
export const getDataStockBatchPhotos = defaultActionCreator(
  GET_DATA_STOCK_BATCH_PHOTOS,
  'param'
);

export const ADD_DATA_STOCK_BATCH_PHOTOS = 'ADD_DATA_STOCK_BATCH_PHOTOS';
export const addDataStockBatchPhotos = defaultActionCreator(
  ADD_DATA_STOCK_BATCH_PHOTOS,
  'data'
);

export const DELETE_DATA_STOCK_BATCH_PHOTOS = 'DELETE_DATA_STOCK_BATCH_PHOTOS';
export const deleteDataStockBatchPhotos = defaultActionCreator(
  DELETE_DATA_STOCK_BATCH_PHOTOS,
  'id'
);
