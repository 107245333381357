import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getUnit,
  getUnitById,
  addUnit,
  updateUnit,
  deleteUnit
} from '../../../services/unit';
import {
  SET_STATE,
  GET_DATA_UNIT,
  GET_DATA_UNIT_BY_ID,
  ADD_DATA_UNIT,
  UPDATE_DATA_UNIT,
  DELETE_DATA_UNIT,
  SET_ERROR_FORM,
  GET_DATA_UNIT_NEXT_OR_PREV
} from './actions';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_UNIT(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getUnit, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_UNIT_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getUnitById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_UNIT_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* ADD_UNIT(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addUnit, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Unit', 'Create Unit');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* UPDATE_UNIT(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateUnit, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Unit', 'Edit Unit');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* DELETE_UNIT(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteUnit, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Unit', 'Delete Unit');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_UNIT, GET_UNIT),
  takeLatest(GET_DATA_UNIT_BY_ID, GET_UNIT_BY_ID),
  takeLatest(GET_DATA_UNIT_NEXT_OR_PREV, GET_UNIT_NEXT_OR_PREV),
  takeLatest(ADD_DATA_UNIT, ADD_UNIT),
  takeLatest(UPDATE_DATA_UNIT, UPDATE_UNIT),
  takeLatest(DELETE_DATA_UNIT, DELETE_UNIT)
]);
