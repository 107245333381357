import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'transfer/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'transfer/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_TRANSFER_OUT = 'GET_DATA_TRANSFER_OUT';
export const getDataTransferOut = defaultActionCreator(
  GET_DATA_TRANSFER_OUT,
  'param'
);

export const GET_DATA_TRANSFER_OUT_BY_ID = 'GET_DATA_TRANSFER_OUT_BY_ID';
export const getDataTransferOutById = defaultActionCreator(
  GET_DATA_TRANSFER_OUT_BY_ID,
  'id'
);

export const GET_DATA_TRANSFER_OUT_NEXT_OR_PREV =
  'GET_DATA_TRANSFER_OUT_NEXT_OR_PREV';
export const getDataTransferOutNextOrPrev = defaultActionCreator(
  GET_DATA_TRANSFER_OUT_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_TRANSFER_OUT = 'ADD_DATA_TRANSFER_OUT';
export const addDataTransferOut = defaultActionCreator(
  ADD_DATA_TRANSFER_OUT,
  'data',
  'log'
);

export const UPDATE_DATA_TRANSFER_OUT = 'UPDATE_DATA_TRANSFER_OUT';
export const updateDataTransferOut = defaultActionCreator(
  UPDATE_DATA_TRANSFER_OUT,
  'data',
  'id',
  'log'
);

export const DELETE_DATA_TRANSFER_OUT = 'DELETE_DATA_TRANSFER_OUT';
export const deleteDataTransferOut = defaultActionCreator(
  DELETE_DATA_TRANSFER_OUT,
  'id'
);

export const APPROVAL_DATA_TRANSFER_OUT = 'APPROVAL_DATA_TRANSFER_OUT';
export const approvalDataTransferOut = defaultActionCreator(
  APPROVAL_DATA_TRANSFER_OUT,
  'id',
  'data'
);
export const DELIVERY_DATA_TRANSFER_OUT = 'DELIVERY_DATA_TRANSFER_OUT';
export const deliveryDataTransferOut = defaultActionCreator(
  DELIVERY_DATA_TRANSFER_OUT,
  'id',
  'data'
);

export const DOWNLOAD_DATA_TRANSFER_OUT = 'DOWNLOAD_DATA_TRANSFER_OUT';
export const downloadDataTransferOut = defaultActionCreator(
  DOWNLOAD_DATA_TRANSFER_OUT,
  'param'
);

export const DOWNLOAD_DATA_TRANSFER_RECEIVED =
  'DOWNLOAD_DATA_TRANSFER_RECEIVED';
export const downloadDataTransferReceived = defaultActionCreator(
  DOWNLOAD_DATA_TRANSFER_RECEIVED,
  'param'
);

export const GET_DATA_TRANSFER_RECEIVED = 'GET_DATA_TRANSFER_RECEIVED';
export const getDataTransferReceived = defaultActionCreator(
  GET_DATA_TRANSFER_RECEIVED,
  'param'
);

export const UPDATE_DATA_TRANSFER_NOT_RECEIVED =
  'UPDATE_DATA_TRANSFER_NOT_RECEIVED';
export const updateDataTransferNotReceived = defaultActionCreator(
  UPDATE_DATA_TRANSFER_NOT_RECEIVED,
  'id',
  'data'
);
export const UPDATE_DATA_TRANSFER_RECEIVED = 'UPDATE_DATA_TRANSFER_RECEIVED';
export const updateDataTransferReceived = defaultActionCreator(
  UPDATE_DATA_TRANSFER_RECEIVED,
  'id',
  'data'
);
