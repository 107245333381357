import React from 'react';

import * as managers from './gtm';
const isProduction = process.env.NODE_ENV === 'production';

export function useTagManagers() {
  React.useEffect(() => {
    if (isProduction) {
      managers.init();
    }
  }, []);
}

export default useTagManagers;
