import { all, put, takeLatest, call } from 'redux-saga/effects';
import {
  getDataTagihanPenjualan,
  getDetailDataTagihanPenjualan,
  putDataTagihan,
  postDataTagihanPenjualan,
  deleteDataTagihanPenjualan
} from '../../../services/penjualan';
import {
  SET_ERROR_FORM,
  SET_STATE,
  GET_DATA_TAGIHAN_PENJUALAN,
  GET_DATA_TAGIHAN_PENJUALAN_BY_ID,
  ADD_DATA_TAGIHAN_PENJUALAN,
  UPDATE_DATA_TAGIHAN_PENJUALAN,
  DELETE_DATA_TAGIHAN_PENJUALAN
  // DOWNLOAD_TAGIHAN_PENJUALAN
} from './actions';
// import { handleDownload, logMatches } from '../../../utils';

import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_TAGIHAN_PENJUALAN(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataTagihanPenjualan, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_TAGIHANN_PENJUALAN_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDetailDataTagihanPenjualan, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_TAGIHAN_PENJUALAN(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(postDataTagihanPenjualan, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Penjualan', 'Create Tagihan Penjualan');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* UPDATE_TAGIHAN_PENJUALAN(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(putDataTagihan, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Penjualan', 'Edit Tagihan Penjualan');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DELETE_TAGIHAN_PENJUALAN(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteDataTagihanPenjualan, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Penjualan', 'Delete Tagihan Penjualan');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

// export function* DOWNLOAD_TAGIHAN(action) {
//   try {
//     const { data, headers } = yield call(
//       downloadTagihanPembelian,
//       action.param
//     );
//     const filename = yield logMatches(headers['content-disposition']);
//     yield handleDownload(data, filename[0]);
//   } catch ({ message: error }) {
//     // yield put({ type: SET_STATE, payload: { error } });
//   }
// }

export default all([
  takeLatest(GET_DATA_TAGIHAN_PENJUALAN, GET_TAGIHAN_PENJUALAN),
  takeLatest(GET_DATA_TAGIHAN_PENJUALAN_BY_ID, GET_TAGIHANN_PENJUALAN_BY_ID),
  takeLatest(ADD_DATA_TAGIHAN_PENJUALAN, ADD_TAGIHAN_PENJUALAN),
  takeLatest(UPDATE_DATA_TAGIHAN_PENJUALAN, UPDATE_TAGIHAN_PENJUALAN),
  takeLatest(DELETE_DATA_TAGIHAN_PENJUALAN, DELETE_TAGIHAN_PENJUALAN)
  // takeLatest(DOWNLOAD_TAGIHAN_PENJUALAN, DOWNLOAD_TAGIHAN)
]);
