import service from './service';
import endpoint from './constantUrl';

export const getStockReservation = (param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-reservation/filter/`,
    param
  );
};

export const getStockReservationById = (id) => {
  return service.get(`${endpoint.POS_URL}/inventory/stock-reservation/${id}/`);
};

export const exportStockReservationPDF = (param) => {
  return service.postDownloadService(
    `${endpoint.DOWNLOAD}/stock-reservation/pdf`,
    param
  );
};

export const exportStockReservationExcel = (param) => {
  return service.postDownloadService(
    `${endpoint.DOWNLOAD}/stock-reservation/excel`,
    param
  );
};
