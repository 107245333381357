import React from 'react';
import '../assets/styles/animate/animate.scss';

function LogoAnimate({ colorin = 'blue' }) {
  let color = '#2753A3';
  if (colorin == 'blue') {
    color = '#2753A3';
  } else if (colorin == 'white') {
    color = '#FFF';
  }
  return (
    <svg
      width="150"
      height="39"
      viewBox="0 0 150 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        style={{
          animationDelay: '0.3s'
        }}
        className="bounce-in-up"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.7491 30.6479C62.7427 30.6479 64.3166 32.2218 64.3166 34.1629C64.3166 36.1041 62.6902 37.6779 60.7491 37.6779C58.7555 37.6779 57.1817 36.1041 57.1817 34.1629C57.1292 32.2218 58.7555 30.6479 60.7491 30.6479ZM75.0714 28.8642C77.5372 28.8642 79.4783 30.8053 79.4783 33.2186C79.4783 35.6319 77.4847 37.573 75.0714 37.573C72.6581 37.573 70.6646 35.6319 70.6646 33.2186C70.6646 30.8053 72.6057 28.8642 75.0714 28.8642ZM80.2652 31.33H94.8498L96.8959 25.5066H86.7706V2.16072H80.2652V31.33ZM63.9493 30.4905V18.8438C63.9493 15.8535 65.5232 14.2796 67.989 14.2796C70.4547 14.2796 71.8712 15.801 71.8712 18.8438V28.2871C72.8155 27.71 73.9172 27.3428 75.0714 27.3428C76.2781 27.3428 77.3798 27.71 78.2716 28.2871V16.8503C78.2716 11.7089 75.4387 8.56116 70.5596 8.56116C67.3069 8.56116 65.4183 10.2924 63.8969 12.1286V0.901611H57.444V30.4905C58.2834 29.7561 59.4375 29.3364 60.6442 29.3364C61.9558 29.3364 63.0575 29.7561 63.9493 30.4905Z"
        fill="#EBB105"
      />
      <path
        style={{
          animationDelay: '0s'
        }}
        className="bounce-in-up"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.574097 31.3301H7.07947V20.0507H21.2444V14.1748H7.07947V7.98424H23.133V2.16089H0.574097V31.3301Z"
        fill={color}
      />
      <path
        style={{
          animationDelay: '0.1s'
        }}
        className="bounce-in-up"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4911 6.51512H33.2588V0.901611H26.4911V6.51512ZM26.7009 31.33H33.1014V8.98086H26.7009V31.33Z"
        fill={color}
      />
      <path
        style={{
          animationDelay: '0.2s'
        }}
        className="bounce-in-up"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.7968 31.7486C50.7283 31.7486 54.4531 29.4927 54.4531 24.5613V24.4563C54.4531 20.3642 50.7807 18.8953 47.5805 17.846C45.1148 17.0066 42.9638 16.4295 42.9638 15.1704V15.0655C42.9638 14.1736 43.7507 13.5441 45.3246 13.5441C46.951 13.5441 49.2068 14.331 51.4627 15.6426L53.9285 11.2882C51.4627 9.66186 48.3674 8.71753 45.482 8.71753C40.9177 8.71753 37.1929 11.2357 37.1929 15.8V15.9049C37.1929 20.2593 40.8128 21.6758 43.9606 22.6201C46.4788 23.3546 48.6822 23.8268 48.6822 25.1908V25.2957C48.6822 26.2925 47.8428 26.9745 45.9542 26.9745C43.8557 26.9745 41.2325 26.0827 38.8192 24.2989L36.0912 28.4435C39.0816 30.6994 42.649 31.7486 45.7968 31.7486Z"
        fill={color}
      />
      <path
        style={{
          animationDelay: '0.4s'
        }}
        className="bounce-in-up"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.799 31.8025C117.881 31.8025 123.128 26.6086 123.128 20.1557V20.0508C123.128 13.6504 117.934 8.50903 110.904 8.50903C103.821 8.50903 98.5751 13.7028 98.5751 20.1557V20.2607C98.5751 26.7136 103.769 31.8025 110.799 31.8025ZM110.851 26.3463C107.284 26.3463 104.871 23.4609 104.871 20.1557V20.0508C104.871 16.7457 107.074 13.9651 110.746 13.9651C114.314 13.9651 116.727 16.8506 116.727 20.1557V20.2607C116.78 23.5658 114.576 26.3463 110.851 26.3463Z"
        fill={color}
      />
      <path
        style={{
          animationDelay: '0.5s'
        }}
        className="bounce-in-up"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136.663 38.0983C141.07 38.0983 144.322 37.2064 146.421 35.1079C148.309 33.2192 149.306 30.3338 149.306 26.2942V8.98147H142.853V11.9194C141.122 10.0307 139.024 8.56177 135.561 8.56177C130.367 8.56177 125.488 12.3391 125.488 19.0018V19.1068C125.488 25.7171 130.262 29.5468 135.561 29.5468C138.971 29.5468 141.07 28.1828 142.958 25.9269V27.0286C142.958 31.0158 140.86 33.1143 136.505 33.1143C133.515 33.1143 131.154 32.3798 128.846 31.0683L126.642 35.8424C129.475 37.2588 132.938 38.0983 136.663 38.0983ZM137.397 24.2481C134.249 24.2481 131.889 22.1496 131.889 19.0543V18.9494C131.889 15.9065 134.249 13.7556 137.397 13.7556C140.545 13.7556 142.958 15.9065 142.958 18.9494V19.0543C142.958 22.0971 140.545 24.2481 137.397 24.2481Z"
        fill={color}
      />
    </svg>
  );
}

export default LogoAnimate;
