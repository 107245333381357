import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getStockInventory,
  getStockInventoryById,
  addStockInventory,
  updateStockInventory,
  deleteStockInventory,
  downloadTemplateStockInventory,
  downloadDataStockInventory,
  uploadStockInventory,
  getStockInventoryProduct,
  getStockInventoryHistories,
  getStockInventoryBatchs,
  getStockInventoryDistributions,
  changeInventory,
  getStockInventoryDistributionByHistory,
  exportStockInventoryExcel,
  getStockInventoryDistributionsV1
} from '../../../services/stockInventory';
import { handleDownload, logMatches } from '../../../utils';
import {
  SET_STATE,
  GET_DATA_STOCK_INVENTORY,
  GET_DATA_STOCK_INVENTORY_BY_ID,
  ADD_DATA_STOCK_INVENTORY,
  UPDATE_DATA_STOCK_INVENTORY,
  DELETE_DATA_STOCK_INVENTORY,
  GET_DATA_STOCK_INVENTORY_NEXT_OR_PREV,
  SET_ERROR_FORM,
  DOWNLOAD_DATA_STOCK_INVENTORY,
  DOWNLOAD_TEMPLATE_STOCK_INVENTORY,
  UPLOAD_DATA_STOCK_INVENTORY,
  GET_DATA_STOCK_INVENTORY_PRODUCT,
  GET_DATA_STOCK_INVENTORY_HISTORIES,
  GET_DATA_STOCK_INVENTORY_BATCHS,
  GET_DATA_STOCK_INVENTORY_DISTRIBUTIONS,
  CHANGE_INVENTORY_TYPE,
  GET_DATA_STOCK_INVENTORY_DISTRIBUTION_BY_HISTORY,
  DOWNLOAD_DATA_STOCK_INVENTORY_EXCEL,
  GET_DATA_STOCK_INVENTORY_DISTRIBUTIONS_V1
} from './actions';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_STOCK_INVENTORY(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getStockInventory, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_STOCK_INVENTORY_PRODUCT(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingProduct: true, product: null }
  });
  try {
    const { data } = yield call(getStockInventoryProduct, action.param);
    yield put({ type: SET_STATE, payload: { product: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingProduct: false } });
  }
}

export function* GET_STOCK_INVENTORY_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getStockInventoryById, action.id, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_STOCK_INVENTORY_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_STOCK_INVENTORY(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addStockInventory, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Stock Inventory', 'Create Stock Inventory');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* UPDATE_STOCK_INVENTORY(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateStockInventory, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Stock Inventory', 'Edit Stock Inventory');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DELETE_STOCK_INVENTORY(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteStockInventory, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Stock Inventory', 'Delete Stock Inventory');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loading: false }
    });
  }
}

export function* DOWNLOAD_TEMPLATE_STOCK_INVENTORY_F() {
  // yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(downloadTemplateStockInventory);
    yield handleDownload(data, 'stock_inventory_template.csv');
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* DOWNLOAD_STOCK_INVENTORY(action) {
  try {
    const { data, headers } = yield call(
      downloadDataStockInventory,
      action.param
    );

    const filename = yield logMatches(headers['content-disposition']);
    yield handleDownload(data, filename[0]);
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* UPLOAD_STOCK_INVENTORY(action) {
  yield put({ type: SET_STATE, payload: { loadingUpload: true } });
  try {
    yield call(uploadStockInventory, action.data);
    yield put({
      type: SET_STATE,
      payload: { isUpload: true, isUpdate: true }
    });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({
      type: SET_STATE,
      payload: { errorUpload: error, isUpload: false }
    });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loadingUpload: false }
    });
  }
}

export function* DOWNLOAD_STOCK_INVENTORY_EXCEL(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(exportStockInventoryExcel, action.param);
    // const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, 'stock inventory.xlsx');
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_STOCK_INVENTORY_HISTORIES(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingHistories: true, dataHistories: null }
  });
  try {
    const { data } = yield call(
      getStockInventoryHistories,
      action.id,
      action.param
    );
    yield put({ type: SET_STATE, payload: { dataHistories: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingHistories: false } });
  }
}

export function* GET_STOCK_INVENTORY_BATCHS(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingBatchs: true, dataBatchs: null }
  });
  try {
    const { data } = yield call(
      getStockInventoryBatchs,
      action.id,
      action.param
    );
    yield put({ type: SET_STATE, payload: { dataBatchs: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingBatchs: false } });
  }
}

export function* GET_STOCK_INVENTORY_DISTRIBUTIONS_V1(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingDistributions: true, dataDistributions: null }
  });
  try {
    const { data } = yield call(
      getStockInventoryDistributionsV1,
      action.id,
      action.param
    );
    yield put({ type: SET_STATE, payload: { dataDistributions: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDistributions: false } });
  }
}

export function* GET_STOCK_INVENTORY_DISTRIBUTIONS(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingDistributions: true, dataDistributions: null }
  });
  try {
    const { data } = yield call(
      getStockInventoryDistributions,
      action.id,
      action.param
    );
    yield put({ type: SET_STATE, payload: { dataDistributions: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDistributions: false } });
  }
}

export function* CHANGE_INVENTORY_TYPE_F(action) {
  yield put({ type: SET_STATE, payload: { loadingChg: true } });
  try {
    yield call(changeInventory, action.data);
    yield setIpUser();
    const body = bodyLog(
      'edit',
      'Stock Inventory',
      'Change Inventory Type : ' + JSON.stringify(action.data)
    );
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({
      type: SET_ERROR_FORM,
      error: error
    });
    yield put({ type: SET_STATE, payload: { loadingChg: false } });
  }
}

export function* GET_STOCK_INVENTORY_DISTRIBUTION_BY_HISTORY(action) {
  yield put({
    type: SET_STATE,
    payload: {
      loadingStockInventoryDistribution: true,
      dataStockInventoryDistribution: null
    }
  });
  try {
    const { data } = yield call(
      getStockInventoryDistributionByHistory,
      action.param
    );
    yield put({
      type: SET_STATE,
      payload: { dataStockInventoryDistribution: data }
    });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loadingStockInventoryDistribution: false }
    });
  }
}

export default all([
  takeLatest(GET_DATA_STOCK_INVENTORY, GET_STOCK_INVENTORY),
  takeLatest(GET_DATA_STOCK_INVENTORY_PRODUCT, GET_STOCK_INVENTORY_PRODUCT),
  takeLatest(GET_DATA_STOCK_INVENTORY_BY_ID, GET_STOCK_INVENTORY_BY_ID),
  takeLatest(
    GET_DATA_STOCK_INVENTORY_NEXT_OR_PREV,
    GET_STOCK_INVENTORY_NEXT_OR_PREV
  ),
  takeLatest(ADD_DATA_STOCK_INVENTORY, ADD_STOCK_INVENTORY),
  takeLatest(UPDATE_DATA_STOCK_INVENTORY, UPDATE_STOCK_INVENTORY),
  takeLatest(DELETE_DATA_STOCK_INVENTORY, DELETE_STOCK_INVENTORY),
  takeLatest(
    DOWNLOAD_TEMPLATE_STOCK_INVENTORY,
    DOWNLOAD_TEMPLATE_STOCK_INVENTORY_F
  ),
  takeLatest(DOWNLOAD_DATA_STOCK_INVENTORY, DOWNLOAD_STOCK_INVENTORY),
  takeLatest(UPLOAD_DATA_STOCK_INVENTORY, UPLOAD_STOCK_INVENTORY),
  takeLatest(GET_DATA_STOCK_INVENTORY_HISTORIES, GET_STOCK_INVENTORY_HISTORIES),
  takeLatest(GET_DATA_STOCK_INVENTORY_BATCHS, GET_STOCK_INVENTORY_BATCHS),
  takeLatest(
    GET_DATA_STOCK_INVENTORY_DISTRIBUTIONS,
    GET_STOCK_INVENTORY_DISTRIBUTIONS
  ),
  takeLatest(
    GET_DATA_STOCK_INVENTORY_DISTRIBUTIONS_V1,
    GET_STOCK_INVENTORY_DISTRIBUTIONS_V1
  ),
  takeLatest(CHANGE_INVENTORY_TYPE, CHANGE_INVENTORY_TYPE_F),
  takeLatest(
    GET_DATA_STOCK_INVENTORY_DISTRIBUTION_BY_HISTORY,
    GET_STOCK_INVENTORY_DISTRIBUTION_BY_HISTORY
  ),
  takeLatest(
    DOWNLOAD_DATA_STOCK_INVENTORY_EXCEL,
    DOWNLOAD_STOCK_INVENTORY_EXCEL
  )
]);
