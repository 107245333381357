import { all, put, takeLatest, call } from 'redux-saga/effects';
import {
  ADD_DATA_STORAGE_CATEGORY,
  DELETE_DATA_STORAGE_CATEGORY,
  EXPORT_DATA_EXCEL_STORAGE_CATEGORY,
  GET_DATA_STORAGE_CATEGORY,
  GET_DATA_STORAGE_CATEGORY_BY_ID62,
  SET_ERROR_FORM,
  SET_STATE,
  UPDATE_DATA_STORAGE_CATEGORY,
  UPDATE_STATUS_DATA_STORAGE_CATEGORY
} from './actions';
import {
  addStorageCategory,
  deleteStorageCategory,
  exportExcelStorageCategory,
  getStorageCategory,
  getStorageCategoryById62,
  updateStorageCategory,
  updateStorageCategoryStatus
} from '../../../services/storageCategory';
import { createLog } from '~/services/Activity';
import { bodyLog, handleDownload, logMatches, setIpUser } from '../../../utils';

export function* GET_STORAGE_CATEGORY(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getStorageCategory, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_STORAGE_CATEGORY_BY_ID62(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getStorageCategoryById62, action.id62);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_STORAGE_CATEGORY(action) {
  yield put({ type: SET_STATE, payload: { loadingForm: true } });
  try {
    yield call(addStorageCategory, action.data);
    yield setIpUser();
    const body = bodyLog(
      'create',
      'Storage Category',
      'Create Storage Category'
    );
    let message = 'Your Data Has Been Submitted';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  }
}

export function* UPDATE_STORAGE_CATEGORY(action) {
  yield put({ type: SET_STATE, payload: { loadingForm: true } });
  try {
    yield call(updateStorageCategory, action.data, action.id62);
    yield setIpUser();
    const body = bodyLog('edit', 'Storage Category', 'Edit Storage Category');
    let message = 'Your Data Has Been Submitted';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  }
}

export function* DELETE_STORAGE_CATEGORY(action) {
  yield put({ type: SET_STATE, payload: { loadingDelete: true } });
  try {
    yield call(deleteStorageCategory, action.id62);
    yield setIpUser();
    const body = bodyLog(
      'delete',
      'Storage Category',
      'Delete Storage Category'
    );
    let message = 'Successfully deleting the Data';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isDelete: true, message } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
    yield put({
      type: SET_STATE,
      payload: { loadingDelete: false }
    });
  }
}

export function* UPDATE_STATUS_STORAGE_CAPACITY(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateStorageCategoryStatus, action.id62);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* EXPORT_EXCEL_STORAGE_CATEGORY(action) {
  try {
    const response = yield call(exportExcelStorageCategory, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_STORAGE_CATEGORY, GET_STORAGE_CATEGORY),
  takeLatest(GET_DATA_STORAGE_CATEGORY_BY_ID62, GET_STORAGE_CATEGORY_BY_ID62),
  takeLatest(ADD_DATA_STORAGE_CATEGORY, ADD_STORAGE_CATEGORY),
  takeLatest(UPDATE_DATA_STORAGE_CATEGORY, UPDATE_STORAGE_CATEGORY),
  takeLatest(DELETE_DATA_STORAGE_CATEGORY, DELETE_STORAGE_CATEGORY),
  takeLatest(
    UPDATE_STATUS_DATA_STORAGE_CATEGORY,
    UPDATE_STATUS_STORAGE_CAPACITY
  ),
  takeLatest(EXPORT_DATA_EXCEL_STORAGE_CATEGORY, EXPORT_EXCEL_STORAGE_CATEGORY)
]);
