import service from './service';
import endpoint from './constantUrl';

// Auth
export const login = (data) => {
  return service.postWithoutHeader(
    `${endpoint.ACCOUNT_URL}/authentication/authenticate/login/`,
    data
  );
};

export const userInfo = (token) => {
  return service.getWToken(
    token,
    `${endpoint.ACCOUNT_URL}/authentication/authenticate/user-info/`
  );
};

export const userInfoDefaultToken = () => {
  return service.get(
    `${endpoint.ACCOUNT_URL}/authentication/authenticate/user-info/`
  );
};
