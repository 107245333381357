import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'NetworkType/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'NetworkType/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_NETWORK_TYPE = 'GET_DATA_NETWORK_TYPE';
export const getDataNetworkType = defaultActionCreator(
  GET_DATA_NETWORK_TYPE,
  'param'
);
export const GET_DATA_NETWORK_TYPE_BY_ID = 'GET_DATA_NETWORK_TYPE_BY_ID';
export const getDataNetworkTypeById = defaultActionCreator(
  GET_DATA_NETWORK_TYPE_BY_ID,
  'id'
);
export const GET_DATA_NETWORK_TYPE_NEXT_OR_PREV =
  'GET_DATA_NETWORK_TYPE_NEXT_OR_PREV';
export const getDataNetworkTypeNextOrPrev = defaultActionCreator(
  GET_DATA_NETWORK_TYPE_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_NETWORK_TYPE = 'ADD_DATA_NETWORK_TYPE';
export const addDataNetworkType = defaultActionCreator(
  ADD_DATA_NETWORK_TYPE,
  'data'
);
export const UPDATE_DATA_NETWORK_TYPE = 'UPDATE_DATA_NETWORK_TYPE';
export const updateDataNetworkType = defaultActionCreator(
  UPDATE_DATA_NETWORK_TYPE,
  'data',
  'id'
);

export const DELETE_DATA_NETWORK_TYPE = 'DELETE_DATA_NETWORK_TYPE';
export const deleteDataNetworkType = defaultActionCreator(
  DELETE_DATA_NETWORK_TYPE,
  'id'
);
