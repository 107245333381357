import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'stokOut/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'stokOut/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_STOK_OUT = 'GET_DATA_STOK_OUT';
export const getDataStokOut = defaultActionCreator(GET_DATA_STOK_OUT, 'param');

export const GENERATE_NO_STOK_OUT = 'GENERATE_NO_STOK_OUT';
export const generateNoStokOut = defaultActionCreator(
  GENERATE_NO_STOK_OUT,
  'data'
);

export const CREATE_DATA_STOCK_OUT = 'CREATE_DATA_STOCK_OUT';
export const createDataStockOut = defaultActionCreator(
  CREATE_DATA_STOCK_OUT,
  'data'
);

export const GET_STOCK_OUT_BY_ID = 'GET_STOCK_OUT_BY_ID';
export const getDataStockOutById = defaultActionCreator(
  GET_STOCK_OUT_BY_ID,
  'id'
);

export const DELETE_DATA_STOCK_OUT = 'DELETE_DATA_STOCK_OUT';
export const deleteDataStockOut = defaultActionCreator(
  DELETE_DATA_STOCK_OUT,
  'id'
);

export const UPDATE_STATUS_KALKULASI_OUT = 'UPDATE_STATUS_KALKULASI_OUT';
export const updateStatusKalkulasi = defaultActionCreator(
  UPDATE_STATUS_KALKULASI_OUT,
  'id',
  'data'
);

export const UPDATE_STOCK_OUT = 'UPDATE_STOCK_OUT';
export const updateStokOut = defaultActionCreator(
  UPDATE_STOCK_OUT,
  'id',
  'data'
);

export const UPLOAD_STOK_OUT = 'UPLOAD_STOK_OUT';
export const uploadDataStockIn = defaultActionCreator(UPLOAD_STOK_OUT, 'data');

export const DOWNLOAD_EXCEL_STOK_OUT = 'DOWNLOAD_EXCEL_STOK_OUT';
export const downloadExcelStokOut = defaultActionCreator(
  DOWNLOAD_EXCEL_STOK_OUT,
  'data'
);

export const TEMPLATE_CSV_STOK_OUT = 'TEMPLATE_CSV_STOK_OUT';
export const templateCsvStokOut = defaultActionCreator(
  TEMPLATE_CSV_STOK_OUT,
  'data'
);
