import service from './service';
import endpoint from './constantUrl';

export const getPackaging = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/product/packaging/`, param);
};
export const addPackaging = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/product/packaging/`, data);
};
export const updatePackaging = (data, id) => {
  return service.put(
    `${endpoint.MASTERDATA_URL}/product/packaging/${id}/`,
    data
  );
};
export const activationPackaging = (id62) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/product/packaging/${id62}/packaging-activation/`
  );
};

export const deletePackaging = (id) => {
  return service.delete(`${endpoint.MASTERDATA_URL}/product/packaging/${id}/`);
};
export const getPackagingByID = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/product/packaging/${id}/`);
};
