import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getProcess,
  getProcessById,
  addProcess,
  updateProcess,
  deleteProcess
} from '../../../services/process';
import {
  SET_STATE,
  SET_ERROR_FORM,
  GET_DATA_PROCESS,
  GET_DATA_PROCESS_BY_ID,
  GET_DATA_PROCESS_NEXT_OR_PREV,
  ADD_DATA_PROCESS,
  UPDATE_DATA_PROCESS,
  DELETE_DATA_PROCESS
} from './actions';

import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_PROCESS(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getProcess, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_PROCESS_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getProcessById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_PROCESS_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_PROCESS(action) {
  yield put({ type: SET_STATE, payload: { loadingForm: true } });
  try {
    yield call(addProcess, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Data Master', 'Create Process');
    yield call(createLog, body);
    let message = 'Your Data Has Been Submitted';
    yield put({ type: SET_STATE, payload: { isAdd: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  }
}

export function* UPDATE_PROCESS(action) {
  yield put({ type: SET_STATE, payload: { loadingForm: true } });
  try {
    yield call(updateProcess, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Data Master', 'Edit Process');
    yield call(createLog, body);
    let message = 'Your Data Has Been Submitted';
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  }
}

export function* DELETE_PROCESS(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteProcess, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Data Master', 'Delete Process');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loading: false }
    });
  }
}

export default all([
  takeLatest(GET_DATA_PROCESS, GET_PROCESS),
  takeLatest(GET_DATA_PROCESS_BY_ID, GET_PROCESS_BY_ID),
  takeLatest(GET_DATA_PROCESS_NEXT_OR_PREV, GET_PROCESS_NEXT_OR_PREV),
  takeLatest(ADD_DATA_PROCESS, ADD_PROCESS),
  takeLatest(UPDATE_DATA_PROCESS, UPDATE_PROCESS),
  takeLatest(DELETE_DATA_PROCESS, DELETE_PROCESS)
]);
