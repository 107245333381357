import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeKategori = [
  {
    path: '/category',
    parent: '/category',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Kategori" */ '../Pages/MasterData/Kategori/kategoriModule'
        )
      )
    )
  },
  {
    path: '/category/:id',
    parent: '/category',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "KategoriView" */ '../Pages/MasterData/Kategori/kategoriView'
        )
      )
    )
  },
  {
    path: '/category/update/:id',
    parent: '/category',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "KategoriUpdate" */ '../Pages/MasterData/Kategori/kategoriUpdate'
        )
      )
    )
  },
  {
    path: '/category/add',
    parent: '/category',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "KategoriUpdate" */ '../Pages/MasterData/Kategori/KategoriAdd'
        )
      )
    )
  }
];
