import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'receiptInbound/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'receiptInbound/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_RECEIPT_INBOUND = 'GET_DATA_RECEIPT_INBOUND';
export const getDataReceiptInbound = defaultActionCreator(
  GET_DATA_RECEIPT_INBOUND,
  'param'
);

export const GET_DATA_RECEIPT_INBOUND_BY_ID = 'GET_DATA_RECEIPT_INBOUND_BY_ID';
export const getDataReceiptInboundById = defaultActionCreator(
  GET_DATA_RECEIPT_INBOUND_BY_ID,
  'id'
);

export const GET_DATA_RECEIPT_INBOUND_NEXT_OR_PREV =
  'GET_DATA_RECEIPT_INBOUND_NEXT_OR_PREV';
export const getDataReceiptInboundNextOrPrev = defaultActionCreator(
  GET_DATA_RECEIPT_INBOUND_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_RECEIPT_INBOUND = 'ADD_DATA_RECEIPT_INBOUND';
export const addDataReceiptInbound = defaultActionCreator(
  ADD_DATA_RECEIPT_INBOUND,
  'data',
  'log'
);

export const UPDATE_DATA_RECEIPT_INBOUND = 'UPDATE_DATA_RECEIPT_INBOUND';
export const updateDataReceiptInbound = defaultActionCreator(
  UPDATE_DATA_RECEIPT_INBOUND,
  'data',
  'id',
  'log'
);

export const DOWNLOAD_DATA_RECEIPT_INBOUND_PDF =
  'DOWNLOAD_DATA_RECEIPT_INBOUND_PDF';
export const downloadDataReceiptInboundPDF = defaultActionCreator(
  DOWNLOAD_DATA_RECEIPT_INBOUND_PDF,
  'id'
);

export const DOWNLOAD_DATA_RECEIPT_INBOUND_EXCEL =
  'DOWNLOAD_DATA_RECEIPT_INBOUND_EXCEL';
export const downloadDataReceiptInboundExcel = defaultActionCreator(
  DOWNLOAD_DATA_RECEIPT_INBOUND_EXCEL,
  'param'
);
