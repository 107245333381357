import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeUnit = [
  {
    path: '/unit',
    parent: '/unit',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Unit" */ '../Pages/MasterData/Unit/unitModule'
        )
      )
    )
  },
  {
    path: '/unit/:id',
    parent: '/unit',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "UnitView" */ '../Pages/MasterData/Unit/UnitView'
        )
      )
    )
  },
  {
    path: '/unit/add',
    parent: '/unit',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "unitAdd " */ '../Pages/MasterData/Unit/unitAdd'
        )
      )
    )
  },
  {
    path: '/unit/update/:id',
    parent: '/unit',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "unitUpdate " */ '../Pages/MasterData/Unit/unitUpdate'
        )
      )
    )
  }
];
