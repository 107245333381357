import service from './service';
import endpoint from './constantUrl';

export const getInbound = (param) => {
  return service.post(`${endpoint.POS_URL}/inbound/inbound/filter/`, param);
};

export const addInbound = (data) => {
  return service.post(`${endpoint.POS_URL}/inbound/inbound/`, data);
};

export const getInboundById = (id) => {
  return service.get(`${endpoint.POS_URL}/inbound/inbound/${id}/`);
};

export const deleteInbound = (id) => {
  return service.delete(`${endpoint.POS_URL}/inbound/inbound/${id}/`);
};

export const updateInbound = (data, id) => {
  return service.put(`${endpoint.POS_URL}/inbound/inbound/${id}/`, data);
};

export const approvalInbound = (id, data) => {
  return service.put(
    `${endpoint.POS_URL}/inbound/inbound/${id}/approval/`,
    data
  );
};

export const downloadExcelInbound = (data) => {
  return service.postArrayBuffer(
    `${endpoint.POS_URL}/inbound/inbound/export-excel/`,
    data
  );
};

export const exportInboundExcel = (param) => {
  return service.postDownloadService(
    `${endpoint.DOWNLOAD}/inbound/excel`,
    param
  );
};
