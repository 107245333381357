import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'Network/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'Network/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_NETWORK = 'GET_DATA_NETWORK';
export const getDataNetwork = defaultActionCreator(GET_DATA_NETWORK, 'param');

export const GET_DATA_NETWORK_BY_ID = 'GET_DATA_NETWORK_BY_ID';
export const getDataNetworkById = defaultActionCreator(
  GET_DATA_NETWORK_BY_ID,
  'id'
);

export const GET_DATA_NETWORK_NEXT_OR_PREV = 'GET_DATA_NETWORK_NEXT_OR_PREV';
export const getDataNetworkNextOrPrev = defaultActionCreator(
  GET_DATA_NETWORK_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_NETWORK = 'ADD_DATA_NETWORK';
export const addDataNetwork = defaultActionCreator(ADD_DATA_NETWORK, 'data');

export const UPDATE_DATA_NETWORK = 'UPDATE_DATA_NETWORK';
export const updateDataNetwork = defaultActionCreator(
  UPDATE_DATA_NETWORK,
  'data',
  'id'
);

export const DELETE_DATA_NETWORK = 'DELETE_DATA_NETWORK';
export const deleteDataNetwork = defaultActionCreator(
  DELETE_DATA_NETWORK,
  'id'
);

export const ACTIVATION_DATA_NETWORK = 'ACTIVATION_DATA_NETWORK';
export const activationDataNetwork = defaultActionCreator(
  ACTIVATION_DATA_NETWORK,
  'id62'
);
