import service from './service';
import endpoint from './constantUrl';

// Category
export const addCategory = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/product/category/`, data);
};

export const getCategory = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/product/category/`, param);
};

export const getCategoryById = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/product/category/${id}/`);
};

export const updateCategory = (body, id) => {
  return service.put(
    `${endpoint.MASTERDATA_URL}/product/category/${id}/`,
    body
  );
};

export const deleteCategory = (id) => {
  return service.delete(`${endpoint.MASTERDATA_URL}/product/category/${id}/`);
};

export const activationCategory = (id62) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/product/category/${id62}/category-activation/`
  );
};

export const downloadTemplateCategory = () => {
  return service.get(
    `${endpoint.MASTERDATA_URL}/product/category/csv-template/`
  );
};

export const downloadDataCategory = (param) => {
  return service.getArrayBuffer(
    `${endpoint.MASTERDATA_URL}/product/category/export-excel/`,
    param
  );
};

export const uploadDataCategory = (data) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/product/category/csv-import/`,
    data
  );
};

export const exportCategoryExcel = (param) => {
  return service.postDownloadService(
    `${endpoint.DOWNLOAD}/category/excel`,
    param
  );
};
