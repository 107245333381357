import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'hsCode/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'hsCode/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_HS_CODE = 'GET_DATA_HS_CODE';
export const getDataHsCode = defaultActionCreator(GET_DATA_HS_CODE, 'param');

export const GET_DATA_HS_CODE_BY_ID62 = 'GET_DATA_HS_CODE_BY_ID62';
export const getDataHsCodeById62 = defaultActionCreator(
  GET_DATA_HS_CODE_BY_ID62,
  'id62'
);

export const ADD_DATA_HS_CODE = 'ADD_DATA_HS_CODE';
export const addDataHsCode = defaultActionCreator(ADD_DATA_HS_CODE, 'data');

export const UPDATE_DATA_HS_CODE = 'UPDATE_DATA_HS_CODE';
export const updateDataHsCode = defaultActionCreator(
  UPDATE_DATA_HS_CODE,
  'id62',
  'data'
);

export const DELETE_DATA_HS_CODE = 'DELETE_DATA_HS_CODE';
export const deleteDataHsCode = defaultActionCreator(
  DELETE_DATA_HS_CODE,
  'id62'
);

export const EXPORT_DATA_EXCEL_HS_CODE = 'EXPORT_DATA_EXCEL_HS_CODE';
export const exportDataExcelHsCode = defaultActionCreator(
  EXPORT_DATA_EXCEL_HS_CODE,
  'param'
);
