import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getNetworkType,
  getNetworkTypeById,
  addNetworkType,
  updateNetworkType,
  deleteNetworkType
} from '../../../services/networkType';
import {
  SET_STATE,
  GET_DATA_NETWORK_TYPE,
  GET_DATA_NETWORK_TYPE_BY_ID,
  ADD_DATA_NETWORK_TYPE,
  UPDATE_DATA_NETWORK_TYPE,
  DELETE_DATA_NETWORK_TYPE,
  GET_DATA_NETWORK_TYPE_NEXT_OR_PREV,
  SET_ERROR_FORM
} from './actions';

import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_NETWORK_TYPE(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getNetworkType, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_NETWORK_TYPE_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getNetworkTypeById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_NETWORK_TYPE_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_NETWORK_TYPE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addNetworkType, action.data);
    yield setIpUser();
    const body = bodyLog(
      'create',
      'Data Master',
      'Create Network Type Master Data'
    );
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* UPDATE_NETWORK_TYPE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateNetworkType, action.data, action.id);
    yield setIpUser();
    const body = bodyLog(
      'edit',
      'Data Master',
      'Edit Network Type Master Data'
    );
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DELETE_NETWORK_TYPE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteNetworkType, action.id);
    yield setIpUser();
    const body = bodyLog(
      'delete',
      'Data Master',
      'Delete Network Type Master Data'
    );
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loading: false }
    });
  }
}

export default all([
  takeLatest(GET_DATA_NETWORK_TYPE, GET_NETWORK_TYPE),
  takeLatest(GET_DATA_NETWORK_TYPE_BY_ID, GET_NETWORK_TYPE_BY_ID),
  takeLatest(GET_DATA_NETWORK_TYPE_NEXT_OR_PREV, GET_NETWORK_TYPE_NEXT_OR_PREV),
  takeLatest(ADD_DATA_NETWORK_TYPE, ADD_NETWORK_TYPE),
  takeLatest(UPDATE_DATA_NETWORK_TYPE, UPDATE_NETWORK_TYPE),
  takeLatest(DELETE_DATA_NETWORK_TYPE, DELETE_NETWORK_TYPE)
]);
