import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeLogActivity = [
  {
    path: '/log-activity',
    parent: '/log-activity',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "routeLogActivity" */ '../Pages/Activity/Activity'
        )
      )
    )
  },
  {
    path: '/log-activity/:id',
    parent: '/fishfin',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "routeLogActivity" */ '../Pages/Activity/LogView'
        )
      )
    )
  }
];
