import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'unit/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'unit/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_UNIT = 'GET_DATA_UNIT';
export const getDataUnit = defaultActionCreator(GET_DATA_UNIT, 'param');

export const GET_DATA_UNIT_NEXT_OR_PREV = 'GET_DATA_UNIT_NEXT_OR_PREV';
export const getDataUnitNextOrPrev = defaultActionCreator(
  GET_DATA_UNIT_NEXT_OR_PREV,
  'link'
);

export const GET_DATA_UNIT_BY_ID = 'GET_DATA_UNIT_BY_ID';
export const getDataUnitById = defaultActionCreator(GET_DATA_UNIT_BY_ID, 'id');

export const ADD_DATA_UNIT = 'ADD_DATA_UNIT';
export const addDataUnit = defaultActionCreator(ADD_DATA_UNIT, 'data');
export const UPDATE_DATA_UNIT = 'UPDATE_DATA_UNIT';
export const updateDataUnit = defaultActionCreator(
  UPDATE_DATA_UNIT,
  'data',
  'id'
);

export const DELETE_DATA_UNIT = 'DELETE_DATA_UNIT';
export const deleteDataUnit = defaultActionCreator(DELETE_DATA_UNIT, 'id');
