import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import {
  getWarehouse,
  getWarehouseByProvince,
  getProductSku,
  getWarehouseProduct,
  getLocationArea,
  getProspectWarehouse
} from '../../../services/Warehouse';
import {
  CLEAR_WAREHOUSE_MAP,
  GET_DATA_WAREHOUSE_BY_PROVINCE,
  GET_DATA_WAREHOUSE_MAP,
  GET_DATA_PRODUCT_SKU_MAP,
  GET_DATA_WAREHOUSE_PRODUCT,
  GET_WAREHOUSE_BY_AREA,
  SET_STATE,
  GET_LOCATION_AREA,
  GET_DATA_PROSPECT_WAREHOUSE
} from './actions';

const mappingBeforeToState = (products, key) => {
  return products.map((product) => {
    return {
      wh_name: product[key[0]],
      code: product[key[1]],
      address: product[key[2]],
      ...product
    };
  });
};

export function* GET_DATA_WAREHOUSE_MAP_F(action) {
  const { warehouses } = yield select((state) => state.dashboardMap);
  try {
    yield put({ type: SET_STATE, payload: { loading: true } });
    yield put({ type: SET_STATE, payload: { error: null } });
    const response = yield call(getWarehouse, { ...action.params });
    let stateNew = [...warehouses, ...response.data.data];
    let suggestions = [...stateNew];
    suggestions.unshift({
      wh_name: action.params.term,
      code: 'Search',
      label: 'test'
    });
    if (action.params.page === 1) {
      stateNew = response.data.data;
    }
    if (response.data.data.length === 0) {
      suggestions.unshift({
        code: 'Data not found',
        wh_name: action.params.term
      });
      yield put({ type: SET_STATE, payload: { error: 'Not found' } });
    }
    yield put({
      type: SET_STATE,
      payload: { nextWarehouse: response.data.data }
    });
    yield put({
      type: SET_STATE,
      payload: { warehouses: stateNew }
    });
    yield put({
      type: SET_STATE,
      payload: { suggestions: suggestions }
    });
    yield put({ type: SET_STATE, payload: { loading: false } });
  } catch (err) {
    console.log(err);
    yield put({ type: SET_STATE, payload: { loading: false } });
    yield put({ type: SET_STATE, payload: { error: err } });
  }
}

export function* GET_DATA_PRODUCT_SKU_F(action) {
  const { warehouses } = yield select((state) => state.dashboardMap);
  try {
    yield put({ type: SET_STATE, payload: { loading: true } });
    const response = yield call(getProductSku, { ...action.params });
    let stateNew = [
      ...warehouses,
      ...mappingBeforeToState(response.data.data, [
        'item_name',
        'sku',
        'description'
      ])
    ];
    if (response.data.data.length === 0) {
      stateNew.unshift({
        code: 'Data not found',
        wh_name: action.params.term
      });
    }
    yield put({
      type: SET_STATE,
      payload: {
        suggestions: stateNew
      }
    });
    yield put({ type: SET_STATE, payload: { loading: false } });
  } catch (err) {
    yield put({ type: SET_STATE, payload: { loading: false } });
    yield put({ type: SET_STATE, payload: { error: err } });
  }
}

export function* GET_DATA_WAREHOUSE_PRODUCT_F(action) {
  const { warehouses } = yield select((state) => state.dashboardMap);
  yield put({ type: SET_STATE, payload: { loading: true } });
  yield put({ type: SET_STATE, payload: { error: null } });

  try {
    const response = yield call(getWarehouseProduct, { ...action.params });
    let stateNew = [...warehouses, ...response.data.data];
    if (action.params.page === 1) {
      stateNew = response.data.data;
    }
    if (response.data.data.length === 0) {
      yield put({ type: SET_STATE, payload: { error: 'Not found' } });
    }
    yield put({
      type: SET_STATE,
      payload: { nextWarehouse: response.data.data }
    });
    yield put({
      type: SET_STATE,
      payload: {
        warehouses: stateNew
      }
    });
    yield put({ type: SET_STATE, payload: { loading: false } });
  } catch (err) {
    yield put({ type: SET_STATE, payload: { loading: false } });
    yield put({ type: SET_STATE, payload: { error: err } });
  }
}

export function* GET_DATA_WAREHOUSE_BY_PROVINCE_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(getWarehouseByProvince, { ...action.params });
    yield put({
      type: SET_STATE,
      payload: {
        warehouses: mappingBeforeToState(response.data.data, [
          'name',
          'code',
          'area'
        ])
      }
    });
    yield put({ type: SET_STATE, payload: { loading: false } });
  } catch (err) {
    yield put({ type: SET_STATE, payload: { loading: false } });
    yield put({ type: SET_STATE, payload: { error: err } });
  }
}

export function* GET_AREA_WAREHOUSE_BY_PROVINCE_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(getLocationArea, { ...action.params });
    yield put({ type: SET_STATE, payload: { area: response.data.data } });
    yield put({ type: SET_STATE, payload: { loading: false } });
  } catch (err) {
    yield put({ type: SET_STATE, payload: { loading: false } });
    yield put({ type: SET_STATE, payload: { error: err } });
  }
}

export function* GET_LOCATION_AREA_F(action) {
  try {
    const response = yield call(getLocationArea, { ...action.params });
    yield put({
      type: SET_STATE,
      payload: { [action.params.groupBy]: response.data.data }
    });
  } catch (err) {
    yield put({ type: SET_STATE, payload: { error: err } });
  }
}

export function* CLEAR_WAREHOUSE_MAP_F() {
  yield put({ type: SET_STATE, payload: { warehouses: [], suggestions: [] } });
}

export function* GET_DATA_PROSPECT_WAREHOUSE_F(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(getProspectWarehouse, { ...action.params });
    yield put({
      type: SET_STATE,
      payload: {
        prospectWarehouses: response.data.data,
        totalProspect: response.data.total
      }
    });
    yield put({ type: SET_STATE, payload: { loading: false } });
  } catch (err) {
    yield put({ type: SET_STATE, payload: { loading: false } });
    yield put({ type: SET_STATE, payload: { error: err } });
  }
}

export default all([
  takeLatest(GET_DATA_WAREHOUSE_MAP, GET_DATA_WAREHOUSE_MAP_F),
  takeLatest(GET_DATA_PRODUCT_SKU_MAP, GET_DATA_PRODUCT_SKU_F),
  takeLatest(GET_DATA_WAREHOUSE_PRODUCT, GET_DATA_WAREHOUSE_PRODUCT_F),
  takeLatest(GET_DATA_WAREHOUSE_BY_PROVINCE, GET_DATA_WAREHOUSE_BY_PROVINCE_F),
  takeLatest(GET_WAREHOUSE_BY_AREA, GET_AREA_WAREHOUSE_BY_PROVINCE_F),
  takeLatest(GET_LOCATION_AREA, GET_LOCATION_AREA_F),
  takeLatest(CLEAR_WAREHOUSE_MAP, CLEAR_WAREHOUSE_MAP_F),
  takeLatest(GET_DATA_PROSPECT_WAREHOUSE, GET_DATA_PROSPECT_WAREHOUSE_F)
]);
