import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'fishfinChanges/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'fishfinChanges/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_FISHFIN_CHANGES = 'GET_DATA_FISHFIN_CHANGES';
export const getDataFishfinChanges = defaultActionCreator(
  GET_DATA_FISHFIN_CHANGES,
  'param'
);

export const GET_DATA_FISHFIN_CHANGES_BY_ID = 'GET_DATA_FISHFIN_CHANGES_BY_ID';
export const getDataFishfinChangesById = defaultActionCreator(
  GET_DATA_FISHFIN_CHANGES_BY_ID,
  'id'
);

export const CREATE_DATA_FISHFIN_CHANGES = 'CREATE_DATA_FISHFIN_CHANGES';
export const createDataFishfinChanges = defaultActionCreator(
  CREATE_DATA_FISHFIN_CHANGES,
  'data',
  'log'
);

export const DELETE_DATA_FISHFIN_CHANGES = 'DELETE_DATA_FISHFIN_CHANGES';
export const deleteDataFishfinChanges = defaultActionCreator(
  DELETE_DATA_FISHFIN_CHANGES,
  'id'
);

export const DOWNLOAD_DATA_FISHFIN_CHANGES = 'DOWNLOAD_DATA_FISHFIN_CHANGES';
export const downloadDataFishfinChanges = defaultActionCreator(
  DOWNLOAD_DATA_FISHFIN_CHANGES,
  'param'
);
