import { all, put, takeLatest, call } from 'redux-saga/effects';
import {
  GET_DATA_PACKAGING,
  SET_STATE,
  DELETE_DATA_PACKAGGING,
  GET_DATA_PACKAGGING_BY_ID,
  ACTIVATION_DATA_PACKAGING,
  SET_ERROR_FORM
} from './actions';
import {
  getPackaging,
  deletePackaging,
  getPackagingByID,
  activationPackaging
} from '../../../services/packaging';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_PACKAGING(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getPackaging, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* DELETE_PACKAGGING(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deletePackaging, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Packaging', 'Delete Packaging');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* GET_PACKAGGING_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getPackagingByID, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* ACTIVATION_PACKAGING(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(activationPackaging, action.id62);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_PACKAGING, GET_PACKAGING),
  takeLatest(GET_DATA_PACKAGGING_BY_ID, GET_PACKAGGING_BY_ID),
  takeLatest(DELETE_DATA_PACKAGGING, DELETE_PACKAGGING),
  takeLatest(ACTIVATION_DATA_PACKAGING, ACTIVATION_PACKAGING)
]);
