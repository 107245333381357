import service from './service';
import endpoint from './constantUrl';

export const getDataSubModule = (param) => {
  return service.get(`${endpoint.ACCOUNT_URL}/module/sub-module/`, param);
};

export const getDataSubModuleById = (id) => {
  return service.get(`${endpoint.ACCOUNT_URL}/module/sub-module/${id}/`);
};

export const addDataSubModule = (data) => {
  return service.post(`${endpoint.ACCOUNT_URL}/module/sub-module/`, data);
};

export const updateDataSubModule = (data, id) => {
  return service.put(`${endpoint.ACCOUNT_URL}/module/sub-module/${id}/`, data);
};

export const deleteDataSubModule = (id) => {
  return service.delete(`${endpoint.ACCOUNT_URL}/module/sub-module/${id}/`);
};
