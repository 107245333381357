import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeStorageCategory = [
  {
    path: '/storage-category',
    parent: '/storage-category',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StorageCategory" */ '../Pages/MasterData/StorageCategory'
        )
      )
    )
  },
  {
    path: '/storage-category/add',
    parent: '/storage-category',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "AddStorageCategory" */ '../Pages/MasterData/StorageCategory/add'
        )
      )
    )
  },
  {
    path: '/storage-category/detail/:id',
    parent: '/storage-category',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "AddStorageCategory" */ '../Pages/MasterData/StorageCategory/add'
        )
      )
    )
  },
  {
    path: '/storage-category/edit/:idEdit',
    parent: '/storage-category',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "EditStorageCategory" */ '../Pages/MasterData/StorageCategory/add'
        )
      )
    )
  }
];
