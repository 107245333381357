import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getInventoryType,
  getInventoryTypeById,
  addInventoryType,
  updateInventoryType,
  deleteInventoryType
} from '../../../services/inventoryType';
import {
  SET_STATE,
  GET_DATA_INVENTORY_TYPE,
  GET_DATA_INVENTORY_TYPE_BY_ID,
  ADD_DATA_INVENTORY_TYPE,
  UPDATE_DATA_INVENTORY_TYPE,
  DELETE_DATA_INVENTORY_TYPE,
  SET_ERROR_FORM,
  GET_DATA_INVENTORY_TYPE_NEXT_OR_PREV
} from './actions';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_INVENTORY_TYPE(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getInventoryType, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_INVENTORY_TYPE_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getInventoryTypeById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_INVENTORY_TYPE_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_INVENTORY_TYPE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addInventoryType, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Inventory Type', 'Create Inventory Type');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* UPDATE_INVENTORY_TYPE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateInventoryType, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Inventory Type', 'Edit Inventory Type');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DELETE_INVENTORY_TYPE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteInventoryType, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Inventory Type', 'Delete Inventory Type');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_INVENTORY_TYPE, GET_INVENTORY_TYPE),
  takeLatest(GET_DATA_INVENTORY_TYPE_BY_ID, GET_INVENTORY_TYPE_BY_ID),
  takeLatest(
    GET_DATA_INVENTORY_TYPE_NEXT_OR_PREV,
    GET_INVENTORY_TYPE_NEXT_OR_PREV
  ),
  takeLatest(ADD_DATA_INVENTORY_TYPE, ADD_DATA_INVENTORY_TYPE),
  takeLatest(UPDATE_DATA_INVENTORY_TYPE, UPDATE_INVENTORY_TYPE),
  takeLatest(DELETE_DATA_INVENTORY_TYPE, DELETE_INVENTORY_TYPE)
]);
