import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeTagihan = [
  {
    path: '/faktur-penjualan',
    parent: '/faktur-penjualan',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupPenjualananTagihan" */ '../Pages/Penjualanan/Tagihan'
        )
      )
    )
  },
  {
    path: '/faktur-penjualan/update/:id',
    parent: '/faktur-penjualan',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupPenjualananTagihan" */ '../Pages/Penjualanan/Tagihan/update'
        )
      )
    )
  },
  {
    path: '/faktur-penjualan/detail/:id',
    parent: '/faktur-penjualan',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupPenjualananTagihanDetail" */ '../Pages/Penjualanan/Tagihan/detail'
        )
      )
    )
  },
  {
    path: '/faktur-penjualan/add/:id',
    parent: '/faktur-penjualan',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupPenjualananTagihanAdd" */ '../Pages/Penjualanan/Tagihan/add'
        )
      )
    )
  },
  {
    path: '/tagihan-pembelian',
    parent: '/tagihan-pembelian',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupTagihanPembelian" */ '../Pages/Pembelian/Tagihan/tagihanPembelian'
        )
      )
    )
  },
  {
    path: '/tagihan-pembelian/:id',
    parent: '/tagihan-pembelian',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupPenjualananReturAdd" */ '../Pages/Pembelian/Tagihan/tagihanPembelianDetail'
        )
      )
    )
  },
  {
    path: '/tagihan-pembelian/add/:id',
    parent: '/tagihan-pembelian',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupPenjualananReturAdd" */ '../Pages/Pembelian/Tagihan/tagihanPembelianAdd'
        )
      )
    )
  },
  {
    path: '/tagihan-pembelian/update/:id',
    parent: '/tagihan-pembelian',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupPenjualananReturAdd" */ '../Pages/Pembelian/Tagihan/tagihanPembelianUpdate'
        )
      )
    )
  }
];
