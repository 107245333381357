import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'supplier/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'supplier/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_SUPLIERS = 'GET_DATA_SUPPLIERS';
export const getDataSuppliers = defaultActionCreator(
  GET_DATA_SUPLIERS,
  'param'
);
