import service from './service';
import endpoint from './constantUrl';

// Unit
export const addUnit = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/unit/unit/`, data);
};
export const getUnit = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/unit/unit/`, param);
};
export const getUnitById = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/unit/unit/${id}/`);
};

export const updateUnit = (data, id) => {
  return service.put(`${endpoint.MASTERDATA_URL}/unit/unit/${id}/`, data);
};

export const deleteUnit = (id) => {
  return service.delete(`${endpoint.MASTERDATA_URL}/unit/unit/${id}/`);
};
