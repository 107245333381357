import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getJenisUsaha,
  getJenisUsahaById,
  addJenisUsaha,
  updateJenisUsaha,
  deleteJenisUsaha,
  downloadDataJenisUsaha
} from '../../../services/jenisUsaha';
import { handleDownload, logMatches } from '../../../utils';
import {
  SET_STATE,
  GET_DATA_JENIS_USAHA,
  GET_DATA_JENIS_USAHA_BY_ID,
  ADD_DATA_JENIS_USAHA,
  UPDATE_DATA_JENIS_USAHA,
  DELETE_DATA_JENIS_USAHA,
  GET_DATA_JENIS_USAHA_NEXT_OR_PREV,
  SET_ERROR_FORM,
  DOWNLOAD_DATA_JENIS_USAHA
} from './actions';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_JENIS_USAHA(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getJenisUsaha, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_JENIS_USAHA_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getJenisUsahaById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_JENIS_USAHA_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_JENIS_USAHA(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addJenisUsaha, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Business Type', 'Create Business Type');
    yield call(createLog, body);

    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* UPDATE_JENIS_USAHA(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateJenisUsaha, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Business Type', 'Edit Business Type');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* DELETE_JENIS_USAHA(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteJenisUsaha, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Business Type', 'Delete Business Type');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loading: false }
    });
  }
}

export function* DOWNLOAD_JENIS_USAHA(action) {
  try {
    const { data, headers } = yield call(downloadDataJenisUsaha, action.param);
    const filename = yield logMatches(headers['content-disposition']);
    yield handleDownload(data, filename[0]);
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export default all([
  takeLatest(GET_DATA_JENIS_USAHA, GET_JENIS_USAHA),
  takeLatest(GET_DATA_JENIS_USAHA_BY_ID, GET_JENIS_USAHA_BY_ID),
  takeLatest(GET_DATA_JENIS_USAHA_NEXT_OR_PREV, GET_JENIS_USAHA_NEXT_OR_PREV),
  takeLatest(ADD_DATA_JENIS_USAHA, ADD_JENIS_USAHA),
  takeLatest(UPDATE_DATA_JENIS_USAHA, UPDATE_JENIS_USAHA),
  takeLatest(DELETE_DATA_JENIS_USAHA, DELETE_JENIS_USAHA),
  takeLatest(DOWNLOAD_DATA_JENIS_USAHA, DOWNLOAD_JENIS_USAHA)
]);
