import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getSize,
  getSizeById,
  addSize,
  updateSize,
  deleteSize
} from '../../../services/size';
import {
  SET_STATE,
  SET_ERROR_FORM,
  GET_DATA_SIZE,
  GET_DATA_SIZE_BY_ID,
  GET_DATA_SIZE_NEXT_OR_PREV,
  ADD_DATA_SIZE,
  UPDATE_DATA_SIZE,
  DELETE_DATA_SIZE
} from './actions';

import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_SIZE(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getSize, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_SIZE_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getSizeById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_SIZE_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_SIZE(action) {
  yield put({ type: SET_STATE, payload: { loadingForm: true } });
  try {
    yield call(addSize, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Data Master', 'Create Size');
    yield call(createLog, body);
    let message = 'Your Data Has Been Submitted';
    yield put({ type: SET_STATE, payload: { isAdd: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  }
}

export function* UPDATE_SIZE(action) {
  yield put({ type: SET_STATE, payload: { loadingForm: true } });
  try {
    yield call(updateSize, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Data Master', 'Edit Size');
    yield call(createLog, body);
    let message = 'Your Data Has Been Submitted';
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  }
}

export function* DELETE_SIZE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteSize, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Data Master', 'Delete Size');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loading: false }
    });
  }
}

export default all([
  takeLatest(GET_DATA_SIZE, GET_SIZE),
  takeLatest(GET_DATA_SIZE_BY_ID, GET_SIZE_BY_ID),
  takeLatest(GET_DATA_SIZE_NEXT_OR_PREV, GET_SIZE_NEXT_OR_PREV),
  takeLatest(ADD_DATA_SIZE, ADD_SIZE),
  takeLatest(UPDATE_DATA_SIZE, UPDATE_SIZE),
  takeLatest(DELETE_DATA_SIZE, DELETE_SIZE)
]);
