import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getProduct,
  getProductById,
  addProduct,
  updateProduct,
  deleteProduct,
  exportProductExcel,
  downloadTemplateProduct,
  downloadDataProduct,
  uploadProduct,
  activationProduct,
  getProductByIdGudang,
  getProductPurchaseSupplier,
  getProductPurchaseSupplierById,
  addProductPurchaseSupplier,
  updateProductPurchaseSupplier,
  deleteProductPurchaseSupplier,
  getProductCertBySupplier
} from '../../../services/product';
import { handleDownload, logMatches } from '../../../utils';
import {
  SET_STATE,
  GET_DATA_PRODUCT,
  GET_DATA_PRODUCT_BY_ID,
  ADD_DATA_PRODUCT,
  UPDATE_DATA_PRODUCT,
  DELETE_DATA_PRODUCT,
  GET_DATA_PRODUCT_NEXT_OR_PREV,
  SET_ERROR_FORM,
  DOWNLOAD_DATA_PRODUCT_EXCEL,
  DOWNLOAD_DATA_PRODUCT,
  DOWNLOAD_TEMPLATE_PRODUCT,
  UPLOAD_DATA_PRODUCT,
  ACTIVATION_DATA_PRODUCT,
  GET_PRODUCT_BY_ID_GUDANG,
  GET_DATA_PRODUCT_PURCHASE_SUPPLIER,
  GET_DATA_PRODUCT_PURCHASE_SUPPLIER_BY_ID,
  ADD_DATA_PRODUCT_PURCHASE_SUPPLIER,
  UPDATE_DATA_PRODUCT_PURCHASE_SUPPLIER,
  DELETE_DATA_PRODUCT_PURCHASE_SUPPLIER,
  GET_DATA_PRODUCT_PURCHASE_SUPPLIER_NEXT_OR_PREV,
  GET_DATA_PRODUCT_CERT_BY_SUPPLIER
} from './actions';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_PRODUCT(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getProduct, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_PRODUCT_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getProductById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_PRODUCT_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_PRODUCT(action) {
  yield put({ type: SET_STATE, payload: { loadingForm: true } });
  try {
    yield call(addProduct, action.data);
    yield setIpUser();
    let message = 'Your Data Has Been Submitted';

    const body = bodyLog('create', 'Product', 'Create Data Product');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  }
}

export function* UPDATE_PRODUCT(action) {
  yield put({ type: SET_STATE, payload: { loadingForm: true } });
  try {
    yield call(updateProduct, action.data, action.id);
    yield setIpUser();
    let message = 'Your Data Has Been Submitted';
    const body = bodyLog('edit', 'Product', 'Edit Data Product');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  }
}

export function* DELETE_PRODUCT(action) {
  yield put({ type: SET_STATE, payload: { loadingDelete: true } });
  try {
    yield call(deleteProduct, action.id);
    yield setIpUser();
    let message = 'Successfully deleting the Draft';
    const body = bodyLog('delete', 'Product', 'Delete Data Product');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isDelete: true, message } });
  } catch ({ response }) {
    let errData = response.data.data;
    let errMessage = response.data.message?.id ?? response.data.message?.en;

    yield put({ type: SET_STATE, payload: { error: errData ?? errMessage } });
    yield put({
      type: SET_STATE,
      payload: { loadingDelete: false }
    });
  }
}

export function* DOWNLOAD_PRODUCT_EXCEL(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(exportProductExcel, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DOWNLOAD_TEMPLATE_PRODUCT_F() {
  // yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(downloadTemplateProduct);
    yield handleDownload(data, 'produk_template.csv');
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* DOWNLOAD_PRODUCT(action) {
  try {
    const { data, headers } = yield call(downloadDataProduct, action.param);
    const filename = yield logMatches(headers['content-disposition']);
    yield handleDownload(data, filename[0]);
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* UPLOAD_PRODUCT(action) {
  yield put({ type: SET_STATE, payload: { loadingUpload: true } });
  try {
    yield call(uploadProduct, action.data);
    yield put({
      type: SET_STATE,
      payload: { isUpload: true, isUpdate: true }
    });
  } catch ({ response }) {
    yield put({
      type: SET_STATE,
      payload: { errorUpload: response?.data, isUpload: false }
    });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loadingUpload: false }
    });
  }
}

export function* ACTIVATION_PRODUCT(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(activationProduct, action.id62);
    let message = 'Successfully updated status';
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_DATA_PRODUCT_BY_ID_GUDANG(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getProductByIdGudang, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

//purchase supplier
export function* GET_PRODUCT_PURCHASE_SUPPLIER(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingPurchaseSupplier: true, dataPurchaseSupplier: null }
  });
  try {
    const { data } = yield call(getProductPurchaseSupplier, action.param);
    yield put({ type: SET_STATE, payload: { dataPurchaseSupplier: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingPurchaseSupplier: false } });
  }
}

export function* GET_PRODUCT_PURCHASE_SUPPLIER_BY_ID(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingPurchaseSupplier: true, dataPurchaseSupplier: null }
  });
  try {
    const { data } = yield call(getProductPurchaseSupplierById, action.id);
    yield put({ type: SET_STATE, payload: { dataPurchaseSupplier: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingPurchaseSupplier: false } });
  }
}

export function* GET_PRODUCT_PURCHASE_SUPPLIER_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loadingPurchaseSupplier: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { dataPurchaseSupplier: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingPurchaseSupplier: false } });
  }
}

export function* ADD_PRODUCT_PURCHASE_SUPPLIER(action) {
  yield put({ type: SET_STATE, payload: { loadingPurchaseSupplier: true } });
  try {
    yield call(addProductPurchaseSupplier, action.data);
    yield setIpUser();
    let message = 'Your Data Has Been Submitted';

    const body = bodyLog('edit', 'Product', 'Edit Data Product');
    yield call(createLog, body);
    yield put({
      type: SET_STATE,
      payload: { isAddPurchaseSupplier: true, message }
    });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingPurchaseSupplier: false } });
  }
}

export function* UPDATE_PRODUCT_PURCHASE_SUPPLIER(action) {
  yield put({ type: SET_STATE, payload: { loadingPurchaseSupplier: true } });
  try {
    yield call(updateProductPurchaseSupplier, action.data, action.id);
    yield setIpUser();
    let message = 'Your Data Has Been Submitted';

    const body = bodyLog('edit', 'Product', 'Edit Data Product');
    yield call(createLog, body);
    yield put({
      type: SET_STATE,
      payload: { isUpdatePurchaseSupplier: true, message }
    });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingPurchaseSupplier: false } });
  }
}

export function* DELETE_PRODUCT_PURCHASE_SUPPLIER(action) {
  yield put({ type: SET_STATE, payload: { loadingPurchaseSupplier: true } });
  try {
    yield call(deleteProductPurchaseSupplier, action.id);
    yield setIpUser();
    let message = 'Successfully deleting the Draft';

    const body = bodyLog('edit', 'Product', 'Edit Data Product');
    yield call(createLog, body);
    yield put({
      type: SET_STATE,
      payload: { isDeletePurchaseSupplier: true, message }
    });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
    yield put({
      type: SET_STATE,
      payload: { loadingPurchaseSupplier: false }
    });
  }
}

export function* GET_PRODUCT_CERT_BY_SUPPLIER(action) {
  yield put({ type: SET_STATE, payload: { loading: true, productCert: null } });
  try {
    const { data } = yield call(getProductCertBySupplier, action.data);
    yield put({ type: SET_STATE, payload: { productCert: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_PRODUCT, GET_PRODUCT),
  takeLatest(GET_DATA_PRODUCT_BY_ID, GET_PRODUCT_BY_ID),
  takeLatest(GET_DATA_PRODUCT_NEXT_OR_PREV, GET_PRODUCT_NEXT_OR_PREV),
  takeLatest(ADD_DATA_PRODUCT, ADD_PRODUCT),
  takeLatest(UPDATE_DATA_PRODUCT, UPDATE_PRODUCT),
  takeLatest(DELETE_DATA_PRODUCT, DELETE_PRODUCT),
  takeLatest(DOWNLOAD_DATA_PRODUCT_EXCEL, DOWNLOAD_PRODUCT_EXCEL),
  takeLatest(DOWNLOAD_TEMPLATE_PRODUCT, DOWNLOAD_TEMPLATE_PRODUCT_F),
  takeLatest(DOWNLOAD_DATA_PRODUCT, DOWNLOAD_PRODUCT),
  takeLatest(UPLOAD_DATA_PRODUCT, UPLOAD_PRODUCT),
  takeLatest(ACTIVATION_DATA_PRODUCT, ACTIVATION_PRODUCT),
  takeLatest(GET_PRODUCT_BY_ID_GUDANG, GET_DATA_PRODUCT_BY_ID_GUDANG),

  //purchase supplier
  takeLatest(GET_DATA_PRODUCT_PURCHASE_SUPPLIER, GET_PRODUCT_PURCHASE_SUPPLIER),
  takeLatest(
    GET_DATA_PRODUCT_PURCHASE_SUPPLIER_BY_ID,
    GET_PRODUCT_PURCHASE_SUPPLIER_BY_ID
  ),
  takeLatest(
    GET_DATA_PRODUCT_PURCHASE_SUPPLIER_NEXT_OR_PREV,
    GET_PRODUCT_PURCHASE_SUPPLIER_NEXT_OR_PREV
  ),
  takeLatest(ADD_DATA_PRODUCT_PURCHASE_SUPPLIER, ADD_PRODUCT_PURCHASE_SUPPLIER),
  takeLatest(
    UPDATE_DATA_PRODUCT_PURCHASE_SUPPLIER,
    UPDATE_PRODUCT_PURCHASE_SUPPLIER
  ),
  takeLatest(
    DELETE_DATA_PRODUCT_PURCHASE_SUPPLIER,
    DELETE_PRODUCT_PURCHASE_SUPPLIER
  ),
  takeLatest(GET_DATA_PRODUCT_CERT_BY_SUPPLIER, GET_PRODUCT_CERT_BY_SUPPLIER)
]);
