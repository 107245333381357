import service from './service';
import endpoint from './constantUrl';

// Process
export const addProcess = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/product/process/`, data);
};

export const getProcess = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/product/process/`, param);
};

export const getProcessById = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/product/process/${id}/`);
};

export const updateProcess = (data, id) => {
  return service.put(`${endpoint.MASTERDATA_URL}/product/process/${id}/`, data);
};

export const deleteProcess = (id) => {
  return service.delete(`${endpoint.MASTERDATA_URL}/product/process/${id}/`);
};
