import { all, put, takeLatest, call } from 'redux-saga/effects';
import {
  ADD_DATA_HS_CODE,
  GET_DATA_HS_CODE,
  GET_DATA_HS_CODE_BY_ID62,
  SET_STATE,
  SET_ERROR_FORM,
  UPDATE_DATA_HS_CODE,
  DELETE_DATA_HS_CODE,
  EXPORT_DATA_EXCEL_HS_CODE
} from './actions';
import {
  getHsCode,
  getHsCodeById62,
  addHsCode,
  updateHsCode,
  deleteHsCode,
  exportExcellHsCode
} from '~/services/hsCode';
import { createLog } from '~/services/Activity';
import { setIpUser, bodyLog, logMatches, handleDownload } from '~/utils';

export function* GET_HS_CODE(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getHsCode, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_HS_CODE_BY_ID62(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getHsCodeById62, action.id62);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_HS_CODE(action) {
  yield put({ type: SET_STATE, payload: { loadingForm: true } });
  try {
    yield call(addHsCode, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Hs Code', 'Create Hs Code');
    let message = 'Your Data Has Been Submitted';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  }
}

export function* UPDATE_HS_CODE(action) {
  yield put({ type: SET_STATE, payload: { loadingForm: true } });
  try {
    yield call(updateHsCode, action.data, action.id62);
    yield setIpUser();
    const body = bodyLog('edit', 'Hs Code', 'Edit Hs Code');
    let message = 'Your Data Has Been Submitted';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  }
}

export function* DELETE_HS_CODE(action) {
  yield put({ type: SET_STATE, payload: { loadingDelete: true } });
  try {
    yield call(deleteHsCode, action.id62);
    yield setIpUser();
    const body = bodyLog('delete', 'Hs Code', 'Delete Hs Code');
    let message = 'Successfully deleting the Data';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isDelete: true, message } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
    yield put({
      type: SET_STATE,
      payload: { loadingDelete: false }
    });
  }
}

export function* EXPORT_EXCEL_HS_CODE(action) {
  try {
    const response = yield call(exportExcellHsCode, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_HS_CODE, GET_HS_CODE),
  takeLatest(GET_DATA_HS_CODE_BY_ID62, GET_HS_CODE_BY_ID62),
  takeLatest(ADD_DATA_HS_CODE, ADD_HS_CODE),
  takeLatest(UPDATE_DATA_HS_CODE, UPDATE_HS_CODE),
  takeLatest(DELETE_DATA_HS_CODE, DELETE_HS_CODE),
  takeLatest(EXPORT_DATA_EXCEL_HS_CODE, EXPORT_EXCEL_HS_CODE)
]);
