import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getTag,
  getTagById,
  addTag,
  updateTag,
  deleteTag,
  activationTag,
  exportTagExcel
} from '../../../services/tag';
import {
  SET_STATE,
  SET_ERROR_FORM,
  GET_DATA_TAG,
  GET_DATA_TAG_BY_ID,
  GET_DATA_TAG_NEXT_OR_PREV,
  ADD_DATA_TAG,
  UPDATE_DATA_TAG,
  DELETE_DATA_TAG,
  ACTIVATION_DATA_TAG,
  DOWNLOAD_DATA_TAG_EXCEL
} from './actions';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog, handleDownload, logMatches } from '../../../utils';

export function* GET_TAG(action) {
  yield put({ type: SET_STATE, payload: { data: null, loading: true } });
  try {
    const { data } = yield call(getTag, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_TAG_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { data: null, loading: true } });
  try {
    const { data } = yield call(getTagById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_TAG_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_TAG(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addTag, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Tag', 'Create Tag');
    yield call(createLog, body);

    let message = 'Your Data Has Been Submitted';
    yield put({ type: SET_STATE, payload: { isAdd: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* UPDATE_TAG(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateTag, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Tag', 'Edit Tag');
    yield call(createLog, body);

    let message = 'Your Data Has Been Submitted';
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DELETE_TAG(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteTag, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Tag', 'Delete Tag');
    yield call(createLog, body);

    let message = 'Successfully deleting tag';
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ message: error }) {
    yield put({
      type: SET_STATE,
      payload: { loading: false }
    });
    yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* ACTIVATION_TAG(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(activationTag, action.id);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DOWNLOAD_TAG_EXCEL(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(exportTagExcel, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_TAG, GET_TAG),
  takeLatest(GET_DATA_TAG_BY_ID, GET_TAG_BY_ID),
  takeLatest(GET_DATA_TAG_NEXT_OR_PREV, GET_TAG_NEXT_OR_PREV),
  takeLatest(ADD_DATA_TAG, ADD_TAG),
  takeLatest(UPDATE_DATA_TAG, UPDATE_TAG),
  takeLatest(DELETE_DATA_TAG, DELETE_TAG),
  takeLatest(ACTIVATION_DATA_TAG, ACTIVATION_TAG),
  takeLatest(DOWNLOAD_DATA_TAG_EXCEL, DOWNLOAD_TAG_EXCEL)
]);
