import { all, put, takeLatest, call } from 'redux-saga/effects';
import {
  getProvince,
  getRegency,
  posBase64Upload,
  masterDataBase64Upload,
  rentalBase64Upload,
  CashBase64Upload,
  uploadFile,
  getGeolocation,
  getCountry,
  getArea,
  getSubarea,
  getFishingMethod,
  getDistrict,
  getGeolocationV2
} from '../../../services/reference';
// import { uploadStockInventory } from '../../../services/stockInventory';
import {
  SET_STATE,
  GET_DATA_PROVINCE,
  GET_DATA_REGENCY,
  POS_BASE64_UPLOAD,
  POS_BASE64_UPLOAD_IMAGE,
  MASTER_DATA_BASE64_UPLOAD,
  RENTAL_BASE64_UPLOAD,
  CASH_BASE64_UPLOAD,
  UPLOAD_FILE,
  GET_DATA_GEOLOCATION,
  GET_DATA_COUNTRY,
  GET_DATA_AREA,
  GET_DATA_SUBAREA,
  GET_DATA_FISHING_METHOD,
  GET_DATA_DISTRICT,
  GET_DATA_GEOLOCATION_V2
} from './actions';

export function* GET_COUNTRY(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getCountry, action.param);
    yield put({ type: SET_STATE, payload: { country: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_PROVINCE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getProvince, action.param);
    yield put({ type: SET_STATE, payload: { province: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_REGENCY(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getRegency, action.param);
    yield put({ type: SET_STATE, payload: { regency: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_DISTRICT(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDistrict, action.param);
    yield put({ type: SET_STATE, payload: { district: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* POS_BASE64_UPLOAD_F(action) {
  yield put({ type: SET_STATE, payload: { loadingPosUpload: true } });
  try {
    const { data } = yield call(posBase64Upload, action.data);
    yield put({
      type: SET_STATE,
      payload: { isPosUpload: true, [action.key]: data }
    });
  } catch ({ message: error }) {
    yield put({
      type: SET_STATE,
      payload: {
        errorPosUpload: error,
        posUploadError: action.data,
        isPosUpload: false
      }
    });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loadingPosUpload: false }
    });
  }
}

export function* POS_BASE64_UPLOAD_IMAGE_F(action) {
  yield put({ type: SET_STATE, payload: { loadingPosUploadImage: true } });
  try {
    const { data } = yield call(posBase64Upload, action.data);
    yield put({
      type: SET_STATE,
      payload: { isPosUploadImage: true, [action.key]: data }
    });
  } catch ({ message: error }) {
    yield put({
      type: SET_STATE,
      payload: {
        errorPosUploadImage: error,
        posUploadImageError: action.data,
        isPosUploadImage: false
      }
    });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loadingPosUploadImage: false }
    });
  }
}

export function* MASTER_POS_BASE64_UPLOAD_F(action) {
  yield put({ type: SET_STATE, payload: { loadingMasterDataUpload: true } });
  try {
    const { data } = yield call(masterDataBase64Upload, action.data);
    yield put({
      type: SET_STATE,
      payload: { isMasterDataUpload: true, [action.key]: data }
    });
  } catch ({ message: error }) {
    yield put({
      type: SET_STATE,
      payload: {
        errorMasterDataUpload: error,
        masterDataUploadError: action.data
      }
    });
  } finally {
    yield put({
      type: SET_STATE,
      payload: {
        loadingMasterDataUpload: false
      }
    });
  }
}

export function* RENTAL_BASE64_UPLOAD_F(action) {
  yield put({ type: SET_STATE, payload: { loadingRentalDataUpload: true } });
  try {
    const { data } = yield call(rentalBase64Upload, action.data);
    yield put({
      type: SET_STATE,
      payload: { isRentalDataUpload: true, [action.key]: data }
    });
  } catch ({ message: error }) {
    yield put({
      type: SET_STATE,
      payload: { errorRentalDataUpload: error, isRentalDataUpload: false }
    });
  } finally {
    yield put({
      type: SET_STATE,
      payload: {
        // isRentalDataUpload: null,
        loadingRentalDataUpload: false
      }
    });
  }
}

export function* CASH_BASE64_UPLOAD_F(action) {
  yield put({ type: SET_STATE, payload: { loadingCashDataUpload: true } });
  try {
    const { data } = yield call(CashBase64Upload, action.data);
    yield put({
      type: SET_STATE,
      payload: { isCashDataUpload: true, [action.key]: data }
    });
  } catch ({ message: error }) {
    yield put({
      type: SET_STATE,
      payload: { errorCashDataUpload: error, isCashDataUpload: false }
    });
  } finally {
    yield put({
      type: SET_STATE,
      payload: {
        isCashDataUpload: null,
        loadingCashDataUpload: false
      }
    });
  }
}

export function* UPLOAD_FILE_F(action) {
  yield put({ type: SET_STATE, payload: { loadingUpload: true } });
  try {
    const { data } = yield call(uploadFile, action.data, action.key);
    yield put({
      type: SET_STATE,
      payload: { isUpload: true, [action.key]: data }
    });
  } catch ({ message: error }) {
    yield put({
      type: SET_STATE,
      payload: { errorUpload: error, isUpload: false }
    });
  } finally {
    yield put({
      type: SET_STATE,
      payload: {
        isUpload: null,
        loadingUpload: false
      }
    });
  }
}

export function* GET_GEOLOCATION(action) {
  yield put({ type: SET_STATE, payload: { loading: true, geolocation: null } });
  try {
    const { data } = yield call(getGeolocation, action.data);
    yield put({ type: SET_STATE, payload: { geolocation: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_GEOLOCATION_V2(action) {
  yield put({
    type: SET_STATE,
    payload: { loading: true, geolocationV2: null }
  });
  try {
    const { data } = yield call(getGeolocationV2, action.data);
    yield put({ type: SET_STATE, payload: { geolocationV2: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_AREA(action) {
  yield put({ type: SET_STATE, payload: { loadingArea: true } });
  try {
    const { data } = yield call(getArea, action.data);
    yield put({ type: SET_STATE, payload: { area: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingArea: false } });
  }
}

export function* GET_SUBAREA(action) {
  yield put({ type: SET_STATE, payload: { loadingSubarea: true } });
  try {
    const { data } = yield call(getSubarea, action.data);
    yield put({ type: SET_STATE, payload: { subarea: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingSubarea: false } });
  }
}

export function* GET_FISHING_METHOD(action) {
  yield put({ type: SET_STATE, payload: { loadingFishingMethod: true } });
  try {
    const { data } = yield call(getFishingMethod, action.data);
    yield put({ type: SET_STATE, payload: { fishingMethod: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingFishingMethod: false } });
  }
}

export default all([
  takeLatest(GET_DATA_COUNTRY, GET_COUNTRY),
  takeLatest(GET_DATA_PROVINCE, GET_PROVINCE),
  takeLatest(GET_DATA_REGENCY, GET_REGENCY),
  takeLatest(GET_DATA_DISTRICT, GET_DISTRICT),
  takeLatest(POS_BASE64_UPLOAD, POS_BASE64_UPLOAD_F),
  takeLatest(POS_BASE64_UPLOAD_IMAGE, POS_BASE64_UPLOAD_IMAGE_F),
  takeLatest(RENTAL_BASE64_UPLOAD, RENTAL_BASE64_UPLOAD_F),
  takeLatest(CASH_BASE64_UPLOAD, CASH_BASE64_UPLOAD_F),
  takeLatest(UPLOAD_FILE, UPLOAD_FILE_F),
  takeLatest(MASTER_DATA_BASE64_UPLOAD, MASTER_POS_BASE64_UPLOAD_F),
  takeLatest(GET_DATA_GEOLOCATION, GET_GEOLOCATION),
  takeLatest(GET_DATA_GEOLOCATION_V2, GET_GEOLOCATION_V2),
  takeLatest(GET_DATA_AREA, GET_AREA),
  takeLatest(GET_DATA_SUBAREA, GET_SUBAREA),
  takeLatest(GET_DATA_FISHING_METHOD, GET_FISHING_METHOD)
]);
