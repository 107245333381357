import React from 'react';
import LogoAnimate from './LogoAnimate';

function LoadingPage() {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        background: '#f9f9f9',
        color: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <LogoAnimate />
    </div>
  );
}

export default LoadingPage;
