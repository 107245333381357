import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'product/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'product/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_PRODUCT = 'GET_DATA_PRODUCT';
export const getDataProduct = defaultActionCreator(GET_DATA_PRODUCT, 'param');

export const GET_DATA_PRODUCT_BY_ID = 'GET_DATA_PRODUCT_BY_ID';
export const getDataProductById = defaultActionCreator(
  GET_DATA_PRODUCT_BY_ID,
  'id'
);
export const GET_DATA_PRODUCT_NEXT_OR_PREV = 'GET_DATA_PRODUCT_NEXT_OR_PREV';
export const getDataProductNextOrPrev = defaultActionCreator(
  GET_DATA_PRODUCT_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_PRODUCT = 'ADD_DATA_PRODUCT';
export const addDataProduct = defaultActionCreator(ADD_DATA_PRODUCT, 'data');
export const UPDATE_DATA_PRODUCT = 'UPDATE_DATA_PRODUCT';
export const updateDataProduct = defaultActionCreator(
  UPDATE_DATA_PRODUCT,
  'data',
  'id'
);

export const DELETE_DATA_PRODUCT = 'DELETE_DATA_PRODUCT';
export const deleteDataProduct = defaultActionCreator(
  DELETE_DATA_PRODUCT,
  'id'
);

export const DOWNLOAD_DATA_PRODUCT_EXCEL = 'DOWNLOAD_DATA_PRODUCT_EXCEL';
export const downloadDataProductExcel = defaultActionCreator(
  DOWNLOAD_DATA_PRODUCT_EXCEL,
  'param'
);

export const DOWNLOAD_TEMPLATE_PRODUCT = 'DOWNLOAD_TEMPLATE_PRODUCT';
export const downloadTemplateProduct = defaultActionCreator(
  DOWNLOAD_TEMPLATE_PRODUCT,
  'id'
);

export const DOWNLOAD_DATA_PRODUCT = 'DOWNLOAD_DATA_PRODUCT';
export const downloadDataProduct = defaultActionCreator(
  DOWNLOAD_DATA_PRODUCT,
  'param'
);

export const UPLOAD_DATA_PRODUCT = 'UPLOAD_DATA_PRODUCT';
export const uploadDataProduct = defaultActionCreator(
  UPLOAD_DATA_PRODUCT,
  'data'
);

export const ACTIVATION_DATA_PRODUCT = 'ACTIVATION_DATA_PRODUCT';
export const activationDataProduct = defaultActionCreator(
  ACTIVATION_DATA_PRODUCT,
  'id62'
);

export const GET_PRODUCT_BY_ID_GUDANG = 'GET_PRODUCT_BY_ID_GUDANG';
export const getDataProductByIdGudang = defaultActionCreator(
  GET_PRODUCT_BY_ID_GUDANG,
  'id'
);

//purchase supplier
export const GET_DATA_PRODUCT_PURCHASE_SUPPLIER =
  'GET_DATA_PRODUCT_PURCHASE_SUPPLIER';
export const getDataProductPurchaseSupplier = defaultActionCreator(
  GET_DATA_PRODUCT_PURCHASE_SUPPLIER,
  'param'
);

export const GET_DATA_PRODUCT_PURCHASE_SUPPLIER_BY_ID =
  'GET_DATA_PRODUCT_PURCHASE_SUPPLIER_BY_ID';
export const getDataProductPurchaseSupplierById = defaultActionCreator(
  GET_DATA_PRODUCT_PURCHASE_SUPPLIER_BY_ID,
  'id'
);

export const GET_DATA_PRODUCT_PURCHASE_SUPPLIER_NEXT_OR_PREV =
  'GET_DATA_PRODUCT_PURCHASE_SUPPLIER_NEXT_OR_PREV';
export const getDataProductPurchaseSupplierNextOrPrev = defaultActionCreator(
  GET_DATA_PRODUCT_PURCHASE_SUPPLIER_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_PRODUCT_PURCHASE_SUPPLIER =
  'ADD_DATA_PRODUCT_PURCHASE_SUPPLIER';
export const addDataProductPurchaseSupplier = defaultActionCreator(
  ADD_DATA_PRODUCT_PURCHASE_SUPPLIER,
  'data'
);

export const UPDATE_DATA_PRODUCT_PURCHASE_SUPPLIER =
  'UPDATE_DATA_PRODUCT_PURCHASE_SUPPLIER';
export const updateDataProductPurchaseSupplier = defaultActionCreator(
  UPDATE_DATA_PRODUCT_PURCHASE_SUPPLIER,
  'data',
  'id'
);

export const DELETE_DATA_PRODUCT_PURCHASE_SUPPLIER =
  'DELETE_DATA_PRODUCT_PURCHASE_SUPPLIER';
export const deleteDataProductPurchaseSupplier = defaultActionCreator(
  DELETE_DATA_PRODUCT_PURCHASE_SUPPLIER,
  'id'
);

export const GET_DATA_PRODUCT_CERT_BY_SUPPLIER =
  'GET_DATA_PRODUCT_CERT_BY_SUPPLIER';
export const getDataProductCertBySupplier = defaultActionCreator(
  GET_DATA_PRODUCT_CERT_BY_SUPPLIER,
  'data'
);
