import service from './service';
import endpoint from './constantUrl';

export const getDataTransaksiPembeli = (param) => {
  return service.post(
    `${endpoint.POS_URL}/purchase/transaction/filter/`,
    param
  );
};
// export const getDataTransaksiPembeli = (param) => {
//   return service.get(`${endpoint.POS_URL}/purchase/transaction/`, param);
// };

export const getDataPembeliById = (id) => {
  return service.get(`${endpoint.POS_URL}/purchase/transaction/${id}/`);
};

export const addDataTransaksiPembeli = (data) => {
  return service.post(`${endpoint.POS_URL}/purchase/transaction/`, data);
};

export const updateDataTransaksiPembelian = (data, id) => {
  return service.put(`${endpoint.POS_URL}/purchase/transaction/${id}/`, data);
};

export const updateDataTransaksiPembelianConfirmed = (body, id) => {
  return service.put(
    `${endpoint.POS_URL}/purchase/transaction/${id}/edit-confirmed/`,
    body
  );
};

export const deleteDataTransaksiPembelian = (id) => {
  return service.delete(`${endpoint.POS_URL}/purchase/transaction/${id}/`);
};

export const approveDataTransaksiPembelian = (data, id) => {
  return service.put(
    `${endpoint.POS_URL}/purchase/transaction/${id}/approve/`,
    data
  );
};

export const downloadTemplateTransaksiPembelian = () => {
  return service.get(`${endpoint.POS_URL}/purchase/transaction/csv-template/`);
};

export const downloadDataTransaksiPembelian = () => {
  return service.get(`${endpoint.POS_URL}/purchase/transaction/export-csv/`);
};

export const uploadTransaksiPembelian = (data) => {
  return service.post(
    `${endpoint.POS_URL}/purchase/transaction/import-csv/`,
    data
  );
};

export const downloadDataTransaksiPembelianExcel = (param) => {
  return service.postArrayBuffer(
    `${endpoint.POS_URL}/purchase/transaction/export-excel/`,
    param
  );
};

export const exportPembelianExcel = (param) => {
  return service.postDownloadService(
    `${endpoint.DOWNLOAD}/purchase/excel`,
    param
  );
};
