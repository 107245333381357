import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'stockinventory/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'stockinventory/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_STOCK_INVENTORY = 'GET_DATA_STOCK_INVENTORY';
export const getDataStockInventory = defaultActionCreator(
  GET_DATA_STOCK_INVENTORY,
  'param'
);

export const GET_DATA_STOCK_INVENTORY_PRODUCT =
  'GET_DATA_STOCK_INVENTORY_PRODUCT';
export const getDataStockInventoryProduct = defaultActionCreator(
  GET_DATA_STOCK_INVENTORY_PRODUCT,
  'param'
);

export const GET_DATA_STOCK_INVENTORY_BY_ID = 'GET_DATA_STOCK_INVENTORY_BY_ID';
export const getDataStockInventoryById = defaultActionCreator(
  GET_DATA_STOCK_INVENTORY_BY_ID,
  'id',
  'param'
);
export const GET_DATA_STOCK_INVENTORY_NEXT_OR_PREV =
  'GET_DATA_STOCK_INVENTORY_NEXT_OR_PREV';
export const getDataStockInventoryNextOrPrev = defaultActionCreator(
  GET_DATA_STOCK_INVENTORY_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_STOCK_INVENTORY = 'ADD_DATA_STOCK_INVENTORY';
export const addDataStockInventory = defaultActionCreator(
  ADD_DATA_STOCK_INVENTORY,
  'data'
);
export const UPDATE_DATA_STOCK_INVENTORY = 'UPDATE_DATA_STOCK_INVENTORY';
export const updateDataStockInventory = defaultActionCreator(
  UPDATE_DATA_STOCK_INVENTORY,
  'data',
  'id'
);

export const DELETE_DATA_STOCK_INVENTORY = 'DELETE_DATA_STOCK_INVENTORY';
export const deleteDataStockInventory = defaultActionCreator(
  DELETE_DATA_STOCK_INVENTORY,
  'id'
);

export const DOWNLOAD_TEMPLATE_STOCK_INVENTORY =
  'DOWNLOAD_TEMPLATE_STOCK_INVENTORY';
export const downloadTemplateStockInventory = defaultActionCreator(
  DOWNLOAD_TEMPLATE_STOCK_INVENTORY,
  'id'
);

export const DOWNLOAD_DATA_STOCK_INVENTORY = 'DOWNLOAD_DATA_STOCK_INVENTORY';
export const downloadDataStockInventory = defaultActionCreator(
  DOWNLOAD_DATA_STOCK_INVENTORY,
  'param'
);

export const UPLOAD_DATA_STOCK_INVENTORY = 'UPLOAD_DATA_STOCK_INVENTORY';
export const uploadDataStockInventory = defaultActionCreator(
  UPLOAD_DATA_STOCK_INVENTORY,
  'data'
);

export const DOWNLOAD_DATA_STOCK_INVENTORY_EXCEL =
  'DOWNLOAD_DATA_STOCK_INVENTORY_EXCEL';
export const downloadDataStockInventoryExcel = defaultActionCreator(
  DOWNLOAD_DATA_STOCK_INVENTORY_EXCEL,
  'param'
);

export const GET_DATA_STOCK_INVENTORY_HISTORIES =
  'GET_DATA_STOCK_INVENTORY_HISTORIES';
export const getDataStockInventoryHistories = defaultActionCreator(
  GET_DATA_STOCK_INVENTORY_HISTORIES,
  'id',
  'param'
);
export const GET_DATA_STOCK_INVENTORY_BATCHS =
  'GET_DATA_STOCK_INVENTORY_BATCHS';
export const getDataStockInventoryBatchs = defaultActionCreator(
  GET_DATA_STOCK_INVENTORY_BATCHS,
  'id',
  'param'
);

export const GET_DATA_STOCK_INVENTORY_DISTRIBUTIONS =
  'GET_DATA_STOCK_INVENTORY_DISTRIBUTIONS';
export const getDataStockInventoryDistributions = defaultActionCreator(
  GET_DATA_STOCK_INVENTORY_DISTRIBUTIONS,
  'id',
  'param'
);

export const GET_DATA_STOCK_INVENTORY_DISTRIBUTIONS_V1 =
  'GET_DATA_STOCK_INVENTORY_DISTRIBUTIONS_V1';
export const getDataStockInventoryDistributionsV1 = defaultActionCreator(
  GET_DATA_STOCK_INVENTORY_DISTRIBUTIONS_V1,
  'id',
  'param'
);

export const CHANGE_INVENTORY_TYPE = 'CHANGE_INVENTORY_TYPE';
export const changeInventoryType = defaultActionCreator(
  CHANGE_INVENTORY_TYPE,
  'data'
);

export const GET_DATA_STOCK_INVENTORY_DISTRIBUTION_BY_HISTORY =
  'GET_DATA_STOCK_INVENTORY_DISTRIBUTION_BY_HISTORY';
export const getDataStockInventoryDistributionByHistory = defaultActionCreator(
  GET_DATA_STOCK_INVENTORY_DISTRIBUTION_BY_HISTORY,
  'param'
);
