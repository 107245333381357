import { all, put, takeLatest, call } from 'redux-saga/effects';
import {
  getGroup,
  getGroupById,
  addGroup,
  updateGroup,
  deleteGroup,
  downloadDataGroup,
  activationGroup,
  exportGroupExcel
} from '../../../services/group';
import { handleDownload, logMatches } from '../../../utils';
import {
  SET_STATE,
  GET_DATA_GROUP,
  GET_DATA_GROUP_BY_ID,
  ADD_DATA_GROUP,
  UPDATE_DATA_GROUP,
  DELETE_DATA_GROUP,
  SET_ERROR_FORM,
  DOWNLOAD_DATA_GROUP,
  ACTIVATION_DATA_GROUP,
  DOWNLOAD_DATA_GROUP_EXCEL
} from './actions';

import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_GROUP(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getGroup, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_GROUP_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getGroupById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* ADD_GROUP(action) {
  yield put({ type: SET_STATE, payload: { loadingForm: true } });
  try {
    yield call(addGroup, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Group', 'Create Group');
    let message = 'Your Data Has Been Submitted';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  }
}
export function* UPDATE_GROUP(action) {
  yield put({ type: SET_STATE, payload: { loadingForm: true } });
  try {
    yield call(updateGroup, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Group', 'Edit Group');
    let message = 'Your Data Has Been Submitted';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  }
}

export function* DELETE_GROUP(action) {
  yield put({ type: SET_STATE, payload: { loadingDelete: true } });
  try {
    yield call(deleteGroup, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Group', 'Delete Group');
    let message = 'Successfully deleting the Data';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isDelete: true, message } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
    yield put({
      type: SET_STATE,
      payload: { loadingDelete: false }
    });
  }
}

export function* DOWNLOAD_GROUP(action) {
  try {
    const response = yield call(downloadDataGroup, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* ACTIVATION_GROUP(action) {
  yield put({ type: SET_STATE, payload: { loadingActive: true } });
  try {
    yield call(activationGroup, action.id62);
    yield setIpUser();
    const body = bodyLog('edit', 'Group', 'Delete Group');
    let message = 'Successfully updateing the Data';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isActive: true, message } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
    yield put({
      type: SET_STATE,
      payload: { loadingActive: false }
    });
  }
}

export function* DOWNLOAD_GROUP_EXCEL(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(exportGroupExcel, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_GROUP, GET_GROUP),
  takeLatest(GET_DATA_GROUP_BY_ID, GET_GROUP_BY_ID),
  takeLatest(ADD_DATA_GROUP, ADD_GROUP),
  takeLatest(UPDATE_DATA_GROUP, UPDATE_GROUP),
  takeLatest(DELETE_DATA_GROUP, DELETE_GROUP),
  takeLatest(DOWNLOAD_DATA_GROUP, DOWNLOAD_GROUP),
  takeLatest(ACTIVATION_DATA_GROUP, ACTIVATION_GROUP),
  takeLatest(DOWNLOAD_DATA_GROUP_EXCEL, DOWNLOAD_GROUP_EXCEL)
]);
