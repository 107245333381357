import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'customer/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'customer/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_CUSTOMER = 'GET_DATA_CUSTOMER';
export const getDataCustomer = defaultActionCreator(GET_DATA_CUSTOMER, 'param');
