import { all, put, takeLatest, call } from 'redux-saga/effects';
import {
  GET_DATA_STOK_OUT,
  SET_STATE,
  CREATE_DATA_STOCK_OUT,
  GENERATE_NO_STOK_OUT,
  SET_ERROR_FORM,
  GET_STOCK_OUT_BY_ID,
  DELETE_DATA_STOCK_OUT,
  UPDATE_STATUS_KALKULASI_OUT,
  UPDATE_STOCK_OUT,
  DOWNLOAD_EXCEL_STOK_OUT,
  TEMPLATE_CSV_STOK_OUT,
  UPLOAD_STOK_OUT
} from './actions';
import {
  getDataStokOut,
  createDataStokOut,
  generateNoStockOut,
  getStockOutbyId,
  deleteStockOut,
  updateStatusKalkulasi,
  updateStokOut,
  downloadExcel,
  templateCsv,
  uploadDataStockIn
} from '../../../services/stockOut';
import { handleDownload, logMatches } from '../../../utils/index';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_STOK_OUT(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataStokOut, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* CREATE_STOCK_OUT(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(createDataStokOut, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Stok Out', 'Create Stok Out');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GENERATE_NUMBER(action) {
  yield put({ type: SET_STATE, payload: { loadingGenerate: true } });
  try {
    const { data } = yield call(generateNoStockOut, action.data);
    yield put({ type: SET_STATE, payload: { generateNumber: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingGenerate: false } });
  }
}

export function* GET_DATA_OUT_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getStockOutbyId, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DELETE_STOCK_OUT(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteStockOut, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Stok Out', 'Delete Stok Out');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loading: false }
    });
  }
}

export function* UPDATE_DATA_KALKULASI(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateStatusKalkulasi, action.data, action.id);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* UPDATE_DATA_STOCK_OUT(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateStokOut, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Stok Out', 'Edit Stok Out');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* UPLOAD_DATA_STOCK_OUT(action) {
  yield put({ type: SET_STATE, payload: { loadingUpload: true } });
  try {
    yield call(uploadDataStockIn, action.data);
    yield put({
      type: SET_STATE,
      payload: { isUpload: true, isUpdate: true }
    });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({
      type: SET_STATE,
      payload: { errorUpload: error, isUpload: false }
    });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loadingUpload: false }
    });
  }
}

export function* DOWNLOAD_DATA_EXCEL_STOK_OUT() {
  // yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(downloadExcel);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* TEMPLATE_DATA_CSV() {
  // yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(templateCsv);
    yield handleDownload(data, 'stok-keluar-template.csv');
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export default all([
  takeLatest(GET_DATA_STOK_OUT, GET_STOK_OUT),
  takeLatest(CREATE_DATA_STOCK_OUT, CREATE_STOCK_OUT),
  takeLatest(GENERATE_NO_STOK_OUT, GENERATE_NUMBER),
  takeLatest(GET_STOCK_OUT_BY_ID, GET_DATA_OUT_BY_ID),
  takeLatest(DELETE_DATA_STOCK_OUT, DELETE_STOCK_OUT),
  takeLatest(UPDATE_STATUS_KALKULASI_OUT, UPDATE_DATA_KALKULASI),
  takeLatest(UPDATE_STOCK_OUT, UPDATE_DATA_STOCK_OUT),
  takeLatest(UPLOAD_STOK_OUT, UPLOAD_DATA_STOCK_OUT),
  takeLatest(DOWNLOAD_EXCEL_STOK_OUT, DOWNLOAD_DATA_EXCEL_STOK_OUT),
  takeLatest(TEMPLATE_CSV_STOK_OUT, TEMPLATE_DATA_CSV)
]);
