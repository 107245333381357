import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'user/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'user/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_USER_ROLE = 'GET_DATA_USER_ROLE';
export const getDataUser = defaultActionCreator(GET_DATA_USER_ROLE, 'param');
export const GETD_DATA_USER_BY_ID = 'GET_DATA_USER_BY_ID';
export const getDataUserById = defaultActionCreator(GETD_DATA_USER_BY_ID, 'id');

// export const GET_MODULE_NEXT_OR_PREV = 'GET_MODULE_NEXT_OR_PREV';
// export const getDataProductNextOrPrev = defaultActionCreator(GET_MODULE_NEXT_OR_PREV, 'link');

export const ADD_DATA_USER = 'ADD_DATA_USER';
export const addDataUser = defaultActionCreator(ADD_DATA_USER, 'data');

export const DELETE_DATA_USER = 'DELETE_DATA_USER';
export const deleteDataUser = defaultActionCreator(DELETE_DATA_USER, 'id');

export const UPDATE_DATA_USER = 'UPDATE_DATA_USER';
export const updateDataUser = defaultActionCreator(
  UPDATE_DATA_USER,
  'id',
  'data'
);

export const GET_USER_TYPE_INTERNAL = 'GET_USER_TYPE_INTERNAL';
export const getUserTypeInternal = defaultActionCreator(
  GET_USER_TYPE_INTERNAL,
  'param'
);

export const DOWNLOAD_DATA_USER_ROLE_EXCEL = 'DOWNLOAD_DATA_USER_ROLE_EXCEL';
export const downloadDataUserRoleExcel = defaultActionCreator(
  DOWNLOAD_DATA_USER_ROLE_EXCEL,
  'param'
);
