import TagManager from 'react-gtm-module';

const getUserIdAndEmail = () => {
  let userId = localStorage.getItem('user_id_logout') || '';
  let email = localStorage.getItem('email_logout') || '';
  if (userId) {
    localStorage.removeItem('user_id_logout');
    localStorage.removeItem('email_logout');
    return { userId, email };
  } else {
    userId = localStorage.getItem('user_id');
    email = localStorage.getItem('email');
    return { userId, email };
  }
};

export const init = (props = {}) => {
  const { email, userId } = getUserIdAndEmail();
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    userId,
    dataLayer: { userId, email, ...props }
  };
  TagManager.initialize(tagManagerArgs);
};

export const sendDataLayer = (tagManagerArgs) =>
  TagManager.dataLayer(tagManagerArgs);

const createEvents = (event, obj) =>
  TagManager.dataLayer({
    dataLayer: {
      event,
      userId: localStorage.getItem('user_id'),
      email: localStorage.getItem('email'),
      ...obj
    }
  });

export const inputSearchEvents = (value_search) =>
  createEvents('input_search', { value_search });

export const filterSearchEvents = (filter_name) =>
  createEvents('filter_search', { filter_name });

export const actionEvents = (action_category) =>
  createEvents('action', { action_category });

export const addReviewEvents = (obj) => createEvents('review', obj);

export const pageTabDurationEvents = () => {
  const pageTab = sessionStorage.getItem('name-tab');
  if (!pageTab) {
    return;
  }
  const startDate = sessionStorage.getItem('start-tab-date');
  const start = new Date(startDate);
  const end = new Date();
  const pageTabDuration = end - start;
  sessionStorage.removeItem('name-tab');
  sessionStorage.removeItem('start-tab-date');
  createEvents('page-tab-duration', {
    pagePath: window.location.pathname,
    pageTab,
    pageTabDuration
  });
};
