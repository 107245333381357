import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'productSku/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'productSku/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_PRODUCT_SKU = 'GET_DATA_PRODUCT_SKU';
export const getDataProductSku = defaultActionCreator(
  GET_DATA_PRODUCT_SKU,
  'param'
);
export const GET_DATA_PRODUCT_SKU_BY_ID = 'GET_DATA_PRODUCT_SKU_BY_ID';
export const getDataProductSkuById = defaultActionCreator(
  GET_DATA_PRODUCT_SKU_BY_ID,
  'id'
);
export const GET_DATA_PRODUCT_SKU_NEXT_OR_PREV =
  'GET_DATA_PRODUCT_SKU_NEXT_OR_PREV';
export const getDataProductSkuNextOrPrev = defaultActionCreator(
  GET_DATA_PRODUCT_SKU_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_PRODUCT_SKU = 'ADD_DATA_PRODUCT_SKU';
export const addDataProductSku = defaultActionCreator(
  ADD_DATA_PRODUCT_SKU,
  'data'
);
export const UPDATE_DATA_PRODUCT_SKU = 'UPDATE_DATA_PRODUCT_SKU';
export const updateDataProductSku = defaultActionCreator(
  UPDATE_DATA_PRODUCT_SKU,
  'data',
  'id'
);

export const DELETE_DATA_PRODUCT_SKU = 'DELETE_DATA_PRODUCT_SKU';
export const deleteDataProductSku = defaultActionCreator(
  DELETE_DATA_PRODUCT_SKU,
  'id'
);

export const DOWNLOAD_TEMPLATE_PRODUCT_SKU = 'DOWNLOAD_TEMPLATE_PRODUCT_SKU';
export const downloadTemplateProductSku = defaultActionCreator(
  DOWNLOAD_TEMPLATE_PRODUCT_SKU,
  'id'
);

export const DOWNLOAD_DATA_PRODUCT_SKU = 'DOWNLOAD_DATA_PRODUCT_SKU';
export const downloadDataProductSku = defaultActionCreator(
  DOWNLOAD_DATA_PRODUCT_SKU,
  'param'
);

export const UPLOAD_DATA_PRODUCT_SKU = 'UPLOAD_DATA_PRODUCT_SKU';
export const uploadDataProductSku = defaultActionCreator(
  UPLOAD_DATA_PRODUCT_SKU,
  'data'
);

export const ACTIVATION_DATA_PRODUCT_SKU = 'ACTIVATION_DATA_PRODUCT_SKU';
export const activationDataProductSku = defaultActionCreator(
  ACTIVATION_DATA_PRODUCT_SKU,
  'id62'
);

export const DOWNLOAD_DATA_PRODUCT_SKU_EXCEL =
  'DOWNLOAD_DATA_PRODUCT_SKU_EXCEL';
export const downloadDataProductSkuExcel = defaultActionCreator(
  DOWNLOAD_DATA_PRODUCT_SKU_EXCEL,
  'param'
);
