import service from './service';
import endpoint from './constantUrl';

export const createApproval = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/user/approval/`, data);
};

export const getApproval = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/user/approval/`, param);
};

export const getUserApproval = (param) => {
  return service.get(
    `${endpoint.ACCOUNT_URL}/user/user/?user_type=internal`,
    param
  );
};

export const deleteApproval = (id) => {
  return service.delete(`${endpoint.MASTERDATA_URL}/user/approval/${id}/`);
};

export const updateApproval = (id, data) => {
  return service.put(`${endpoint.MASTERDATA_URL}/user/approval/${id}/`, data);
};

export const getApprovalById = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/user/approval/${id}/`);
};
