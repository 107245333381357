import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeInboundManagement = [
  {
    path: '/inbound',
    parent: '/inbound',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "InboundManagement" */ '../Pages/InboundManagement'
        )
      )
    )
  },
  {
    path: '/inbound/add',
    parent: '/inbound',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "InboundManagementAdd" */ '../Pages/InboundManagement/add'
        )
      )
    )
  },
  {
    path: '/inbound/:id',
    parent: '/inbound',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "InboundManagementDetail" */ '../Pages/InboundManagement/detail'
        )
      )
    )
  },
  {
    path: '/inbound/approval/:id',
    parent: '/inbound',
    action: 'approve',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "InboundManagementApproval" */ '../Pages/InboundManagement/approval'
        )
      )
    )
  }
];
