import service from './service';
import endpoint from './constantUrl';

// DeliveryMethod
export const addDeliveryMethod = (data) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/delivery-method/delivery-method/`,
    data
  );
};
export const getDeliveryMethod = (param) => {
  return service.get(
    `${endpoint.MASTERDATA_URL}/delivery-method/delivery-method/`,
    param
  );
};
export const getDeliveryMethodById = (id) => {
  return service.get(
    `${endpoint.MASTERDATA_URL}/delivery-method/delivery-method/${id}/`
  );
};

export const updateDeliveryMethod = (data, id) => {
  return service.put(
    `${endpoint.MASTERDATA_URL}/delivery-method/delivery-method/${id}/`,
    data
  );
};

export const deleteDeliveryMethod = (id) => {
  return service.delete(
    `${endpoint.MASTERDATA_URL}/delivery-method/delivery-method/${id}/`
  );
};
