import { SET_STATE, SET_ERROR_FORM } from './actions';

const initialState = {
  filter: {
    search: '',
    limit: 10,
    offset: 0,
    role_id62: null,
    warehouse_id62: null,
    tenant_id62: null,
    sort: null
  },
  currentPage: 0,
  loading: false,
  error: null,
  form: {},
  formValidation: {}
};

export default function assessmentReducer(state = initialState, action) {
  switch (action.type) {
    case SET_STATE:
      return { ...state, ...action.payload };

    case SET_ERROR_FORM:
      return { ...state, formValidation: { ...action.error } };
    default:
      return state;
  }
}
