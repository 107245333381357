import service from './service';
import endpoint from './constantUrl';

export const getReceiptInbound = (param) => {
  return service.post(`${endpoint.POS_URL}/inbound/receipt/filter/`, param);
};

export const getReceiptInboundById = (id) => {
  return service.get(`${endpoint.POS_URL}/inbound/receipt/${id}/`);
};

export const addReceiptInbound = (data) => {
  return service.post(`${endpoint.POS_URL}/inbound/receipt/`, data);
};

export const updateReceiptInbound = (data, id) => {
  return service.put(`${endpoint.POS_URL}/inbound/receipt/${id}/`, data);
};

export const exportReceiptInboundPDF = (id) => {
  return service.postDownloadService(
    `${endpoint.DOWNLOAD}/inbound/${id}/pdf-receipt`
  );
};

export const exportReceiptInboundExcel = (param) => {
  return service.postDownloadService(
    `${endpoint.DOWNLOAD}/inbound/excel-receipt-list`,
    param
  );
};
