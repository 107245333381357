import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeDocumentType = [
  {
    path: '/document-type',
    parent: '/document-type',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "DocumentType" */ '../Pages/MasterData/DocumentType'
        )
      )
    )
  },
  {
    path: '/document-type/:id',
    parent: '/document-type',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "DocumentTypeDetail" */ '../Pages/MasterData/DocumentType/detail'
        )
      )
    )
  },
  {
    path: '/document-type/add',
    parent: '/document-type',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "DocumentTypeAdd" */ '../Pages/MasterData/DocumentType/add'
        )
      )
    )
  },
  {
    path: '/document-type/update/:id',
    parent: '/document-type',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "DocumentTypeUpdate" */ '../Pages/MasterData/DocumentType/update'
        )
      )
    )
  }
];
