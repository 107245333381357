import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'group/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'group/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_GROUP = 'GET_DATA_GROUP';
export const getDataGroup = defaultActionCreator(GET_DATA_GROUP, 'param');

export const GET_DATA_GROUP_BY_ID = 'GET_DATA_GROUP_BY_ID';
export const getDataGroupById = defaultActionCreator(
  GET_DATA_GROUP_BY_ID,
  'id'
);

export const ADD_DATA_GROUP = 'ADD_DATA_GROUP';
export const addDataGroup = defaultActionCreator(ADD_DATA_GROUP, 'data');

export const UPDATE_DATA_GROUP = 'UPDATE_DATA_GROUP';
export const updateDataGroup = defaultActionCreator(
  UPDATE_DATA_GROUP,
  'id',
  'data'
);

export const DELETE_DATA_GROUP = 'DELETE_DATA_GROUP';
export const deleteDataGroup = defaultActionCreator(DELETE_DATA_GROUP, 'id');

export const DOWNLOAD_DATA_GROUP = 'DOWNLOAD_DATA_GROUP';
export const downloadDataGroup = defaultActionCreator(
  DOWNLOAD_DATA_GROUP,
  'param'
);

export const ACTIVATION_DATA_GROUP = 'ACTIVATION_DATA_GROUP';
export const activationDataGroup = defaultActionCreator(
  ACTIVATION_DATA_GROUP,
  'id62'
);

export const DOWNLOAD_DATA_GROUP_EXCEL = 'DOWNLOAD_DATA_GROUP_EXCEL';
export const downloadDataGroupExcel = defaultActionCreator(
  DOWNLOAD_DATA_GROUP_EXCEL,
  'param'
);
