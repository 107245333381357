import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'container/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'container/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_CONTAINER = 'GET_DATA_CONTAINER';
export const getDataContainer = defaultActionCreator(
  GET_DATA_CONTAINER,
  'param'
);

export const GET_DATA_CONTAINER_BY_ID = 'GET_DATA_CONTAINER_BY_ID';
export const getDataContainerById = defaultActionCreator(
  GET_DATA_CONTAINER_BY_ID,
  'id'
);

export const GET_DATA_CONTAINER_NEXT_OR_PREV =
  'GET_DATA_CONTAINER_NEXT_OR_PREV';
export const getDataContainerNextOrPrev = defaultActionCreator(
  GET_DATA_CONTAINER_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_CONTAINER = 'ADD_DATA_CONTAINER';
export const addDataContainer = defaultActionCreator(
  ADD_DATA_CONTAINER,
  'data'
);

export const UPDATE_DATA_CONTAINER = 'UPDATE_DATA_CONTAINER';
export const updateDataContainer = defaultActionCreator(
  UPDATE_DATA_CONTAINER,
  'data',
  'id'
);

export const DELETE_DATA_CONTAINER = 'DELETE_DATA_CONTAINER';
export const deleteDataContainer = defaultActionCreator(
  DELETE_DATA_CONTAINER,
  'id'
);
