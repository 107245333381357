import { all, put, takeLatest, call } from 'redux-saga/effects';
import { GET_DATA_INTEREST_SERVICE, SET_STATE } from './actions';
import { getInterestServiceData } from '../../../services/interestService';

export function* GET_INTEREST_SERVICE(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getInterestServiceData, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_INTEREST_SERVICE, GET_INTEREST_SERVICE)
]);
