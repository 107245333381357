import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeRetur = [
  {
    path: '/retur-penjualan',
    parent: '/retur-penjualan',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupPenjualananRetur" */ '../Pages/Penjualanan/Retur'
        )
      )
    )
  },
  {
    path: '/retur-penjualan/detail/:id',
    parent: '/retur-penjualan',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupPenjualananReturDetail" */ '../Pages/Penjualanan/Retur/detail'
        )
      )
    )
  },
  {
    path: '/retur-penjualan/update/:id',
    parent: '/retur-penjualan',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupPenjualananReturUpdate" */ '../Pages/Penjualanan/Retur/update'
        )
      )
    )
  },
  {
    path: '/retur-pembelian',
    parent: '/retur-penjualan',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupPenjualananReturAdd" */ '../Pages/Pembelian/Retur/returPembelian'
        )
      )
    )
  },
  {
    path: '/retur-pembelian/update/:id',
    parent: '/retur-penjualan',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupPenjualananReturAdd" */ '../Pages/Pembelian/Retur/returPembelianUpdate'
        )
      )
    )
  },
  {
    path: '/retur-pembelian/:id',
    parent: '/retur-penjualan',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupPenjualananReturAdd" */ '../Pages/Pembelian/Retur/returPembelianDetail'
        )
      )
    )
  },
  {
    path: '/retur-pembelian/add',
    parent: '/retur-penjualan',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupPenjualananReturAdd" */ '../Pages/Pembelian/Retur/returPembelianAdd'
        )
      )
    )
  },
  {
    path: '/retur-penjualan/add',
    parent: '/retur-penjualan',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupPenjualananReturAdd" */ '../Pages/Penjualanan/Retur/add'
        )
      )
    )
  }
];
