import { combineReducers } from 'redux';

import auth from './auth/reducer';
import product from './product/reducer';
import productSku from './productSku/reducer';
import category from './category/reducer';
import unit from './unit/reducer';
import userMasterData from './userMasterData/reducer';
import module from './module/reducer';
import subModule from './subModule/reducer';
import reference from './reference/reducer';
import gudang from './gudang/reducer';
import moduleGroup from './moduleGroup/reducer';
import rolePermission from './rolePermission/reducer';
import penyewa from './penyewa/reducer';
import stokIn from './stokIn/reducer';
import stokOut from './stokOut/reducer';
import transaksiPenjualan from './transaksiPenjualan/reducer';
import stockInventory from './stockInventory/reducer';
import networkType from './networkType/reducer';
import komoditi from './komoditi/reducer';
import jenisUsaha from './jenisUsaha/reducer';
import perusahaan from './perusahaan/reducer';
import transaksiPembeli from './transaksiPembeli/reducer';
import network from './network/reducer';
import user from './userRole/reducer';
import supplier from './supplier/reducer';
import customer from './customer/reducer';
import packaging from './packaging/reducer';
import tagihanPembelian from './tagihanPembelian/reducer';
import returPembelian from './returPembelian/reducer';
import tagihanPenjualan from './tagihanPenjualan/reducer';
import returPenjualan from './returPenjualan/reducer';
import approval from './approval/reducer';
import log from './Activity/reducer';
import profileUser from './profileUser/reducer';
import stockBatch from './stockBatch/reducer';
import inboundManagement from './inboundManagement/reducer';
import inventoryType from './inventoryType/reducer';
import dashboardMap from './dashboardMap/reducer';
import outbound from './outbound/reducer';
import deliveryMethod from './deliveryMethod/reducer';
import stockMovement from './stockMovement/reducer';
import transfer from './transfer/reducer';
import delivery from './delivery/reducer';
import warehouseType from './warehouseType/reducer';
import inventoryChanges from './inventoryChanges/reducer';
import stockAdjustment from './stockAdjustment/reducer';
import fishfinChanges from './fishfinChanges/reducer';
import voidTransaction from './void/reducer';
import fishfinChangesReturn from './fishfinChangesReturn/reducer';
import size from './size/reducer';
import grade from './grade/reducer';
import process from './process/reducer';
import container from './container/reducer';
import prospectWarehouse from './prospectWarehouse/reducer';
import specificCondition from './specificCondition/reducer';
import group from './group/reducer';
import fishingArea from './fishingArea/reducer';
import stockReservation from './stockReservation/reducer';
import receiptInbound from './receiptInbound/reducer';
import documentType from './documentType/reducer';
import hsCode from './hsCode/reducer';
import certification from './certification/reducer';
import interestService from './interest-service/reducer';
import tag from './tag/reducer';
import storageCategory from './storageCategory/reducer';

const createRootReducer = () =>
  combineReducers({
    reference,
    rolePermission,
    moduleGroup,
    gudang,
    unit,
    network,
    transaksiPembeli,
    tagihanPembelian,
    returPembelian,
    user,
    category,
    module,
    subModule,
    productSku,
    product,
    userMasterData,
    auth,
    penyewa,
    stokIn,
    stokOut,
    transaksiPenjualan,
    stockInventory,
    networkType,
    jenisUsaha,
    komoditi,
    perusahaan,
    supplier,
    packaging,
    tagihanPenjualan,
    returPenjualan,
    customer,
    approval,
    log,
    stockBatch,
    profileUser,
    inboundManagement,
    inventoryType,
    dashboardMap,
    transfer,
    stockMovement,
    deliveryMethod,
    outbound,
    delivery,
    inventoryChanges,
    warehouseType,
    stockAdjustment,
    fishfinChanges,
    voidTransaction,
    fishfinChangesReturn,
    size,
    grade,
    process,
    container,
    prospectWarehouse,
    specificCondition,
    group,
    fishingArea,
    stockReservation,
    receiptInbound,
    documentType,
    hsCode,
    certification,
    interestService,
    tag,
    storageCategory
  });

export default createRootReducer;
