import { all, put, takeLatest, call } from 'redux-saga/effects';
import {
  createApproval,
  getApproval,
  deleteApproval,
  updateApproval,
  getApprovalById
} from '../../../services/approval';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

import {
  CREATE_APPROVAL,
  SET_STATE,
  SET_ERROR_FORM,
  GET_APPROVAL,
  DELETE_APPROVAL,
  UPDATE_APPROVAL,
  GET_APPROVAL_BY_ID
} from './actions';

export function* CREATE_DATA_APPROVAL(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(createApproval, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Approval', 'Create Approval');
    yield call(createLog, body);

    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_DATA_APPROVAL(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getApproval, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DELETE_USER_APPROVAL(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteApproval, action.id);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
    yield setIpUser();
    const body = bodyLog('delete', 'Approval', 'Delete Approval');

    yield call(createLog, body);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loading: false }
    });
  }
}

export function* UPDATE_DATA_APPROVAL(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateApproval, action.data, action.id);
    const body = bodyLog('edit', 'Approval', 'Edit Approval');
    yield call(createLog, body);

    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_DATA_APPROVAL_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getApprovalById, action.id);
    yield put({ type: SET_STATE, payload: { dataApproval: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(CREATE_APPROVAL, CREATE_DATA_APPROVAL),
  takeLatest(GET_APPROVAL, GET_DATA_APPROVAL),
  takeLatest(DELETE_APPROVAL, DELETE_USER_APPROVAL),
  takeLatest(UPDATE_APPROVAL, UPDATE_DATA_APPROVAL),
  takeLatest(GET_APPROVAL_BY_ID, GET_DATA_APPROVAL_BY_ID)
]);
