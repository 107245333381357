import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'void/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'void/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_VOID = 'GET_DATA_VOID';
export const getDataVoid = defaultActionCreator(GET_DATA_VOID, 'param');

export const GET_DATA_VOID_NEXT_OR_PREV = 'GET_DATA_VOID_NEXT_OR_PREV';
export const getDataVoidNextOrPrev = defaultActionCreator(
  GET_DATA_VOID_NEXT_OR_PREV,
  'link'
);

export const GET_DATA_VOID_BY_ID = 'GET_DATA_VOID_BY_ID';
export const getDataVoidById = defaultActionCreator(GET_DATA_VOID_BY_ID, 'id');

export const ADD_DATA_VOID = 'ADD_DATA_VOID';
export const addDataVoid = defaultActionCreator(ADD_DATA_VOID, 'data');

export const APPROVAL_DATA_VOID = 'APPROVAL_DATA_VOID';
export const approvalDataVoid = defaultActionCreator(
  APPROVAL_DATA_VOID,
  'id',
  'data'
);

export const DELETE_DATA_VOID = 'DELETE_DATA_VOID';
export const deleteDataVoid = defaultActionCreator(DELETE_DATA_VOID, 'id');

export const EXPORT_DATA_VOID = 'EXPORT_DATA_VOID';
export const exportDataVoid = defaultActionCreator(EXPORT_DATA_VOID, 'param');
