import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeCertification = [
  {
    path: '/certification',
    parent: '/certification',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Certification" */ '../Pages/MasterData/Certification'
        )
      )
    )
  },
  {
    path: '/certification/:id',
    parent: '/certification',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "CertificationDetail" */ '../Pages/MasterData/Certification/detail'
        )
      )
    )
  },
  {
    path: '/certification/add',
    parent: '/certification',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "CertificationAdd" */ '../Pages/MasterData/Certification/add'
        )
      )
    )
  }
  //   {
  //     path: '/certification/update/:id',
  //     parent: '/certification',
  //     action: 'update',
  //     component: lazy(() =>
  //       retry(() =>
  //         import(
  //           /* webpackChunkName: "CertificationUpdate" */ '../Pages/MasterData/Certification/update'
  //         )
  //       )
  //     )
  //   }
];
