import service from './service';
import endpoint from './constantUrl';

// Komoditi
export const addKomoditi = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/company/comodity/`, data);
};

export const getKomoditi = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/company/comodity/`, param);
};

export const getKomoditiById = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/company/comodity/${id}/`);
};

export const updateKomoditi = (data, id) => {
  return service.put(
    `${endpoint.MASTERDATA_URL}/company/comodity/${id}/`,
    data
  );
};

export const deleteKomoditi = (id) => {
  return service.delete(`${endpoint.MASTERDATA_URL}/company/comodity/${id}/`);
};

export const downloadKomoditi = (param) => {
  return service.getArrayBuffer(
    `${endpoint.MASTERDATA_URL}/company/comodity/export-excel/`,
    param
  );
};

export const exportCommodityExcel = (param) => {
  return service.postDownloadService(
    `${endpoint.DOWNLOAD}/commodity/excel`,
    param
  );
};
