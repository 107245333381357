import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'category/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'category/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_CATEGORY = 'GET_DATA_CATEGORY';
export const getDataCategory = defaultActionCreator(GET_DATA_CATEGORY, 'param');
export const GET_DATA_CATEGORY_NEXT_OR_PREV = 'GET_DATA_CATEGORY_NEXT_OR_PREV';
export const getDataCategoryNextOrPrev = defaultActionCreator(
  GET_DATA_CATEGORY_NEXT_OR_PREV,
  'link'
);
export const GET_DATA_CATEGORY_BY_ID = 'GET_DATA_CATEGORY_BY_ID';
export const getDataCategoryById = defaultActionCreator(
  GET_DATA_CATEGORY_BY_ID,
  'id'
);

export const ADD_DATA_CATEGORY = 'ADD_DATA_CATEGORY';
export const addDataCategory = defaultActionCreator(ADD_DATA_CATEGORY, 'data');
export const UPDATE_DATA_CATEGORY = 'UPDATE_DATA_CATEGORY';
export const updateDataCategory = defaultActionCreator(
  UPDATE_DATA_CATEGORY,
  'data',
  'id'
);

export const DELETE_DATA_CATEGORY = 'DELETE_DATA_CATEGORY';
export const deleteDataCategory = defaultActionCreator(
  DELETE_DATA_CATEGORY,
  'id'
);

export const ACTIVATION_DATA_CATEGORY = 'ACTIVATION_DATA_CATEGORY';
export const activationDataCategory = defaultActionCreator(
  ACTIVATION_DATA_CATEGORY,
  'id62'
);

export const DOWNLOAD_TEMPLATE_CATEGORY = 'DOWNLOAD_TEMPLATE_CATEGORY';
export const downloadTemplateCategory = defaultActionCreator(
  DOWNLOAD_TEMPLATE_CATEGORY,
  'id'
);

export const DOWNLOAD_DATA_CATEGORY = 'DOWNLOAD_DATA_CATEGORY';
export const downloadDataCategory = defaultActionCreator(
  DOWNLOAD_DATA_CATEGORY,
  'param'
);

export const UPLOAD_DATA_CATEGORY = 'UPLOAD_DATA_CATEGORY';
export const uploadDataCategory = defaultActionCreator(
  UPLOAD_DATA_CATEGORY,
  'data'
);

export const DOWNLOAD_DATA_CATEGORY_EXCEL = 'DOWNLOAD_DATA_CATEGORY_EXCEL';
export const downloadDataCategoryExcel = defaultActionCreator(
  DOWNLOAD_DATA_CATEGORY_EXCEL,
  'param'
);
