import { all, put, takeLatest, call } from 'redux-saga/effects';
import {
  SET_STATE,
  GET_DATA_TRANSAKSI_PEMBELI,
  SET_ERROR_FORM,
  ADD_DATA_TRANSAKSI_PEMBELI,
  DELETE_DATA_TRANSAKSI_PEMBELIAN,
  GET_DATA_TRANSAKSI_PEMBELIAN_BY_ID,
  DOWNLOAD_DATA_TRANSAKSI_PEMBELIAN,
  DOWNLOAD_TEMPLATE_TRANSAKSI_PEMBELIAN,
  UPLOAD_DATA_TRANSAKSI_PEMBELIAN,
  UPDATE_DATA_TRANSAKSI_PEMBELIAN,
  APPROVE_DATA_TRANSAKSI_PEMBELI,
  UPDATE_DATA_TRANSAKSI_PEMBELIAN_CONFIRMED,
  DOWNLOAD_DATA_TRANSAKSI_PEMBELIAN_EXCEL
} from './action';
import {
  getDataTransaksiPembeli,
  getDataPembeliById,
  addDataTransaksiPembeli,
  deleteDataTransaksiPembelian,
  downloadTemplateTransaksiPembelian,
  uploadTransaksiPembelian,
  updateDataTransaksiPembelian,
  downloadDataTransaksiPembelianExcel,
  approveDataTransaksiPembelian,
  updateDataTransaksiPembelianConfirmed,
  exportPembelianExcel
} from '../../../services/transaksiPembeli';
import { handleDownload, logMatches } from '../../../utils';

import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_TRANSAKSI_PEMBELI(action) {
  yield put({ type: SET_STATE, payload: { data: null, loading: true } });
  try {
    const { data } = yield call(getDataTransaksiPembeli, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_TRANSAKSI_PEMBELIAN(action) {
  yield put({ type: SET_STATE, payload: { loadingDraft: true } });
  try {
    yield call(addDataTransaksiPembeli, action.data);
    yield setIpUser();
    const body = bodyLog(action.log.log_type, 'Pembelian', action.log.desc);
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDraft: false } });
  }
}

export function* APPROVE_TRANSAKSI_PEMBELIAN(action) {
  yield put({ type: SET_STATE, payload: { loadingApprove: true } });
  try {
    yield call(approveDataTransaksiPembelian, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('create', 'Pembelian', 'Create Purchase Transaction');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingApprove: false } });
  }
}

export function* DELETE_TRANSAKSI_PEMBELIAN(action) {
  yield put({ type: SET_STATE, payload: { loadingDelete: true } });
  try {
    yield call(deleteDataTransaksiPembelian, action.id);
    yield setIpUser();
    const body = bodyLog(
      'delete',
      'Pembelian',
      'Delete Draft Purchase Transaction'
    );
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isDelete: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDelete: false } });
  }
}

export function* GET_TRANSAKSI_PEMBELIAN_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataPembeliById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* UPDATE_TRANSAKSI_PEMBELIAN(action) {
  yield put({ type: SET_STATE, payload: { loadingDraft: true } });
  try {
    yield call(updateDataTransaksiPembelian, action.data, action.id);
    yield setIpUser();
    const body = bodyLog(action.log.log_type, 'Pembelian', action.log.desc);
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDraft: false } });
  }
}

export function* UPDATE_TRANSAKSI_PEMBELIAN_CONFIRMED(action) {
  yield put({ type: SET_STATE, payload: { loadingDraft: true } });
  try {
    yield call(updateDataTransaksiPembelianConfirmed, action.data, action.id);
    yield setIpUser();
    const body = bodyLog(action.log.log_type, 'Pembelian', action.log.desc);
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDraft: false } });
  }
}

export function* DOWNLOAD_TEMPLATE_TRANSAKSI_PEMBELIAN_F() {
  // yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(downloadTemplateTransaksiPembelian);
    yield handleDownload(data, 'transaksi_pembelian_template.csv');
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* DOWNLOAD_TRANSAKSI_PEMBELIAN(action) {
  try {
    const response = yield call(
      downloadDataTransaksiPembelianExcel,
      action.param
    );
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    console.log(error);
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* UPLOAD_TRANSAKSI_PEMBELIAN(action) {
  yield put({ type: SET_STATE, payload: { loadingUpload: true } });
  try {
    yield call(uploadTransaksiPembelian, action.data);
    yield put({
      type: SET_STATE,
      payload: { isUpload: true, isUpdate: true }
    });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({
      type: SET_STATE,
      payload: { errorUpload: error, isUpload: false }
    });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loadingUpload: false }
    });
  }
}

export function* DOWNLOAD_TRANSAKSI_PEMBELIAN_EXCEL(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(exportPembelianExcel, action.param);
    // const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, 'purchase transaction.xlsx');
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(ADD_DATA_TRANSAKSI_PEMBELI, ADD_TRANSAKSI_PEMBELIAN),
  takeLatest(APPROVE_DATA_TRANSAKSI_PEMBELI, APPROVE_TRANSAKSI_PEMBELIAN),
  takeLatest(GET_DATA_TRANSAKSI_PEMBELI, GET_TRANSAKSI_PEMBELI),
  takeLatest(DELETE_DATA_TRANSAKSI_PEMBELIAN, DELETE_TRANSAKSI_PEMBELIAN),
  takeLatest(GET_DATA_TRANSAKSI_PEMBELIAN_BY_ID, GET_TRANSAKSI_PEMBELIAN_BY_ID),
  takeLatest(DOWNLOAD_DATA_TRANSAKSI_PEMBELIAN, DOWNLOAD_TRANSAKSI_PEMBELIAN),
  takeLatest(UPDATE_DATA_TRANSAKSI_PEMBELIAN, UPDATE_TRANSAKSI_PEMBELIAN),
  takeLatest(
    UPDATE_DATA_TRANSAKSI_PEMBELIAN_CONFIRMED,
    UPDATE_TRANSAKSI_PEMBELIAN_CONFIRMED
  ),
  takeLatest(
    DOWNLOAD_TEMPLATE_TRANSAKSI_PEMBELIAN,
    DOWNLOAD_TEMPLATE_TRANSAKSI_PEMBELIAN_F
  ),
  takeLatest(UPLOAD_DATA_TRANSAKSI_PEMBELIAN, UPLOAD_TRANSAKSI_PEMBELIAN),
  takeLatest(
    DOWNLOAD_DATA_TRANSAKSI_PEMBELIAN_EXCEL,
    DOWNLOAD_TRANSAKSI_PEMBELIAN_EXCEL
  )
]);
