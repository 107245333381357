import { SET_ERROR_FORM, SET_STATE } from './actions';

const initialState = {
  loading: false,
  error: null,
  filter: {
    sku_id62: null,
    warehouse_id62: null,
    product_type: null,
    product_id62: null,
    commodity_type: null,
    offset: 0,
    search: '',
    limit: 10
  },
  filterBatch: {
    created_at: null,
    expired_date: null,
    limit: 10,
    offset: 0,
    search: null,
    sort: null
  },
  form: {},
  formValidation: {}
};

export default function assessmentReducer(state = initialState, action) {
  switch (action.type) {
    case SET_STATE:
      return { ...state, ...action.payload };
    case SET_ERROR_FORM:
      return { ...state, formValidation: { ...action.error } };
    default:
      return state;
  }
}
