import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Chart as ChartJS, registerables } from 'chart.js';

import Layout from './components/Layout';
import NoMatchPage from './components/NoMatchPage';
import { getActionModule, getToken } from './utils';
import { routePublic, routeWithoutAuth } from './routes/listRoutes';
import routeProtected from './routes/routesProcted';
import { useSelector } from 'react-redux';
import UserInfo from './components/UserInfo';
import LoadingPage from './components/LoadingPage';
import useTagManagers from './utils/useGtm';

ChartJS.register(...registerables);

function App() {
  useTagManagers();

  const auth = useSelector((state) => state.auth);
  const token = getToken();
  const actionModule = getActionModule(auth.userInfo);
  const dataAuth = auth.userInfo;
  const roleName = dataAuth?.role?.display_name;

  const filteredRoleName = roleName?.toLowerCase()?.replaceAll(' ', '');

  let routes;

  if (filteredRoleName === 'superadmin') {
    routes = routeProtected(actionModule, true);
  } else {
    routes = routeProtected(actionModule, false);
  }

  if (
    (token != null && auth.login) ||
    (token != null && auth.userInfo == null)
  ) {
    return <UserInfo />;
  }

  return (
    <>
      <Suspense fallback={<LoadingPage />}>
        <Routes>
          {token == null ? (
            routeWithoutAuth.map((item, key) => (
              <Route key={key} element={<item.component />} path={item.path} />
            ))
          ) : (
            <Route
              path="/"
              element={
                <Layout dataAuth={dataAuth} actionModule={actionModule} />
              }
            >
              {routes.listOfRoute.map((item, key) => (
                <Route
                  key={key}
                  element={
                    <item.component
                      {...{
                        token,
                        dataAuth,
                        actionModule: actionModule[item.parent],
                        pathUrl: item.path,
                        isSuperUser: dataAuth.is_superuser
                      }}
                    />
                  }
                  state={item.name}
                  path={item.path}
                />
              ))}
            </Route>
          )}
          {routePublic.map((item, key) => (
            <Route
              key={key}
              element={
                <item.component
                  {...{
                    dataAuth,
                    actionModule
                  }}
                />
              }
              path={item.path}
            />
          ))}
          <Route
            path="*"
            element={<NoMatchPage objRoutes={routes.objRoutes} />}
          />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
