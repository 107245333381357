import service from './service';
import endpoint from './constantUrl';

// Size
export const addSize = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/product/size/`, data);
};

export const getSize = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/product/size/`, param);
};

export const getSizeById = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/product/size/${id}/`);
};

export const updateSize = (data, id) => {
  return service.put(`${endpoint.MASTERDATA_URL}/product/size/${id}/`, data);
};

export const deleteSize = (id) => {
  return service.delete(`${endpoint.MASTERDATA_URL}/product/size/${id}/`);
};
