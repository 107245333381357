import service from './service';
import endpoint from './constantUrl';

export const getCertification = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/certification/`, param);
};

export const getCertificationById = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/certification/${id}/`);
};

export const addCertification = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/certification/`, data);
};

export const updateCertification = (data, id) => {
  return service.put(`${endpoint.MASTERDATA_URL}/certification/${id}/`, data);
};

export const deleteCertification = (id) => {
  return service.delete(`${endpoint.MASTERDATA_URL}/certification/${id}/`);
};

export const activationCertification = (id) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/certification/${id}/activation/`
  );
};

export const exportCertificationPDF = (param) => {
  return service.postDownloadService(
    `${endpoint.DOWNLOAD}/certification/pdf`,
    param
  );
};

export const exportCertificationExcel = (param) => {
  return service.postDownloadService(
    `${endpoint.DOWNLOAD}/certification/excel`,
    param
  );
};
