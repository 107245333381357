import service from './service';
import endpoint from './constantUrl';

export const getStockAdjustment = (param) => {
  return service.post(`${endpoint.POS_URL}/inventory/opname/filter/`, param);
};

export const addStockAdjustment = (data) => {
  return service.post(`${endpoint.POS_URL}/inventory/opname/`, data);
};

export const addStockAdjustmentById = (id62) => {
  return service.get(`${endpoint.POS_URL}/inventory/opname/${id62}/`);
};

export const getProductList = (param, id62) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/opname/${id62}/list-product/`,
    param
  );
};

export const startStockAdjustment = (data, id62) => {
  return service.put(
    `${endpoint.POS_URL}/inventory/opname/${id62}/status/`,
    data
  );
};

export const adjustStock = (data, id62) => {
  return service.put(
    `${endpoint.POS_URL}/inventory/opname/${id62}/adjust-stock/`,
    data
  );
};

export const submitStockAdjustment = (data, id62) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/opname/${id62}/submit-adjustment/`,
    data
  );
};

export const submitApproval = (data, id62) => {
  return service.put(
    `${endpoint.POS_URL}/inventory/opname/${id62}/approval/`,
    data
  );
};

export const exportExcelAdjustment = (param) => {
  return service.postArrayBuffer(
    `${endpoint.POS_URL}/inventory/opname/export-excel/`,
    param
  );
};

export const exportPDF = (id62) => {
  return service.postArrayBuffer(
    `${endpoint.POS_URL}/inventory/opname/${id62}/list-stock-pdf/`
  );
};
