import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'returPembelian/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'returPembelian/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_RETUR_PEMBELIAN = 'GET_DATA_RETUR_PEMBELIAN';
export const getDatarReturPembelian = defaultActionCreator(
  GET_DATA_RETUR_PEMBELIAN,
  'param'
);

export const GET_DATA_UNIT_NEXT_OR_PREV = 'GET_DATA_UNIT_NEXT_OR_PREV';
export const getDataUnitNextOrPrev = defaultActionCreator(
  GET_DATA_UNIT_NEXT_OR_PREV,
  'link'
);

export const GET_DATA_RETUR_PEMBELIAN_BY_ID = 'GET_DATA_RETUR_PEMBELIAN_BY_ID';
export const getDataReturPembelianById = defaultActionCreator(
  GET_DATA_RETUR_PEMBELIAN_BY_ID,
  'id'
);

export const ADD_DATA_RETUR_PEMBELIAN = 'ADD_DATA_RETUR_PEMBELIAN';
export const addDataReturPembelian = defaultActionCreator(
  ADD_DATA_RETUR_PEMBELIAN,
  'data'
);
export const UPDATE_DATA_RETUR_PEMBELIAN = 'UPDATE_DATA_RETUR_PEMBELIAN';
export const updateDataReturPembelian = defaultActionCreator(
  UPDATE_DATA_RETUR_PEMBELIAN,
  'data',
  'id'
);

export const DELETE_DATA_RETUR_PEMBELIAN = 'DELETE_DATA_RETUR_PEMBELIAN';
export const deleteDataReturPembelian = defaultActionCreator(
  DELETE_DATA_RETUR_PEMBELIAN,
  'id'
);

export const DOWNLOAD_DATA_RETUR_PEMBELIAN = 'DOWNLOAD_DATA_RETUR_PEMBELIAN';
export const downloadDataReturPembelian = defaultActionCreator(
  DOWNLOAD_DATA_RETUR_PEMBELIAN,
  'param'
);
