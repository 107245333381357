import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'profileUSer/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'profileUSer/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_PROFILE_USER = 'GET_DATA_PROFILE_USER';
export const getDataProfileUser = defaultActionCreator(
  GET_DATA_PROFILE_USER,
  'param'
);
