import service from './service';
import endpoint from './constantUrl';

// Grade
export const addGrade = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/product/grade/`, data);
};

export const getGrade = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/product/grade/`, param);
};

export const getGradeById = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/product/grade/${id}/`);
};

export const updateGrade = (data, id) => {
  return service.put(`${endpoint.MASTERDATA_URL}/product/grade/${id}/`, data);
};

export const deleteGrade = (id) => {
  return service.delete(`${endpoint.MASTERDATA_URL}/product/grade/${id}/`);
};
