import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'log/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'log/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_LOG = 'GET_LOG';
export const getDataLog = defaultActionCreator(GET_LOG, 'param');

export const GET_LOG_BY_ID = 'GET_LOG_BY_ID';
export const getLogById = defaultActionCreator(GET_LOG_BY_ID, 'id');

export const DELETE_LOG = 'DELETE_LOG';
export const deleteLog = defaultActionCreator(DELETE_LOG);

export const EXPORT_CSV = 'EXPORT_CSV';
export const exportCsv = defaultActionCreator(EXPORT_CSV, 'data');
