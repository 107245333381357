import service from './service';
import endpoint from './constantUrl';

export const getHsCode = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/hs-code/`, param);
};

export const getHsCodeById62 = (id62) => {
  return service.get(`${endpoint.MASTERDATA_URL}/hs-code/${id62}/`);
};

export const addHsCode = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/hs-code/`, data);
};

export const updateHsCode = (data, id62) => {
  return service.put(`${endpoint.MASTERDATA_URL}/hs-code/${id62}/`, data);
};

export const deleteHsCode = (id62) => {
  return service.delete(`${endpoint.MASTERDATA_URL}/hs-code/${id62}/`);
};

export const exportExcellHsCode = (param) => {
  return service.postDownloadService(
    `${endpoint.DOWNLOAD}/hs-code/excel`,
    param
  );
};
