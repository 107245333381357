import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'userData/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'userData/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_USER_MASTER_DATA = 'GET_DATA_USER_MASTER_DATA';
export const getDataUserMasterData = defaultActionCreator(
  GET_DATA_USER_MASTER_DATA,
  'param'
);

export const GET_DATA_USER_MASTER_DATA_NEXT_OR_PREV =
  'GET_DATA_USER_MASTER_DATA_NEXT_OR_PREV';
export const getDataUserMasterDataNextOrPrev = defaultActionCreator(
  GET_DATA_USER_MASTER_DATA_NEXT_OR_PREV,
  'link'
);

export const GET_DATA_USER_MASTER_DATA_BY_ID =
  'GET_DATA_USER_MASTER_DATA_BY_ID';
export const getDataUserMasterDataById = defaultActionCreator(
  GET_DATA_USER_MASTER_DATA_BY_ID,
  'id'
);

export const ADD_DATA_USER_MASTER_DATA = 'ADD_DATA_USER_MASTER_DATA';
export const addDataUserMasterData = defaultActionCreator(
  ADD_DATA_USER_MASTER_DATA,
  'data'
);

export const UPDATE_DATA_USER_MASTER_DATA = 'UPDATE_DATA_USER_MASTER_DATA';
export const updateDataUserMasterData = defaultActionCreator(
  UPDATE_DATA_USER_MASTER_DATA,
  'data',
  'id'
);

export const DELETE_DATA_USER_MASTER_DATA = 'DELETE_DATA_USER_MASTER_DATA';
export const deleteDataUserMasterData = defaultActionCreator(
  DELETE_DATA_USER_MASTER_DATA,
  'id'
);

export const GET_DATA_USER_MASTER_DATA_BY_USERTYPE =
  'GET_DATA_USER_MASTER_DATA_BY_USERTYPE';
export const getDataUserMasterDataByUserType = defaultActionCreator(
  GET_DATA_USER_MASTER_DATA_BY_USERTYPE,
  'param',
  'userType'
);

export const GET_DATA_USER_MASTER_DATA_BY_SUPPLIER =
  'GET_DATA_USER_MASTER_DATA_BY_SUPPLIER';
export const getDataUserMasterDataBySupplier = defaultActionCreator(
  GET_DATA_USER_MASTER_DATA_BY_SUPPLIER,
  'param'
);

export const ACTIVATION_DATA_USER = 'ACTIVATION_DATA_USER';
export const activationDataUser = defaultActionCreator(
  ACTIVATION_DATA_USER,
  'id62'
);

export const DOWNLOAD_DATA_TEMPLATE_USER_MASTER_DATA =
  'DOWNLOAD_DATA_TEMPLATE_USER_MASTER_DATA';
export const downloadTemplateUserMasterData = defaultActionCreator(
  DOWNLOAD_DATA_TEMPLATE_USER_MASTER_DATA,
  'id'
);

export const DOWNLOAD_DATA_USER_MASTER_DATA = 'DOWNLOAD_DATA_USER_MASTER_DATA';
export const downloadDataUserMasterData = defaultActionCreator(
  DOWNLOAD_DATA_USER_MASTER_DATA,
  'param'
);

export const UPLOAD_DATA_USER_MASTER_DATA = 'UPLOAD_DATA_USER_MASTER_DATA';
export const uploadDataUserMasterData = defaultActionCreator(
  UPLOAD_DATA_USER_MASTER_DATA,
  'data'
);

export const GET_USER_BY_ID62_ID_GUDANG = 'GET_USER_BY_ID62_ID_GUDANG';
export const getUserById62IdGudang = defaultActionCreator(
  'GET_USER_BY_ID62_ID_GUDANG',
  'id',
  'warehouse_id62'
);

export const GET_DATA_USER_SUPPLIER_MASTER_DATA =
  'GET_DATA_USER_SUPPLIER_MASTER_DATA';
export const getDataUserSupplierMasterData = defaultActionCreator(
  GET_DATA_USER_SUPPLIER_MASTER_DATA,
  'param'
);

export const GET_DATA_USER_CUSTOMER_MASTER_DATA =
  'GET_DATA_USER_CUSTOMER_MASTER_DATA';
export const getDataUserCustomerMasterData = defaultActionCreator(
  GET_DATA_USER_CUSTOMER_MASTER_DATA,
  'param'
);
