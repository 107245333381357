import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'grade/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'grade/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_GRADE = 'GET_DATA_GRADE';
export const getDataGrade = defaultActionCreator(GET_DATA_GRADE, 'param');

export const GET_DATA_GRADE_BY_ID = 'GET_DATA_GRADE_BY_ID';
export const getDataGradeById = defaultActionCreator(
  GET_DATA_GRADE_BY_ID,
  'id'
);

export const GET_DATA_GRADE_NEXT_OR_PREV = 'GET_DATA_GRADE_NEXT_OR_PREV';
export const getDataGradeNextOrPrev = defaultActionCreator(
  GET_DATA_GRADE_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_GRADE = 'ADD_DATA_GRADE';
export const addDataGrade = defaultActionCreator(ADD_DATA_GRADE, 'data');

export const UPDATE_DATA_GRADE = 'UPDATE_DATA_GRADE';
export const updateDataGrade = defaultActionCreator(
  UPDATE_DATA_GRADE,
  'data',
  'id'
);

export const DELETE_DATA_GRADE = 'DELETE_DATA_GRADE';
export const deleteDataGrade = defaultActionCreator(DELETE_DATA_GRADE, 'id');
