import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'specificCondition/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'specificCondition/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_SPECIFIC_CONDITION = 'GET_DATA_SPECIFIC_CONDITION';
export const getDataspecificCondition = defaultActionCreator(
  GET_DATA_SPECIFIC_CONDITION,
  'param'
);
