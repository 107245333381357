import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'inboundManagement/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'inboundManagement/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_INBOUND = 'GET_DATA_INBOUND';
export const getDataInbound = defaultActionCreator(GET_DATA_INBOUND, 'param');

export const GET_DATA_INBOUND_BY_ID = 'GET_DATA_INBOUND_BY_ID';
export const getDataInboundById = defaultActionCreator(
  GET_DATA_INBOUND_BY_ID,
  'id'
);

export const GET_DATA_INBOUND_NEXT_OR_PREV = 'GET_DATA_INBOUND_NEXT_OR_PREV';
export const getDataInboundNextOrPrev = defaultActionCreator(
  GET_DATA_INBOUND_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_INBOUND = 'ADD_DATA_INBOUND';
export const addDataInbound = defaultActionCreator(
  ADD_DATA_INBOUND,
  'data',
  'log'
);

export const UPDATE_DATA_INBOUND = 'UPDATE_DATA_INBOUND';
export const updateDataInbound = defaultActionCreator(
  UPDATE_DATA_INBOUND,
  'data',
  'id',
  'log'
);

export const DELETE_DATA_INBOUND = 'DELETE_DATA_INBOUND';
export const deleteDataInbound = defaultActionCreator(
  DELETE_DATA_INBOUND,
  'id'
);

export const APPROVAL_DATA_INBOUND = 'APPROVAL_DATA_INBOUND';
export const approvalDataInbound = defaultActionCreator(
  APPROVAL_DATA_INBOUND,
  'id',
  'data'
);

export const DOWNLOAD_DATA_INBOUND = 'DOWNLOAD_DATA_INBOUND';
export const downloadDataInbound = defaultActionCreator(
  DOWNLOAD_DATA_INBOUND,
  'param'
);
