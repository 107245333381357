import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeNetwork = [
  {
    path: '/network-type',
    parent: '/network-type',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "BillingTagihanTagihanTerpisah" */ '../Pages/MasterData/NetworkType/NetworkType'
        )
      )
    )
  },
  {
    path: '/network-type/add',
    parent: '/network-type',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "BillingTagihanTagihanTerpisah" */ '../Pages/MasterData/NetworkType/AddData'
        )
      )
    )
  },
  {
    path: '/network-type/view/:id',
    parent: '/network-type',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "BillingTagihanTagihanTerpisah" */ '../Pages/MasterData/NetworkType/ViewData'
        )
      )
    )
  },
  {
    path: '/network-type/update/:id',
    parent: '/network-type',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "BillingTagihanTagihanTerpisah" */ '../Pages/MasterData/NetworkType/UpdateData'
        )
      )
    )
  },
  {
    path: '/network',
    parent: '/network',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(/* webpackChunkName: "Network" */ '../Pages/MasterData/Network')
      )
    )
  },
  {
    path: '/network/:id',
    parent: '/network',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Network" */ '../Pages/MasterData/Network/View'
        )
      )
    )
  },
  {
    path: '/network/add',
    parent: '/network',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Network" */ '../Pages/MasterData/Network/Add'
        )
      )
    )
  },
  {
    path: '/network/update/:id',
    parent: '/network',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Network" */ '../Pages/MasterData/Network/Update'
        )
      )
    )
  }
  // {
  //   path: '/fishlog-network',
  //   parent: '/fishlog-network',
  //   action: 'view',
  //   isParent: true,
  //   component: lazy(() =>
  //     retry(() =>
  //       import(/* webpackChunkName: "FishLogNetwok" */ '../Pages/FishLogNetwok')
  //     )
  //   )
  // },
  // {
  //   path: '/fishlog-network/:id',
  //   parent: '/fishlog-network',
  //   action: 'view',
  //   component: lazy(() =>
  //     retry(() =>
  //       import(
  //         /* webpackChunkName: "FishLogNetwok" */ '../Pages/FishLogNetwok/rincianInventoryGudang'
  //       )
  //     )
  //   )
  // }
];
