import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'module/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'module/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_MODULE = 'GET_DATA_MODULE';
export const getDataModule = defaultActionCreator(GET_DATA_MODULE, 'param');
export const GET_DATA_MODULE_BY_ID = 'GET_DATA_MODULE_BY_ID';
export const getDataModuleById = defaultActionCreator(
  GET_DATA_MODULE_BY_ID,
  'id'
);
// export const GET_MODULE_NEXT_OR_PREV = 'GET_MODULE_NEXT_OR_PREV';
// export const getDataProductNextOrPrev = defaultActionCreator(GET_MODULE_NEXT_OR_PREV, 'link');

export const ADD_DATA_MODULE = 'ADD_DATA_MODULE';
export const addDataModule = defaultActionCreator(
  ADD_DATA_MODULE,
  'data',
  'link'
);

export const GET_DATA_MODULE_NEXT_OR_PREV = 'GET_DATA_MODULE_NEXT_OR_PREV';
export const getDataModuleNextOrPrev = defaultActionCreator(
  GET_DATA_MODULE_NEXT_OR_PREV,
  'link'
);
export const UPDATE_DATA_MODULE = 'UPDATE_DATA_MODULE';
export const updateDataModule = defaultActionCreator(
  UPDATE_DATA_MODULE,
  'data',
  'id'
);

export const DELETE_DATA_MODULE = 'DELETE_DATA_MODULE';
export const deleteDataModule = defaultActionCreator(DELETE_DATA_MODULE, 'id');
