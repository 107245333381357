import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getVoid,
  getVoidById,
  addVoid,
  approvalVoid,
  deleteVoid,
  exportVoid
} from '../../../services/Void';
import {
  SET_STATE,
  SET_ERROR_FORM,
  GET_DATA_VOID,
  GET_DATA_VOID_NEXT_OR_PREV,
  GET_DATA_VOID_BY_ID,
  ADD_DATA_VOID,
  APPROVAL_DATA_VOID,
  DELETE_DATA_VOID,
  EXPORT_DATA_VOID
} from './actions';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog, handleDownload, logMatches } from '../../../utils';

export function* GET_VOID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getVoid, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_VOID_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getVoidById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_VOID_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_VOID(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addVoid, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Void Transaction', 'Create Void Request');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* APPROVAL_VOID(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(approvalVoid, action.id, action.data);
    yield setIpUser();
    const body = bodyLog('edit', 'Void Transaction', 'Approval Void');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DELETE_VOID(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteVoid, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Void Transacrion', 'Delete Void');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DOWNLOAD_VOID(action) {
  try {
    const response = yield call(exportVoid, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  }
}

export default all([
  takeLatest(GET_DATA_VOID, GET_VOID),
  takeLatest(GET_DATA_VOID_BY_ID, GET_VOID_BY_ID),
  takeLatest(GET_DATA_VOID_NEXT_OR_PREV, GET_VOID_NEXT_OR_PREV),
  takeLatest(ADD_DATA_VOID, ADD_VOID),
  takeLatest(APPROVAL_DATA_VOID, APPROVAL_VOID),
  takeLatest(DELETE_DATA_VOID, DELETE_VOID),
  takeLatest(EXPORT_DATA_VOID, DOWNLOAD_VOID)
]);
