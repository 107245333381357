import service from './service';
import endpoint from './constantUrl';
import { convertValueNullInObj } from '../utils';

export const getWarehouse = async ({ term, page, province, regency_name }) => {
  if (province) {
    let query = `warehouse?province_name=${province}&page=${page}&limit=100`;
    query = regency_name ? query + `&regency_name=${regency_name}` : query;
    return await service.get(`${endpoint.WAREHOUSE}/${query}`);
  }
  let result = await service.get(
    `${endpoint.WAREHOUSE}/warehouse?term=${term}&page=${page}&limit=10`
  );
  if (result.data.data.length === 0) {
    result = await service.get(
      `${endpoint.WAREHOUSE}/warehouse?regency_name=${term}&page=${page}&limit=10`
    );
  }

  return result;
};

export const getProductSku = ({ term, page }) => {
  return service.get(
    `${endpoint.WAREHOUSE}/product/sku?term=${term}&page=${page}&limit=20`
  );
};

export const getWarehouseProduct = ({ skuId, page }) => {
  return service.get(
    `${endpoint.WAREHOUSE}/warehouse/sku/${skuId}?page=${page}&limit=10`
  );
};

export const getWarehouseByProvince = (params) => {
  return service.get(
    `${endpoint.WAREHOUSE}/warehouse/province/detail?limit=30&orderBy=capacity`,
    convertValueNullInObj(params)
  );
};

export const getLocationArea = ({
  groupBy,
  province,
  sortBy,
  orderBy,
  country,
  city_name,
  term
}) => {
  let order = orderBy ? orderBy : 'wh_capacity';
  console.log(order, term, sortBy, groupBy);
  let query = `orderBy=${order}&term=${
    term || ''
  }&sortBy=${sortBy}&groupBy=${groupBy}&limit=100`;
  if (province) {
    query = query + `&province_name=${province}`;
  }
  if (country) {
    query += `&country=${country}`;
  }
  if (city_name) {
    query += `&city_name=${city_name}`;
  }

  return service.get(`${endpoint.WAREHOUSE}/warehouse/area/count?${query}`);
};

export const getComodity = (params) => {
  return service.get(
    `${endpoint.WAREHOUSE}/warehouse/province/detail/commodity?orderBy=capacity`,
    convertValueNullInObj(params)
  );
};

export const getStack = (params) => {
  return service.get(
    `${endpoint.WAREHOUSE}/warehouse/province/detail/stack?orderBy=capacity`,
    convertValueNullInObj(params)
  );
};

export const getStackCustomer = (id) => {
  return service.get(
    `${endpoint.WAREHOUSE}/warehouse/${id}/stakeholdercustomer`
  );
};

export const getStackSupplier = (id) => {
  return service.get(
    `${endpoint.WAREHOUSE}/warehouse/${id}/stakeholdersupplier?limit=100`
  );
};

export const getProspectWarehouse = (params) => {
  return service.get(
    `${endpoint.WAREHOUSE}/warehouse/type/prospect?limit=30&orderBy=storage_capacity`,
    convertValueNullInObj(params)
  );
};
