import service from './service';
import endpoint from './constantUrl';

export const getStorageCategory = (param) => {
  return service.get(
    `${endpoint.MASTERDATA_URL}/warehouse/storage-category/`,
    param
  );
};

export const getStorageCategoryById62 = (id62) => {
  return service.get(
    `${endpoint.MASTERDATA_URL}/warehouse/storage-category/${id62}/`
  );
};

export const addStorageCategory = (data) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/warehouse/storage-category/`,
    data
  );
};

export const updateStorageCategory = (data, id62) => {
  return service.put(
    `${endpoint.MASTERDATA_URL}/warehouse/storage-category/${id62}/`,
    data
  );
};

export const deleteStorageCategory = (id62) => {
  return service.delete(
    `${endpoint.MASTERDATA_URL}/warehouse/storage-category/${id62}/`
  );
};

export const updateStorageCategoryStatus = (id62) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/warehouse/storage-category/${id62}/activation/`
  );
};

export const exportExcelStorageCategory = (param) => {
  return service.postDownloadService(
    `${endpoint.DOWNLOAD}/storage-category/excel`,
    param
  );
};
