import service from './service';
import endpoint from './constantUrl';

export const getStockBatch = (param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-batch/filter/`,
    param
  );
};

export const getStockBatchById = (id, isAuth) => {
  return service.getPublic(
    `${endpoint.POS_URL}/inventory/stock-batch/id62/${id}/`,
    isAuth
  );
};
export const getStockBatchByBatchNumber = (id, isAuth) => {
  return service.getPublic(
    `${endpoint.POS_URL}/inventory/stock-batch/batch-number/${id}/`,
    isAuth
  );
};

export const getStockBatchDistributionByBatchNumber = (batch_number, param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-batch/distributions/${batch_number}/`,
    param
  );
};

export const stockBatchTransfer = (data) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-batch/transfer/`,
    data
  );
};

export const stockBatchB2BSync = (data) => {
  return service.post(`${endpoint.POS_URL}/inventory/stock-batch/b2b/`, data);
};

export const getStockBatchDistribution = (param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-batch/distributions/`,
    { ...param, available_stock_only: true }
  );
};

export const getStockBatchDetailDistribution = (param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-batch/detail-distributions/`,
    param
  );
};

export const getStockBatchDistributionByBarcodeNumber = (barcode, param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-batch/barcode-distributions/${barcode}/`,
    param
  );
};

export const getStockBatchByBarcodeNumber = (barcode, param) => {
  return service.getPublic(
    `${endpoint.POS_URL}/inventory/stock-batch/barcode-number/${barcode}/`,
    param
  );
};
export const getStockBatchByBatchRef = (batch_ref, param) => {
  return service.getPublic(
    `${endpoint.POS_URL}/inventory/stock-batch/batch_number_ref/${batch_ref}/`,
    param
  );
};

export const getStockInventoryDistributionByBarcode = (param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-batch/detail-distributions-by-barcode/`,
    param
  );
};

export const getStockInventoryDistributionByBatchRef = (param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-batch/detail-distributions-by-batch-number-ref/`,
    param
  );
};

export const getStockBatchPhotos = (param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/batch-photo/filter/`,
    param
  );
};

export const addStockBatchPhotos = (data) => {
  return service.post(`${endpoint.POS_URL}/inventory/batch-photo/`, data);
};

export const deleteStockBatchPhotos = (id) => {
  return service.delete(`${endpoint.POS_URL}/inventory/batch-photo/${id}/`);
};
