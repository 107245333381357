import { lazy } from 'react';
import { retry } from '../utils/index';
export const routeProspectWarehouses = [
  {
    path: '/prospect-warehouse',
    parent: '/prospect-warehouse',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ProspectWarehouses" */ '../Pages/MasterData/ProspectWarehouses'
        )
      )
    )
  },
  {
    path: '/prospect-warehouse/add',
    parent: '/prospect-warehouse',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ProspectWarehousesAdd" */ '../Pages/MasterData/ProspectWarehouses/add'
        )
      )
    )
  },
  {
    path: '/prospect-warehouse/:id',
    parent: '/prospect-warehouse',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ProspectWarehousesDetail" */ '../Pages/MasterData/ProspectWarehouses/detail'
        )
      )
    )
  },
  {
    path: '/prospect-warehouse/edit/:idEdit',
    parent: '/prospect-warehouse',
    action: 'approve',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ProspectWarehousesEdit" */ '../Pages/MasterData/ProspectWarehouses/add'
        )
      )
    )
  }
];
