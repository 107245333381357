import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'subModule/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'subModule/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_SUBMODULE = 'GET_DATA_SUBMODULE';
export const getDataSubModule = defaultActionCreator(
  GET_DATA_SUBMODULE,
  'param'
);

export const GET_DATA_SUBMODULE_BY_ID = 'GET_DATA_SUBMODULE_BY_ID';
export const getDataSubModuleById = defaultActionCreator(
  GET_DATA_SUBMODULE_BY_ID,
  'id'
);

export const GET_DATA_SUBMODULE_NEXT_OR_PREV =
  'GET_DATA_SUBMODULE_NEXT_OR_PREV';
export const getDataProductNextOrPrev = defaultActionCreator(
  GET_DATA_SUBMODULE_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_SUBMODULE = 'ADD_DATA_SUBMODULE';
export const addDataSubModule = defaultActionCreator(
  ADD_DATA_SUBMODULE,
  'data'
);

export const UPDATE_DATA_SUB_MODULE = 'UPDATE_DATA_SUB_MODULE';
export const updateDataSUbModule = defaultActionCreator(
  UPDATE_DATA_SUB_MODULE,
  'data',
  'id'
);

export const DELETE_DATA_SUBMODULE = 'DELETE_DATA_SUBMODULE';
export const deleteDataSubModule = defaultActionCreator(
  DELETE_DATA_SUBMODULE,
  'id'
);
