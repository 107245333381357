import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'DashboardWarehouseMap/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const GET_DATA_WAREHOUSE_MAP = 'GET_DATA_WAREHOUSE_MAP';
export const getWarehouseMap = defaultActionCreator(
  GET_DATA_WAREHOUSE_MAP,
  'params'
);

export const GET_DATA_PRODUCT_SKU_MAP = 'GET_DATA_PRODUCT_SKU_MAP';
export const getProductSKU = defaultActionCreator(
  GET_DATA_PRODUCT_SKU_MAP,
  'params'
);

export const GET_DATA_WAREHOUSE_PRODUCT = 'GET_DATA_WAREHOUSE_PRODUCT';
export const getWarehouseProduct = defaultActionCreator(
  GET_DATA_WAREHOUSE_PRODUCT,
  'params'
);

export const GET_DATA_WAREHOUSE_BY_PROVINCE = 'GET_DATA_WAREHOUSE_BY_PROVINCE';
export const getWarehouseByProvince = defaultActionCreator(
  GET_DATA_WAREHOUSE_BY_PROVINCE,
  'params'
);

export const GET_WAREHOUSE_BY_AREA = 'GET_WAREHOUSE_BY_AREA';
export const getWarehouseByArea = defaultActionCreator(
  GET_WAREHOUSE_BY_AREA,
  'params'
);

export const CLEAR_WAREHOUSE_MAP = 'CLEAR_WAREHOUSE_MAP';
export const clearWareHouseMap = defaultActionCreator(
  CLEAR_WAREHOUSE_MAP,
  'data'
);

export const GET_LOCATION_AREA = 'GET_LOCATION_AREA';
export const getLocationArea = defaultActionCreator(
  GET_LOCATION_AREA,
  'params'
);

export const GET_DATA_PROSPECT_WAREHOUSE = 'GET_DATA_PROSPECT_WAREHOUSE';
export const getDataProspectWarehouse = defaultActionCreator(
  GET_DATA_PROSPECT_WAREHOUSE,
  'params'
);
