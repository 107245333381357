import service from './service';
import endpoint from './constantUrl';

export const getDocumentType = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/document-type/`, param);
};

export const getDocumentTypeById = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/document-type/${id}/`);
};

export const addDocumentType = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/document-type/`, data);
};

export const updateDocumentType = (data, id) => {
  return service.put(`${endpoint.MASTERDATA_URL}/document-type/${id}/`, data);
};

export const deleteDocumentType = (id) => {
  return service.delete(`${endpoint.MASTERDATA_URL}/document-type/${id}/`);
};

export const activationDocumentType = (id) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/document-type/${id}/activation/`
  );
};

export const exportDocumentTypePDF = (param) => {
  return service.postDownloadService(
    `${endpoint.DOWNLOAD}/document-type/pdf`,
    param
  );
};

export const exportDocumentTypeExcel = (param) => {
  return service.postDownloadService(
    `${endpoint.DOWNLOAD}/document-type/excel`,
    param
  );
};
