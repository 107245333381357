import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'transaksiPenjualan/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'TransaksiPenjualan/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

// export const GET_DATA_TRANSAKSI = 'GET_DATA_TRANSAKSI';
// export const getDataTransaksi = defaultActionCreator(
//   GET_DATA_TRANSAKSI,
//   'payload'
// );

export const GET_DATA_TRANSAKSI_PENJUALAN = 'GET_DATA_TRANSAKSI_PENJUALAN';
export const getDataTransaksiPenjualan = defaultActionCreator(
  GET_DATA_TRANSAKSI_PENJUALAN,
  'param'
);

export const ADD_DATA_TRANSAKSI_PENJUALAN = 'ADD_DATA_TRANSAKSI_PENJUALAN';
export const addDataTransaksiPenjualan = defaultActionCreator(
  ADD_DATA_TRANSAKSI_PENJUALAN,
  'data',
  'log'
);

export const DELETE_DATA_TRANSAKSI_PENJUALAN =
  'DELETE_DATA_TRANSAKSI_PENJUALAN';
export const deleteDataTransaksiPenjualan = defaultActionCreator(
  DELETE_DATA_TRANSAKSI_PENJUALAN,
  'id'
);

export const GET_DATA_TRANSAKSI_PENJUALAN_BY_ID =
  'GET_DATA_TRANSAKSI_PENJUALAN_BY_ID';
export const getDataTransaksiPenjualanById = defaultActionCreator(
  GET_DATA_TRANSAKSI_PENJUALAN_BY_ID,
  'id'
);

export const DOWNLOAD_TEMPLATE_TRANSAKSI_PENJUALAN =
  'DOWNLOAD_TEMPLATE_TRANSAKSI_PENJUALAN';
export const downloadTemplateTransaksiPenjualan = defaultActionCreator(
  DOWNLOAD_TEMPLATE_TRANSAKSI_PENJUALAN,
  'id'
);

export const UPDATE_DATA_TRANSAKSI_PENJUALAN =
  'UPDATE_DATA_TRANSAKSI_PENJUALAN';
export const updateDataTransaksiPenjualan = defaultActionCreator(
  UPDATE_DATA_TRANSAKSI_PENJUALAN,
  'data',
  'id',
  'log'
);

export const UPDATE_DATA_TRANSAKSI_PENJUALAN_CONFIRMED =
  'UPDATE_DATA_TRANSAKSI_PENJUALAN_CONFIRMED';
export const updateDataTransaksiPenjualanConfirmed = defaultActionCreator(
  UPDATE_DATA_TRANSAKSI_PENJUALAN_CONFIRMED,
  'data',
  'id',
  'log'
);

export const DOWNLOAD_DATA_TRANSAKSI_PENJUALAN =
  'DOWNLOAD_DATA_TRANSAKSI_PENJUALAN';
export const downloadDataTransaksiPenjualan = defaultActionCreator(
  DOWNLOAD_DATA_TRANSAKSI_PENJUALAN,
  'param'
);

export const UPLOAD_DATA_TRANSAKSI_PENJUALAN =
  'UPLOAD_DATA_TRANSAKSI_PENJUALAN';
export const uploadDataTransaksiPenjualan = defaultActionCreator(
  UPLOAD_DATA_TRANSAKSI_PENJUALAN,
  'data'
);

export const APPROVE_DATA_SALES = 'APPROVE_DATA_SALES';
export const approveDataSales = defaultActionCreator(
  APPROVE_DATA_SALES,
  'data',
  'id'
);

export const DOWNLOAD_DATA_PENJUALAN_EXCEL = 'DOWNLOAD_DATA_PENJUALAN_EXCEL';
export const downloadDataPenjualanExcel = defaultActionCreator(
  DOWNLOAD_DATA_PENJUALAN_EXCEL,
  'param'
);
