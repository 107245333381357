import { all, put, takeLatest, call } from 'redux-saga/effects';
import { addDeliveryOrder } from '../../../services/delivery';
import { SET_STATE, ADD_DATA_DELIVERY_ORDER, SET_ERROR_FORM } from './actions';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* ADD_DELIVERY_ORDER(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addDeliveryOrder, action.data);
    yield setIpUser();
    let message = 'Transactions On The Go';
    let model = 'success';
    const body = bodyLog('create', action.module, 'Create Delivery Order');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true, message, model } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([takeLatest(ADD_DATA_DELIVERY_ORDER, ADD_DELIVERY_ORDER)]);
