import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getCategory,
  getCategoryById,
  addCategory,
  updateCategory,
  deleteCategory,
  activationCategory,
  downloadDataCategory,
  downloadTemplateCategory,
  uploadDataCategory,
  exportCategoryExcel
} from '../../../services/category';
import { handleDownload, logMatches } from '../../../utils';
import {
  SET_STATE,
  GET_DATA_CATEGORY,
  GET_DATA_CATEGORY_BY_ID,
  ADD_DATA_CATEGORY,
  UPDATE_DATA_CATEGORY,
  DELETE_DATA_CATEGORY,
  GET_DATA_CATEGORY_NEXT_OR_PREV,
  SET_ERROR_FORM,
  ACTIVATION_DATA_CATEGORY,
  DOWNLOAD_DATA_CATEGORY,
  DOWNLOAD_TEMPLATE_CATEGORY,
  UPLOAD_DATA_CATEGORY,
  DOWNLOAD_DATA_CATEGORY_EXCEL
} from './actions';

import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_CATEGORY(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getCategory, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_CATEGORY_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_CATEGORY_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getCategoryById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_CATEGORY(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addCategory, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Category', 'Create Data Category');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* UPDATE_CATEGORY(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateCategory, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Category', 'Edit Data Category');
    yield call(createLog, body);

    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* DELETE_CATEGORY(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteCategory, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Category', 'Delete Data Category');
    yield call(createLog, body);

    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loading: false }
    });
  }
}

export function* ACTIVATION_CATEGORY(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(activationCategory, action.id62);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DOWNLOAD_TEMPLATE_CATEGORY_F() {
  // yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(downloadTemplateCategory);
    yield handleDownload(data, 'Category_template.csv');
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* DOWNLOAD_DATA_CATEGORY_F(action) {
  try {
    const { data, headers } = yield call(downloadDataCategory, action.param);
    const filename = yield logMatches(headers['content-disposition']);
    yield handleDownload(data, filename[0]);
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* UPLOAD_DATA_CATEGORY_F(action) {
  yield put({ type: SET_STATE, payload: { loadingUpload: true } });
  try {
    yield call(uploadDataCategory, action.data);
    yield put({
      type: SET_STATE,
      payload: { isUpload: true, isUpdate: true }
    });
  } catch ({ response }) {
    yield put({
      type: SET_STATE,
      payload: { errorUpload: response?.data, isUpload: false }
    });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loadingUpload: false }
    });
  }
}

export function* DOWNLOAD_CATEGORY_EXCEL(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(exportCategoryExcel, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_CATEGORY, GET_CATEGORY),
  takeLatest(GET_DATA_CATEGORY_NEXT_OR_PREV, GET_CATEGORY_NEXT_OR_PREV),
  takeLatest(GET_DATA_CATEGORY_BY_ID, GET_CATEGORY_BY_ID),
  takeLatest(ADD_DATA_CATEGORY, ADD_CATEGORY),
  takeLatest(UPDATE_DATA_CATEGORY, UPDATE_CATEGORY),
  takeLatest(DELETE_DATA_CATEGORY, DELETE_CATEGORY),
  takeLatest(ACTIVATION_DATA_CATEGORY, ACTIVATION_CATEGORY),
  takeLatest(DOWNLOAD_TEMPLATE_CATEGORY, DOWNLOAD_TEMPLATE_CATEGORY_F),
  takeLatest(DOWNLOAD_DATA_CATEGORY, DOWNLOAD_DATA_CATEGORY_F),
  takeLatest(UPLOAD_DATA_CATEGORY, UPLOAD_DATA_CATEGORY_F),
  takeLatest(DOWNLOAD_DATA_CATEGORY_EXCEL, DOWNLOAD_CATEGORY_EXCEL)
]);
