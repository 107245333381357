import service from './service';
import endpoint from './constantUrl';

export const addGroup = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/product/group/`, data);
};
export const getGroup = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/product/group/`, param);
};
export const getGroupById = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/product/group/${id}/`);
};

export const updateGroup = (data, id) => {
  return service.put(`${endpoint.MASTERDATA_URL}/product/group/${id}/`, data);
};

export const deleteGroup = (id) => {
  return service.delete(`${endpoint.MASTERDATA_URL}/product/group/${id}/`);
};

export const downloadDataGroup = (param) => {
  return service.postDownloadService(
    `${endpoint.DOWNLOAD}/group-product/excel`,
    param
  );
};

export const activationGroup = (id62) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/product/group/${id62}/group-activation/`
  );
};

export const exportGroupExcel = (param) => {
  return service.postDownloadService(`${endpoint.DOWNLOAD}/group/excel`, param);
};
