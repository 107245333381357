import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'approval/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'stokIn/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const CREATE_APPROVAL = 'CREATE_APPROVAL';
export const createApproval = defaultActionCreator(CREATE_APPROVAL, 'data');

export const GET_APPROVAL = 'GET_APPROVAL';
export const getApproval = defaultActionCreator(GET_APPROVAL, 'param');

export const DELETE_APPROVAL = 'DELETE_APPROVAL';
export const deleteApproval = defaultActionCreator(DELETE_APPROVAL, 'id');

export const UPDATE_APPROVAL = 'UPDATE_APPROVAL';
export const updateApproval = defaultActionCreator(
  UPDATE_APPROVAL,
  'id',
  'data'
);

export const GET_APPROVAL_BY_ID = 'GET_APPROVAL_BY_ID';
export const getApprovalById = defaultActionCreator(GET_APPROVAL_BY_ID, 'id');
