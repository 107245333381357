import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getDataModuleGroup,
  getDataModuleGroupById,
  addDataModuleGroup,
  updateDataModuleGroup,
  deleteDataModuleGroup
} from '../../../services/moduleGroup';
import {
  SET_STATE,
  GET_DATA_MODULE_GROUP,
  GET_DATA_MODULE_GROUP_BY_ID,
  ADD_DATA_MODULE_GROUP,
  UPDATE_DATA_MODULE_GROUP,
  DELETE_DATA_MODULE_GROUP,
  GET_DATA_MODULE_GROUP_NEXT_OR_PREV,
  SET_ERROR_FORM
} from './actions';

import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_MODULE_GROUP(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataModuleGroup, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_MODULE_GROUP_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataModuleGroupById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_MODULE_GROUP_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_MODULE_GROUP(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addDataModuleGroup, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'string', 'Create Module Group');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* UPDATE_MODULE_GROUP(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateDataModuleGroup, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'string', 'Edit Module Group');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* DELETE_MODULE_GROUP(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteDataModuleGroup, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'string', 'Delete Module Group');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loading: false }
    });
  }
}

export default all([
  takeLatest(GET_DATA_MODULE_GROUP, GET_MODULE_GROUP),
  takeLatest(GET_DATA_MODULE_GROUP_BY_ID, GET_MODULE_GROUP_BY_ID),
  takeLatest(GET_DATA_MODULE_GROUP_NEXT_OR_PREV, GET_MODULE_GROUP_NEXT_OR_PREV),
  takeLatest(ADD_DATA_MODULE_GROUP, ADD_MODULE_GROUP),
  takeLatest(UPDATE_DATA_MODULE_GROUP, UPDATE_MODULE_GROUP),
  takeLatest(DELETE_DATA_MODULE_GROUP, DELETE_MODULE_GROUP)
]);
