import { SET_ERROR_FORM, SET_STATE } from './action';

const initialState = {
  loading: false,
  error: null,
  filter: {
    state_id: null,
    province_id: null,
    regency_id: null,
    company_id62: null,
    user_types: null,
    is_active: null,
    offset: 0,
    search: '',
    limit: 10
  },
  form: {},
  formValidation: {}
};

export default function assessmentReducer(state = initialState, action) {
  switch (action.type) {
    case SET_STATE:
      return { ...state, ...action.payload };
    case SET_ERROR_FORM:
      return { ...state, formValidation: { ...action.error } };
    default:
      return state;
  }
}
