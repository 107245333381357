import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeWhStock = [
  {
    path: '/stock-in',
    parent: '/stock-in',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Produk" */ '../Pages/WarehouseRental/StockIn/StockIn'
        )
      )
    )
  },
  {
    path: '/stock-in/addData',
    parent: '/stock-in',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupAdd" */ '../Pages/WarehouseRental/StockIn/AddData'
        )
      )
    )
  },
  {
    path: '/stock-in/:id',
    parent: '/stock-in',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupAdd" */ '../Pages/WarehouseRental/StockIn/StockInDetail'
        )
      )
    )
  },
  {
    path: '/stock-in/update/:id',
    parent: '/stock-in',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupAdd" */ '../Pages/WarehouseRental/StockIn/StockInUpdate'
        )
      )
    )
  },
  {
    path: '/stock-out',
    parent: '/stock-out',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Produk" */ '../Pages/WarehouseRental/StockOut/StockOut'
        )
      )
    )
  },
  {
    path: '/stock-out/:id',
    parent: '/stock-out',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Produk" */ '../Pages/WarehouseRental/StockOut/StockOutDetail'
        )
      )
    )
  },
  {
    path: '/stock-out/addData',
    parent: '/stock-out',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupAdd" */ '../Pages/WarehouseRental/StockOut/AddData'
        )
      )
    )
  },
  {
    path: '/stock-out/update/:id',
    parent: '/stock-out',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupAdd" */ '../Pages/WarehouseRental/StockOut/StockOutUpdate'
        )
      )
    )
  }
];
