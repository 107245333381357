import service from './service';
import endpoint from './constantUrl';

// Void
export const getVoid = (param) => {
  return service.post(`${endpoint.POS_URL}/void/filter/`, param);
};

export const getVoidById = (id62) => {
  return service.get(`${endpoint.POS_URL}/void/${id62}/`);
};

export const addVoid = (data) => {
  return service.post(`${endpoint.POS_URL}/void/`, data);
};

export const approvalVoid = (id62, data) => {
  return service.put(`${endpoint.POS_URL}/void/${id62}/approval/`, data);
};

export const deleteVoid = (id62) => {
  return service.delete(`${endpoint.POS_URL}/void/${id62}/`);
};

export const exportVoid = (param) => {
  return service.postArrayBuffer(
    `${endpoint.POS_URL}/void/export-excel/`,
    param
  );
};
