import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getToken } from '../utils';
import Image from '../assets/images/dev.svg';
import Gembok from '../assets/images/gembox.png';
function NoMatchPage({ objRoutes, height = '100vh' }) {
  const token = getToken();
  const loc = useLocation();
  if (loc.pathname === '/login') {
    return <Navigate to="/" />;
  }
  if (token == null) {
    let pathGoto = '';
    if (loc.pathname !== '/' && loc.pathname !== '/login') {
      pathGoto = '#goto=' + btoa(loc.pathname);
    }

    return <Navigate to={'/login' + pathGoto} />;
  }

  // console.log(objRoutes, loc.pathname);
  return objRoutes[loc.pathname] ? (
    <div
      className="d-flex align-items-center flex-column justify-content-center bg-white"
      style={{ height }}
    >
      <img src={Gembok} height="250px" alt="gambar" className="w-50" />
      <h3 className="mt-5 mb-0">Sorry, but you have no permission</h3>
      <h3 className="">to access this service</h3>
    </div>
  ) : (
    <div
      className="d-flex align-items-center flex-column justify-content-center"
      style={{ height }}
    >
      <img src={Image} alt="gambar" className="w-50" />

      <h3 className="mt-5">System Under Development</h3>
      <p>We apologize for any inconvenience while we make improvements</p>
    </div>
  );
}

export default NoMatchPage;
