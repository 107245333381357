import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { refreshUserInfo } from '../store/reducers/auth/actions';
import LoadingPage from './LoadingPage';

function UserInfo() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(refreshUserInfo());
    const timer = setInterval(() => {
      dispatch(refreshUserInfo());
    }, 50000);
    return () => clearInterval(timer);
  }, []);

  return <LoadingPage />;
}

export default UserInfo;
