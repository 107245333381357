import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'delivery/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'delivery/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const ADD_DATA_DELIVERY_ORDER = 'ADD_DATA_DELIVERY_ORDER';
export const addDataDeliveryOrder = defaultActionCreator(
  ADD_DATA_DELIVERY_ORDER,
  'data',
  'module'
);
