import service from './service';
import endpoint from './constantUrl';

export const getStockMovement = (param) => {
  return service.post(`${endpoint.POS_URL}/stock-movement/filter/`, param);
};

export const getStockMovementById = (id) => {
  return service.get(`${endpoint.POS_URL}/stock-movement/${id}/`);
};
