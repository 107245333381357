import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'interest-service/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'interest-service/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_INTEREST_SERVICE = 'GET_DATA_INTEREST_SERVICE';
export const getDataInterestService = defaultActionCreator(
  GET_DATA_INTEREST_SERVICE,
  'param'
);
