import service from './service';
import endpoint from './constantUrl';

// ProductSku
export const addProductSku = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/product/sku/`, data);
};

export const getProductSku = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/product/sku/`, param);
};
export const getProductSkuById = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/product/sku/${id}/`);
};

export const updateProductSku = (data, id) => {
  return service.put(`${endpoint.MASTERDATA_URL}/product/sku/${id}/`, data);
};

export const deleteProductSku = (id) => {
  return service.delete(`${endpoint.MASTERDATA_URL}/product/sku/${id}/`);
};

export const downloadTemplateProductSku = () => {
  return service.post(`${endpoint.MASTERDATA_URL}/product/sku/csv-template/`);
};

export const downloadDataProductSku = (param) => {
  return service.getArrayBuffer(
    `${endpoint.MASTERDATA_URL}/product/sku/export-excel/`,
    param
  );
};

export const uploadProductSku = (data) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/product/sku/csv-import/`,
    data
  );
};

export const activationProductSku = (id62) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/product/sku/${id62}/sku-activation/`
  );
};

export const exportProductSkuExcel = (param) => {
  return service.postDownloadService(`${endpoint.DOWNLOAD}/sku/excel`, param);
};
