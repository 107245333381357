import service from './service';
import endpoint from './constantUrl';

// Network
export const addNetwork = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/network/`, data);
};

export const getNetwork = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/network/`, param);
};

export const getNetworkById = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/network/${id}/`);
};

export const updateNetwork = (data, id) => {
  return service.put(`${endpoint.MASTERDATA_URL}/network/${id}/`, data);
};

export const deleteNetwork = (id) => {
  return service.delete(`${endpoint.MASTERDATA_URL}/network/${id}/`);
};

export const activationNetwork = (id62) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/network/${id62}/network-activation/`
  );
};
