import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'invetoryChanges/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'invetoryChanges/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_INVENTORY_CHANGES = 'GET_DATA_INVENTORY_CHANGES';
export const getDataInventoryChanges = defaultActionCreator(
  GET_DATA_INVENTORY_CHANGES,
  'param'
);

export const GET_DATA_INVENTORY_CHANGES_BY_ID =
  'GET_DATA_INVENTORY_CHANGES_BY_ID';
export const getDataInventoryChangesById = defaultActionCreator(
  GET_DATA_INVENTORY_CHANGES_BY_ID,
  'id'
);

export const CREATE_DATA_INVENTORY_CHANGES = 'CREATE_DATA_INVENTORY_CHANGES';
export const createDataInventoryChanges = defaultActionCreator(
  CREATE_DATA_INVENTORY_CHANGES,
  'data',
  'log'
);

export const DELETE_DATA_INVENTORY_CHANGES = 'DELETE_DATA_INVENTORY_CHANGES';
export const deleteDataInventoryChanges = defaultActionCreator(
  DELETE_DATA_INVENTORY_CHANGES,
  'id'
);

export const DOWNLOAD_DATA_INVENTORY_CHANGES =
  'DOWNLOAD_DATA_INVENTORY_CHANGES';
export const downloadDatanventoryChanges = defaultActionCreator(
  DOWNLOAD_DATA_INVENTORY_CHANGES,
  'param'
);
