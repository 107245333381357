import { all, put, takeLatest, call } from 'redux-saga/effects';
import {
  getDataLog,
  getDataLogById,
  deleteLog,
  exportCsv
} from '../../../services/Activity';
import {
  GET_LOG,
  GET_LOG_BY_ID,
  SET_STATE,
  DELETE_LOG,
  EXPORT_CSV
} from './actions';
import { handleDownload } from '../../../utils';

export function* GET_DATA_LOG(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataLog, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_DATA_LOG_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataLogById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DELETE_DATA_LOG() {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteLog);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loading: false }
    });
  }
}

export function* EXPORT_DATA_CSV() {
  // yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(exportCsv);
    yield handleDownload(data, 'log.csv');
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export default all([
  takeLatest(GET_LOG, GET_DATA_LOG),
  takeLatest(GET_LOG_BY_ID, GET_DATA_LOG_BY_ID),
  takeLatest(DELETE_LOG, DELETE_DATA_LOG),
  takeLatest(EXPORT_CSV, EXPORT_DATA_CSV)
]);
