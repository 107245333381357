import { SET_STATE } from './actions';

const initialState = {
  warehouses: [],
  prospectWarehouses: [],
  suggestions: [],
  area: [],
  nextWarehouse: [],
  country: [],
  province: [],
  city: [],
  loading: false,
  error: undefined
};

export default function dashboardMapReducer(state = initialState, action) {
  switch (action.type) {
    case SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
