import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeProduk = [
  {
    path: '/product',
    parent: '/product',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Produk" */ '../Pages/MasterData/Produk/Produk'
        )
      )
    )
  },
  {
    path: '/product/:id',
    parent: '/product',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ProdukView" */ '../Pages/MasterData/Produk/ProdukView'
        )
      )
    )
  },
  {
    path: '/product/add',
    parent: '/product',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Produk" */ '../Pages/MasterData/Produk/ProdukAdd'
        )
      )
    )
  },
  {
    path: '/product/update/:id',
    parent: '/product',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Produk" */ '../Pages/MasterData/Produk/ProdukUpdate'
        )
      )
    )
  },
  {
    path: '/product-variants',
    parent: '/product-variants',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ProdukVariants" */ '../Pages/MasterData/ProdukSku/ProdukSku'
        )
      )
    )
  },
  {
    path: '/product-variants/:id',
    parent: '/product-variants',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ProdukVariantsView" */ '../Pages/MasterData/ProdukSku/ProdukSkuView'
        )
      )
    )
  },
  {
    path: '/product-variants/add',
    parent: '/product-variants',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ProdukVariantsAdd" */ '../Pages/MasterData/ProdukSku/ProdukSkuAdd'
        )
      )
    )
  },
  {
    path: '/product-variants/update/:id',
    parent: '/product-variants',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ProdukVariantsUpdate" */ '../Pages/MasterData/ProdukSku/ProdukSkuUpdate'
        )
      )
    )
  },
  {
    path: '/approval',
    parent: '/approval',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockInventoryView" */ '../Pages/MasterData/Approval/Approval'
        )
      )
    )
  },
  {
    path: '/approval/addData',
    parent: '/approval',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockInventoryView" */ '../Pages/MasterData/Approval/AddData'
        )
      )
    )
  },
  {
    path: '/approval/update/:id',
    parent: '/approval',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockInventoryView" */ '../Pages/MasterData/Approval/ApprovalUpdate'
        )
      )
    )
  },
  {
    path: '/approval/:id',
    parent: '/approval',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockInventoryView" */ '../Pages/MasterData/Approval/AprovalDetail'
        )
      )
    )
  }
];
