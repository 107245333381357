import service from './service';
import endpoint from './constantUrl';

export const getDataUser = (param) => {
  return service.get(`${endpoint.ACCOUNT_URL}/user/user/`, param);
};

export const getDataUserById = (id) => {
  return service.get(`${endpoint.ACCOUNT_URL}/user/user/${id}/`);
};

export const addDataUser = (data) => {
  return service.post(`${endpoint.ACCOUNT_URL}/user/user/`, data);
};

export const updateDataUser = (data, id) => {
  return service.put(`${endpoint.ACCOUNT_URL}/user/user/${id}/`, data);
};

export const deleteDataUser = (id) => {
  return service.delete(`${endpoint.ACCOUNT_URL}/user/user/${id}/`);
};

export const getUserInternal = (param) => {
  return service.get(
    `${endpoint.ACCOUNT_URL}/user/user/?user_type=internal`,
    param
  );
};

export const exportUserRoleExcel = (param) => {
  return service.postDownloadService(
    `${endpoint.DOWNLOAD}/user-role/excel`,
    param
  );
};
