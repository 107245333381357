import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getInbound,
  getInboundById,
  addInbound,
  updateInbound,
  deleteInbound,
  approvalInbound,
  exportInboundExcel
} from '../../../services/InboundManagement';
import {
  SET_STATE,
  SET_ERROR_FORM,
  GET_DATA_INBOUND,
  GET_DATA_INBOUND_BY_ID,
  GET_DATA_INBOUND_NEXT_OR_PREV,
  ADD_DATA_INBOUND,
  UPDATE_DATA_INBOUND,
  DELETE_DATA_INBOUND,
  APPROVAL_DATA_INBOUND,
  DOWNLOAD_DATA_INBOUND
} from './actions';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog, handleDownload, logMatches } from '../../../utils';

export function* GET_INBOUND(action) {
  yield put({ type: SET_STATE, payload: { data: null, loading: true } });
  try {
    const { data } = yield call(getInbound, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_INBOUND_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { data: null, loading: true } });
  try {
    const { data } = yield call(getInboundById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_INBOUND_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_INBOUND(action) {
  yield put({ type: SET_STATE, payload: { loadingDraft: true } });
  try {
    yield call(addInbound, action.data);
    yield setIpUser();
    const body = bodyLog(action.log.log_type, 'Inbound', action.log.desc);

    let message = 'Your Data Has Been Submitted';
    if (action?.data?.is_draft) {
      message = 'Successfully Save as Draft';
    }
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDraft: false } });
  }
}

export function* UPDATE_INBOUND(action) {
  yield put({ type: SET_STATE, payload: { loadingDraft: true } });
  try {
    // console.log(action.data, action.id, 'SAGAS UPDATE INBOUND');
    yield call(updateInbound, action.data, action.id);
    yield setIpUser();
    const body = bodyLog(action.log.log_type, 'Inbound', action.log.desc);

    let message = 'Your Data Has Been Submitted';
    if (action?.data?.is_draft) {
      message = 'Successfully Save as Draft';
    }
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDraft: false } });
  }
}

export function* DELETE_INBOUND(action) {
  yield put({ type: SET_STATE, payload: { loadingDelete: true } });
  try {
    yield call(deleteInbound, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Inbound', 'Delete Inbound');
    yield call(createLog, body);
    let message = 'Successfully deleting the Draft';

    yield put({ type: SET_STATE, payload: { isDelete: true, message } });
  } catch ({ message: error }) {
    yield put({
      type: SET_STATE,
      payload: { loadingDelete: false }
    });
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loadingDelete: false }
    });
  }
}

export function* APPROVAL_INBOUND(action) {
  yield put({ type: SET_STATE, payload: { loadingAprroval: true } });
  try {
    yield call(approvalInbound, action.id, action.data);
    yield setIpUser();
    const body = bodyLog('edit', 'Inbound', 'Approval Inbound');
    yield call(createLog, body);
    let message = 'Transaction Has Been Approved';
    let model = 'success';
    if (!action?.data?.is_approved) {
      message = 'Transaction Has Been Rejected';
      model = 'fail';
    }
    yield put({ type: SET_STATE, payload: { isUpdate: true, message, model } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingAprroval: false } });
  }
}

export function* DOWNLOAD_INBOUND(action) {
  try {
    const response = yield call(exportInboundExcel, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    console.log(error);
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export default all([
  takeLatest(GET_DATA_INBOUND, GET_INBOUND),
  takeLatest(GET_DATA_INBOUND_BY_ID, GET_INBOUND_BY_ID),
  takeLatest(GET_DATA_INBOUND_NEXT_OR_PREV, GET_DATA_INBOUND_NEXT_OR_PREV),
  takeLatest(ADD_DATA_INBOUND, ADD_INBOUND),
  takeLatest(DOWNLOAD_DATA_INBOUND, DOWNLOAD_INBOUND),
  takeLatest(UPDATE_DATA_INBOUND, UPDATE_INBOUND),
  takeLatest(DELETE_DATA_INBOUND, DELETE_INBOUND),
  takeLatest(APPROVAL_DATA_INBOUND, APPROVAL_INBOUND)
]);
