import { all, put, takeLatest, call } from 'redux-saga/effects';
import {
  getFishingArea,
  getFishingAreaById,
  addFishingArea,
  updateFishingArea,
  deleteFishingArea,
  downloadDataFishingArea,
  activationFishingArea
} from '../../../services/fishingArea';
import { handleDownload, logMatches } from '../../../utils';
import {
  SET_STATE,
  GET_DATA_FISHING_AREA,
  GET_DATA_FISHING_AREA_BY_ID,
  ADD_DATA_FISHING_AREA,
  UPDATE_DATA_FISHING_AREA,
  DELETE_DATA_FISHING_AREA,
  SET_ERROR_FORM,
  DOWNLOAD_DATA_FISHING_AREA,
  ACTIVATION_DATA_FISHING_AREA
} from './actions';

import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_FISHING_AREA(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getFishingArea, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_FISHING_AREA_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getFishingAreaById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* ADD_FISHING_AREA(action) {
  yield put({ type: SET_STATE, payload: { loadingForm: true } });
  try {
    yield call(addFishingArea, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Fishing Area', 'Create Fishing Area');
    let message = 'Your Data Has Been Submitted';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  }
}
export function* UPDATE_FISHING_AREA(action) {
  yield put({ type: SET_STATE, payload: { loadingForm: true } });
  try {
    yield call(updateFishingArea, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Fishing Area', 'Edit Fishing Area');
    let message = 'Your Data Has Been Submitted';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  }
}

export function* DELETE_FISHING_AREA(action) {
  yield put({ type: SET_STATE, payload: { loadingDelete: true } });
  try {
    yield call(deleteFishingArea, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Fishing Area', 'Delete Fishing Area');
    let message = 'Successfully deleting the Data';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isDelete: true, message } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
    yield put({
      type: SET_STATE,
      payload: { loadingDelete: false }
    });
  }
}

export function* DOWNLOAD_FISHING_AREA(action) {
  try {
    const response = yield call(downloadDataFishingArea, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* ACTIVATION_FISHING_AREA(action) {
  yield put({ type: SET_STATE, payload: { loadingActive: true } });
  try {
    yield call(activationFishingArea, action.id62);
    yield setIpUser();
    const body = bodyLog('edit', 'Fishing Area', 'Delete Fishing Area');
    let message = 'Successfully updateing the Data';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isActive: true, message } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
    yield put({
      type: SET_STATE,
      payload: { loadingActive: false }
    });
  }
}

export default all([
  takeLatest(GET_DATA_FISHING_AREA, GET_FISHING_AREA),
  takeLatest(GET_DATA_FISHING_AREA_BY_ID, GET_FISHING_AREA_BY_ID),
  takeLatest(ADD_DATA_FISHING_AREA, ADD_FISHING_AREA),
  takeLatest(UPDATE_DATA_FISHING_AREA, UPDATE_FISHING_AREA),
  takeLatest(DELETE_DATA_FISHING_AREA, DELETE_FISHING_AREA),
  takeLatest(DOWNLOAD_DATA_FISHING_AREA, DOWNLOAD_FISHING_AREA),
  takeLatest(ACTIVATION_DATA_FISHING_AREA, ACTIVATION_FISHING_AREA)
]);
