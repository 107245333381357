import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  GET_DATA_PENYEWA,
  SET_STATE,
  GET_DATA_PENYEWA_BY_ID,
  GET_DATA_PENYEWA_NEXT_OR_PREV,
  GET_DATA_PENYEWA_BY_ID_GUDANG,
  DOWNLOAD_CSV
} from './action';
import {
  getDataPenyewa,
  getDataPenyewaById,
  getPenyewaByIdGudang,
  downloadCsvPenyewa
} from '../../../services/penyewa';
import { handleDownload } from '../../../utils';

export function* GET_PENYEWA(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataPenyewa, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_PENYEWA_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataPenyewaById, action.id);
    yield put({ type: SET_STATE, payload: { dataById: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_PENYEWA_NEXT_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_PENYEWA_ID_GUDANG(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getPenyewaByIdGudang, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DOWNLOAD_DATA_CSV(action) {
  try {
    const { data } = yield call(downloadCsvPenyewa, action.param);
    yield handleDownload(data, 'penyewa.csv');
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export default all([
  takeLatest(GET_DATA_PENYEWA, GET_PENYEWA),
  takeLatest(GET_DATA_PENYEWA_BY_ID, GET_PENYEWA_ID),
  takeLatest(GET_DATA_PENYEWA_NEXT_OR_PREV, GET_PENYEWA_NEXT_PREV),
  takeLatest(GET_DATA_PENYEWA_BY_ID_GUDANG, GET_PENYEWA_ID_GUDANG),
  takeLatest(DOWNLOAD_CSV, DOWNLOAD_DATA_CSV)
]);
