import { all, put, takeLatest, call } from 'redux-saga/effects';
import {
  getReturPembelian,
  getReturPembelianById,
  addReturPembelian,
  updateDataReturPembelian,
  deleteReturPembelian,
  downloadReturnPembelian
} from '../../../services/returPembelian';
import { handleDownload, logMatches } from '../../../utils';
import {
  SET_ERROR_FORM,
  SET_STATE,
  GET_DATA_RETUR_PEMBELIAN,
  GET_DATA_RETUR_PEMBELIAN_BY_ID,
  ADD_DATA_RETUR_PEMBELIAN,
  UPDATE_DATA_RETUR_PEMBELIAN,
  DOWNLOAD_DATA_RETUR_PEMBELIAN,
  DELETE_DATA_RETUR_PEMBELIAN
} from './actions';

import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_RETUR_PEMBELIAN(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getReturPembelian, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_RETURN_PEMBELIAN_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getReturPembelianById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_RETUR_PEMBELIAN(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addReturPembelian, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Pembelian', 'Create Retur Pembelian');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
    yield put({ type: SET_ERROR_FORM, error: [] });
  }
}
export function* UPDATE_RETUR_PEMBELIAN(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateDataReturPembelian, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Pembelian', 'Edit Retur Pembelian');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
    yield put({ type: SET_ERROR_FORM, error: [] });
  }
}
export function* DELETE_RETUR_PEMBELIAN(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteReturPembelian, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Pembelian', 'Delete Retur Pembelian');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* DOWNLOAD_RETUR(action) {
  try {
    const { data, headers } = yield call(downloadReturnPembelian, action.param);
    const filename = yield logMatches(headers['content-disposition']);
    yield handleDownload(data, filename[0]);
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export default all([
  takeLatest(GET_DATA_RETUR_PEMBELIAN, GET_RETUR_PEMBELIAN),
  takeLatest(GET_DATA_RETUR_PEMBELIAN_BY_ID, GET_RETURN_PEMBELIAN_BY_ID),
  takeLatest(ADD_DATA_RETUR_PEMBELIAN, ADD_RETUR_PEMBELIAN),
  takeLatest(UPDATE_DATA_RETUR_PEMBELIAN, UPDATE_RETUR_PEMBELIAN),
  takeLatest(DELETE_DATA_RETUR_PEMBELIAN, DELETE_RETUR_PEMBELIAN),
  takeLatest(DOWNLOAD_DATA_RETUR_PEMBELIAN, DOWNLOAD_RETUR)
]);
