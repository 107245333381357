import service from './service';
import endpoint from './constantUrl';

export const getTag = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/product/tag/`, param);
};

export const getTagById = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/product/tag/${id}/`);
};

export const addTag = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/product/tag/`, data);
};

export const updateTag = (data, id) => {
  return service.put(`${endpoint.MASTERDATA_URL}/product/tag/${id}/`, data);
};

export const deleteTag = (id) => {
  return service.delete(`${endpoint.MASTERDATA_URL}/product/tag/${id}/`);
};

export const activationTag = (id) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/product/tag/${id}/activation/`
  );
};

export const exportTagExcel = (param) => {
  return service.postDownloadService(`${endpoint.DOWNLOAD}/tag/excel`, param);
};
