import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'documentType/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'documentType/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_DOCUMENT_TYPE = 'GET_DATA_DOCUMENT_TYPE';
export const getDataDocumentType = defaultActionCreator(
  GET_DATA_DOCUMENT_TYPE,
  'param'
);

export const GET_DATA_DOCUMENT_TYPE_BY_ID = 'GET_DATA_DOCUMENT_TYPE_BY_ID';
export const getDataDocumentTypeById = defaultActionCreator(
  GET_DATA_DOCUMENT_TYPE_BY_ID,
  'id'
);

export const GET_DATA_DOCUMENT_TYPE_NEXT_OR_PREV =
  'GET_DATA_DOCUMENT_TYPE_NEXT_OR_PREV';
export const getDataDocumentTypeNextOrPrev = defaultActionCreator(
  GET_DATA_DOCUMENT_TYPE_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_DOCUMENT_TYPE = 'ADD_DATA_DOCUMENT_TYPE';
export const addDataDocumentType = defaultActionCreator(
  ADD_DATA_DOCUMENT_TYPE,
  'data'
);

export const UPDATE_DATA_DOCUMENT_TYPE = 'UPDATE_DATA_DOCUMENT_TYPE';
export const updateDataDocumentType = defaultActionCreator(
  UPDATE_DATA_DOCUMENT_TYPE,
  'data',
  'id'
);

export const DELETE_DATA_DOCUMENT_TYPE = 'DELETE_DATA_DOCUMENT_TYPE';
export const deleteDataDocumentType = defaultActionCreator(
  DELETE_DATA_DOCUMENT_TYPE,
  'id'
);

export const ACTIVATION_DATA_DOCUMENT_TYPE = 'ACTIVATION_DATA_DOCUMENT_TYPE';
export const activationDataDocumentType = defaultActionCreator(
  ACTIVATION_DATA_DOCUMENT_TYPE,
  'id'
);

export const DOWNLOAD_DATA_DOCUMENT_TYPE_PDF =
  'DOWNLOAD_DATA_DOCUMENT_TYPE_PDF';
export const downloadDataDocumentTypePDF = defaultActionCreator(
  DOWNLOAD_DATA_DOCUMENT_TYPE_PDF,
  'param'
);

export const DOWNLOAD_DATA_DOCUMENT_TYPE_EXCEL =
  'DOWNLOAD_DATA_DOCUMENT_TYPE_EXCEL';
export const downloadDataDocumentTypeExcel = defaultActionCreator(
  DOWNLOAD_DATA_DOCUMENT_TYPE_EXCEL,
  'param'
);
