import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'penyewa/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const GET_DATA_PENYEWA = 'GET_DATA_PENYEWA';
export const getDataPenyewa = defaultActionCreator(GET_DATA_PENYEWA, 'param');

export const GET_DATA_PENYEWA_BY_ID = 'GET_DATA_PENYEWA_BY_ID';
export const getDataPenyewaById = defaultActionCreator(
  GET_DATA_PENYEWA_BY_ID,
  'id'
);

export const GET_DATA_PENYEWA_NEXT_OR_PREV = 'GET_DATA_PENYEWA_NEXT_OR_PREV';
export const getDataPenyewaNextOrPrev = defaultActionCreator(
  GET_DATA_PENYEWA_NEXT_OR_PREV,
  'link'
);

export const GET_DATA_PENYEWA_BY_ID_GUDANG = 'GET_DATA_PENYEWA_BY_ID_GUDANG';
export const getDataPenyewaByIdGudang = defaultActionCreator(
  GET_DATA_PENYEWA_BY_ID_GUDANG,
  'id'
);

export const DOWNLOAD_CSV = 'DOWNLOAD_SCV';
export const downloadCsvPenyewa = defaultActionCreator(DOWNLOAD_CSV);
