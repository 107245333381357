import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeProfileUser = [
  {
    path: '/profile-user',
    action: 'view',
    parent: '/profile-user',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(/* webpackChunkName: "Login" */ '../Pages/ProfileUser')
      )
    )
  }
];
