import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeGoodsTransfer = [
  {
    path: '/stock-transfer',
    parent: '/stock-transfer',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "GoodsTransfer" */ '../Pages/TransferManagement/GoodsTransfer'
        )
      )
    )
  },
  {
    path: '/stock-transfer/add',
    parent: '/stock-transfer',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "GoodsTransferAdd" */ '../Pages/TransferManagement/GoodsTransfer/add'
        )
      )
    )
  },
  {
    path: '/stock-transfer/:id',
    parent: '/stock-transfer',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "GoodsTransferDetail" */ '../Pages/TransferManagement/GoodsTransfer/detail'
        )
      )
    )
  },
  {
    path: '/stock-transfer/approval/:id',
    parent: '/stock-transfer',
    action: 'approve',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "GoodsTransferApproval" */ '../Pages/TransferManagement/GoodsTransfer/detail'
        )
      )
    )
  },
  {
    path: '/stock-transfer/delivery/:id',
    parent: '/stock-transfer',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "GoodsTransferApproval" */ '../Pages/TransferManagement/GoodsTransfer/detail'
        )
      )
    )
  }
];

export const routeGoodsReceived = [
  {
    path: '/stock-received',
    parent: '/stock-received',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "GoodsReceived" */ '../Pages/TransferManagement/GoodsReceived'
        )
      )
    )
  },
  {
    path: '/stock-received/:id',
    parent: '/stock-received',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "GoodsReceivedTransferReceived" */ '../Pages/TransferManagement/GoodsReceived/detail'
        )
      )
    )
  },
  {
    path: '/stock-received/transfer-received/:id',
    parent: '/stock-received',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "GoodsReceivedDetail" */ '../Pages/TransferManagement/GoodsReceived/detail'
        )
      )
    )
  }
];
