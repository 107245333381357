import { all, put, takeLatest, call } from 'redux-saga/effects';
import {
  addStockAdjustment,
  addStockAdjustmentById,
  adjustStock,
  exportExcelAdjustment,
  getProductList,
  getStockAdjustment,
  startStockAdjustment,
  submitApproval,
  submitStockAdjustment
} from '../../../services/stockAdjustment';
import {
  SET_STATE,
  SET_ERROR_FORM,
  GET_DATA_STOCK_ADJUSTMENT,
  ADD_DATA_STOCK_ADJUSTMENT,
  GET_DATA_STOCK_ADJUSTMENT_BY_ID,
  GET_DATA_PRODUCT_LIST,
  START_ADJUSTMENT_PROCESS,
  ADJUST_DATA_STOCK,
  SUBMIT_DATA_ADJUSTMENT,
  SUBMIT_ADJUSTMENT_APPROVAL,
  EXPORT_EXCEL_DATA_ADJUSTMENT
} from './actions';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog, logMatches, handleDownload } from '../../../utils';

export function* GET_STOCK_ADJUSTMENT(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getStockAdjustment, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_STOCK_ADJUSTMENT_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(addStockAdjustmentById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_ERROR_FORM, error: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_PRODUCT_LIST(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getProductList, action.param, action.id);
    yield put({ type: SET_STATE, payload: { product_list: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_ERROR_FORM, error: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_STOCK_ADJUSTMENT(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addStockAdjustment, action.data);
    yield setIpUser();
    const body = bodyLog(
      'create',
      'Stock Adjustment',
      'Create Stock Adjustment'
    );
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* START_ADJUSTMENT(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(startStockAdjustment, action.data, action.id);
    yield setIpUser();
    const body = bodyLog(
      'edit',
      'Stock Adjustment',
      'Start Stock Adjustment Process'
    );
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdjustmentStart: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADJUST_STOCK(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(adjustStock, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Stock Adjustment', 'Adjust stock of Product');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdjustStock: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* SUBMIT_ADJUSTMENT(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  yield put({ type: SET_ERROR_FORM, error: {} });
  try {
    yield call(submitStockAdjustment, action.data, action.id);
    yield setIpUser();
    const body = bodyLog(
      'create',
      'Stock Adjustment',
      'Confirm Stock Adjustment'
    );
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isSubmitAdjustment: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* SUBMIT_APPROVAL(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(submitApproval, action.data, action.id);
    yield setIpUser();
    const body = bodyLog(
      'edit',
      'Stock Adjustment',
      'Submit Stock Adjustment APproval'
    );
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isSubmitApproval: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* EXPORT_EXCEL_ADJUSTMENT(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(exportExcelAdjustment, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_STOCK_ADJUSTMENT, GET_STOCK_ADJUSTMENT),
  takeLatest(ADD_DATA_STOCK_ADJUSTMENT, ADD_STOCK_ADJUSTMENT),
  takeLatest(GET_DATA_STOCK_ADJUSTMENT_BY_ID, GET_STOCK_ADJUSTMENT_BY_ID),
  takeLatest(GET_DATA_PRODUCT_LIST, GET_PRODUCT_LIST),
  takeLatest(START_ADJUSTMENT_PROCESS, START_ADJUSTMENT),
  takeLatest(ADJUST_DATA_STOCK, ADJUST_STOCK),
  takeLatest(SUBMIT_DATA_ADJUSTMENT, SUBMIT_ADJUSTMENT),
  takeLatest(SUBMIT_ADJUSTMENT_APPROVAL, SUBMIT_APPROVAL),
  takeLatest(EXPORT_EXCEL_DATA_ADJUSTMENT, EXPORT_EXCEL_ADJUSTMENT)
]);
