import service from './service';
import endpoint from './constantUrl';

export const getDataStokOut = (param) => {
  return service.get(`${endpoint.POS_URL}/stock/out/`, param);
};

export const createDataStokOut = (param) => {
  return service.post(`${endpoint.POS_URL}/stock/out/`, param);
};

export const generateNoStockOut = (data) => {
  return service.post(`${endpoint.POS_URL}/stock/out/generate-number/`, data);
};

export const getStockOutbyId = (id) => {
  return service.get(`${endpoint.POS_URL}/stock/out/${id}/`);
};

export const deleteStockOut = (id) => {
  return service.delete(`${endpoint.POS_URL}/stock/out/${id}/`);
};

export const updateStatusKalkulasi = (data, id) => {
  return service.put(
    `${endpoint.POS_URL}/stock/out/${id}/update-has-calculated/`,
    data
  );
};

export const updateStokOut = (id, data) => {
  return service.put(`${endpoint.POS_URL}/stock/out/${id}/`, data);
};

export const uploadDataStockIn = (data) => {
  return service.post(`${endpoint.POS_URL}/stock/out/import-csv/`, data);
};

export const downloadCsv = (data) => {
  return service.get(`${endpoint.POS_URL}/stock/out/export-csv/`, data);
};

export const downloadExcel = (data) => {
  return service.getArrayBuffer(
    `${endpoint.POS_URL}/stock/out/export-excel/`,
    data
  );
};

export const templateCsv = (data) => {
  return service.get(`${endpoint.POS_URL}/stock/out/csv-template/`, data);
};
