import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeInvetoryType = [
  {
    path: '/inventory-type',
    parent: '/inventory-type',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Unit" */ '../Pages/MasterData/InventoryType/index'
        )
      )
    )
  },
  {
    path: '/inventory-type/:id',
    parent: '/inventory-type',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "UnitView" */ '../Pages/MasterData/InventoryType/view'
        )
      )
    )
  },
  {
    path: '/inventory-type/add',
    parent: '/inventory-type',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "unitAdd " */ '../Pages/MasterData/InventoryType/add'
        )
      )
    )
  },
  {
    path: '/inventory-type/update/:id',
    parent: '/inventory-type',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "unitUpdate " */ '../Pages/MasterData/InventoryType/update'
        )
      )
    )
  }
];
