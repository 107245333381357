import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getProfileUser } from '../../../services/profileUser';
import { SET_STATE, GET_DATA_PROFILE_USER } from './actions';

export function* GET_PROFILE_USER(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getProfileUser, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([takeLatest(GET_DATA_PROFILE_USER, GET_PROFILE_USER)]);
