import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getSpecificCondition } from '../../../services/specificCondition';
import { SET_STATE, GET_DATA_SPECIFIC_CONDITION } from './actions';

export function* GET_SPECIFIC_CONDITION(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getSpecificCondition, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_SPECIFIC_CONDITION, GET_SPECIFIC_CONDITION)
]);
