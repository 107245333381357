import service from './service';
import endpoint from './constantUrl';

// Warehouse Type
export const getWarehouseType = (param) => {
  return service.get(
    `${endpoint.MASTERDATA_URL}/warehouse/warehouse-type/`,
    param
  );
};

export const addWarehouseType = (data) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/warehouse/warehouse-type/`,
    data
  );
};

export const getWarehouseTypeById = (id) => {
  return service.get(
    `${endpoint.MASTERDATA_URL}/warehouse/warehouse-type/${id}/`
  );
};

export const updateWarehouseType = (data, id) => {
  return service.put(
    `${endpoint.MASTERDATA_URL}/warehouse/warehouse-type/${id}/`
  );
};

export const deleteWarehouseType = (id) => {
  return service.delete(
    `${endpoint.MASTERDATA_URL}/warehouse/warehouse-type/${id}/`
  );
};
