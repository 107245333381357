import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeDashboardWarehouse = [
  {
    path: '/owner-dashboard',
    isParent: true,
    action: 'view',
    parent: '/owner-dashboard',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "dashboard owner" */ '../Pages/DashboardOwner/'
        )
      )
    )
  },
  {
    path: '/owner-dashboard/:id',
    action: 'view',
    parent: '/owner-dashboard',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "dashboard owner" */ '../Pages/DashboardOwner/detail'
        )
      )
    )
  },
  {
    path: '/maps',
    isParent: true,
    action: 'view',
    parent: '/maps',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "maps warehouse" */ '../Pages/DashboardWarehouse/DashboardMap/DashboardWareHouseMap'
        )
      )
    )
  },
  {
    path: '/maps/filter',
    isParent: false,
    action: 'view',
    parent: '/maps',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "maps warehouse" */ '../Pages/DashboardWarehouse/DashboardMap/DashboardWareHouseFilter'
        )
      )
    )
  },
  {
    path: '/maps/area',
    isParent: false,
    action: 'view',
    parent: '/maps',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "maps warehouse" */ '../Pages/DashboardWarehouse/DashboardMap/DashboardWareHouseArea'
        )
      )
    )
  }
];
