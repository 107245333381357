import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setState } from '../../store/reducers/auth/actions';
import './styles.scss';

import constants from '../../utils/constants';

const statusMenu = JSON.parse(constants.statusMenu || '{}');

function Menu({ dataAuth }) {
  const [submenu, setsubmenu] = useState();
  const dispatch = useDispatch();
  const loc = useLocation();
  const parrentPath = loc.pathname.split(/\/(.*)/s, 2); // only split on first '/'
  const auth = useSelector((state) => state.auth);
  const [authInfo, setAuthInfo] = useState(dataAuth);
  const [modules, setModules] = useState(dataAuth?.role?.modules);
  const onChangeSubmenu = (key) => {
    if (key === submenu) setsubmenu(false);
    else setsubmenu(key);
  };
  const now = new Date();
  useEffect(() => {
    if (auth.isRefresh) {
      setAuthInfo(dataAuth);
      setModules(dataAuth?.role?.modules);
      dispatch(setState({ isRefresh: false }));
    }
  }, [auth]);

  useEffect(() => {
    if (baseModule()) {
      baseModule().forEach((item) => {
        const subModules = item.module?.sub_modules;

        if (subModules && subModules.length > 0) {
          const foundItem = subModules.find((someItem) => {
            return someItem.module?.url === `/${parrentPath[1]?.split('/')[0]}`;
          });

          if (foundItem) {
            setsubmenu(item.module.id62);
          }
        }
      });
    }
  }, []);

  const isCollapseSubmenu = (key) => {
    if (!submenu) {
      return false;
    } else if (submenu === key) {
      return true;
    }
  };

  const baseModule = () => {
    const roleName = authInfo?.role?.display_name;

    const filteredRoleName = roleName?.toLowerCase()?.replaceAll(' ', '');

    let newModules = modules?.sort((a, b) => a.module.order - b.module.order);
    if (filteredRoleName === 'superadmin') {
      newModules = newModules.filter((val) => val.module.id62 !== 'CT');
    }
    return newModules;
  };

  const baseSubmodule = (item) => {
    let newSubmodules = item.sort((a, b) => a.module.order - b.module.order);
    return newSubmodules;
  };
  return (
    <ul className="fl_nav  ">
      {baseModule()?.map((item, key) =>
        item.module.sub_modules?.length > 0 ? (
          <li key={key} className={'fl_nav__item fl_nav__item__sub '}>
            <button
              className="fl_nav__link"
              onClick={() => onChangeSubmenu(item.module.id62)}
            >
              <i className={item.module.icon} />
              <label className="text-start">{item.module.name}</label>
            </button>
            <Collapse in={isCollapseSubmenu(item.module.id62)}>
              <ul className="fl_nav sub">
                {baseSubmodule(item.module.sub_modules).map(
                  (itemSub, keySub) => (
                    <li key={keySub}>
                      <a
                        href={itemSub.module?.url}
                        className={
                          'fl_nav__link__sub ' +
                          (itemSub.module?.url ===
                          `/${parrentPath[1].split('/')[0]}`
                            ? 'active'
                            : '')
                        }
                      >
                        <i className={itemSub.module?.icon} />{' '}
                        <label className="text-start">
                          {itemSub.module?.name}
                        </label>
                        {statusMenu[itemSub.module?.url] &&
                          now <
                            new Date(
                              statusMenu[itemSub.module?.url].expired_date
                            ) && (
                            <span>
                              {statusMenu[itemSub.module?.url]?.title}
                            </span>
                          )}
                      </a>
                    </li>
                  )
                )}
              </ul>
            </Collapse>
          </li>
        ) : (
          <li key={key} className="fl_nav__item">
            <a
              href={item.module.url}
              className={
                'fl_nav__link ' +
                (item.module.url === `/${parrentPath[1]}` ? 'active' : '')
              }
            >
              <i className={item.module.icon} /> {item.module.name}
              {statusMenu[item.module?.url] && (
                <span>{statusMenu[item.module?.url]?.title}</span>
              )}
            </a>
          </li>
        )
      )}
    </ul>
  );
}

export default Menu;
