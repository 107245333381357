import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'tag/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'tag/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_TAG = 'GET_DATA_TAG';
export const getDataTag = defaultActionCreator(GET_DATA_TAG, 'param');

export const GET_DATA_TAG_BY_ID = 'GET_DATA_TAG_BY_ID';
export const getDataTagById = defaultActionCreator(GET_DATA_TAG_BY_ID, 'id');

export const GET_DATA_TAG_NEXT_OR_PREV = 'GET_DATA_TAG_NEXT_OR_PREV';
export const getDataTagNextOrPrev = defaultActionCreator(
  GET_DATA_TAG_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_TAG = 'ADD_DATA_TAG';
export const addDataTag = defaultActionCreator(ADD_DATA_TAG, 'data');

export const UPDATE_DATA_TAG = 'UPDATE_DATA_TAG';
export const updateDataTag = defaultActionCreator(
  UPDATE_DATA_TAG,
  'data',
  'id'
);

export const DELETE_DATA_TAG = 'DELETE_DATA_TAG';
export const deleteDataTag = defaultActionCreator(DELETE_DATA_TAG, 'id');

export const ACTIVATION_DATA_TAG = 'ACTIVATION_DATA_TAG';
export const activationDataTag = defaultActionCreator(
  ACTIVATION_DATA_TAG,
  'id'
);

export const DOWNLOAD_DATA_TAG_EXCEL = 'DOWNLOAD_DATA_TAG_EXCEL';
export const downloadDataTagExcel = defaultActionCreator(
  DOWNLOAD_DATA_TAG_EXCEL,
  'param'
);
