import { all, put, takeLatest, takeEvery, call } from 'redux-saga/effects';
import { createLog } from '../../../services/Activity';
import {
  getStockBatch,
  getStockBatchByBatchNumber,
  getStockBatchById,
  getStockBatchDistributionByBatchNumber,
  stockBatchTransfer,
  stockBatchB2BSync,
  getStockBatchDistribution,
  getStockBatchDetailDistribution,
  getStockBatchPhotos,
  addStockBatchPhotos,
  deleteStockBatchPhotos
} from '../../../services/stockBatch';
import { bodyLog, setIpUser } from '../../../utils';
import {
  GET_DATA_STOCK_BATCH,
  GET_DATA_STOCK_BATCH_BY_BATCH_NUMBER,
  GET_DATA_STOCK_BATCH_BY_ID,
  GET_DATA_STOCK_BATCH_SEARCH,
  GET_DATA_STOCK_DISTRIBUTION_BY_BATCH_NUMBER,
  GET_DATA_STOCK_BATCH_DISTRIBUTION,
  GET_DATA_STOCK_BATCH_DETIAL_DISTRIBUTION,
  GET_DATA_STOCK_BATCH_PHOTOS,
  ADD_DATA_STOCK_BATCH_PHOTOS,
  DELETE_DATA_STOCK_BATCH_PHOTOS,
  STOCK_TRANSFER,
  STOCK_BATCH_B2B_SYNC,
  SET_ERROR_FORM,
  SET_STATE
} from './action';

export function* GET_STOCK_BATCH(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getStockBatch, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_STOCK_BATCH_SEARCH(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingSearch: true, dataSearch: null }
  });
  try {
    const { data } = yield call(getStockBatch, action.param);
    yield put({ type: SET_STATE, payload: { dataSearch: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingSearch: false } });
  }
}

export function* GET_STOCK_BATCH_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loadingByid: true, data: null } });
  try {
    const { data } = yield call(getStockBatchById, action.id, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingByid: false } });
  }
}

export function* GET_STOCK_BATCH_BY_BATCH_NUMBER(action) {
  yield put({ type: SET_STATE, payload: { loadingByid: true, data: null } });
  try {
    const { data } = yield call(
      getStockBatchByBatchNumber,
      action.id,
      action.param
    );
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingByid: false } });
  }
}

export function* GET_STOCK_BATCH_DISTRIBUTION_BY_BATCH_NUMBER(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingDistribution: true, dataDistribution: null }
  });
  try {
    const { data } = yield call(
      getStockBatchDistributionByBatchNumber,
      action.id,
      action.param
    );
    yield put({ type: SET_STATE, payload: { dataDistribution: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDistribution: false } });
  }
}

export function* STOCK_BATCH_TRANSFER(action) {
  yield put({ type: SET_STATE, payload: { loadingTrf: true } });
  try {
    yield call(stockBatchTransfer, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Stock Batch', 'Stock Batch Transfer');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingTrf: false } });
  }
}

export function* STOCK_BATCH_B2B_SYNC_F(action) {
  yield put({ type: SET_STATE, payload: { loadingB2BSync: true } });
  try {
    yield call(stockBatchB2BSync, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Stock Batch', 'Stock Batch B2B Sync');
    yield call(createLog, body);
    let message = 'Data sended to B2B';
    yield put({ type: SET_STATE, payload: { isAddB2BSync: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingB2BSync: false } });
  }
}

export function* GET_STOCK_BATCH_DISTRIBUTION(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getStockBatchDistribution, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_STOCK_BATCH_DETAIL_DISTRIBUTION(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getStockBatchDetailDistribution, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_STOCK_BATCH_PHOTOS(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingPhotos: true, photos: null }
  });
  try {
    const { data } = yield call(getStockBatchPhotos, action.param);
    yield put({ type: SET_STATE, payload: { dataPhotos: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingPhotos: false } });
  }
}

export function* ADD_STOCK_BATCH_PHOTOS(action) {
  yield put({ type: SET_STATE, payload: { loadingPhotos: true } });
  try {
    yield call(addStockBatchPhotos, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Stock Batch', 'Create Stock Batch Photos');
    yield call(createLog, body);

    let message = 'Your Data Has Been Submitted';
    yield put({ type: SET_STATE, payload: { isAddPhotos: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error });
    yield put({ type: SET_STATE, payload: { loadingPhotos: false } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingPhotos: false } });
  }
}

export function* DELETE_STOCK_BATCH_PHOTOS(action) {
  yield put({ type: SET_STATE, payload: { loadingPhotos: true } });
  try {
    yield call(deleteStockBatchPhotos, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Stock Batch', 'Delete Stock Batch Photos');
    yield call(createLog, body);

    let message = 'Successfully deleting the Data';
    yield put({ type: SET_STATE, payload: { isDeletePhotos: true, message } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
    yield put({ type: SET_STATE, payload: { loadingPhotos: false } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingPhotos: false } });
  }
}

export default all([
  takeEvery(GET_DATA_STOCK_BATCH_SEARCH, GET_STOCK_BATCH_SEARCH),
  takeEvery(GET_DATA_STOCK_BATCH, GET_STOCK_BATCH),
  takeLatest(GET_DATA_STOCK_BATCH_BY_ID, GET_STOCK_BATCH_BY_ID),
  takeLatest(
    GET_DATA_STOCK_BATCH_BY_BATCH_NUMBER,
    GET_STOCK_BATCH_BY_BATCH_NUMBER
  ),
  takeLatest(
    GET_DATA_STOCK_DISTRIBUTION_BY_BATCH_NUMBER,
    GET_STOCK_BATCH_DISTRIBUTION_BY_BATCH_NUMBER
  ),
  takeLatest(STOCK_TRANSFER, STOCK_BATCH_TRANSFER),
  takeLatest(STOCK_BATCH_B2B_SYNC, STOCK_BATCH_B2B_SYNC_F),
  takeLatest(GET_DATA_STOCK_BATCH_DISTRIBUTION, GET_STOCK_BATCH_DISTRIBUTION),
  takeLatest(
    GET_DATA_STOCK_BATCH_DETIAL_DISTRIBUTION,
    GET_STOCK_BATCH_DETAIL_DISTRIBUTION
  ),
  takeLatest(GET_DATA_STOCK_BATCH_PHOTOS, GET_STOCK_BATCH_PHOTOS),
  takeLatest(ADD_DATA_STOCK_BATCH_PHOTOS, ADD_STOCK_BATCH_PHOTOS),
  takeLatest(DELETE_DATA_STOCK_BATCH_PHOTOS, DELETE_STOCK_BATCH_PHOTOS)
]);
