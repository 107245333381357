import { lazy } from 'react';
import { retry } from '../utils/index';

export const routePersedian = [
  {
    path: '/stock-inventory',
    parent: '/stock-inventory',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockInventory" */ '../Pages/Persediaan/StockInventory/StockInventory'
        )
      )
    )
  },
  {
    path: '/stock-inventory/:id',
    parent: '/stock-inventory',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockInventoryView" */ '../Pages/Persediaan/StockInventory/StockInventoryView'
        )
      )
    )
  },
  {
    path: '/stock-inventory/add',
    parent: '/stock-inventory',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockInventoryAdd" */ '../Pages/Persediaan/StockInventory/StockInventoryAdd'
        )
      )
    )
  },
  {
    path: '/stock-inventory/change-inventory-type/:id',
    parent: '/stock-inventory',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockInventoryChange" */ '../Pages/Persediaan/StockInventory/ChangeInventoryType'
        )
      )
    )
  },
  {
    path: '/stock-inventory/change-to-fishfin/:id',
    parent: '/stock-inventory',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockInventoryChangeFishfin" */ '../Pages/Persediaan/StockInventory/ChangeToFishfin'
        )
      )
    )
  },
  {
    path: '/stock-inventory/change-to-fishfin/v1/:id',
    parent: '/stock-inventory',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockInventoryChangeFishfinV1" */ '../Pages/Persediaan/StockInventory/ChangeToFishfinV1'
        )
      )
    )
  },
  // {
  //   path: '/stock-opname',
  //   parent: '/stock-opname',
  //   action: 'view',
  //   isParent: true,
  //   component: lazy(() =>
  //     retry(() =>
  //       import(
  //         /* webpackChunkName: "StockOpname" */ '../Pages/Persediaan/StockOpname/StockOpname'
  //       )
  //     )
  //   )
  // },
  // {
  //   path: '/stock-opname/add',
  //   parent: '/stock-opname',
  //   action: 'create',
  //   component: lazy(() =>
  //     retry(() =>
  //       import(
  //         /* webpackChunkName: "StockOpname" */ '../Pages/Persediaan/StockOpname/StockOpnameAdd'
  //       )
  //     )
  //   )
  // },
  // {
  //   path: '/stock-opname/update/:id',
  //   parent: '/stock-opname',
  //   action: 'update',
  //   component: lazy(() =>
  //     retry(() =>
  //       import(
  //         /* webpackChunkName: "StockOpname" */ '../Pages/Persediaan/StockOpname/StockOpnameUpdate'
  //       )
  //     )
  //   )
  // },
  // {
  //   path: '/stock-opname/detail/:id',
  //   parent: '/stock-opname',
  //   action: 'view',
  //   component: lazy(() =>
  //     retry(() =>
  //       import(
  //         /* webpackChunkName: "StockOpnameDetail" */ '../Pages/Persediaan/StockOpname/StockOpnameDetail'
  //       )
  //     )
  //   )
  // },
  // {
  //   path: '/stock-opname/approval/:id',
  //   parent: '/stock-opname',
  //   action: 'view',
  //   component: lazy(() =>
  //     retry(() =>
  //       import(
  //         /* webpackChunkName: "StockOpnameApprove" */ '../Pages/Persediaan/StockOpname/StockOpnameApproval'
  //       )
  //     )
  //   )
  // },
  {
    path: '/scan-stock',
    parent: '/scan-stock',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockOpname" */ '../Pages/Persediaan/ScannerQR'
        )
      )
    )
  },
  {
    path: '/stock-batch',
    parent: '/stock-batch',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockBatch" */ '../Pages/Persediaan/StockBatch'
        )
      )
    )
  },
  {
    path: '/stock-batch/:id',
    parent: '/stock-batch',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockBatch" */ '../Pages/Persediaan/StockBatch/Detail'
        )
      )
    )
  },
  {
    path: '/stock-batch/stock-transfer/:batch_number',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockBatchTransfer" */ '../Pages/Persediaan/StockBatch/StockTransfer'
        )
      )
    )
  },
  {
    path: '/stock-movement',
    parent: '/stock-movement',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockMovement" */ '../Pages/Persediaan/StockMovement'
        )
      )
    )
  },
  {
    path: '/stock-movement/:id',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockMovementView" */ '../Pages/Persediaan/StockMovement/Detail'
        )
      )
    )
  },
  {
    path: '/inventory-changes',
    parent: '/inventory-changes',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "InventoryChanges" */ '../Pages/Persediaan/inventoryChanges'
        )
      )
    )
  },
  {
    path: '/inventory-changes/:id',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "InventoryChangesView" */ '../Pages/Persediaan/inventoryChanges/detail'
        )
      )
    )
  },
  {
    path: '/inventory-changes/draft/:id',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "InventoryChangesUpdate" */ '../Pages/Persediaan/inventoryChanges/edit'
        )
      )
    )
  },

  {
    path: '/stock-adjustment',
    parent: '/stock-adjustment',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockAdjustment" */ '../Pages/Persediaan/StockAdjustment'
        )
      )
    )
  },
  {
    path: '/stock-adjustment/add',
    parent: '/stock-adjustment',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockAdjustmentAdd" */ '../Pages/Persediaan/StockAdjustment/StockAdjustmentAdd'
        )
      )
    )
  },
  {
    path: '/stock-adjustment/detail/:id',
    parent: '/stock-adjustment',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockAdjustmentDetail" */ '../Pages/Persediaan/StockAdjustment/StockAdjustmentDetail'
        )
      )
    )
  },
  {
    path: '/stock-adjustment/submission/:id',
    parent: '/stock-adjustment',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockAdjustmentSubmission" */ '../Pages/Persediaan/StockAdjustment/StockAdjustmentSubmission'
        )
      )
    )
  },

  {
    path: '/void-transaction',
    parent: '/void-transaction',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "VoidTransaction" */ '../Pages/Persediaan/VoidTransaction'
        )
      )
    )
  },
  {
    path: '/void-transaction/inbound/:id',
    parent: '/void-transaction',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "VoidTransaction" */ '../Pages/Persediaan/VoidTransaction/DetailInbound'
        )
      )
    )
  },
  {
    path: '/void-transaction/purchase/:id',
    parent: '/void-transaction',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "VoidTransaction" */ '../Pages/Persediaan/VoidTransaction/DetailPurchase'
        )
      )
    )
  },
  {
    path: '/void-transaction/inbound-approve/:id',
    parent: '/void-transaction',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "VoidTransaction" */ '../Pages/Persediaan/VoidTransaction/ApprovalInbound'
        )
      )
    )
  },
  {
    path: '/void-transaction/purchase-approve/:id',
    parent: '/void-transaction',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "VoidTransaction" */ '../Pages/Persediaan/VoidTransaction/ApprovalPurchase'
        )
      )
    )
  }
];
