import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';

import {
  GET_DATA_MODULE,
  GET_DATA_MODULE_BY_ID,
  ADD_DATA_MODULE,
  UPDATE_DATA_MODULE,
  DELETE_DATA_MODULE,
  SET_STATE,
  GET_DATA_MODULE_NEXT_OR_PREV,
  SET_ERROR_FORM
} from './actions';
import {
  geteDataModule,
  getDataModuleById,
  addDataModule,
  updateDataModule,
  deleteDataModule
} from '../../../services/module';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_MODULE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(geteDataModule, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* GET_MODULE_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* GET_MODULE_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataModuleById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_MODULE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addDataModule, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'string', 'Create Module');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* UPDATE_MODULE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateDataModule, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'string', 'Edit Module');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DELETE_MODULE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteDataModule, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'string', 'Delete Module');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loading: false }
    });
  }
}

export default all([
  takeLatest(GET_DATA_MODULE, GET_MODULE),
  takeLatest(GET_DATA_MODULE_BY_ID, GET_MODULE_BY_ID),
  takeLatest(ADD_DATA_MODULE, ADD_MODULE),
  takeLatest(GET_DATA_MODULE_NEXT_OR_PREV, GET_MODULE_NEXT_OR_PREV),
  takeLatest(UPDATE_DATA_MODULE, UPDATE_MODULE),
  takeLatest(DELETE_DATA_MODULE, DELETE_MODULE)
]);
