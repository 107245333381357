import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getProductSku,
  getProductSkuById,
  addProductSku,
  updateProductSku,
  deleteProductSku,
  downloadTemplateProductSku,
  downloadDataProductSku,
  uploadProductSku,
  activationProductSku,
  exportProductSkuExcel
} from '../../../services/productSku';
import { handleDownload, logMatches } from '../../../utils';
import {
  SET_STATE,
  GET_DATA_PRODUCT_SKU,
  GET_DATA_PRODUCT_SKU_BY_ID,
  ADD_DATA_PRODUCT_SKU,
  UPDATE_DATA_PRODUCT_SKU,
  DELETE_DATA_PRODUCT_SKU,
  GET_DATA_PRODUCT_SKU_NEXT_OR_PREV,
  SET_ERROR_FORM,
  DOWNLOAD_DATA_PRODUCT_SKU,
  DOWNLOAD_TEMPLATE_PRODUCT_SKU,
  UPLOAD_DATA_PRODUCT_SKU,
  ACTIVATION_DATA_PRODUCT_SKU,
  DOWNLOAD_DATA_PRODUCT_SKU_EXCEL
} from './actions';

import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_PRODUCT_SKU(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getProductSku, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_PRODUCT_SKU_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getProductSkuById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_PRODUCT_SKU_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_PRODUCT_SKU(action) {
  yield put({ type: SET_STATE, payload: { loadingForm: true } });
  try {
    yield call(addProductSku, action.data);
    yield setIpUser();
    const body = bodyLog(
      'create',
      'Product Variants',
      'Create Product Variants'
    );
    yield call(createLog, body);
    let message = 'Your Data Has Been Submitted';
    yield put({ type: SET_STATE, payload: { isAdd: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  }
}

export function* UPDATE_PRODUCT_SKU(action) {
  yield put({ type: SET_STATE, payload: { loadingForm: true } });
  try {
    yield call(updateProductSku, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Product Variants', 'Edit Product Variants');
    yield call(createLog, body);
    let message = 'Your Data Has Been Submitted';
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  }
}

export function* DELETE_PRODUCT_SKU(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteProductSku, action.id);
    yield setIpUser();
    const body = bodyLog(
      'delete',
      'Product Variants',
      'Delete Product Variants'
    );
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    let errData = response?.data?.data;
    let errMessage = response?.data?.message?.id || response.data.message?.en;

    yield put({ type: SET_STATE, payload: { error: errData ?? errMessage } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loading: false }
    });
  }
}

export function* DOWNLOAD_TEMPLATE_PRODUCT_SKU_F() {
  // yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(downloadTemplateProductSku);
    yield handleDownload(data, 'SKU_template.csv');
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* DOWNLOAD_PRODUCT_SKU(action) {
  try {
    const { data, headers } = yield call(downloadDataProductSku, action.param);
    const filename = yield logMatches(headers['content-disposition']);
    yield handleDownload(data, filename[0]);
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* UPLOAD_PRODUCT_SKU(action) {
  yield put({ type: SET_STATE, payload: { loadingUpload: true } });
  try {
    yield call(uploadProductSku, action.data);
    yield put({
      type: SET_STATE,
      payload: { isUpload: true, isUpdate: true }
    });
  } catch ({ response }) {
    yield put({
      type: SET_STATE,
      payload: { errorUpload: response?.data, isUpload: false }
    });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loadingUpload: false }
    });
  }
}

export function* ACTIVATION_PRODUCT_SKU(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(activationProductSku, action.id62);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DOWNLOAD_PRODUCT_SKU_EXCEL(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(exportProductSkuExcel, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_PRODUCT_SKU, GET_PRODUCT_SKU),
  takeLatest(GET_DATA_PRODUCT_SKU_BY_ID, GET_PRODUCT_SKU_BY_ID),
  takeLatest(GET_DATA_PRODUCT_SKU_NEXT_OR_PREV, GET_PRODUCT_SKU_NEXT_OR_PREV),
  takeLatest(ADD_DATA_PRODUCT_SKU, ADD_PRODUCT_SKU),
  takeLatest(UPDATE_DATA_PRODUCT_SKU, UPDATE_PRODUCT_SKU),
  takeLatest(DELETE_DATA_PRODUCT_SKU, DELETE_PRODUCT_SKU),
  takeLatest(DOWNLOAD_TEMPLATE_PRODUCT_SKU, DOWNLOAD_TEMPLATE_PRODUCT_SKU_F),
  takeLatest(DOWNLOAD_DATA_PRODUCT_SKU, DOWNLOAD_PRODUCT_SKU),
  takeLatest(UPLOAD_DATA_PRODUCT_SKU, UPLOAD_PRODUCT_SKU),
  takeLatest(ACTIVATION_DATA_PRODUCT_SKU, ACTIVATION_PRODUCT_SKU),
  takeLatest(DOWNLOAD_DATA_PRODUCT_SKU_EXCEL, DOWNLOAD_PRODUCT_SKU_EXCEL)
]);
