import { lazy } from 'react';
import { retry } from '../utils/index';

export const routePackagging = [
  {
    path: '/packaging',
    parent: '/packaging',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockInventory" */ '../Pages/MasterData/Packagging'
        )
      )
    )
  },
  {
    path: '/packaging/add',
    parent: '/packaging',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupPenjualananReturAdd" */ '../Pages/MasterData/Packagging/add'
        )
      )
    )
  },
  {
    path: '/packaging/:id',
    parent: '/packaging',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockInventory" */ '../Pages/MasterData/Packagging/detail'
        )
      )
    )
  },
  {
    path: '/packaging/update/:id',
    parent: '/packaging',
    action: 'update',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockInventory" */ '../Pages/MasterData/Packagging/update'
        )
      )
    )
  }
];
