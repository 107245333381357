import service from './service';
import endpoint from './constantUrl';

export const getDataStokIn = (param) => {
  return service.get(`${endpoint.POS_URL}/stock/in/`, param);
};

export const createDataStokIn = (param) => {
  return service.post(`${endpoint.POS_URL}/stock/in/`, param);
};

export const generateNoStockIn = (data) => {
  return service.post(`${endpoint.POS_URL}/stock/in/generate-number/`, data);
};

export const deleteDataStockIn = (id) => {
  return service.delete(`${endpoint.POS_URL}/stock/in/${id}/`);
};

export const getStockInById = (id) => {
  return service.get(`${endpoint.POS_URL}/stock/in/${id}/`);
};

export const updateStatusKalkulasi = (data, id) => {
  return service.put(
    `${endpoint.POS_URL}/stock/in/${id}/update-has-calculated/`,
    data
  );
};

export const updateStokIn = (id, data) => {
  return service.put(`${endpoint.POS_URL}/stock/in/${id}/`, data);
};

export const uploadDataStockIn = (data) => {
  return service.post(`${endpoint.POS_URL}/stock/in/import-csv/`, data);
};

export const downloadCsv = (data) => {
  return service.get(`${endpoint.POS_URL}/stock/in/export-csv/`, data);
};

export const downloadExcel = (data) => {
  return service.getArrayBuffer(
    `${endpoint.POS_URL}/stock/in/export-excel/`,
    data
  );
};

export const templateCsv = (data) => {
  return service.get(`${endpoint.POS_URL}/stock/in/csv-template/`, data);
};
