import { all, put, takeLatest, call } from 'redux-saga/effects';
import { SET_STATE, GET_DATA_RETUR } from './action';
import { getDataReturPenjualan } from '../../../services/penjualan';

export function* GET_RETUR(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });

  try {
    const data = yield call(getDataReturPenjualan, action.body);

    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: err }) {
    yield put({ type: SET_STATE, payload: { err } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: true } });
  }
}

export default all([takeLatest(GET_DATA_RETUR, GET_RETUR)]);
