import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'gudang/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'gudang/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_GUDANG = 'GET_DATA_GUDANG';
export const getDataGudang = defaultActionCreator(GET_DATA_GUDANG, 'param');

export const GET_DATA_GUDANG_2 = 'GET_DATA_GUDANG_2';
export const getDataGudang2 = defaultActionCreator(GET_DATA_GUDANG_2, 'param');

export const GET_DATA_GUDANGS = 'GET_DATA_GUDANGS';
export const getDataGudangs = defaultActionCreator(
  GET_DATA_GUDANGS,
  'param',
  'key'
);

export const GET_DATA_GUDANG_BY_ID = 'GET_DATA_GUDANG_BY_ID';
export const getDataGudangById = defaultActionCreator(
  GET_DATA_GUDANG_BY_ID,
  'id'
);
export const GET_DATA_GUDANG_NEXT_OR_PREV = 'GET_DATA_GUDANG_NEXT_OR_PREV';
export const getDataGudangNextOrPrev = defaultActionCreator(
  GET_DATA_GUDANG_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_GUDANG = 'ADD_DATA_GUDANG';
export const addDataGudang = defaultActionCreator(ADD_DATA_GUDANG, 'data');
export const UPDATE_DATA_GUDANG = 'UPDATE_DATA_GUDANG';
export const updateDataGudang = defaultActionCreator(
  UPDATE_DATA_GUDANG,
  'data',
  'id'
);

export const DELETE_DATA_GUDANG = 'DELETE_DATA_GUDANG';
export const deleteDataGudang = defaultActionCreator(DELETE_DATA_GUDANG, 'id');

export const DOWNLOAD_TEMPLATE_GUDANG = 'DOWNLOAD_TEMPLATE_GUDANG';
export const downloadTemplateGudang = defaultActionCreator(
  DOWNLOAD_TEMPLATE_GUDANG,
  'id'
);

export const DOWNLOAD_DATA_GUDANG = 'DOWNLOAD_DATA_GUDANG';
export const downloadDataGudang = defaultActionCreator(
  DOWNLOAD_DATA_GUDANG,
  'param'
);

export const UPLOAD_DATA_GUDANG = 'UPLOAD_DATA_GUDANG';
export const uploadDataGudang = defaultActionCreator(
  UPLOAD_DATA_GUDANG,
  'data'
);

export const ACTIVATION_DATA_GUDANG = 'ACTIVATION_DATA_GUDANG';
export const activationDataGudang = defaultActionCreator(
  ACTIVATION_DATA_GUDANG,
  'id62'
);

export const TARIF_HISTORI_DATA_GUDANG = 'TARIF_HISTORI_DATA_GUDANG';
export const tarifHistoriDataGudang = defaultActionCreator(
  TARIF_HISTORI_DATA_GUDANG,
  'data'
);

//warehouse category

export const GET_DATA_WAREHOUSE_CATEGORY = 'GET_DATA_WAREHOUSE_CATEGORY';
export const getDataWarehouseCategory = defaultActionCreator(
  GET_DATA_WAREHOUSE_CATEGORY,
  'param'
);
