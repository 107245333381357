import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'reference/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const GET_DATA_COUNTRY = 'GET_DATA_COUNTRY';
export const getDataCountry = defaultActionCreator(GET_DATA_COUNTRY, 'param');

export const GET_DATA_PROVINCE = 'GET_DATA_PROVINCE';
export const getDataProvince = defaultActionCreator(GET_DATA_PROVINCE, 'param');

export const GET_DATA_REGENCY = 'GET_DATA_REGENCY';
export const getDataRegency = defaultActionCreator(GET_DATA_REGENCY, 'param');

export const GET_DATA_DISTRICT = 'GET_DATA_DISTRICT';
export const getDataDistrict = defaultActionCreator(GET_DATA_DISTRICT, 'param');

export const MASTER_DATA_BASE64_UPLOAD = 'MASTER_DATA_BASE64_UPLOAD';
export const masterDataBase64Upload = defaultActionCreator(
  MASTER_DATA_BASE64_UPLOAD,
  'data',
  'key'
);

export const ACCOUNT_BASE64_UPLOAD = 'ACCOUNT_BASE64_UPLOAD';
export const accountBase64Upload = defaultActionCreator(
  ACCOUNT_BASE64_UPLOAD,
  'data',
  'key'
);

export const POS_BASE64_UPLOAD = 'POS_BASE64_UPLOAD';
export const posBase64Upload = defaultActionCreator(
  POS_BASE64_UPLOAD,
  'data',
  'key'
);

export const POS_BASE64_UPLOAD_IMAGE = 'POS_BASE64_UPLOAD_IMAGE';
export const posBase64UploadImage = defaultActionCreator(
  POS_BASE64_UPLOAD_IMAGE,
  'data',
  'key'
);

export const RENTAL_BASE64_UPLOAD = 'RENTAL_BASE64_UPLOAD';
export const rentalBase64Upload = defaultActionCreator(
  RENTAL_BASE64_UPLOAD,
  'data',
  'key'
);

export const CASH_BASE64_UPLOAD = 'CASH_BASE64_UPLOAD';
export const cashBase64Upload = defaultActionCreator(
  CASH_BASE64_UPLOAD,
  'data',
  'key'
);

export const UPLOAD_FILE = 'UPLOAD_FILE';
export const uploadFile = defaultActionCreator(UPLOAD_FILE, 'data', 'key');

export const GET_DATA_GEOLOCATION = 'GET_DATA_GEOLOCATION';
export const getDataGeolocation = defaultActionCreator(
  GET_DATA_GEOLOCATION,
  'data'
);

export const GET_DATA_GEOLOCATION_V2 = 'GET_DATA_GEOLOCATION_V2';
export const getDataGeolocationV2 = defaultActionCreator(
  GET_DATA_GEOLOCATION_V2,
  'data'
);

export const GET_DATA_AREA = 'GET_DATA_AREA';
export const getDataArea = defaultActionCreator(GET_DATA_AREA, 'data');

export const GET_DATA_SUBAREA = 'GET_DATA_SUBAREA';
export const getDataSubarea = defaultActionCreator(GET_DATA_SUBAREA, 'data');

export const GET_DATA_FISHING_METHOD = 'GET_DATA_FISHING_METHOD';
export const getDataFishingMethod = defaultActionCreator(
  GET_DATA_FISHING_METHOD,
  'data'
);
