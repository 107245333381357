import { all, put, takeLatest, takeEvery, call } from 'redux-saga/effects';

import {
  getStockMovement,
  getStockMovementById
} from '../../../services/stockMovement';
import {
  GET_DATA_STOCK_MOVEMENT,
  GET_DATA_STOCK_MOVEMENT_BY_ID,
  GET_DATA_STOCK_MOVEMENT_SEARCH,
  SET_STATE
} from './action';

export function* GET_STOCK_MOVEMENT(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getStockMovement, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* GET_STOCK_MOVEMENT_SEARCH(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingSearch: true, dataSearch: null }
  });
  try {
    const { data } = yield call(getStockMovement, action.param);
    yield put({ type: SET_STATE, payload: { dataSearch: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingSearch: false } });
  }
}
export function* GET_STOCK_MOVEMENT_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loadingByid: true, data: null } });
  try {
    const { data } = yield call(getStockMovementById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingByid: false } });
  }
}

export default all([
  takeEvery(GET_DATA_STOCK_MOVEMENT_SEARCH, GET_STOCK_MOVEMENT_SEARCH),
  takeEvery(GET_DATA_STOCK_MOVEMENT, GET_STOCK_MOVEMENT),
  takeLatest(GET_DATA_STOCK_MOVEMENT_BY_ID, GET_STOCK_MOVEMENT_BY_ID)
]);
