import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'Perusahaan/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'Perusahaan/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_PERUSAHAAN = 'GET_DATA_PERUSAHAAN';
export const getDataPerusahaan = defaultActionCreator(
  GET_DATA_PERUSAHAAN,
  'param'
);

export const GET_DATA_PERUSAHAAN_BY_ID = 'GET_DATA_PERUSAHAAN_BY_ID';
export const getDataPerusahaanById = defaultActionCreator(
  GET_DATA_PERUSAHAAN_BY_ID,
  'id'
);

export const GET_DATA_PERUSAHAAN_NEXT_OR_PREV =
  'GET_DATA_PERUSAHAAN_NEXT_OR_PREV';
export const getDataPerusahaanNextOrPrev = defaultActionCreator(
  GET_DATA_PERUSAHAAN_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_PERUSAHAAN = 'ADD_DATA_PERUSAHAAN';
export const addDataPerusahaan = defaultActionCreator(
  ADD_DATA_PERUSAHAAN,
  'data'
);

export const UPDATE_DATA_PERUSAHAAN = 'UPDATE_DATA_PERUSAHAAN';
export const updateDataPerusahaan = defaultActionCreator(
  UPDATE_DATA_PERUSAHAAN,
  'data',
  'id'
);

export const DELETE_DATA_PERUSAHAAN = 'DELETE_DATA_PERUSAHAAN';
export const deleteDataPerusahaan = defaultActionCreator(
  DELETE_DATA_PERUSAHAAN,
  'id'
);

export const DOWNLOAD_DATA_PERUSAHAAN = 'DOWNLOAD_DATA_PERUSAHAAN';
export const downloadDataPerusahaan = defaultActionCreator(
  DOWNLOAD_DATA_PERUSAHAAN,
  'param'
);

export const STATUS_DATA_PERUSAHAAN = 'STATUS_DATA_PERUSAHAAN';
export const statusDataPerusahaan = defaultActionCreator(
  STATUS_DATA_PERUSAHAAN,
  'id'
);
