import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getGrade,
  getGradeById,
  addGrade,
  updateGrade,
  deleteGrade
} from '../../../services/grade';
import {
  SET_STATE,
  SET_ERROR_FORM,
  GET_DATA_GRADE,
  GET_DATA_GRADE_BY_ID,
  GET_DATA_GRADE_NEXT_OR_PREV,
  ADD_DATA_GRADE,
  UPDATE_DATA_GRADE,
  DELETE_DATA_GRADE
} from './actions';

import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_GRADE(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getGrade, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_GRADE_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getGradeById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_GRADE_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_GRADE(action) {
  yield put({ type: SET_STATE, payload: { loadingForm: true } });
  try {
    yield call(addGrade, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Data Master', 'Create Grade');
    yield call(createLog, body);
    let message = 'Your Data Has Been Submitted';
    yield put({ type: SET_STATE, payload: { isAdd: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  }
}

export function* UPDATE_GRADE(action) {
  yield put({ type: SET_STATE, payload: { loadingForm: true } });
  try {
    yield call(updateGrade, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Data Master', 'Edit Grade');
    yield call(createLog, body);
    let message = 'Your Data Has Been Submitted';
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  }
}

export function* DELETE_GRADE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteGrade, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Data Master', 'Delete Grade');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loading: false }
    });
  }
}

export default all([
  takeLatest(GET_DATA_GRADE, GET_GRADE),
  takeLatest(GET_DATA_GRADE_BY_ID, GET_GRADE_BY_ID),
  takeLatest(GET_DATA_GRADE_NEXT_OR_PREV, GET_GRADE_NEXT_OR_PREV),
  takeLatest(ADD_DATA_GRADE, ADD_GRADE),
  takeLatest(UPDATE_DATA_GRADE, UPDATE_GRADE),
  takeLatest(DELETE_DATA_GRADE, DELETE_GRADE)
]);
