import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeOutboundManagement = [
  {
    path: '/outbound',
    parent: '/outbound',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "OutboundManagement" */ '../Pages/OutboundManagement'
        )
      )
    )
  },
  {
    path: '/outbound/add',
    parent: '/outbound',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "OutboundManagementAdd" */ '../Pages/OutboundManagement/add'
        )
      )
    )
  },
  {
    path: '/outbound/:id',
    parent: '/outbound',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "OutboundManagementDetail" */ '../Pages/OutboundManagement/detail'
        )
      )
    )
  },
  {
    path: '/outbound/approval/:id',
    parent: '/outbound',
    action: 'approve',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "OutboundManagementApproval" */ '../Pages/OutboundManagement/detail'
        )
      )
    )
  },
  {
    path: '/outbound/delivery/:id',
    parent: '/outbound',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "OutboundManagementApproval" */ '../Pages/OutboundManagement/detail'
        )
      )
    )
  }
];
