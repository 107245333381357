import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeGudang = [
  {
    path: '/warehouse',
    parent: '/warehouse',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Gudang" */ '../Pages/MasterData/Gudang/gudangModule'
        )
      )
    )
  },
  {
    path: '/warehouse/add',
    parent: '/warehouse',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "GudangAdd" */ '../Pages/MasterData/Gudang/gudangAdd'
        )
      )
    )
  },
  {
    path: '/warehouse/update/:idUpdate',
    parent: '/warehouse',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "GudangUpdate" */ '../Pages/MasterData/Gudang/gudangAdd'
        )
      )
    )
  },
  {
    path: '/warehouse/detail/:idDetail',
    parent: '/warehouse',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "GudangView" */ '../Pages/MasterData/Gudang/gudangView'
        )
      )
    )
  }
];
