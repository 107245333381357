import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'stockAdjustment/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'stockAdjustment/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_STOCK_ADJUSTMENT = 'GET_DATA_STOCK_ADJUSTMENT';
export const getDataStockAdjustment = defaultActionCreator(
  GET_DATA_STOCK_ADJUSTMENT,
  'param'
);

export const ADD_DATA_STOCK_ADJUSTMENT = 'ADD_DATA_STOCK_ADJUSTMENT';
export const addDataStockAdjustment = defaultActionCreator(
  ADD_DATA_STOCK_ADJUSTMENT,
  'data'
);

export const GET_DATA_STOCK_ADJUSTMENT_BY_ID =
  'GET_DATA_STOCK_ADJUSTMENT_BY_ID';
export const getDataStockAdjustmentById = defaultActionCreator(
  GET_DATA_STOCK_ADJUSTMENT_BY_ID,
  'id'
);

export const GET_DATA_PRODUCT_LIST = 'GET_DATA_PRODUCT_LIST';
export const getDataProductList = defaultActionCreator(
  GET_DATA_PRODUCT_LIST,
  'param',
  'id'
);

export const START_ADJUSTMENT_PROCESS = 'START_ADJUSTMENT_PROCESS';
export const startAdjustmentProcess = defaultActionCreator(
  START_ADJUSTMENT_PROCESS,
  'data',
  'id'
);

export const ADJUST_DATA_STOCK = 'ADJUST_DATA_STOCK';
export const adjustDataStock = defaultActionCreator(
  ADJUST_DATA_STOCK,
  'data',
  'id'
);

export const SUBMIT_DATA_ADJUSTMENT = 'SUBMIT_DATA_ADJUSTMENT';
export const submitDataAdjustment = defaultActionCreator(
  SUBMIT_DATA_ADJUSTMENT,
  'data',
  'id'
);

export const SUBMIT_ADJUSTMENT_APPROVAL = 'SUBMIT_ADJUSTMENT_APPROVAL';
export const submitAdjustmentApproval = defaultActionCreator(
  SUBMIT_ADJUSTMENT_APPROVAL,
  'data',
  'id'
);

export const EXPORT_EXCEL_DATA_ADJUSTMENT = 'EXPORT_EXCEL_DATA_ADJUSTMENT';
export const exportExcelAdjustment = defaultActionCreator(
  EXPORT_EXCEL_DATA_ADJUSTMENT,
  'param'
);
