import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getCertification,
  getCertificationById,
  addCertification,
  updateCertification,
  deleteCertification,
  activationCertification,
  exportCertificationPDF,
  exportCertificationExcel
} from '../../../services/certification';
import {
  SET_STATE,
  SET_ERROR_FORM,
  GET_DATA_CERTIFICATION,
  GET_DATA_CERTIFICATION_BY_ID,
  GET_DATA_CERTIFICATION_NEXT_OR_PREV,
  ADD_DATA_CERTIFICATION,
  UPDATE_DATA_CERTIFICATION,
  DELETE_DATA_CERTIFICATION,
  ACTIVATION_DATA_CERTIFICATION,
  DOWNLOAD_DATA_CERTIFICATION_PDF,
  DOWNLOAD_DATA_CERTIFICATION_EXCEL
} from './actions';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog, handleDownload, logMatches } from '../../../utils';

export function* GET_CERTIFICATION(action) {
  yield put({ type: SET_STATE, payload: { data: null, loading: true } });
  try {
    const { data } = yield call(getCertification, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_CERTIFICATION_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { data: null, loading: true } });
  try {
    const { data } = yield call(getCertificationById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_CERTIFICATION_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_CERTIFICATION(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addCertification, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Certification', 'Create Certification');
    yield call(createLog, body);

    let message = 'Your Data Has Been Submitted';
    yield put({ type: SET_STATE, payload: { isAdd: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* UPDATE_CERTIFICATION(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateCertification, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Certification', 'Edit Certification');
    yield call(createLog, body);

    let message = 'Your Data Has Been Submitted';
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DELETE_CERTIFICATION(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteCertification, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Certification', 'Delete Certification');
    yield call(createLog, body);

    let message = 'Successfully deleting certification';
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ message: error }) {
    yield put({
      type: SET_STATE,
      payload: { loading: false }
    });
    yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* ACTIVATION_CERTIFICATION(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(activationCertification, action.id);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DOWNLOAD_CERTIFICATION_PDF(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(exportCertificationPDF, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DOWNLOAD_CERTIFICATION_EXCEL(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(exportCertificationExcel, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_CERTIFICATION, GET_CERTIFICATION),
  takeLatest(GET_DATA_CERTIFICATION_BY_ID, GET_CERTIFICATION_BY_ID),
  takeLatest(
    GET_DATA_CERTIFICATION_NEXT_OR_PREV,
    GET_CERTIFICATION_NEXT_OR_PREV
  ),
  takeLatest(ADD_DATA_CERTIFICATION, ADD_CERTIFICATION),
  takeLatest(UPDATE_DATA_CERTIFICATION, UPDATE_CERTIFICATION),
  takeLatest(DELETE_DATA_CERTIFICATION, DELETE_CERTIFICATION),
  takeLatest(ACTIVATION_DATA_CERTIFICATION, ACTIVATION_CERTIFICATION),
  takeLatest(DOWNLOAD_DATA_CERTIFICATION_PDF, DOWNLOAD_CERTIFICATION_PDF),
  takeLatest(DOWNLOAD_DATA_CERTIFICATION_EXCEL, DOWNLOAD_CERTIFICATION_EXCEL)
]);
