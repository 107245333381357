import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'packaging/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'packaging/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_PACKAGING = 'GET_DATA_PACKAGING';
export const getDataPackaging = defaultActionCreator(
  GET_DATA_PACKAGING,
  'param'
);

export const DELETE_DATA_PACKAGGING = 'DELETE_DATA_PACKAGGING';
export const deleteDataPackagging = defaultActionCreator(
  DELETE_DATA_PACKAGGING,
  'id'
);
export const ACTIVATION_DATA_PACKAGING = 'ACTIVATION_DATA_PACKAGING';
export const activationDataPackaging = defaultActionCreator(
  ACTIVATION_DATA_PACKAGING,
  'id62'
);

export const GET_DATA_PACKAGGING_BY_ID = 'GET_DATA_PACKAGGING_BY_ID';
export const getDataPackaggingById = defaultActionCreator(
  GET_DATA_PACKAGGING_BY_ID,
  'id'
);
