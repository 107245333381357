import service from './service';
import endpoint from './constantUrl';

export const getDataUserMasterData = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/user/user/`, param);
};

export const getDataUserMasterDataById = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/user/user/${id}/`);
};

export const addDataUserMasterData = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/user/user/`, data);
};

export const updateDataUserMasterData = (data, id) => {
  return service.put(`${endpoint.MASTERDATA_URL}/user/user/${id}/`, data);
};

export const deleteDataUserMasterData = (id) => {
  return service.delete(`${endpoint.MASTERDATA_URL}/user/user/${id}/`);
};

export const activationUser = (id62) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/user/user/${id62}/user-activation/`
  );
};

export const downloadTemplateUserMasterData = () => {
  return service.post(`${endpoint.MASTERDATA_URL}/user/user/csv-template/`);
};

export const downloadDataUserMasterData = (param) => {
  return service.getArrayBuffer(
    `${endpoint.MASTERDATA_URL}/user/user/export-excel/`,
    param
  );
};

export const uploadUserMasterData = (data) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/user/user/import-user-csv/`,
    data
  );
};

export const getUserById62IdGudang = (id, warehouse_id62) => {
  return service.get(
    `${endpoint.MASTERDATA_URL}/user/user/${id}/warehouse/?warehouse_id62=${warehouse_id62}`
  );
};

export const getUserSupplier = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/user/user-supplier/`, param);
};

export const getUserCustomer = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/user/user-customer/`, param);
};
