import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeUser = [
  {
    path: '/partner',
    parent: '/partner',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Usermaster" */ '../Pages/MasterData/User/userModule'
        )
      )
    )
  },
  {
    path: '/partner/add',
    parent: '/partner',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Usermaster" */ '../Pages/MasterData/User/userAdd'
        )
      )
    )
  },
  {
    path: '/partner/view/:id',
    parent: '/partner',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Usermaster" */ '../Pages/MasterData/User/userView'
        )
      )
    )
  },
  {
    path: '/partner/update/:id',
    parent: '/partner',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Usermaster" */ '../Pages/MasterData/User/userEdit'
        )
      )
    )
  },
  {
    path: '/penyewa',
    parent: '/penyewa',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupAdd" */ '../Pages/WarehouseRental/Penyewa/Penyewa'
        )
      )
    )
  },
  {
    path: '/penyewa/detail/:id',
    parent: '/penyewa',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupAdd" */ '../Pages/WarehouseRental/Penyewa/Detail'
        )
      )
    )
  }
];
