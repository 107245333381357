import moment from 'moment';
import trans from './useTranslate';
import haversine from 'haversine-distance';
import constants from './constants';

export function distanceLatLong(positionStart, positionEnd) {
  return haversine(positionStart, positionEnd);
}

function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

const setToken = (token) => {
  localStorage.setItem('token', token);
};

const setUserInfo = (data) => {
  localStorage.setItem('user_info', JSON.stringify(data));
};

const getToken = () => {
  return localStorage.getItem('token');
};

const isLatitude = (num) => isFinite(num) && Math.abs(num) <= 90;
const isLongitude = (num) => isFinite(num) && Math.abs(num) <= 180;

const fileReaderHandler = (file) => {
  return new Promise(function (resolve) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
  });
};
const fileLocalToBase64 = (file) => {
  return new Promise((resolve) => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', file, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onload = function (event) {
        var res = event.target.result;
        resolve(res);
      };
      var file = this.response;
      reader.readAsDataURL(file);
    };
    xhr.send();
  });
};
const download = (url, filename) => {
  fetch(url, {
    headers: {
      Authorization: `Token ${localStorage.getItem('token')}`,
      'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  })
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    })
    .catch(console.error);
};

const formatMoney = (num, fixed = 2) => {
  if (typeof num == 'string') {
    num = num.replace(',', '');
  }
  return Number(num)
    .toFixed(fixed)
    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
};

const formatMoneyNotDecimal = (num) => {
  if (typeof num == 'string') {
    num = num.replace(',', '');
  }
  return Number(num)
    .toFixed(0)
    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
};

const handleDownload = (data, filename) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename); //or any other extension
  document.body.appendChild(link);
  link.click();
};
const capitalizeFirstLetter = (string) => {
  if (string == null || string === '') {
    return;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const capitalizeEachWords = (sentence = '') => {
  if (!sentence) {
    return '';
  }
  const words = sentence.split(' ');
  let temp = [];

  for (let i = 0; i < words.length; i++) {
    temp.push(words[i][0].toUpperCase() + words[i].substring(1).toLowerCase());
  }
  return temp.join(' ');
};

const monthName = [
  trans(['January']),
  trans(['February']),
  trans(['March']),
  trans(['April']),
  trans(['May']),
  trans(['June']),
  trans(['July']),
  trans(['August']),
  trans(['September']),
  trans(['October']),
  trans(['November']),
  trans(['December'])
];

const monthNameSimple = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Des'
];

const convertDate = (item, isMonthLong = false) => {
  if (item == null || item === '') {
    return '';
  }
  const newDate = new Date(item);
  const date = newDate.getDate();
  let monthsx = newDate.getMonth();
  monthsx += 1;
  const year = newDate.getFullYear();
  let month = monthNameSimple[monthsx - 1];
  if (isMonthLong) {
    month = monthName[monthsx - 1];
  }
  let h = newDate.getHours().toString();
  let m = newDate.getMinutes().toString();
  let s = newDate.getSeconds().toString();
  if (h.length < 2) {
    h = `0${h}`;
  }
  if (m.length < 2) {
    m = `0${m}`;
  }
  if (s.length < 2) {
    s = `0${s}`;
  }
  const dTime = h + ':' + m;
  return {
    date,
    month,
    year,
    dTime,
    full: `${date} ${month} ${year}`,
    fullTime: `${date} ${month} ${year} ${dTime}`,
    ddmmyyyy: `${String(newDate.getDate()).padStart(2, '0')}-${String(
      monthsx
    ).padStart(2, '0')}-${year}`,
    yyyymmdd: `${year}-${String(monthsx).padStart(2, '0')}-${String(
      newDate.getDate()
    ).padStart(2, '0')}`,
    ddmmyyyyTimeSlash: `${String(newDate.getDate()).padStart(2, '0')}/${String(
      monthsx
    ).padStart(2, '0')}/${year} ${dTime}`
  };
};

const convertDateInput = (date) => {
  let convertDate = new Date(date);
  const d = convertDate.getDate();
  let m = convertDate.getMonth('MM');
  m += 1;
  m = m.toString().length === 1 ? `0${m}` : m;
  const y = convertDate.getFullYear();

  return `${y}-${m}-${d}`;
};

const getActionModule = (uerInfo) => {
  try {
    let obj = {};
    for (let i = 0; i < uerInfo.role.modules.length; i++) {
      const element = uerInfo.role.modules[i];
      if (element.module.sub_modules.length > 0) {
        for (let i1 = 0; i1 < element.module.sub_modules.length; i1++) {
          const element2 = element.module.sub_modules[i1];
          let actions = {};
          if (element2.actions.length == 0) {
            actions = { view: true };
          } else {
            for (let ia = 0; ia < element2.actions.length; ia++) {
              const elementIa = element2.actions[ia];
              actions = { ...actions, [elementIa]: true };
            }
          }
          obj = {
            ...obj,
            [element2.module.url]: {
              ...actions,
              url: element2.module.url,
              name: element2.module.name,
              parentName: element.module.name
            }
          };
        }
      } else {
        let actions = {};
        if (element.actions.length == 0) {
          actions = { view: true };
        } else {
          for (let ia = 0; ia < element.actions.length; ia++) {
            const elementIa = element.actions[ia];
            actions = { ...actions, [elementIa]: true };
          }
        }
        obj = {
          ...obj,
          [element.module.url]: {
            ...actions,
            url: element.module.url,
            name: element.module.name
          }
        };
      }
    }
    return obj;
  } catch (error) {
    return [];
  }
};

const getYear = (startYear = 1960) => {
  var currentYear = new Date().getFullYear(),
    years = [];
  while (startYear <= currentYear) {
    years.push(currentYear--);
  }
  return years;
};

const setIpUser = async () => {
  try {
    // const res = await axios.get('https://geolocation-db.com/json/');
    localStorage.setItem('ip', '0.0.0.0');
  } catch {
    localStorage.setItem('ip', '0.0.0.0');
  }
};

const bodyLog = (type, module_, desc) => {
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'Chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'Firefox';
  } else if (userAgent.match(/safari/i)) {
    browserName = 'Safari';
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'Opera';
  } else if (userAgent.match(/edg/i)) {
    browserName = 'Edge';
  } else {
    browserName = 'No browser detection';
  }
  return {
    log_type: type,
    module: module_,
    log_desc: desc,
    ip_addr: localStorage.getItem('ip'),
    browser: browserName
  };
};
function getRandomColor() {
  let letters = '0123456789ABCDEF'.split('');
  let color = '#';
  let crypto = window.crypto;
  let typedArray = new Uint16Array(10);
  crypto.getRandomValues(typedArray);
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(typedArray[0] * 16)];
  }
  return color;
}
const isMustLessThanTotal = (num, num2) => {
  return (
    Number(`${num}`.replaceAll(',', '')) <=
    Number(`${num2}`.replaceAll(',', ''))
  );
};

const convertMoney = (value, type) => {
  if (type === '1') {
    if (value < 1000) {
      return 'Rp ' + value;
    } else if (value >= 1000 && value < 1000000) {
      return 'Rp ' + value / 1000 + ' ribu';
    } else if (value >= 1000000 && value < 1000000000) {
      return 'Rp ' + value / 1000000 + ' juta';
    } else if (value >= 1000000000 && value < 1000000000000) {
      return 'Rp ' + value / 1000000000 + ' miliar';
    } else if (value >= 1000000000000) {
      return 'Rp ' + value / 1000000000000 + ' triliun';
    }
  } else {
    if (value < 1000) {
      return value;
    } else if (value >= 1000 && value < 1000000) {
      return value / 1000 + 'rb';
    } else if (value >= 1000000 && value < 1000000000) {
      return value / 1000000 + 'jt';
    } else if (value >= 1000000000 && value < 1000000000000) {
      return value / 1000000000 + 'M';
    } else if (value >= 1000000000000) {
      return value / 1000000000000 + 'T';
    }
  }
};

const convertMoneyEN = (value, type) => {
  if (type === '1') {
    if (value < 1000) {
      return 'Rp ' + value;
    } else if (value >= 1000 && value < 1000000) {
      return 'Rp ' + value / 1000 + ' thousand';
    } else if (value >= 1000000 && value < 1000000000) {
      return 'Rp ' + value / 1000000 + ' million';
    } else if (value >= 1000000000 && value < 1000000000000) {
      return 'Rp ' + value / 1000000000 + ' billion';
    } else if (value >= 1000000000000) {
      return 'Rp ' + value / 1000000000000 + ' trillion';
    }
  } else {
    if (value < 1000) {
      return value;
    } else if (value >= 1000 && value < 1000000) {
      return value / 1000 + 'K';
    } else if (value >= 1000000 && value < 1000000000) {
      return value / 1000000 + 'M';
    } else if (value >= 1000000000 && value < 1000000000000) {
      return value / 1000000000 + 'B';
    } else if (value >= 1000000000000) {
      return value / 1000000000000 + 'T';
    }
  }
};

function logMatches(disposition) {
  let filename = '';
  if (disposition && disposition.indexOf('attachment') !== -1) {
    let filenameRegex = constants.regexFilename;
    let matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }

  return [filename];
}

const equalObjects = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

const stringToNumber = (text) =>
  text ? Number(`${text}`?.replaceAll(',', '')) : 0;

const sliceString = (string, length, withDots = false) =>
  `${string ?? ''}`.slice(0, length) +
  (string?.length > length && withDots ? '...' : '');

const getFisrtPath = (dataAuth) => {
  const pathGoto = window.location.hash;
  if (pathGoto.includes('#goto=')) {
    return atob(pathGoto.split('#goto=')[1]);
  }
  const authObj = dataAuth?.role?.modules?.sort(
    (a, b) => a.module.order - b.module.order
  )[0];
  let firstPath = authObj?.module?.url;
  if (firstPath == '#') {
    firstPath = authObj?.module?.sub_modules?.[0]?.module?.url;
  }
  return firstPath;
};

const padL = (nr, chr = `0`) => `${nr}`.padStart(2, chr);

export function getUniqueListBy(arr, key) {
  return [...new Map(arr?.map((item) => [item[key], item])).values()];
}

const convertValueNullInObj = (obj) => {
  const check = JSON.parse(JSON.stringify(obj), (key, value) =>
    value === null || value === '' ? undefined : value
  );
  return check;
};

const convertQueryStringToObj = (search) => {
  return JSON.parse(
    '{"' +
      decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
};

const getImageProperties = (imageSrc, callback) => {
  const img = document.createElement('img');
  img.src = imageSrc;
  img.crossOrigin = 'anonymous';
  img.style.display = 'none';
  document.body.appendChild(img);
  let colorSum = 0;

  img.onload = function () {
    const canvas = document.createElement('canvas');
    let width = img.width;
    let height = img.height;

    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, width, height);

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;
    let r, g, b, avg;

    for (let x = 0, len = data.length; x < len; x += 4) {
      r = data[x];
      g = data[x + 1];
      b = data[x + 2];
      avg = Math.floor((r + g + b) / 3);
      colorSum += avg;
    }

    const brightness = Math.floor(colorSum / (img.width * img.height));
    callback(brightness, canvas.toDataURL('image/jpeg'));
  };
};

const convertDateToTime = (x, range = false, isHours = false) => {
  if (range) {
    const start = new Date(x + ' ' + '00' + ':' + '00' + ':' + '00');
    const end = new Date(x + ' ' + '23' + ':' + '59' + ':' + '59');
    return { start: start.getTime(), end: end.getTime() };
  } else {
    const date = new Date();
    let newDate = new Date(
      x +
        ' ' +
        date.getHours() +
        ':' +
        date.getMinutes() +
        ':' +
        date.getSeconds()
    );
    if (isHours) {
      newDate = new Date(x);
    }
    return newDate.getTime();
  }
};

const getBackDate = (isTime = false, day = 365) => {
  var startdate = moment();
  startdate = startdate.subtract(day, 'days').format('YYYY-MM-DDTHH:mm:ssZZ');
  if (isTime) return startdate.slice(0, 16);
  return startdate.slice(0, 10);
};

const getFrontDate = (isTime = false, day = 365) => {
  var startdate = moment();
  startdate = startdate.add(day, 'days').format('YYYY-MM-DDTHH:mm:ssZZ');
  if (isTime) return startdate.slice(0, 16);
  return startdate.slice(0, 10);
};

const getBackYear = () => {
  var startdate = moment();
  startdate = startdate.add(-1, 'years');
  return startdate.format('YYYY-MM-DDTHH:mm:ssZZ').slice(0, 10);
};

const isNotBackDate = (d, isLastYear = false, num = 0) => {
  if (d) {
    let newDate = new Date(d);
    newDate.setDate(newDate.getDate() + (1 + num));
    const now = new Date();
    if (isLastYear) {
      newDate.setFullYear(newDate.getFullYear() + 1);
    }
    return newDate > now;
  } else {
    return true;
  }
};

const arrayOfObjToObjOfArray = (array = []) => {
  if (!array) {
    return;
  }
  let y = {};
  array.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      y[key] = (y[key] || []).concat([obj[key]]);
    });
  });
  return y;
};

const removeLocalstorage = (isLogout = false) => {
  if (isLogout) {
    const ui = localStorage.getItem('user_id');
    const e = localStorage.getItem('email');
    localStorage.setItem('user_id_logout', ui);
    localStorage.setItem('email_logout', e);
  }
  localStorage.removeItem('token');
  localStorage.removeItem('user_id');
  localStorage.removeItem('email');

  window.location = '/login';
};

const dialCodeSplitter = (phone) => {
  if (phone && phone.includes('+')) {
    const match = constants.regexDialCode.exec(phone);
    if (match !== null) {
      const dial_code = `${match[1]}${match[2]}`;
      return dial_code;
    }
    return null;
  }
  return 0;
};

const findCommodityType = (value) => {
  return constants.COMODITY_TYPE_OPTIONS.find((e) => e.value === value);
};

const findProductNature = (value) => {
  return constants.PRODUCT_NATURE_CHOICES.find((e) => e.value === value);
};

const findPreparation = (value) => {
  return constants.PREPARATION_OPTIONS.find((e) => e.value === value);
};

const findTreatment = (value) => {
  return constants.TREATMENT_OPTIONS.find((e) => e.value === value);
};

const findLocationType = (value) => {
  return constants.LOCATION_TYPE_OPTIONS.find((e) => e.value === value);
};

const findProductType = (value) => {
  return constants.PRODUCT_TYPE_OPTIONS.find((e) => e.value === value);
};

export {
  getImageProperties,
  setToken,
  getToken,
  retry,
  setUserInfo,
  isLatitude,
  isLongitude,
  fileReaderHandler,
  formatMoney,
  handleDownload,
  capitalizeFirstLetter,
  convertDate,
  fileLocalToBase64,
  download,
  monthName,
  monthNameSimple,
  getYear,
  convertDateInput,
  getActionModule,
  setIpUser,
  bodyLog,
  getRandomColor,
  isMustLessThanTotal,
  convertMoney,
  logMatches,
  equalObjects,
  stringToNumber,
  sliceString,
  getFisrtPath,
  padL,
  // convertDateAndTime,
  convertValueNullInObj,
  convertQueryStringToObj,
  convertDateToTime,
  getBackDate,
  isNotBackDate,
  getBackYear,
  convertMoneyEN,
  formatMoneyNotDecimal,
  capitalizeEachWords,
  getFrontDate,
  arrayOfObjToObjOfArray,
  removeLocalstorage,
  dialCodeSplitter,
  findCommodityType,
  findProductNature,
  findPreparation,
  findTreatment,
  findLocationType,
  findProductType
};
