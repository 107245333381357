import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeReceiptInbound = [
  {
    path: '/receipt-inbound',
    parent: '/receipt-inbound',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ReceiptInbound" */ '../Pages/ReceiptInbound'
        )
      )
    )
  },
  {
    path: '/receipt-inbound/add',
    parent: '/receipt-inbound',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ReceiptInboundAdd" */ '../Pages/ReceiptInbound/add'
        )
      )
    )
  },
  {
    path: '/receipt-inbound/:id',
    parent: '/receipt-inbound',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ReceiptInboundDetail" */ '../Pages/ReceiptInbound/detail'
        )
      )
    )
  }
];
