import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'size/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'size/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_SIZE = 'GET_DATA_SIZE';
export const getDataSize = defaultActionCreator(GET_DATA_SIZE, 'param');

export const GET_DATA_SIZE_BY_ID = 'GET_DATA_SIZE_BY_ID';
export const getDataSizeById = defaultActionCreator(GET_DATA_SIZE_BY_ID, 'id');

export const GET_DATA_SIZE_NEXT_OR_PREV = 'GET_DATA_SIZE_NEXT_OR_PREV';
export const getDataSizeNextOrPrev = defaultActionCreator(
  GET_DATA_SIZE_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_SIZE = 'ADD_DATA_SIZE';
export const addDataSize = defaultActionCreator(ADD_DATA_SIZE, 'data');

export const UPDATE_DATA_SIZE = 'UPDATE_DATA_SIZE';
export const updateDataSize = defaultActionCreator(
  UPDATE_DATA_SIZE,
  'data',
  'id'
);

export const DELETE_DATA_SIZE = 'DELETE_DATA_SIZE';
export const deleteDataSize = defaultActionCreator(DELETE_DATA_SIZE, 'id');
