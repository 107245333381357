import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getDocumentType,
  getDocumentTypeById,
  addDocumentType,
  updateDocumentType,
  deleteDocumentType,
  activationDocumentType,
  exportDocumentTypePDF,
  exportDocumentTypeExcel
} from '../../../services/documentType';
import {
  SET_STATE,
  SET_ERROR_FORM,
  GET_DATA_DOCUMENT_TYPE,
  GET_DATA_DOCUMENT_TYPE_BY_ID,
  GET_DATA_DOCUMENT_TYPE_NEXT_OR_PREV,
  ADD_DATA_DOCUMENT_TYPE,
  UPDATE_DATA_DOCUMENT_TYPE,
  DELETE_DATA_DOCUMENT_TYPE,
  ACTIVATION_DATA_DOCUMENT_TYPE,
  DOWNLOAD_DATA_DOCUMENT_TYPE_PDF,
  DOWNLOAD_DATA_DOCUMENT_TYPE_EXCEL
} from './actions';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog, handleDownload, logMatches } from '../../../utils';

export function* GET_DOCUMENT_TYPE(action) {
  yield put({ type: SET_STATE, payload: { data: null, loading: true } });
  try {
    const { data } = yield call(getDocumentType, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_DOCUMENT_TYPE_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { data: null, loading: true } });
  try {
    const { data } = yield call(getDocumentTypeById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_DOCUMENT_TYPE_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_DOCUMENT_TYPE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addDocumentType, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Document Type', 'Create Document Type');
    yield call(createLog, body);

    let message = 'Your Data Has Been Submitted';
    yield put({ type: SET_STATE, payload: { isAdd: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* UPDATE_DOCUMENT_TYPE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateDocumentType, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Document Type', 'Edit Document Type');
    yield call(createLog, body);

    let message = 'Your Data Has Been Submitted';
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DELETE_DOCUMENT_TYPE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteDocumentType, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Document Type', 'Delete Document Type');
    yield call(createLog, body);

    let message = 'Successfully deleting document type';
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ message: error }) {
    yield put({
      type: SET_STATE,
      payload: { loading: false }
    });
    yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* ACTIVATION_DOCUMENT_TYPE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(activationDocumentType, action.id);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DOWNLOAD_DOCUMENT_TYPE_PDF(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(exportDocumentTypePDF, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DOWNLOAD_DOCUMENT_TYPE_EXCEL(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(exportDocumentTypeExcel, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_DOCUMENT_TYPE, GET_DOCUMENT_TYPE),
  takeLatest(GET_DATA_DOCUMENT_TYPE_BY_ID, GET_DOCUMENT_TYPE_BY_ID),
  takeLatest(
    GET_DATA_DOCUMENT_TYPE_NEXT_OR_PREV,
    GET_DOCUMENT_TYPE_NEXT_OR_PREV
  ),
  takeLatest(ADD_DATA_DOCUMENT_TYPE, ADD_DOCUMENT_TYPE),
  takeLatest(UPDATE_DATA_DOCUMENT_TYPE, UPDATE_DOCUMENT_TYPE),
  takeLatest(DELETE_DATA_DOCUMENT_TYPE, DELETE_DOCUMENT_TYPE),
  takeLatest(ACTIVATION_DATA_DOCUMENT_TYPE, ACTIVATION_DOCUMENT_TYPE),
  takeLatest(DOWNLOAD_DATA_DOCUMENT_TYPE_PDF, DOWNLOAD_DOCUMENT_TYPE_PDF),
  takeLatest(DOWNLOAD_DATA_DOCUMENT_TYPE_EXCEL, DOWNLOAD_DOCUMENT_TYPE_EXCEL)
]);
