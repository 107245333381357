import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'Komoditi/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'Komoditi/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_KOMODITI = 'GET_DATA_KOMODITI';
export const getDataKomoditi = defaultActionCreator(GET_DATA_KOMODITI, 'param');

export const GET_DATA_KOMODITI_BY_ID = 'GET_DATA_KOMODITI_BY_ID';
export const getDataKomoditiById = defaultActionCreator(
  GET_DATA_KOMODITI_BY_ID,
  'id'
);

export const GET_DATA_KOMODITI_NEXT_OR_PREV = 'GET_DATA_KOMODITI_NEXT_OR_PREV';
export const getDataKomoditiNextOrPrev = defaultActionCreator(
  GET_DATA_KOMODITI_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_KOMODITI = 'ADD_DATA_KOMODITI';
export const addDataKomoditi = defaultActionCreator(ADD_DATA_KOMODITI, 'data');

export const UPDATE_DATA_KOMODITI = 'UPDATE_DATA_KOMODITI';
export const updateDataKomoditi = defaultActionCreator(
  UPDATE_DATA_KOMODITI,
  'data',
  'id'
);

export const DELETE_DATA_KOMODITI = 'DELETE_DATA_KOMODITI';
export const deleteDataKomoditi = defaultActionCreator(
  DELETE_DATA_KOMODITI,
  'id'
);

export const DOWNLOAD_DATA_KOMODITI = 'DOWNLOAD_DATA_KOMODITI';
export const downloadDataKomoditi = defaultActionCreator(
  DOWNLOAD_DATA_KOMODITI,
  'param'
);

export const DOWNLOAD_DATA_KOMODITI_EXCEL = 'DOWNLOAD_DATA_KOMODITI_EXCEL';
export const downloadDataKomoditiExcel = defaultActionCreator(
  DOWNLOAD_DATA_KOMODITI_EXCEL,
  'param'
);
