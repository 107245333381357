import service from './service';
import endpoint from './constantUrl';

// JenisUsaha
export const addJenisUsaha = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/company/company_type/`, data);
};

export const getJenisUsaha = (param) => {
  const { search, limit, offset } = param;
  return service.get(
    `${endpoint.MASTERDATA_URL}/company/company_type/?search=${search}&limit=${limit}&offset=${offset}`
  );
};

export const getJenisUsahaById = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/company/company_type/${id}/`);
};

export const updateJenisUsaha = (data, id) => {
  return service.put(
    `${endpoint.MASTERDATA_URL}/company/company_type/${id}/`,
    data
  );
};

export const deleteJenisUsaha = (id) => {
  return service.delete(
    `${endpoint.MASTERDATA_URL}/company/company_type/${id}/`
  );
};

export const downloadDataJenisUsaha = (param) => {
  return service.getArrayBuffer(
    `${endpoint.MASTERDATA_URL}/company/company_type/export-excel/`,
    param
  );
};
