import service from './service';
import endpoint from './constantUrl';

// Inventory Type
export const addInventoryType = (data) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/inventory-type/inventory-type/`,
    data
  );
};

export const getInventoryType = (param) => {
  return service.get(
    `${endpoint.MASTERDATA_URL}/inventory-type/inventory-type/`,
    param
  );
};

export const getInventoryTypeById = (id) => {
  return service.get(
    `${endpoint.MASTERDATA_URL}/inventory-type/inventory-type/${id}/`
  );
};

export const updateInventoryType = (data, id) => {
  return service.put(
    `${endpoint.MASTERDATA_URL}/inventory-type/inventory-type/${id}/`,
    data
  );
};

export const deleteInventoryType = (id) => {
  return service.delete(
    `${endpoint.MASTERDATA_URL}/inventory-type/inventory-type/${id}/`
  );
};
