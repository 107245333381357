import { all, put, takeLatest, call } from 'redux-saga/effects';
import { GET_DATA_SUPLIERS, SET_STATE } from './actions';
import { getSupplier } from '../../../services/suppliers';

export function* GET_SUPPLIERS(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getSupplier, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([takeLatest(GET_DATA_SUPLIERS, GET_SUPPLIERS)]);
