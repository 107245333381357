import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'stockReservation/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'stockReservation/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_STOCK_RESERVATION = 'GET_DATA_STOCK_RESERVATION';
export const getDataStockReservation = defaultActionCreator(
  GET_DATA_STOCK_RESERVATION,
  'param'
);

export const GET_DATA_STOCK_RESERVATION_BY_ID =
  'GET_DATA_STOCK_RESERVATION_BY_ID';
export const getDataStockReservationById = defaultActionCreator(
  GET_DATA_STOCK_RESERVATION_BY_ID,
  'id'
);

export const GET_DATA_STOCK_RESERVATION_NEXT_OR_PREV =
  'GET_DATA_STOCK_RESERVATION_NEXT_OR_PREV';
export const getDataStockReservationNextOrPrev = defaultActionCreator(
  GET_DATA_STOCK_RESERVATION_NEXT_OR_PREV,
  'link'
);

export const DOWNLOAD_DATA_STOCK_RESERVATION_PDF =
  'DOWNLOAD_DATA_STOCK_RESERVATION_PDF';
export const downloadDataStockReservationPDF = defaultActionCreator(
  DOWNLOAD_DATA_STOCK_RESERVATION_PDF,
  'param'
);

export const DOWNLOAD_DATA_STOCK_RESERVATION_EXCEL =
  'DOWNLOAD_DATA_STOCK_RESERVATION_EXCEL';
export const downloadDataStockReservationExcel = defaultActionCreator(
  DOWNLOAD_DATA_STOCK_RESERVATION_EXCEL,
  'param'
);
