import service from './service';
import endpoint from './constantUrl';

export const getTransfer = (param) => {
  return service.post(
    `${endpoint.POS_URL}/stock-transfer/transfer-out/filter/`,
    param
  );
};

export const addTransfer = (data) => {
  return service.post(`${endpoint.POS_URL}/stock-transfer/transfer-out/`, data);
};

export const getTransferById = (id) => {
  return service.get(`${endpoint.POS_URL}/stock-transfer/transfer-out/${id}/`);
};

export const deleteTransfer = (id) => {
  return service.delete(
    `${endpoint.POS_URL}/stock-transfer/transfer-out/${id}/`
  );
};

export const updateTransfer = (data, id) => {
  return service.put(
    `${endpoint.POS_URL}/stock-transfer/transfer-out/${id}/`,
    data
  );
};

export const approvalTransfer = (id, data) => {
  return service.put(
    `${endpoint.POS_URL}/stock-transfer/transfer-out/${id}/approval/`,
    data
  );
};
export const deliveryTransfer = (id, data) => {
  return service.post(
    `${endpoint.POS_URL}/stock-transfer/transfer-out/${id}/delivery/`,
    data
  );
};

export const downloadExcelTransfer = (data) => {
  return service.postArrayBuffer(
    `${endpoint.POS_URL}/stock-transfer/transfer-out/export-excel/`,
    data
  );
};

export const getTransferReceived = (param) => {
  return service.post(
    `${endpoint.POS_URL}/stock-transfer/received/filter/`,
    param
  );
};

export const updateTransferNotReceived = (id, data) => {
  return service.put(
    `${endpoint.POS_URL}/stock-transfer/received/${id}/not-receive/`,
    data
  );
};

export const updateTransferReceived = (id, data) => {
  return service.put(
    `${endpoint.POS_URL}/stock-transfer/received/${id}/receipt-confirmation/`,
    data
  );
};

export const getTotalOfData = () => {
  return service.get(`${endpoint.POS_URL}/stock-transfer/received/count/`);
};

export const downloadExcelTransferReceived = (data) => {
  return service.postArrayBuffer(
    `${endpoint.POS_URL}/stock-transfer/received/export-excel/`,
    data
  );
};
