import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeTransaksi = [
  {
    path: '/transaksi-penjualan',
    parent: '/transaksi-penjualan',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupPenjualananTransaksi" */ '../Pages/Penjualanan/Transaksi'
        )
      )
    )
  },
  {
    path: '/transaksi-penjualan/update/:id',
    parent: '/transaksi-penjualan',
    action: 'update',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupPenjualananTransaksi" */ '../Pages/Penjualanan/Transaksi/update'
        )
      )
    )
  },
  {
    path: '/transaksi-penjualan/detail/:id',
    parent: '/transaksi-penjualan',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupPenjualananTransaksi" */ '../Pages/Penjualanan/Transaksi/detail'
        )
      )
    )
  },
  {
    path: '/transaksi-penjualan/add',
    parent: '/transaksi-penjualan',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupPenjualananTransaksiAdd" */ '../Pages/Penjualanan/Transaksi/add'
        )
      )
    )
  },
  {
    path: '/transaksi-pembelian',
    parent: '/transaksi-pembelian',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupTransaksiPembelian" */ '../Pages/Pembelian/Transaksi/transaksiPembelian'
        )
      )
    )
  },
  {
    path: '/transaksi-pembelian/update/:id',
    parent: '/transaksi-pembelian',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupTransaksiPembelian" */ '../Pages/Pembelian/Transaksi/transaksiPembelianUpdate'
        )
      )
    )
  },
  {
    path: '/transaksi-pembelian/:id',
    parent: '/transaksi-pembelian',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupPenjualananReturAdd" */ '../Pages/Pembelian/Transaksi/transaksiPembelianDetail'
        )
      )
    )
  },
  {
    path: '/transaksi-pembelian/add',
    parent: '/transaksi-pembelian',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "TransaksiPembelianAdd" */ '../Pages/Pembelian/Transaksi/transaksiPembelianAdd'
        )
      )
    )
  }
  // {
  //   path: '/financing',
  //   parent: '/financing',
  //   action: 'view',
  //   isParent: true,
  //   component: lazy(() =>
  //     retry(() =>
  //       import(/* webpackChunkName: "financing" */ '../Pages/Financing')
  //     )
  //   )
  // }
];
