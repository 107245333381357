import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'fishingArea/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'fishingArea/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_FISHING_AREA = 'GET_DATA_FISHING_AREA';
export const getDataFishingArea = defaultActionCreator(
  GET_DATA_FISHING_AREA,
  'param'
);

export const GET_DATA_FISHING_AREA_BY_ID = 'GET_DATA_FISHING_AREA_BY_ID';
export const getDataFishingAreaById = defaultActionCreator(
  GET_DATA_FISHING_AREA_BY_ID,
  'id'
);

export const ADD_DATA_FISHING_AREA = 'ADD_DATA_FISHING_AREA';
export const addDataFishingArea = defaultActionCreator(
  ADD_DATA_FISHING_AREA,
  'data'
);

export const UPDATE_DATA_FISHING_AREA = 'UPDATE_DATA_FISHING_AREA';
export const updateDataFishingArea = defaultActionCreator(
  UPDATE_DATA_FISHING_AREA,
  'id',
  'data'
);

export const DELETE_DATA_FISHING_AREA = 'DELETE_DATA_FISHING_AREA';
export const deleteDataFishingArea = defaultActionCreator(
  DELETE_DATA_FISHING_AREA,
  'id'
);

export const DOWNLOAD_DATA_FISHING_AREA = 'DOWNLOAD_DATA_FISHING_AREA';
export const downloadDataFishingArea = defaultActionCreator(
  DOWNLOAD_DATA_FISHING_AREA,
  'param'
);

export const ACTIVATION_DATA_FISHING_AREA = 'ACTIVATION_DATA_FISHING_AREA';
export const activationDataFishingArea = defaultActionCreator(
  ACTIVATION_DATA_FISHING_AREA,
  'id62'
);
