import { all, put, takeLatest, call } from 'redux-saga/effects';
import { GET_DATA_CUSTOMER, SET_STATE } from './actions';
import { getDataCustomer } from '../../../services/customer';

export function* GET_CUSTOMER(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataCustomer, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([takeLatest(GET_DATA_CUSTOMER, GET_CUSTOMER)]);
