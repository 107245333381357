import { SET_STATE } from './actions';

const initialState = {
  loading: false,
  error: null,
  filterCountry: {
    limit: 50,
    offset: 0,
    search: ''
  },
  filterProvince: {
    country_id: null,
    limit: 50,
    offset: 0,
    search: ''
  },
  filterRegency: {
    province_id: null,
    limit: 50,
    offset: 0,
    search: ''
  },
  filterDistrict: {
    regency_id: null,
    limit: 50,
    offset: 0,
    search: ''
  }
};

export default function assessmentReducer(state = initialState, action) {
  switch (action.type) {
    case SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
