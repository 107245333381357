import { all, put, takeLatest, call } from 'redux-saga/effects';
import { login, userInfo, userInfoDefaultToken } from '../../../services/auth';
import { setIpUser, setToken, bodyLog, getFisrtPath } from '../../../utils';
import { LOGIN, REFRESH_USER_INFO, SET_STATE } from './actions';
import { createlogWithToken } from '../../../services/Activity';
import * as managers from '../../../utils/gtm';
const isProduction = process.env.NODE_ENV === 'production';

export function* AUTH_LOGIN(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });

  try {
    const { data } = yield call(login, action.data);
    const dataInfo = yield call(userInfo, data.data.token);
    yield setToken(data.data.token);
    yield setIpUser();
    const body = bodyLog('login', 'string', 'Login');
    yield call(createlogWithToken, data.data.token, body);
    yield put({ type: SET_STATE, payload: { userInfo: dataInfo.data.data } });
    const m = getFisrtPath(dataInfo.data.data);
    localStorage.setItem('alert-login', true);
    localStorage.setItem('user_id', dataInfo.data.data?.id62);
    localStorage.setItem('email', action.data.email);
    if (isProduction) {
      managers.init({ event: 'login', email: action.data.email });
    }

    yield (window.location.href = m);
  } catch ({ response }) {
    yield put({ type: SET_STATE, payload: { error: response?.data } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* REFRESH_USER_INFO_F() {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const dataInfo = yield call(userInfoDefaultToken);
    yield put({
      type: SET_STATE,
      payload: { isRefresh: true, userInfo: dataInfo.data.data }
    });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

// export default function* profileSaga() {
//   yield all([takeEvery(actions.GET_PROFILE, GET_PROFILE)]);
// }

export default all([
  takeLatest(REFRESH_USER_INFO, REFRESH_USER_INFO_F),
  takeLatest(LOGIN, AUTH_LOGIN)
]);
