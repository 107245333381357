import service from './service';
import endpoint from './constantUrl';

export const getOutbound = (param) => {
  return service.post(`${endpoint.POS_URL}/outbound/outbound/filter/`, param);
};

export const addOutbound = (data) => {
  return service.post(`${endpoint.POS_URL}/outbound/outbound/`, data);
};

export const getOutboundById = (id) => {
  return service.get(`${endpoint.POS_URL}/outbound/outbound/${id}/`);
};

export const deleteOutbound = (id) => {
  return service.delete(`${endpoint.POS_URL}/outbound/outbound/${id}/`);
};

export const updateOutbound = (data, id) => {
  return service.put(`${endpoint.POS_URL}/outbound/outbound/${id}/`, data);
};

export const approvalOutbound = (id, data) => {
  return service.put(
    `${endpoint.POS_URL}/outbound/outbound/${id}/approval/`,
    data
  );
};
export const deliveryOutbound = (id, data) => {
  return service.post(
    `${endpoint.POS_URL}/outbound/outbound/${id}/delivery/`,
    data
  );
};

export const downloadExcelOutbound = (data) => {
  return service.postArrayBuffer(
    `${endpoint.POS_URL}/outbound/outbound/export-excel/`,
    data
  );
};

export const getTrackingDeliverySlip = (param) => {
  return service.postWithoutHeader(
    `${endpoint.POS_URL}/outbound/outbound/tracking/`,
    param
  );
};

export const exportOutboundExcel = (param) => {
  return service.postDownloadService(
    `${endpoint.DOWNLOAD}/outbound/excel`,
    param
  );
};
