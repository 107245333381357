import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getDataSubModule,
  getDataSubModuleById,
  addDataSubModule,
  updateDataSubModule,
  deleteDataSubModule
} from '../../../services/subModule';
import {
  SET_STATE,
  GET_DATA_SUBMODULE_BY_ID,
  GET_DATA_SUBMODULE_NEXT_OR_PREV,
  GET_DATA_SUBMODULE,
  ADD_DATA_SUBMODULE,
  UPDATE_DATA_SUB_MODULE,
  DELETE_DATA_SUBMODULE,
  SET_ERROR_FORM
} from './action';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_SUBMODULE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataSubModule, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_SUBMODULE_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataSubModuleById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_SUBMODULE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addDataSubModule, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'string', 'Create Sub Module');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* UPDATE_SUBMODULE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateDataSubModule, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'string', 'Edit Sub Module');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DELETE_SUBMODULE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteDataSubModule, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'string', 'Delete Sub Module');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_SUBMODULE_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_SUBMODULE, GET_SUBMODULE),
  takeLatest(GET_DATA_SUBMODULE_BY_ID, GET_SUBMODULE_BY_ID),
  takeLatest(ADD_DATA_SUBMODULE, ADD_SUBMODULE),
  takeLatest(GET_DATA_SUBMODULE_NEXT_OR_PREV, GET_SUBMODULE_NEXT_OR_PREV),
  takeLatest(UPDATE_DATA_SUB_MODULE, UPDATE_SUBMODULE),
  takeLatest(DELETE_DATA_SUBMODULE, DELETE_SUBMODULE)
]);
