import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'tagihanPembelian/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'tagihanPembelian/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_TAGIHAN_PEMBELIAN = 'GET_DATA_TAGIHAN_PEMBELIAN';
export const getDataTagihanPembelian = defaultActionCreator(
  GET_DATA_TAGIHAN_PEMBELIAN,
  'param'
);

export const GET_DATA_UNIT_NEXT_OR_PREV = 'GET_DATA_UNIT_NEXT_OR_PREV';
export const getDataUnitNextOrPrev = defaultActionCreator(
  GET_DATA_UNIT_NEXT_OR_PREV,
  'link'
);

export const GET_DATA_TAGIHAN_PEMBELIAN_BY_ID =
  'GET_DATA_TAGIHAN_PEMBELIAN_BY_ID';
export const getDataTagihanPembelianById = defaultActionCreator(
  GET_DATA_TAGIHAN_PEMBELIAN_BY_ID,
  'id'
);

export const ADD_DATA_TAGIHAN_PEMBELIAN = 'ADD_DATA_TAGIHAN_PEMBELIAN';
export const addDataTagihanPembelian = defaultActionCreator(
  ADD_DATA_TAGIHAN_PEMBELIAN,
  'data'
);
export const UPDATE_DATA_TAGIHAN_PEMBELIAN = 'UPDATE_DATA_TAGIHAN_PEMBELIAN';
export const updateDataTagihanPembelian = defaultActionCreator(
  UPDATE_DATA_TAGIHAN_PEMBELIAN,
  'data',
  'id'
);

export const DELETE_DATA_TAGIHAN_PEMBELIAN = 'DELETE_DATA_TAGIHAN_PEMBELIAN';
export const deleteDataTagihanPembelian = defaultActionCreator(
  DELETE_DATA_TAGIHAN_PEMBELIAN,
  'id'
);

export const DOWNLOAD_TAGIHAN_PEMBELIAN = 'DOWNLOAD_TAGIHAN_PEMBELIAN';
export const downloadTagihanPembelian = defaultActionCreator(
  DOWNLOAD_TAGIHAN_PEMBELIAN,
  'param'
);
