import { all, put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import {
  GET_DATA_USER_MASTER_DATA,
  GET_DATA_USER_MASTER_DATA_BY_ID,
  ADD_DATA_USER_MASTER_DATA,
  UPDATE_DATA_USER_MASTER_DATA,
  DELETE_DATA_USER_MASTER_DATA,
  SET_STATE,
  SET_ERROR_FORM,
  GET_DATA_USER_MASTER_DATA_BY_USERTYPE,
  GET_DATA_USER_MASTER_DATA_BY_SUPPLIER,
  ACTIVATION_DATA_USER,
  DOWNLOAD_DATA_USER_MASTER_DATA,
  DOWNLOAD_DATA_TEMPLATE_USER_MASTER_DATA,
  UPLOAD_DATA_USER_MASTER_DATA,
  GET_USER_BY_ID62_ID_GUDANG,
  GET_DATA_USER_CUSTOMER_MASTER_DATA,
  GET_DATA_USER_SUPPLIER_MASTER_DATA
} from './action';
import { handleDownload, logMatches } from '../../../utils';
import {
  getDataUserMasterData,
  getDataUserMasterDataById,
  addDataUserMasterData,
  deleteDataUserMasterData,
  updateDataUserMasterData,
  downloadDataUserMasterData,
  downloadTemplateUserMasterData,
  uploadUserMasterData,
  activationUser,
  getUserById62IdGudang,
  getUserCustomer,
  getUserSupplier
} from '../../../services/userMasterData';

import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_USER(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getDataUserMasterData, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_USER_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getDataUserMasterDataById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_USER(action) {
  yield put({ type: SET_STATE, payload: { loadingAdd: true } });
  try {
    yield call(addDataUserMasterData, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Partner', 'Create Partner');
    yield call(createLog, body);
    let message = 'Your Data Has Been Submitted';
    yield put({ type: SET_STATE, payload: { isAdd: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error });
    yield put({ type: SET_STATE, payload: { loadingAdd: false } });
  }
}

export function* UPDATE_USER(action) {
  yield put({ type: SET_STATE, payload: { loadingUpdate: true } });
  try {
    yield call(updateDataUserMasterData, action.data, action.id);
    yield setIpUser();
    let message = 'Your Data Has Been Submitted';
    const body = bodyLog('edit', 'Partner', 'Edit Partner');
    yield call(createLog, body);

    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error });
    yield put({ type: SET_STATE, payload: { loadingUpdate: false } });
  }
}

export function* DELETE_USER(action) {
  yield put({ type: SET_STATE, payload: { loadingDelete: true } });
  try {
    yield call(deleteDataUserMasterData, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Partner', 'Delete Partner');
    yield call(createLog, body);
    let message = 'Successfully deleting the Data';
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
    yield put({ type: SET_STATE, payload: { loadingDelete: false } });
  }
}

export function* GET_USER_BY_USER_TYPE(action) {
  yield put({
    type: SET_STATE,
    payload: { [`loading_${action.userType}`]: true, [action.userType]: null }
  });
  try {
    const { data } = yield call(getDataUserMasterData, action.param);
    yield put({ type: SET_STATE, payload: { [action.userType]: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { [`loading_${action.userType}`]: false }
    });
  }
}

export function* GET_USER_BY_SUPPLIER(action) {
  yield put({
    type: SET_STATE,
    payload: { loading_supplier: true, suppliers: null }
  });
  try {
    const { data } = yield call(getDataUserMasterData, {
      ...action.param,
      user_type: 'supplier'
    });
    yield put({ type: SET_STATE, payload: { suppliers: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading_supplier: false } });
  }
}

export function* ACTIVATION_USER(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(activationUser, action.id62);
    let message = 'Success change activation status';
    let modelToast = 'success';
    yield put({
      type: SET_STATE,
      payload: { isUpdateStatus: true, message, modelToast }
    });
  } catch ({ response }) {
    let message = 'Fail change activation status';
    let modelToast = 'fail';
    yield put({
      type: SET_STATE,
      payload: { isUpdateStatus: true, message, modelToast }
    });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DOWNLOAD_TEMPLATE_USER_MASTER_DATA() {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(downloadTemplateUserMasterData);
    yield handleDownload(data, 'user_template.csv');
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DOWNLOAD_USER_MASTER_DATA(action) {
  yield put({ type: SET_STATE, payload: { loadingDownload: true } });
  try {
    const { data, headers } = yield call(
      downloadDataUserMasterData,
      action.param
    );
    const filename = yield logMatches(headers['content-disposition']);
    yield handleDownload(data, filename[0]);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDownload: false } });
  }
}

export function* UPLOAD_USER_MASTER_DATA(action) {
  yield put({ type: SET_STATE, payload: { loadingUpload: true } });
  try {
    yield call(uploadUserMasterData, action.data);
    yield put({
      type: SET_STATE,
      payload: { isUpload: true, isUpdate: true }
    });
  } catch ({ response }) {
    yield put({
      type: SET_STATE,
      payload: { errorUpload: response?.data, isUpload: false }
    });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loadingUpload: false }
    });
  }
}

export function* GET_DATA_USER_BY_ID_USER_ID_GUDANG(action) {
  yield put({
    type: SET_STATE,
    payload: { loading: true, errorDetail: null, detailData: null }
  });
  try {
    const { data } = yield call(
      getUserById62IdGudang,
      action.id,
      action.warehouse_id62
    );
    yield put({ type: SET_STATE, payload: { detailData: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { errorDetail: error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_USER_CUSTOMER(action) {
  yield put({ type: SET_STATE, payload: { loading: true, customer: null } });
  try {
    const { data } = yield call(getUserCustomer, action.param);
    yield put({ type: SET_STATE, payload: { customer: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { errorCustomer: error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading_customer: false } });
  }
}

export function* GET_USER_SUPPLIER(action) {
  yield put({ type: SET_STATE, payload: { loading: true, supplier: null } });
  try {
    const { data } = yield call(getUserSupplier, action.param);
    yield put({ type: SET_STATE, payload: { supplier: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { errorSupplier: error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading_supplier: false } });
  }
}

export default all([
  takeLatest(GET_DATA_USER_MASTER_DATA, GET_USER),
  takeLatest(GET_DATA_USER_MASTER_DATA_BY_ID, GET_USER_BY_ID),
  takeLatest(ADD_DATA_USER_MASTER_DATA, ADD_USER),
  takeLatest(UPDATE_DATA_USER_MASTER_DATA, UPDATE_USER),
  takeLatest(DELETE_DATA_USER_MASTER_DATA, DELETE_USER),
  takeEvery(GET_DATA_USER_MASTER_DATA_BY_USERTYPE, GET_USER_BY_USER_TYPE),
  takeLatest(GET_DATA_USER_MASTER_DATA_BY_SUPPLIER, GET_USER_BY_SUPPLIER),
  takeLatest(ACTIVATION_DATA_USER, ACTIVATION_USER),
  takeLatest(
    DOWNLOAD_DATA_TEMPLATE_USER_MASTER_DATA,
    DOWNLOAD_TEMPLATE_USER_MASTER_DATA
  ),
  takeLatest(DOWNLOAD_DATA_USER_MASTER_DATA, DOWNLOAD_USER_MASTER_DATA),
  takeLatest(UPLOAD_DATA_USER_MASTER_DATA, UPLOAD_USER_MASTER_DATA),
  takeLatest(GET_USER_BY_ID62_ID_GUDANG, GET_DATA_USER_BY_ID_USER_ID_GUDANG),
  takeLatest(GET_DATA_USER_CUSTOMER_MASTER_DATA, GET_USER_CUSTOMER),
  takeLatest(GET_DATA_USER_SUPPLIER_MASTER_DATA, GET_USER_SUPPLIER)
]);
