import { lazy } from 'react';
import { retry } from '../utils/index';
import { routeCompany } from './routeCompany';
import { routeGudang } from './routeGudang';
import { routeKategori } from './routeKategori';
import { routeKomiditas } from './routeKomoditas';
import { routeNetwork } from './routeNetwork';
import { routePersedian } from './routePersediaan';
import { routeProduk } from './routeProduk';
import { routeRetur } from './routeRetur';
import { routeTagihan } from './routeTagihan';
import { routeTransaksi } from './routeTransaksi';
import { routeUnit } from './routeUnit';
import { routeUser } from './routeUser';
import { routeWhStock } from './routeWhStock';
import { routeLogActivity } from './routeLogActivity';
import { routePackagging } from './routePackagging';
import { routeInboundManagement } from './routeInboundManagement';
import { routeDashboardWarehouse } from './routeDashboardWarehouse';
import { routeInvetoryType } from './routeInventoryType';
import { routeOutboundManagement } from './routeOutboundManagement';
import { routeDashboardCommercial } from './routeDashboardCommercial';
import {
  routeGoodsReceived,
  routeGoodsTransfer
} from './routeTransferManagement';
import { routeFishfinv2 } from './routeFishfin';
import { routeProspectWarehouses } from './routeProspectWarehouses';
import { routeGroup } from './routeGroup';
import { routeFishingArea } from './routeFishingArea';
import { routeStockReservation } from './routeStockReservation';
import { routeUserRoleManagement } from './routeUserRole';
import { routeReceiptInbound } from './routeReceiptInbound';
import { routeDocumentType } from './routeDocumentType';
import { routeHsCode } from './routeHsCode';
import { routeCertification } from './routeCertification';
import { routeTag } from './routeTag';
import { routeStorageCategory } from './routeStorageCategory';

export const routeReguler = [
  ...routeCompany,
  ...routeGudang,
  ...routeKategori,
  ...routeKomiditas,
  ...routeNetwork,
  ...routePersedian,
  ...routeProduk,
  ...routeRetur,
  ...routeTagihan,
  ...routeTransaksi,
  ...routeUnit,
  ...routeUser,
  ...routeWhStock,
  ...routeLogActivity,
  ...routePackagging,
  ...routeInboundManagement,
  ...routeDashboardWarehouse,
  ...routeInvetoryType,
  ...routeOutboundManagement,
  ...routeDashboardCommercial,
  ...routeGoodsReceived,
  ...routeGoodsTransfer,
  ...routeFishfinv2,
  ...routeProspectWarehouses,
  ...routeGroup,
  ...routeFishingArea,
  ...routeStockReservation,
  ...routeUserRoleManagement,
  ...routeReceiptInbound,
  ...routeDocumentType,
  ...routeHsCode,
  ...routeCertification,
  ...routeTag,
  ...routeStorageCategory
];

export const routeWithoutAuth = [
  {
    path: '/login',
    component: lazy(() =>
      retry(() => import(/* webpackChunkName: "Login" */ './Login'))
    )
  }
];
export const routePublic = [
  {
    path: '/qr-code',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ScanQRCode" */ '../Pages/Public/ScanQRCode'
        )
      )
    )
  },
  {
    path: '/delivery-slip',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "DeliverySlip" */ '../Pages/Public/DeliverySlipTracking'
        )
      )
    )
  },
  {
    path: '/error',
    component: lazy(() =>
      retry(() =>
        import(/* webpackChunkName: "ErrorPage" */ '../Pages/Public/ErrorPage')
      )
    )
  }
];
