import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeUserRoleManagement = [
  {
    path: '/user-role',
    parent: '/user-role',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "AddUser" */ '../Pages/UserRoleManage/User/User'
        )
      )
    )
  },
  {
    path: '/user-role/add',
    parent: '/user-role',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "AddUser" */ '../Pages/UserRoleManage/User/AddUser'
        )
      )
    )
  },
  {
    path: '/user-role/:id',
    parent: '/user-role',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "UserView" */ '../Pages/UserRoleManage/User/UserView'
        )
      )
    )
  },
  {
    path: '/user-role/update/:id',
    parent: '/user-role',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "UpdateUser" */ '../Pages/UserRoleManage/User/updateUser'
        )
      )
    )
  },
  {
    path: '/module',
    parent: '/module',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "Module" */ '../Pages/UserRoleManage/Module/Module'
        )
      )
    )
  },
  {
    path: '/module/add',
    parent: '/module',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "AddModule" */ '../Pages/UserRoleManage/Module/AddModule'
        )
      )
    )
  },
  {
    path: '/module/:id',
    parent: '/module',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ViewModule" */ '../Pages/UserRoleManage/Module/DetailModule'
        )
      )
    )
  },
  {
    path: '/module/update/:id',
    parent: '/module',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "UpdateModule" */ '../Pages/UserRoleManage/Module/UpdateModule'
        )
      )
    )
  },
  {
    path: '/submodule',
    parent: '/submodule',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "SubModule" */ '../Pages/UserRoleManage/SubModule/SubModule'
        )
      )
    )
  },
  {
    path: '/submodule/:id',
    parent: '/submodule',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ViewSubModule" */ '../Pages/UserRoleManage/SubModule/subModuleDetail'
        )
      )
    )
  },
  {
    path: 'submodule/add',
    parent: '/submodule',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "AddSubModule" */ '../Pages/UserRoleManage/SubModule/AddSubModule'
        )
      )
    )
  },
  {
    path: 'submodule/update/:id',
    parent: '/submodule',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "UpdateSubModule" */ '../Pages/UserRoleManage/SubModule/UpdateSubModule'
        )
      )
    )
  },
  {
    path: '/module-group',
    parent: '/module-group',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroup" */ '../Pages/UserRoleManage/ModuleGroup/ModuleGroup'
        )
      )
    )
  },
  {
    path: '/module-group/:id',
    parent: '/module-group',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupView" */ '../Pages/UserRoleManage/ModuleGroup/ModuleGroupView'
        )
      )
    )
  },
  {
    path: '/module-group/add',
    parent: '/module-group',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupAdd" */ '../Pages/UserRoleManage/ModuleGroup/ModuleGroupAdd'
        )
      )
    )
  },
  {
    path: '/module-group/update/:id',
    parent: '/module-group',
    action: 'upadte',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ModuleGroupUpdate" */ '../Pages/UserRoleManage/ModuleGroup/ModuleGroupUpdate'
        )
      )
    )
  }
];
