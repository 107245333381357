import service from './service';
import endpoint from './constantUrl';

export const addFishingArea = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/fishing-area/`, data);
};
export const getFishingArea = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/fishing-area/`, param);
};
export const getFishingAreaById = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/fishing-area/${id}/`);
};

export const updateFishingArea = (data, id) => {
  return service.put(`${endpoint.MASTERDATA_URL}/fishing-area/${id}/`, data);
};

export const deleteFishingArea = (id) => {
  return service.delete(`${endpoint.MASTERDATA_URL}/fishing-area/${id}/`);
};

export const downloadDataFishingArea = (param) => {
  return service.postDownloadService(
    `${endpoint.DOWNLOAD}/fishing-area/excel`,
    param
  );
};

export const activationFishingArea = (id62) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/fishing-area/${id62}/activation/`
  );
};
