import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getWarehouseType,
  getWarehouseTypeById,
  addWarehouseType,
  updateWarehouseType,
  deleteWarehouseType
} from '../../../services/warehouseType';
import {
  SET_STATE,
  GET_DATA_WAREHOUSE_TYPE,
  GET_DATA_WAREHOUSE_TYPE_BY_ID,
  ADD_DATA_WAREHOUSE_TYPE,
  UPDATE_DATA_WAREHOUSE_TYPE,
  DELETE_DATA_WAREHOUSE_TYPE,
  SET_ERROR_FORM,
  GET_DATA_WAREHOUSE_TYPE_NEXT_OR_PREV
} from './actions';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_WAREHOUSE_TYPE(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getWarehouseType, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_WAREHOUSE_TYPE_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getWarehouseTypeById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_WAREHOUSE_TYPE_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_WAREHOUSE_TYPE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addWarehouseType, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Data Master', 'Create Inventory Type');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* UPDATE_WAREHOUSE_TYPE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateWarehouseType, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Data Master', 'Edit Inventory Type');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DELETE_WAREHOUSE_TYPE(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteWarehouseType, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Data Master', 'Delete Inventory Type');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_WAREHOUSE_TYPE, GET_WAREHOUSE_TYPE),
  takeLatest(GET_DATA_WAREHOUSE_TYPE_BY_ID, GET_WAREHOUSE_TYPE_BY_ID),
  takeLatest(
    GET_DATA_WAREHOUSE_TYPE_NEXT_OR_PREV,
    GET_WAREHOUSE_TYPE_NEXT_OR_PREV
  ),
  takeLatest(ADD_DATA_WAREHOUSE_TYPE, ADD_DATA_WAREHOUSE_TYPE),
  takeLatest(UPDATE_DATA_WAREHOUSE_TYPE, UPDATE_WAREHOUSE_TYPE),
  takeLatest(DELETE_DATA_WAREHOUSE_TYPE, DELETE_WAREHOUSE_TYPE)
]);
