import axios from 'axios';
import { removeLocalstorage } from '../utils';

class Service {
  constructor() {
    let service = axios.create();
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
    this.token = localStorage.getItem('token');
  }

  handleSuccess(response) {
    return response;
  }

  handleError = (error) => {
    console.log('-ERROR-', error);

    if (
      error.response &&
      (error.response.status === 502 || error.response.status === 504)
    ) {
      window.location.replace('/error');
    }

    // handle network error
    if (
      error?.message === 'Network request failed' ||
      error?.message === 'Network Error'
    ) {
      alert(
        'Network Error!',
        'Silakan periksa koneksi Anda dan coba kembali.',
        'iconNoInet'
      );
      window.location.replace('/error');
    }

    //handle Common Error
    // if (error && error?.data?.msg === 'invalid token') {
    //   alert(
    //     'Session kamu telah habis',
    //     'Silakan login kembali dengan akun kamu yg telah terdaftar'
    //   );
    //   localStorage.removeItem('token');
    //   window.location = '/login';
    // }

    // handle error access
    if (error.response && error.response.status === 401) {
      removeLocalstorage(true);
    }
    return Promise.reject(error);
  };

  getWToken(token, path, params, callback) {
    return this.service
      .get(path, {
        params,
        headers: {
          Authorization: `Token ${token}`
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  get(path, params, callback) {
    return this.service
      .get(path, {
        params,
        headers: {
          Authorization: `Token ${this.token}`
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  getPublic(path, isAuth, callback) {
    const auth = () =>
      isAuth
        ? {
            headers: {
              Authorization: `Token ${this.token}`
            }
          }
        : {};
    return this.service
      .get(path, {
        ...auth()
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  getArrayBuffer(path, params, callback) {
    return this.service
      .get(path, {
        params,
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Token ${this.token}`
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  getBlob(path, params, callback) {
    return this.service
      .get(path, {
        params,
        responseType: 'blob',
        headers: {
          Authorization: `Token ${this.token}`
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  postBlob(path, params, callback) {
    return this.service
      .post(path, {
        params,
        responseType: 'blob',
        headers: {
          Authorization: `Token ${this.token}`
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  patch(path, payload, callback) {
    return this.service
      .request({
        method: 'PATCH',
        url: path,
        responseType: 'json',
        data: payload,
        headers: {
          Authorization: `Token ${this.token}`
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  put(put, payload, callback) {
    return this.service
      .request({
        method: 'PUT',
        url: put,
        responseType: 'json',
        data: payload,
        headers: {
          Authorization: `Token ${this.token}`
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  post(path, payload, callback) {
    return this.service
      .request({
        method: 'POST',
        url: path,
        responseType: 'json',
        data: payload,
        headers: {
          Authorization: `Token ${this.token}`
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  postMultipart(path, payload, callback) {
    return this.service
      .request({
        method: 'POST',
        url: path,
        data: payload,
        headers: {
          'x-private': 'false',
          Authorization: `Token ${this.token}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  postArrayBuffer(path, payload, callback) {
    return this.service
      .request({
        method: 'POST',
        url: path,
        responseType: 'arraybuffer',
        data: payload,
        headers: {
          Authorization: `Token ${this.token}`
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  postWithoutHeader(path, payload, callback) {
    return this.service
      .request({
        method: 'POST',
        url: path,
        responseType: 'json',
        data: payload
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  delete(path, payload, callback) {
    return this.service
      .request({
        method: 'DELETE',
        url: path,
        responseType: 'json',
        data: payload,
        headers: {
          Authorization: `Token ${this.token}`
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  postWithToken(token, path, payload, callback) {
    return this.service
      .request({
        method: 'POST',
        url: path,
        responseType: 'json',
        data: payload,
        headers: {
          Authorization: `Token ${token}`
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  postPublic(path, isAuth, callback) {
    const auth = () =>
      isAuth
        ? {
            headers: {
              Authorization: `Token ${this.token}`
            }
          }
        : {};
    return this.service
      .request({
        method: 'POST',
        url: path,
        responseType: 'json',
        ...auth()
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  getDownloadService(path, params, callback) {
    return this.service
      .get(path, {
        params,
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Token ${this.token}`,
          'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  postDownloadService(path, payload, callback) {
    return this.service
      .request({
        method: 'POST',
        url: path,
        responseType: 'arraybuffer',
        data: payload,
        headers: {
          Authorization: `Token ${this.token}`,
          'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  postB2BPublic(path, payload, callback) {
    return this.service
      .request({
        method: 'POST',
        url: path,
        data: payload,
        headers: {
          'x-private': 'false',
          Authorization: `Token ${this.token}`,
          'Content-Type': 'multipart/form-data',
          'x-api-key':
            '$128r8zVxziQvRV5mJJimfh9cmLZ28SQWupXFdcUyQMf6m3zTdu9aqZG4wBCGvL6E06ycQ89rH1xbD4W7z0rPVinHgu08qdnrMvWo5ddHRoRuAMK8MANtNcGC4A2NNKi'
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }
}

export default new Service();
