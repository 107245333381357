const text = {
  en: {
    'SKU Name': 'SKU Name',
    Province: 'Province',
    City: 'City',
    Customer: 'Customer',
    Data: 'Data',
    List: 'list',
    'Supplier List': 'Supplier List',
    'City/regency': 'City/Regency',
    Address: 'Address',
    'Available for rent': 'Available for rent',
    'Aarehouse status': 'Warehouse Status',
    'Capacity Status': 'Capacity Status',
    'Available Capacity': 'Available Capacity',
    'Inventory Capacity': 'Inventory Capacity',
    'Owned By': 'Owned by',
    Company: 'Company',
    Select: 'Select',
    'Warehouse Code': 'Warehouse Code',
    'Company Name': 'Company Name',
    'Add Warehouse Data': 'Add Warehouse Data',
    Suppliers: 'Suppliers',
    Supplier: 'Supplier',
    Add: 'add',
    'Supplier Name': 'Supplier Name',
    'Warehouse Name': 'Warehouse Name',
    'Warehouse Capacity': 'Warehouse Capacity',
    Yes: 'Yes',
    No: 'No',
    Name: 'Name',
    'This field is required': 'This field is required',
    "Can't be zero": "Can't be zero",
    'Select one': 'Select one',
    'longitude must be between -180 and 180 degrees inclusive':
      'longitude must be between -180 and 180 degrees inclusive',
    'latitude must be between -90 and 90 degrees inclusive':
      'latitude must be between -90 and 90 degrees inclusive',
    'Search Data': 'Search Data',
    'Item Name': 'Item Name'
  },
  id: {
    'SKU Name': 'Nama SKU',
    Province: 'Provinsi',
    City: 'Kota',
    Customer: 'Customer',
    Data: 'Data',
    List: 'daftar',
    'City/regency': 'Kota/Kabupaten',
    Address: 'Alamat',
    'Warehouse Status': 'Status Gudang',
    'Available for rent': 'Available for rent',
    'Capacity Status': 'Status Kapasitas',
    'Available Capacity': 'Kapasitas Tersedia',
    'Inventory Capacity': 'Kapasitas Inventori',
    'Owned By': 'Pemilik',
    Company: 'Perusahaan',
    Select: 'Pilih',
    'Warehouse Code': 'Kode Gudang',
    'Company Name': 'Nama Perusahaan',
    'Add Warehouse Data': 'Buat Data Gudang',
    Suppliers: 'Daftar Supplier',
    Supplier: 'Supplier',
    Add: 'tambahkan',
    'Supplier Name': 'Nama Supplier',
    'Warehouse Name': 'Nama Gudang',
    'Warehouse Capacity': 'Kapasitas Gudang',
    Yes: 'Ya',
    No: 'Tidak',
    Name: 'Nama',
    'This field is required': 'Kolom ini wajib diisi',
    "Can't be zero": 'Tidak boleh nol',
    'Select one': 'Pilih salah satu',
    'longitude must be between -180 and 180 degrees inclusive':
      'longitude harus antara -180 dan 180 derajat inklusif',
    'latitude must be between -90 and 90 degrees inclusive':
      'latitude harus antara -90 dan 90 derajat inklusif',
    'Search Data': 'Cari Data',
    'Item Name': 'Nama Item'
  }
};

const mapTest = (list, lang) => {
  const arrText = [];
  for (let i = 0; i < list.length; i++) {
    const element = list[i].split('|');
    const textOne = text[lang][element[0]];
    if (textOne) {
      arrText.push(textOne + (element[1] || ''));
    } else {
      arrText.push(list[i]);
    }
  }
  return arrText.join(' ');
};

const useTranslate = (listString = []) => {
  try {
    const language = localStorage.getItem('language') || 'en';
    return mapTest(listString, language);
  } catch (error) {
    return mapTest(listString, 'en');
  }
};

export default useTranslate;
