import { all, put, takeLatest, call } from 'redux-saga/effects';
import * as act from '../../../services/fishfin';
import {
  SET_STATE,
  GET_DATA_FISHFIN_CHANGES_RETURN,
  GET_DATA_FISHFIN_CHANGES_RETURN_BY_ID,
  CREATE_DATA_FISHFIN_CHANGES_RETURN,
  DELETE_DATA_FISHFIN_CHANGES_RETURN,
  SET_ERROR_FORM,
  DOWNLOAD_DATA_FISHFIN_CHANGES_RETURN
} from './actions';
import { SET_STATE as SET_STATE_inv } from '../stockInventory/actions';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog, handleDownload, logMatches } from '../../../utils';

export function* GET_FISHFIN_CHANGES_RETURN(action) {
  yield put({ type: SET_STATE_inv, payload: { data: null } });
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(act.getFishfinReturn, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_FISHFIN_CHANGES_RETURN_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(act.getFishfinReturnDetail, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* CREATE_FISHFIN_CHANGES_RETURN(action) {
  yield put({ type: SET_STATE, payload: { [action.log.loading]: true } });
  try {
    yield call(act.createFishfinReturn, action.data);
    yield setIpUser();
    const body = bodyLog(
      action.log.log_type,
      'FishFin Return',
      action.log.desc
    );

    let message = 'Your Data Has Been Submitted';
    if (action?.data?.is_draft) {
      message = 'Successfully Save as Draft';
    }
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { [action.log.loading]: false } });
  }
}

export function* DELETE_FISHFIN_CHANGES_RETURN(action) {
  yield put({ type: SET_STATE, payload: { loadingDelete: true } });
  try {
    yield call(act.deleteFishfinReturn, action.id);
    yield setIpUser();
    const body = bodyLog(
      'delete',
      'FishFin Return',
      'Delete draft FishFin Return'
    );
    yield call(createLog, body);
    let message = 'Successfully deleting the Draft';
    yield put({ type: SET_STATE, payload: { isDelete: true, message } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
    yield put({ type: SET_STATE, payload: { loadingDelete: false } });
  }
}

export function* DOWNLOAD_FISHFIN_CHANGES_RETURN(action) {
  try {
    const response = yield call(act.downloadExcelFishfinReturn, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    console.log(error);
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export default all([
  takeLatest(GET_DATA_FISHFIN_CHANGES_RETURN, GET_FISHFIN_CHANGES_RETURN),
  takeLatest(
    GET_DATA_FISHFIN_CHANGES_RETURN_BY_ID,
    GET_FISHFIN_CHANGES_RETURN_BY_ID
  ),
  takeLatest(CREATE_DATA_FISHFIN_CHANGES_RETURN, CREATE_FISHFIN_CHANGES_RETURN),
  takeLatest(DELETE_DATA_FISHFIN_CHANGES_RETURN, DELETE_FISHFIN_CHANGES_RETURN),
  takeLatest(
    DOWNLOAD_DATA_FISHFIN_CHANGES_RETURN,
    DOWNLOAD_FISHFIN_CHANGES_RETURN
  )
]);
