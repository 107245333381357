import { routeReguler } from './listRoutes';
import { routeSuperUser } from './routeSuperUser';
import { routeProfileUser } from './routeProfileUser';

const routeProtected = (modules, isSuperUser) => {
  const listOfSuperUser = routeSuperUser;
  let objRoutes = {};
  let listOfRoute = [];
  if (isSuperUser) {
    listOfRoute = routeReguler.concat(listOfSuperUser);
  } else {
    try {
      listOfRoute = routeReguler.filter((item) => {
        const parentPath = item.path.split('/');
        objRoutes = { ...objRoutes, [item.path]: true };
        return modules[`/${parentPath[1]}`]?.[item.action];
      });
    } catch (error) {
      listOfRoute = [];
    }
  }
  listOfRoute = [...listOfRoute, ...routeProfileUser];
  return { listOfRoute, objRoutes };
};
export default routeProtected;
