import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getReceiptInbound,
  getReceiptInboundById,
  addReceiptInbound,
  updateReceiptInbound,
  exportReceiptInboundPDF,
  exportReceiptInboundExcel
} from '../../../services/receiptInbound';
import {
  SET_STATE,
  SET_ERROR_FORM,
  GET_DATA_RECEIPT_INBOUND,
  GET_DATA_RECEIPT_INBOUND_BY_ID,
  GET_DATA_RECEIPT_INBOUND_NEXT_OR_PREV,
  ADD_DATA_RECEIPT_INBOUND,
  UPDATE_DATA_RECEIPT_INBOUND,
  DOWNLOAD_DATA_RECEIPT_INBOUND_PDF,
  DOWNLOAD_DATA_RECEIPT_INBOUND_EXCEL
} from './actions';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog, handleDownload, logMatches } from '../../../utils';

export function* GET_RECEIPT_INBOUND(action) {
  yield put({ type: SET_STATE, payload: { data: null, loading: true } });
  try {
    const { data } = yield call(getReceiptInbound, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_RECEIPT_INBOUND_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { data: null, loading: true } });
  try {
    const { data } = yield call(getReceiptInboundById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_RECEIPT_INBOUND_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_RECEIPT_INBOUND(action) {
  yield put({ type: SET_STATE, payload: { loadingDraft: true, id62: null } });
  try {
    const dataResponse = yield call(addReceiptInbound, action.data);

    yield setIpUser();
    const body = bodyLog(
      action.log.log_type,
      'Receipt Inbound',
      action.log.desc
    );

    let message = 'Your Data Has Been Submitted';
    if (action?.data?.is_draft) {
      message = 'Successfully Save as Draft';
    }
    yield call(createLog, body);
    yield put({
      type: SET_STATE,
      payload: { isAdd: true, id62: dataResponse?.data?.data?.id62, message }
    });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDraft: false } });
  }
}

export function* UPDATE_RECEIPT_INBOUND(action) {
  yield put({ type: SET_STATE, payload: { loadingDraft: true, id62: null } });
  try {
    const dataResponse = yield call(
      updateReceiptInbound,
      action.data,
      action.id
    );

    yield setIpUser();
    const body = bodyLog(
      action.log.log_type,
      'Receipt Inbound',
      action.log.desc
    );

    let message = 'Your Data Has Been Submitted';
    if (action?.data?.is_draft) {
      message = 'Successfully Save as Draft';
    }
    yield call(createLog, body);
    yield put({
      type: SET_STATE,
      payload: { isUpdate: true, id62: dataResponse?.data?.data?.id62, message }
    });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDraft: false } });
  }
}

export function* DOWNLOAD_RECEIPT_INBOUND_PDF(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(exportReceiptInboundPDF, action.id);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DOWNLOAD_RECEIPT_INBOUND_EXCEL(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(exportReceiptInboundExcel, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_RECEIPT_INBOUND, GET_RECEIPT_INBOUND),
  takeLatest(GET_DATA_RECEIPT_INBOUND_BY_ID, GET_RECEIPT_INBOUND_BY_ID),
  takeLatest(
    GET_DATA_RECEIPT_INBOUND_NEXT_OR_PREV,
    GET_RECEIPT_INBOUND_NEXT_OR_PREV
  ),
  takeLatest(ADD_DATA_RECEIPT_INBOUND, ADD_RECEIPT_INBOUND),
  takeLatest(UPDATE_DATA_RECEIPT_INBOUND, UPDATE_RECEIPT_INBOUND),
  takeLatest(DOWNLOAD_DATA_RECEIPT_INBOUND_PDF, DOWNLOAD_RECEIPT_INBOUND_PDF),
  takeLatest(
    DOWNLOAD_DATA_RECEIPT_INBOUND_EXCEL,
    DOWNLOAD_RECEIPT_INBOUND_EXCEL
  )
]);
