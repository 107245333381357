import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getDeliveryMethod,
  getDeliveryMethodById,
  addDeliveryMethod,
  updateDeliveryMethod,
  deleteDeliveryMethod
} from '../../../services/deliveryMethod';
import {
  SET_STATE,
  GET_DATA_DELIVERY_METHOD,
  GET_DATA_DELIVERY_METHOD_BY_ID,
  ADD_DATA_DELIVERY_METHOD,
  UPDATE_DATA_DELIVERY_METHOD,
  DELETE_DATA_DELIVERY_METHOD,
  SET_ERROR_FORM,
  GET_DATA_DELIVERY_METHOD_NEXT_OR_PREV
} from './actions';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_DELIVERY_METHOD(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getDeliveryMethod, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_DELIVERY_METHOD_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getDeliveryMethodById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_DELIVERY_METHOD_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* ADD_DELIVERY_METHOD(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addDeliveryMethod, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Data Master', 'Create DeliveryMethod');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* UPDATE_DELIVERY_METHOD(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateDeliveryMethod, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Data Master', 'Edit DeliveryMethod');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* DELETE_DELIVERY_METHOD(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteDeliveryMethod, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Data Master', 'Delete DeliveryMethod');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_DELIVERY_METHOD, GET_DELIVERY_METHOD),
  takeLatest(GET_DATA_DELIVERY_METHOD_BY_ID, GET_DELIVERY_METHOD_BY_ID),
  takeLatest(
    GET_DATA_DELIVERY_METHOD_NEXT_OR_PREV,
    GET_DELIVERY_METHOD_NEXT_OR_PREV
  ),
  takeLatest(ADD_DATA_DELIVERY_METHOD, ADD_DELIVERY_METHOD),
  takeLatest(UPDATE_DATA_DELIVERY_METHOD, UPDATE_DELIVERY_METHOD),
  takeLatest(DELETE_DATA_DELIVERY_METHOD, DELETE_DELIVERY_METHOD)
]);
