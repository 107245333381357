import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getContainer,
  getContainerById,
  addContainer,
  updateContainer,
  deleteContainer
} from '../../../services/container';
import {
  SET_STATE,
  SET_ERROR_FORM,
  GET_DATA_CONTAINER,
  GET_DATA_CONTAINER_BY_ID,
  GET_DATA_CONTAINER_NEXT_OR_PREV,
  ADD_DATA_CONTAINER,
  UPDATE_DATA_CONTAINER,
  DELETE_DATA_CONTAINER
} from './actions';

import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_CONTAINER(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getContainer, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_CONTAINER_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getContainerById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_CONTAINER_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_CONTAINER(action) {
  yield put({ type: SET_STATE, payload: { loadingForm: true } });
  try {
    yield call(addContainer, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Data Master', 'Create Container');
    yield call(createLog, body);
    let message = 'Your Data Has Been Submitted';
    yield put({ type: SET_STATE, payload: { isAdd: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  }
}

export function* UPDATE_CONTAINER(action) {
  yield put({ type: SET_STATE, payload: { loadingForm: true } });
  try {
    yield call(updateContainer, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Data Master', 'Edit Container');
    yield call(createLog, body);
    let message = 'Your Data Has Been Submitted';
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loadingForm: false } });
  }
}

export function* DELETE_CONTAINER(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteContainer, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Data Master', 'Delete Container');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loading: false }
    });
  }
}

export default all([
  takeLatest(GET_DATA_CONTAINER, GET_CONTAINER),
  takeLatest(GET_DATA_CONTAINER_BY_ID, GET_CONTAINER_BY_ID),
  takeLatest(GET_DATA_CONTAINER_NEXT_OR_PREV, GET_CONTAINER_NEXT_OR_PREV),
  takeLatest(ADD_DATA_CONTAINER, ADD_CONTAINER),
  takeLatest(UPDATE_DATA_CONTAINER, UPDATE_CONTAINER),
  takeLatest(DELETE_DATA_CONTAINER, DELETE_CONTAINER)
]);
