import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'moduleGroup/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'moduleGroup/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_MODULE_GROUP = 'GET_DATA_MODULE_GROUP';
export const getDataModuleGroup = defaultActionCreator(
  GET_DATA_MODULE_GROUP,
  'param'
);
export const GET_DATA_MODULE_GROUP_BY_ID = 'GET_DATA_MODULE_GROUP_BY_ID';
export const getDataModuleGroupById = defaultActionCreator(
  GET_DATA_MODULE_GROUP_BY_ID,
  'id'
);
export const GET_DATA_MODULE_GROUP_NEXT_OR_PREV =
  'GET_DATA_MODULE_GROUP_NEXT_OR_PREV';
export const getDataModuleGroupNextOrPrev = defaultActionCreator(
  GET_DATA_MODULE_GROUP_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_MODULE_GROUP = 'ADD_DATA_MODULE_GROUP';
export const addDataModuleGroup = defaultActionCreator(
  ADD_DATA_MODULE_GROUP,
  'data'
);
export const UPDATE_DATA_MODULE_GROUP = 'UPDATE_DATA_MODULE_GROUP';
export const updateDataModuleGroup = defaultActionCreator(
  UPDATE_DATA_MODULE_GROUP,
  'data',
  'id'
);

export const DELETE_DATA_MODULE_GROUP = 'DELETE_DATA_MODULE_GROUP';
export const deleteDataModuleGroup = defaultActionCreator(
  DELETE_DATA_MODULE_GROUP,
  'id'
);
