import service from './service';
import endpoint from './constantUrl';

export const addTagihanPembelian = (data) => {
  return service.post(`${endpoint.POS_URL}/purchase/invoice/`, data);
};

export const getTagihanPembelian = (param) => {
  return service.post(`${endpoint.POS_URL}/purchase/invoice/filter/`, param);
};
export const getTagihanPembelianById = (id) => {
  return service.get(`${endpoint.POS_URL}/purchase/invoice/${id}/`);
};

export const updateTagihanPembelian = (data, id) => {
  return service.put(`${endpoint.POS_URL}/purchase/invoice/${id}/`, data);
};

export const deleteTagihanPembelian = (id) => {
  return service.delete(`${endpoint.POS_URL}/purchase/invoice/${id}/`);
};

export const downloadTagihanPembelian = (param) => {
  return service.postArrayBuffer(
    `${endpoint.POS_URL}/purchase/invoice/export-excel/`,
    param
  );
};
