import React, { useState, useEffect } from 'react';
import Menu from './Menu';
import BaseNavbar from './Navbar';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getFisrtPath, removeLocalstorage } from '../utils';
import { Card } from 'react-bootstrap';
// import UserInfo from './UserInfo';

function Layout({ dataAuth, actionModule }) {
  const userId = localStorage.getItem('user_id');
  const email = localStorage.getItem('email');
  useEffect(() => {
    if (!userId || !email) {
      removeLocalstorage();
    }
  }, []);

  const firstPath = getFisrtPath(dataAuth);
  const [closeAlert, setcloseAlert] = useState(
    localStorage.getItem('alert-login')
  );
  const [isHideMenu, setIsHideMenu] = useState(
    localStorage.getItem('expand') === 'true'
  );
  const loc = useLocation();
  const navigate = useNavigate();
  const parrentPath = loc.pathname.split('/');
  let moduleDesc = {};
  try {
    moduleDesc = actionModule[`/${parrentPath[1]}`];
  } catch (error) {
    moduleDesc = {};
  }
  if (loc.state === 'detail_invoice') {
    return <Outlet />;
  }

  let noPadding = '';
  switch (loc.pathname) {
    case '/':
      navigate(firstPath);
      break;
    case '/profile-user':
      noPadding = 'no-padding ';
      break;
    default:
      noPadding = '';
      break;
  }
  useEffect(() => {
    if (closeAlert) {
      setTimeout(() => {
        localStorage.removeItem('alert-login');
      }, 1000);
    }
    if (loc.pathname == '/profile-user') {
      setIsHideMenu(true);
    }
  }, []);

  const ignoreNavigationBar = () =>
    ![
      '/main-dashboard',
      '/daftar-gudang',
      '/financial-dashboard',
      '/operation-dashboard',
      // '/commercial-dashboard',
      '/maps'
    ]?.includes(moduleDesc?.url);

  return (
    <>
      {/* <UserInfo /> */}
      <div className="fl_layout">
        <BaseNavbar
          name={dataAuth.full_name}
          isHideMenu={isHideMenu}
          setIsHideMenu={setIsHideMenu}
        />
        <div
          onClick={() => setIsHideMenu(!isHideMenu)}
          className={'fl_backdrop ' + (isHideMenu ? 'hide' : '')}
        />
        <div className={'fl_menu ' + (isHideMenu ? 'hide' : '')}>
          <Menu dataAuth={dataAuth} actionModule={actionModule} />
        </div>
        <div className={'fl_content ' + noPadding + (isHideMenu ? 'full' : '')}>
          {ignoreNavigationBar() &&
            moduleDesc?.name &&
            parrentPath.length === 2 &&
            (moduleDesc?.parentName ? (
              <h5
                className="mb-lg-3 mb-sm-1 h5 "
                style={{
                  fontWeight: '600',
                  fontSize: '20px',
                  lineHeight: '150%'
                }}
              >
                <span style={{ marginRight: '16px' }}>
                  {moduleDesc?.parentName}
                </span>
                <span style={{ marginRight: '16px' }}>
                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 2.5L8 10.5L2 18.5"
                      stroke="#9E9E9E"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <span className="text-blue">{moduleDesc?.name}</span>
              </h5>
            ) : (
              <h5 className="mb-lg-3 mb-sm-1">{moduleDesc?.name}</h5>
            ))}
          {closeAlert && (
            <Card className="fl-alert-login">
              <Card.Body className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <p>Welcome to {dataAuth.full_name}! </p>
                  <svg
                    width="30"
                    height="31"
                    viewBox="0 0 30 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.63669 8.24352C2.82082 8.24633 3.00369 8.21401 3.17465 8.14844C3.34561 8.08287 3.50125 7.98537 3.6325 7.86162C3.76374 7.73786 3.86798 7.59032 3.93912 7.42759C4.01027 7.26486 4.04691 7.0902 4.04691 6.91377C4.04691 6.73734 4.01027 6.56268 3.93912 6.39995C3.86798 6.23722 3.76374 6.08968 3.6325 5.96593C3.50125 5.84217 3.34561 5.74467 3.17465 5.6791C3.00369 5.61354 2.82082 5.58122 2.63669 5.58403C1.87834 5.59503 1.26953 6.18717 1.26953 6.91383C1.26953 7.64048 1.87834 8.23252 2.63669 8.24352ZM23.8944 7.11626C24.0782 7.11853 24.2607 7.0858 24.4311 7.01998C24.6016 6.95415 24.7568 6.85655 24.8876 6.73282C25.0184 6.60909 25.1222 6.46171 25.1931 6.29923C25.264 6.13674 25.3004 5.96239 25.3004 5.78629C25.3004 5.61019 25.2639 5.43585 25.193 5.27338C25.122 5.11091 25.0182 4.96355 24.8873 4.83986C24.7565 4.71616 24.6013 4.61859 24.4308 4.55281C24.2603 4.48703 24.0779 4.45435 23.8941 4.45666C23.1357 4.46766 22.527 5.05992 22.527 5.78657C22.5271 6.51323 23.1361 7.10537 23.8944 7.11626Z"
                      fill="#FF37AD"
                    />
                    <path
                      d="M25.0237 24.2012C25.2078 24.204 25.3907 24.1717 25.5617 24.1061C25.7326 24.0405 25.8882 23.943 26.0195 23.8192C26.1507 23.6954 26.2549 23.5479 26.326 23.3851C26.3972 23.2224 26.4338 23.0477 26.4338 22.8713C26.4337 22.6949 26.3971 22.5202 26.3259 22.3575C26.2547 22.1948 26.1505 22.0473 26.0192 21.9235C25.888 21.7998 25.7323 21.7023 25.5613 21.6367C25.3904 21.5712 25.2075 21.5389 25.0234 21.5417C24.265 21.5527 23.6563 22.145 23.6564 22.8716C23.6564 23.5983 24.2654 24.1903 25.0237 24.2012ZM8.30856 3.15972C8.49236 3.16201 8.6748 3.1293 8.84529 3.0635C9.01579 2.9977 9.17095 2.90011 9.30176 2.7764C9.43258 2.65269 9.53644 2.50532 9.60734 2.34284C9.67823 2.18036 9.71473 2.00602 9.71473 1.82991C9.71473 1.65381 9.67823 1.47947 9.60734 1.31699C9.53644 1.15451 9.43258 1.00714 9.30176 0.883433C9.17095 0.759722 9.01579 0.662134 8.84529 0.596332C8.6748 0.53053 8.49236 0.497824 8.30856 0.500112C7.5501 0.511228 6.94141 1.10326 6.94141 1.82991C6.94141 2.55657 7.55022 3.14871 8.30856 3.15972Z"
                      fill="#FCC954"
                    />
                    <path
                      d="M16.5182 12.3941C16.3535 12.3941 16.1911 12.3581 16.0431 12.289C15.8952 12.2198 15.7656 12.1193 15.6642 11.995C15.5628 11.8708 15.4924 11.726 15.4581 11.5717C15.4238 11.4175 15.4267 11.2577 15.4665 11.1047C15.5099 10.9383 15.5452 10.7712 15.5719 10.6045C15.2466 10.7331 14.9001 10.8055 14.5485 10.8183C13.9182 10.8413 13.3538 10.7395 12.8727 10.5162C12.4013 10.2999 12.0087 9.95258 11.746 9.51957C11.2286 8.66043 11.2733 7.53026 11.859 6.64024C12.4174 5.79053 13.3633 5.33065 14.3887 5.40576C15.1786 5.46493 15.8708 5.74832 16.4252 6.23459C16.5261 5.96176 16.6229 5.6695 16.7151 5.35849C17.2814 3.44328 17.4685 1.5201 17.47 1.50068C17.4828 1.36493 17.5234 1.23293 17.5895 1.11222C17.6556 0.991515 17.7458 0.884467 17.8551 0.797194C17.9644 0.70992 18.0906 0.644134 18.2264 0.603593C18.3622 0.563052 18.505 0.548552 18.6467 0.560921C18.9329 0.585933 19.1969 0.71877 19.3808 0.930235C19.5648 1.1417 19.6535 1.41448 19.6276 1.68863C19.6198 1.77474 19.4204 3.82525 18.7993 5.92538C18.4857 6.98583 18.1151 7.88191 17.6954 8.59733C17.7263 8.75677 17.751 8.92047 17.7693 9.08821C17.8567 9.89783 17.7871 10.7694 17.5684 11.6083C17.5096 11.8325 17.3743 12.0315 17.1841 12.1738C16.9939 12.3161 16.7595 12.3937 16.5182 12.3941ZM14.1599 7.47333C14.0479 7.47333 13.8505 7.50477 13.6916 7.74617C13.5395 7.97746 13.5097 8.29329 13.6227 8.4809C13.773 8.73049 14.215 8.75227 14.4653 8.74318C14.7494 8.73285 15.0405 8.56219 15.3253 8.25118C15.2778 8.15606 15.2217 8.06507 15.1577 7.97925C14.9225 7.66634 14.6248 7.50634 14.2204 7.47603C14.2052 7.47513 14.1847 7.47333 14.1599 7.47333Z"
                      fill="#2890E9"
                    />
                    <path
                      d="M13.4022 15.2385C8.9087 11.845 4.21201 10.3748 2.91188 11.9548C2.78956 12.1051 2.69965 12.2772 2.64738 12.4611C2.6394 12.4834 2.63329 12.5063 2.6291 12.5296V12.5323C2.61164 12.6032 2.59863 12.6748 2.59007 12.7475L0.0460792 27.0176C-0.361396 29.3094 2.00669 31.1443 4.26111 30.2841L18.057 25.0174C18.3003 24.9247 18.899 24.5703 19.0629 24.3747C19.1062 24.3341 19.1469 24.2909 19.1844 24.2453C20.4843 22.665 17.8953 18.6328 13.4022 15.2381V15.2385Z"
                      fill="#F0A420"
                    />
                    <path
                      d="M19.1827 24.2454C20.4835 22.6646 17.8954 18.6316 13.402 15.2379C8.90848 11.8442 4.21121 10.3746 2.9105 11.9554C1.60967 13.5363 4.19762 17.5692 8.69109 20.9629C13.1846 24.3567 17.8818 25.8263 19.1827 24.2454Z"
                      fill="#FCC954"
                    />
                    <path
                      d="M18.397 23.3251C19.4644 22.0276 17.1131 18.5462 13.1449 15.5492C9.17664 12.552 5.09415 11.1743 4.02689 12.4714C2.95927 13.7689 5.3106 17.2502 9.27883 20.2472C13.2469 23.2444 17.3295 24.6225 18.397 23.3251Z"
                      fill="#F0A420"
                    />
                    <path
                      d="M8.91401 28.5081C7.23501 28.5159 5.86656 27.7108 4.20783 26.4582C2.27769 24.9999 0.995843 23.2545 0.894707 22.2603L0.347656 25.3285C0.60079 26.204 1.46367 27.3139 2.71516 28.259C3.75617 29.0454 4.80633 29.5853 5.89515 29.6602L8.91401 28.5076V28.5081ZM7.23442 22.9391C4.01833 20.5098 1.92904 17.722 1.98775 16.1274L1.45465 19.1174C1.49578 20.5416 3.23303 22.8431 5.70425 24.7096C7.91847 26.3821 10.2259 27.5592 11.9928 27.3327L14.992 26.1875C12.5755 26.3475 10.0259 25.0481 7.23442 22.9391Z"
                      fill="#2E6AC9"
                    />
                    <path
                      d="M13.8906 16.7862C14.0744 16.7885 14.2568 16.7558 14.4273 16.69C14.5978 16.6242 14.753 16.5266 14.8838 16.4029C15.0146 16.2792 15.1185 16.1318 15.1894 15.9693C15.2603 15.8068 15.2968 15.6325 15.2968 15.4564C15.2968 15.2803 15.2603 15.1059 15.1894 14.9435C15.1185 14.781 15.0146 14.6336 14.8838 14.5099C14.753 14.3862 14.5978 14.2886 14.4273 14.2228C14.2568 14.157 14.0744 14.1243 13.8906 14.1266C13.1322 14.1376 12.5234 14.7297 12.5234 15.4564C12.5234 16.183 13.1322 16.7752 13.8906 16.7862Z"
                      fill="#2890E9"
                    />
                    <path
                      d="M21.378 14.1983L21.3882 14.1874C21.4008 14.1744 21.4243 14.1503 21.458 14.1177C21.5632 14.0175 21.6726 13.9216 21.7862 13.8302C22.2034 13.4957 22.6592 13.2082 23.1449 12.9736C24.3318 12.3982 26.099 11.9286 28.4167 12.4406C29.1132 12.5944 29.8078 12.1783 29.9685 11.5112C30.129 10.844 29.6947 10.1785 28.9984 10.0246C25.998 9.36187 23.6126 9.96783 21.976 10.7618C21.3115 11.0831 20.6879 11.4768 20.1177 11.935C19.9465 12.0727 19.7817 12.2176 19.6237 12.3691C19.5624 12.4281 19.5026 12.4886 19.4444 12.5504L19.4304 12.5656L19.4254 12.571L19.4231 12.5734L19.4223 12.5743C19.4219 12.5749 19.4212 12.5754 20.4021 13.3838L19.4212 12.5754C18.9669 13.0944 19.0312 13.8666 19.5657 14.3099C20.1002 14.7532 20.9071 14.7035 21.378 14.1983ZM8.55933 5.35551C8.46282 5.20865 8.33701 5.08148 8.18911 4.98129C8.0412 4.8811 7.87412 4.80985 7.69743 4.77164C7.52074 4.73342 7.33791 4.72899 7.15943 4.75858C6.98094 4.78818 6.81031 4.85124 6.65731 4.94413C6.50419 5.03669 6.37161 5.15726 6.26715 5.29893C6.16269 5.44061 6.08839 5.60062 6.0485 5.76982C6.00862 5.93901 6.00392 6.11408 6.03468 6.28502C6.06545 6.45596 6.13107 6.61941 6.2278 6.76604C6.27784 6.84272 7.30338 8.43447 7.51409 10.6144C7.72855 12.8293 7.04931 14.8652 5.49219 16.6908C6.0165 17.3292 6.64746 17.9928 7.36819 18.6565C8.36549 17.5523 9.11587 16.3658 9.60889 15.1104C10.2154 13.5659 10.4299 11.9283 10.247 10.2435C9.95555 7.56298 8.73839 5.62778 8.55933 5.35551Z"
                      fill="#FF37AD"
                    />
                    <path
                      d="M25.8094 18.0801C26.5128 18.2545 27.0099 18.4446 27.2247 18.5331C27.4758 18.6807 27.7693 18.7481 28.063 18.7255C28.3566 18.7029 28.6351 18.5915 28.8584 18.4073C29.0063 18.2849 29.1256 18.134 29.2085 17.9644C29.2915 17.7949 29.3361 17.6104 29.3397 17.4232C29.3446 17.141 29.2558 16.8647 29.086 16.634C28.9162 16.4034 28.6742 16.2305 28.3951 16.1403C27.7367 15.8602 25.2421 14.9143 21.9444 15.0824C19.7247 15.1954 17.5902 15.7844 15.6005 16.8348C13.7165 17.8295 11.9671 19.2388 10.3789 21.0367C11.1366 21.5534 11.9259 22.026 12.7427 22.4522C15.4242 19.524 18.5182 17.9368 21.9656 17.727C23.2583 17.6518 24.5552 17.7709 25.8094 18.0801Z"
                      fill="#2890E9"
                    />
                  </svg>
                </div>
                <span onClick={() => setcloseAlert(!closeAlert)}>
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 15.5L8 8.5M15 1.5L8 8.5M1 1.5L8 8.5M15 15.5L8 8.5"
                      stroke="#1C1C1C"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Card.Body>
            </Card>
          )}
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default Layout;
