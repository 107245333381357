import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeKomiditas = [
  {
    path: '/commodity',
    parent: '/commodity',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "BillingTagihanTagihanTerpisah" */ '../Pages/MasterData/Komoditas/Komoditas'
        )
      )
    )
  },
  {
    path: '/commodity/add',
    parent: '/commodity',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "BillingTagihanTagihanTerpisah" */ '../Pages/MasterData/Komoditas/AddData'
        )
      )
    )
  },
  {
    path: '/commodity/view/:id',
    parent: '/commodity',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "BillingTagihanTagihanTerpisah" */ '../Pages/MasterData/Komoditas/ViewData'
        )
      )
    )
  },
  {
    path: '/commodity/update/:id',
    parent: '/commodity',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "BillingTagihanTagihanTerpisah" */ '../Pages/MasterData/Komoditas/UpdateData'
        )
      )
    )
  }
];
