import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'process/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'process/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_PROCESS = 'GET_DATA_PROCESS';
export const getDataProcess = defaultActionCreator(GET_DATA_PROCESS, 'param');

export const GET_DATA_PROCESS_BY_ID = 'GET_DATA_PROCESS_BY_ID';
export const getDataProcessById = defaultActionCreator(
  GET_DATA_PROCESS_BY_ID,
  'id'
);

export const GET_DATA_PROCESS_NEXT_OR_PREV = 'GET_DATA_PROCESS_NEXT_OR_PREV';
export const getDataProcessNextOrPrev = defaultActionCreator(
  GET_DATA_PROCESS_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_PROCESS = 'ADD_DATA_PROCESS';
export const addDataProcess = defaultActionCreator(ADD_DATA_PROCESS, 'data');

export const UPDATE_DATA_PROCESS = 'UPDATE_DATA_PROCESS';
export const updateDataProcess = defaultActionCreator(
  UPDATE_DATA_PROCESS,
  'data',
  'id'
);

export const DELETE_DATA_PROCESS = 'DELETE_DATA_PROCESS';
export const deleteDataProcess = defaultActionCreator(
  DELETE_DATA_PROCESS,
  'id'
);
