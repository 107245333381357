import { all, put, takeLatest, call } from 'redux-saga/effects';
import {
  GET_DATA_STOK_IN,
  SET_STATE,
  CREATE_DATA_STOCK_IN,
  GENERATE_NO_STOK_IN,
  SET_ERROR_FORM,
  DELETE_DATA_STOCK_IN,
  GET_STOCK_IN_BY_ID,
  UPDATE_STATUS_KALKULASI_IN,
  UPDATE_STOCK_IN,
  UPLOAD_STOCK_IN,
  DOWNLOAD_EXCEL,
  TEMPLATE_CSV
} from './actions';
import {
  getDataStokIn,
  createDataStokIn,
  generateNoStockIn,
  deleteDataStockIn,
  getStockInById,
  updateStatusKalkulasi,
  updateStokIn,
  uploadDataStockIn,
  downloadExcel,
  templateCsv
} from '../../../services/stockIn';
import { handleDownload, logMatches } from '../../../utils';

import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_STOK_IN(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataStokIn, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* CREATE_STOCK_IN(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(createDataStokIn, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Stok In', 'Create Stok In');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GENERATE_NUMBER(action) {
  yield put({ type: SET_STATE, payload: { loadingGenerate: true } });
  try {
    const { data } = yield call(generateNoStockIn, action.data);
    yield put({ type: SET_STATE, payload: { generateNumber: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingGenerate: false } });
  }
}

export function* DELETE_STOCK_IN(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteDataStockIn, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Stok In', 'Delete Stok In');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loading: false }
    });
  }
}

export function* GET_DATA_STOCK_IN_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getStockInById, action.id);
    yield put({ type: SET_STATE, payload: { dataStokIn: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* UPDATE_DATA_KALKULASI(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateStatusKalkulasi, action.data, action.id);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* UPDATE_DATA_STOCK_IN(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateStokIn, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Stok In', 'Edit Stok In');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* UPLOAD_DATA_STOCK_IN(action) {
  yield put({ type: SET_STATE, payload: { loadingUpload: true } });
  try {
    yield call(uploadDataStockIn, action.data);
    yield put({
      type: SET_STATE,
      payload: { isUpload: true, isUpdate: true }
    });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({
      type: SET_STATE,
      payload: { errorUpload: error, isUpload: false }
    });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loadingUpload: false }
    });
  }
}

export function* DOWNLOAD_DATA_EXCEL() {
  // yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const response = yield call(downloadExcel);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* TEMPLATE_DATA_CSV() {
  // yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(templateCsv);
    yield handleDownload(data, 'stok-masuk-template.csv');
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export default all([
  takeLatest(GET_DATA_STOK_IN, GET_STOK_IN),
  takeLatest(CREATE_DATA_STOCK_IN, CREATE_STOCK_IN),
  takeLatest(GENERATE_NO_STOK_IN, GENERATE_NUMBER),
  takeLatest(DELETE_DATA_STOCK_IN, DELETE_STOCK_IN),
  takeLatest(GET_STOCK_IN_BY_ID, GET_DATA_STOCK_IN_BY_ID),
  takeLatest(UPDATE_STATUS_KALKULASI_IN, UPDATE_DATA_KALKULASI),
  takeLatest(UPDATE_STOCK_IN, UPDATE_DATA_STOCK_IN),
  takeLatest(UPLOAD_STOCK_IN, UPLOAD_DATA_STOCK_IN),
  takeLatest(DOWNLOAD_EXCEL, DOWNLOAD_DATA_EXCEL),
  takeLatest(TEMPLATE_CSV, TEMPLATE_DATA_CSV)
]);
