import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getGudang,
  getGudangById,
  addGudang,
  updateGudang,
  deleteGudang,
  downloadTemplateGudang,
  downloadDataGudang,
  uploadGudang,
  activationGudang,
  tarifHistoriGudang,
  getWarehouseCategory
} from '../../../services/gudang';
import { handleDownload, logMatches } from '../../../utils';
import {
  SET_STATE,
  GET_DATA_GUDANG,
  GET_DATA_GUDANGS,
  GET_DATA_GUDANG_BY_ID,
  ADD_DATA_GUDANG,
  UPDATE_DATA_GUDANG,
  DELETE_DATA_GUDANG,
  GET_DATA_GUDANG_NEXT_OR_PREV,
  SET_ERROR_FORM,
  DOWNLOAD_DATA_GUDANG,
  DOWNLOAD_TEMPLATE_GUDANG,
  UPLOAD_DATA_GUDANG,
  ACTIVATION_DATA_GUDANG,
  TARIF_HISTORI_DATA_GUDANG,
  GET_DATA_GUDANG_2,
  GET_DATA_WAREHOUSE_CATEGORY
} from './actions';

import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_GUDANG(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getGudang, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* GET_GUDANG_2(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data2: null } });
  try {
    const { data } = yield call(getGudang, action.param);
    yield put({ type: SET_STATE, payload: { data2: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* GET_GUDANGS(action) {
  yield put({
    type: SET_STATE,
    payload: { loading: true, [action.key]: null }
  });
  try {
    const { data } = yield call(getGudang, action.param);
    yield put({ type: SET_STATE, payload: { [action.key]: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_GUDANG_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getGudangById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_GUDANG_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* ADD_GUDANG(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addGudang, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Warehouse', 'Create Warehouse');
    yield call(createLog, body);

    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* UPDATE_GUDANG(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateGudang, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Warehouse', 'Edit Warehouse');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* DELETE_GUDANG(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteGudang, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Warehouse', 'Delete Warehouse');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loading: false }
    });
  }
}

export function* DOWNLOAD_TEMPLATE_GUDANG_F() {
  // yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(downloadTemplateGudang);
    yield handleDownload(data, 'gudang_template.csv');
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* DOWNLOAD_GUDANG(action) {
  try {
    const response = yield call(downloadDataGudang, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* UPLOAD_GUDANG(action) {
  yield put({ type: SET_STATE, payload: { loadingUpload: true } });
  try {
    yield call(uploadGudang, action.data);
    yield put({
      type: SET_STATE,
      payload: { isUpload: true, isUpdate: true }
    });
  } catch ({ response }) {
    yield put({
      type: SET_STATE,
      payload: { errorUpload: response?.data, isUpload: false }
    });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loadingUpload: false }
    });
  }
}

export function* ACTIVATION_GUDANG(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(activationGudang, action.id62);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* TARIF_HISTORI_GUDANG(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingTarifHistori: true, dataTarifHistori: null }
  });
  try {
    const { data } = yield call(tarifHistoriGudang, action.data);
    yield put({ type: SET_STATE, payload: { dataTarifHistori: data } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingTarifHistori: false } });
  }
}

//warehouse category
export function* GET_WAREHOUSE_CATEGORY(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingCategory: true, dataCategory: null }
  });
  try {
    const { data } = yield call(getWarehouseCategory, action.param);
    yield put({ type: SET_STATE, payload: { dataCategory: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingCategory: false } });
  }
}

export default all([
  takeLatest(GET_DATA_GUDANG, GET_GUDANG),
  takeLatest(GET_DATA_GUDANG_2, GET_GUDANG_2),
  takeLatest(GET_DATA_GUDANGS, GET_GUDANGS),
  takeLatest(GET_DATA_GUDANG_BY_ID, GET_GUDANG_BY_ID),
  takeLatest(GET_DATA_GUDANG_NEXT_OR_PREV, GET_GUDANG_NEXT_OR_PREV),
  takeLatest(ADD_DATA_GUDANG, ADD_GUDANG),
  takeLatest(UPDATE_DATA_GUDANG, UPDATE_GUDANG),
  takeLatest(DELETE_DATA_GUDANG, DELETE_GUDANG),
  takeLatest(DOWNLOAD_TEMPLATE_GUDANG, DOWNLOAD_TEMPLATE_GUDANG_F),
  takeLatest(DOWNLOAD_DATA_GUDANG, DOWNLOAD_GUDANG),
  takeLatest(UPLOAD_DATA_GUDANG, UPLOAD_GUDANG),
  takeLatest(TARIF_HISTORI_DATA_GUDANG, TARIF_HISTORI_GUDANG),
  takeLatest(ACTIVATION_DATA_GUDANG, ACTIVATION_GUDANG),
  takeLatest(GET_DATA_WAREHOUSE_CATEGORY, GET_WAREHOUSE_CATEGORY)
]);
