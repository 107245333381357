import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'stockMovement/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'stockMovement/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_STOCK_MOVEMENT = 'GET_DATA_STOCK_MOVEMENT';
export const getDataStockMovement = defaultActionCreator(
  GET_DATA_STOCK_MOVEMENT,
  'param'
);
export const GET_DATA_STOCK_MOVEMENT_SEARCH = 'GET_DATA_STOCK_MOVEMENT_SEARCH';
export const getDataStockMovementSearch = defaultActionCreator(
  GET_DATA_STOCK_MOVEMENT_SEARCH,
  'param'
);
export const GET_DATA_STOCK_MOVEMENT_BY_ID = 'GET_DATA_STOCK_MOVEMENT_BY_ID';
export const getDataStockMovementById = defaultActionCreator(
  GET_DATA_STOCK_MOVEMENT_BY_ID,
  'id',
  'param'
);

export const GET_DATA_STOCK_MOVEMENT_BY_BATCH_NUMBER =
  'GET_DATA_STOCK_MOVEMENT_BY_BATCH_NUMBER';
export const getDataStockMovementByBatchNUmber = defaultActionCreator(
  GET_DATA_STOCK_MOVEMENT_BY_BATCH_NUMBER,
  'id',
  'param'
);
