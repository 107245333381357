import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import { handleDownload, logMatches } from '../../../utils';
import {
  SET_STATE,
  GET_DATA_PERUSAHAAN,
  GET_DATA_PERUSAHAAN_BY_ID,
  ADD_DATA_PERUSAHAAN,
  UPDATE_DATA_PERUSAHAAN,
  DELETE_DATA_PERUSAHAAN,
  GET_DATA_PERUSAHAAN_NEXT_OR_PREV,
  SET_ERROR_FORM,
  DOWNLOAD_DATA_PERUSAHAAN,
  STATUS_DATA_PERUSAHAAN
} from './actions';

import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';
import {
  addContact,
  deleteContact,
  downloadDataContact,
  getContact,
  getContactById,
  updateContact,
  updateContactStatus
} from '../../../services/contact';

export function* GET_PERUSAHAAN(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getContact, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_PERUSAHAAN_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getContactById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_PERUSAHAAN_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_PERUSAHAAN(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addContact, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Contact', 'Create Contact');
    let message = 'Your Data Has Been Submitted';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* UPDATE_PERUSAHAAN(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateContact, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Contact', 'Edit Contact');
    let message = 'Your Data Has Been Submitted';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* DELETE_PERUSAHAAN(action) {
  yield put({ type: SET_STATE, payload: { loadingDelete: true } });
  try {
    yield call(deleteContact, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Contact', 'Delete Contact');
    let message = 'Successfully deleting the Data';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isDelete: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    yield put({ type: SET_STATE, payload: { error } });
    yield put({
      type: SET_STATE,
      payload: { loadingDelete: false }
    });
  }
}

export function* DOWNLOAD_PERUSAHAAN(action) {
  yield put({ type: SET_STATE, payload: { loadingDownload: true } });
  try {
    const { data, headers } = yield call(downloadDataContact, action.param);
    const filename = yield logMatches(headers['content-disposition']);
    yield handleDownload(data, filename[0]);
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDownload: false } });
  }
}

export function* STATUS_PERUSAHAAN(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateContactStatus, action.id);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_PERUSAHAAN, GET_PERUSAHAAN),
  takeLatest(GET_DATA_PERUSAHAAN_BY_ID, GET_PERUSAHAAN_BY_ID),
  takeLatest(GET_DATA_PERUSAHAAN_NEXT_OR_PREV, GET_PERUSAHAAN_NEXT_OR_PREV),
  takeLatest(ADD_DATA_PERUSAHAAN, ADD_PERUSAHAAN),
  takeLatest(UPDATE_DATA_PERUSAHAAN, UPDATE_PERUSAHAAN),
  takeLatest(DELETE_DATA_PERUSAHAAN, DELETE_PERUSAHAAN),
  takeLatest(DOWNLOAD_DATA_PERUSAHAAN, DOWNLOAD_PERUSAHAAN),
  takeLatest(STATUS_DATA_PERUSAHAAN, STATUS_PERUSAHAAN)
]);
