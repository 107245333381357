import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'delivery_method/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'delivery_method/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_DELIVERY_METHOD = 'GET_DATA_DELIVERY_METHOD';
export const getDataDeliveryMethod = defaultActionCreator(
  GET_DATA_DELIVERY_METHOD,
  'param'
);

export const GET_DATA_DELIVERY_METHOD_NEXT_OR_PREV =
  'GET_DATA_DELIVERY_METHOD_NEXT_OR_PREV';
export const getDataDeliveryMethodNextOrPrev = defaultActionCreator(
  GET_DATA_DELIVERY_METHOD_NEXT_OR_PREV,
  'link'
);

export const GET_DATA_DELIVERY_METHOD_BY_ID = 'GET_DATA_DELIVERY_METHOD_BY_ID';
export const getDataDeliveryMethodById = defaultActionCreator(
  GET_DATA_DELIVERY_METHOD_BY_ID,
  'id'
);

export const ADD_DATA_DELIVERY_METHOD = 'ADD_DATA_DELIVERY_METHOD';
export const addDataDeliveryMethod = defaultActionCreator(
  ADD_DATA_DELIVERY_METHOD,
  'data'
);
export const UPDATE_DATA_DELIVERY_METHOD = 'UPDATE_DATA_DELIVERY_METHOD';
export const updateDataDeliveryMethod = defaultActionCreator(
  UPDATE_DATA_DELIVERY_METHOD,
  'data',
  'id'
);

export const DELETE_DATA_DELIVERY_METHOD = 'DELETE_DATA_DELIVERY_METHOD';
export const deleteDataDeliveryMethod = defaultActionCreator(
  DELETE_DATA_DELIVERY_METHOD,
  'id'
);
