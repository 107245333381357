import service from './service';
import endpoint from './constantUrl';

export const getDataRolePermission = (param) => {
  const { search, limit, offset } = param;
  return service.get(
    `${endpoint.ACCOUNT_URL}/role/permission/?search=${search}&limit=${limit}&offset=${offset}`
  );
};

export const getDataRolePermissionById = (id) => {
  return service.get(`${endpoint.ACCOUNT_URL}/role/permission/${id}/`);
};

export const addDataRolePermission = (data) => {
  return service.post(`${endpoint.ACCOUNT_URL}/role/permission/`, data);
};
