import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'storageCategory/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'storageCategory/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_STORAGE_CATEGORY = 'GET_DATA_STORAGE_CATEGORY';
export const getDataStorageCategory = defaultActionCreator(
  GET_DATA_STORAGE_CATEGORY,
  'param'
);

export const GET_DATA_STORAGE_CATEGORY_BY_ID62 =
  'GET_DATA_STORAGE_CATEGORY_BY_ID62';
export const getDataStorageCategoryById62 = defaultActionCreator(
  GET_DATA_STORAGE_CATEGORY_BY_ID62,
  'id62'
);

export const ADD_DATA_STORAGE_CATEGORY = 'ADD_DATA_STORAGE_CATEGORY';
export const addDataStorageCategory = defaultActionCreator(
  ADD_DATA_STORAGE_CATEGORY,
  'data'
);

export const UPDATE_DATA_STORAGE_CATEGORY = 'UPDATE_DATA_STORAGE_CATEGORY';
export const updateDataStorageCategory = defaultActionCreator(
  UPDATE_DATA_STORAGE_CATEGORY,
  'id62',
  'data'
);

export const DELETE_DATA_STORAGE_CATEGORY = 'DELETE_DATA_STORAGE_CATEGORY';
export const deleteDataStorageCategory = defaultActionCreator(
  DELETE_DATA_STORAGE_CATEGORY,
  'id62'
);

export const UPDATE_STATUS_DATA_STORAGE_CATEGORY =
  'UPDATE_STATUS_DATA_STORAGE_CATEGORY';
export const updateStatusDataStorageCategory = defaultActionCreator(
  UPDATE_STATUS_DATA_STORAGE_CATEGORY,
  'id62'
);

export const EXPORT_DATA_EXCEL_STORAGE_CATEGORY =
  'EXPORT_DATA_EXCEL_STORAGE_CATEGORY';
export const exportDataExcelStorageCategory = defaultActionCreator(
  EXPORT_DATA_EXCEL_STORAGE_CATEGORY,
  'param'
);
