import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'tagihanPenjualan/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'tagihanPenjualan/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_TAGIHAN_PENJUALAN = 'GET_DATA_TAGIHAN_PENJUALAN';
export const getDataTagihanPenjualan = defaultActionCreator(
  GET_DATA_TAGIHAN_PENJUALAN,
  'param'
);

export const GET_DATA_UNIT_NEXT_OR_PREV = 'GET_DATA_UNIT_NEXT_OR_PREV';
export const getDataUnitNextOrPrev = defaultActionCreator(
  GET_DATA_UNIT_NEXT_OR_PREV,
  'link'
);

export const GET_DATA_TAGIHAN_PENJUALAN_BY_ID =
  'GET_DATA_TAGIHAN_PENJUALAN_BY_ID';
export const getDataTagihanPenjualanById = defaultActionCreator(
  GET_DATA_TAGIHAN_PENJUALAN_BY_ID,
  'id'
);

export const ADD_DATA_TAGIHAN_PENJUALAN = 'ADD_DATA_TAGIHAN_PENJUALAN';
export const addDataTagihanPenjualan = defaultActionCreator(
  ADD_DATA_TAGIHAN_PENJUALAN,
  'data'
);
export const UPDATE_DATA_TAGIHAN_PENJUALAN = 'UPDATE_DATA_TAGIHAN_PENJUALAN';
export const updateDataTagihanPenjualan = defaultActionCreator(
  UPDATE_DATA_TAGIHAN_PENJUALAN,
  'data',
  'id'
);

export const DELETE_DATA_TAGIHAN_PENJUALAN = 'DELETE_DATA_TAGIHAN_PENJUALAN';
export const deleteDataTagihanPenjualan = defaultActionCreator(
  DELETE_DATA_TAGIHAN_PENJUALAN,
  'id'
);

export const DOWNLOAD_TAGIHAN_PENJUALAN = 'DOWNLOAD_TAGIHAN_PENJUALAN';
export const downloadTagihanPenjualan = defaultActionCreator(
  DOWNLOAD_TAGIHAN_PENJUALAN,
  'param'
);
