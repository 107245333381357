import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'jenisusaha/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'jenisusaha/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_JENIS_USAHA = 'GET_DATA_JENIS_USAHA';
export const getDataJenisUsaha = defaultActionCreator(
  GET_DATA_JENIS_USAHA,
  'param'
);

export const GET_DATA_JENIS_USAHA_BY_ID = 'GET_DATA_JENIS_USAHA_BY_ID';
export const getDataJenisUsahaById = defaultActionCreator(
  GET_DATA_JENIS_USAHA_BY_ID,
  'id'
);

export const GET_DATA_JENIS_USAHA_NEXT_OR_PREV =
  'GET_DATA_JENIS_USAHA_NEXT_OR_PREV';
export const getDataJenisUsahaNextOrPrev = defaultActionCreator(
  GET_DATA_JENIS_USAHA_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_JENIS_USAHA = 'ADD_DATA_JENIS_USAHA';
export const addDataJenisUsaha = defaultActionCreator(
  ADD_DATA_JENIS_USAHA,
  'data'
);

export const UPDATE_DATA_JENIS_USAHA = 'UPDATE_DATA_JENIS_USAHA';
export const updateDataJenisUsaha = defaultActionCreator(
  UPDATE_DATA_JENIS_USAHA,
  'data',
  'id'
);

export const DELETE_DATA_JENIS_USAHA = 'DELETE_DATA_JENIS_USAHA';
export const deleteDataJenisUsaha = defaultActionCreator(
  DELETE_DATA_JENIS_USAHA,
  'id'
);

export const DOWNLOAD_DATA_JENIS_USAHA = 'DOWNLOAD_DATA_JENIS_USAHA';
export const downloadDataJenisUsaha = defaultActionCreator(
  DOWNLOAD_DATA_JENIS_USAHA,
  'param'
);
