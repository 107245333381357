import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'certification/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'certification/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_CERTIFICATION = 'GET_DATA_CERTIFICATION';
export const getDataCertification = defaultActionCreator(
  GET_DATA_CERTIFICATION,
  'param'
);

export const GET_DATA_CERTIFICATION_BY_ID = 'GET_DATA_CERTIFICATION_BY_ID';
export const getDataCertificationById = defaultActionCreator(
  GET_DATA_CERTIFICATION_BY_ID,
  'id'
);

export const GET_DATA_CERTIFICATION_NEXT_OR_PREV =
  'GET_DATA_CERTIFICATION_NEXT_OR_PREV';
export const getDataCertificationNextOrPrev = defaultActionCreator(
  GET_DATA_CERTIFICATION_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_CERTIFICATION = 'ADD_DATA_CERTIFICATION';
export const addDataCertification = defaultActionCreator(
  ADD_DATA_CERTIFICATION,
  'data'
);

export const UPDATE_DATA_CERTIFICATION = 'UPDATE_DATA_CERTIFICATION';
export const updateDataCertification = defaultActionCreator(
  UPDATE_DATA_CERTIFICATION,
  'data',
  'id'
);

export const DELETE_DATA_CERTIFICATION = 'DELETE_DATA_CERTIFICATION';
export const deleteDataCertification = defaultActionCreator(
  DELETE_DATA_CERTIFICATION,
  'id'
);

export const ACTIVATION_DATA_CERTIFICATION = 'ACTIVATION_DATA_CERTIFICATION';
export const activationDataCertification = defaultActionCreator(
  ACTIVATION_DATA_CERTIFICATION,
  'id'
);

export const DOWNLOAD_DATA_CERTIFICATION_PDF =
  'DOWNLOAD_DATA_CERTIFICATION_PDF';
export const downloadDataCertificationPDF = defaultActionCreator(
  DOWNLOAD_DATA_CERTIFICATION_PDF,
  'param'
);

export const DOWNLOAD_DATA_CERTIFICATION_EXCEL =
  'DOWNLOAD_DATA_CERTIFICATION_EXCEL';
export const downloadDataCertificationExcel = defaultActionCreator(
  DOWNLOAD_DATA_CERTIFICATION_EXCEL,
  'param'
);
