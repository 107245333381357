import service from './service';
import endpoint from './constantUrl';

export const getDataLog = (param) => {
  return service.get(`${endpoint.ACCOUNT_URL}/log/log/`, param);
};

export const getDataLogById = (id) => {
  return service.get(`${endpoint.ACCOUNT_URL}/log/log/${id}/`);
};

export const createLog = (data) => {
  return service.post(`${endpoint.ACCOUNT_URL}/log/log/`, data);
};

export const createlogWithToken = (token, data) => {
  return service.postWithToken(token, `${endpoint.ACCOUNT_URL}/log/log/`, data);
};

export const deleteLog = () => {
  return service.delete(`${endpoint.ACCOUNT_URL}/log/log/bulk-delete/`);
};

export const exportCsv = (data) => {
  return service.get(`${endpoint.ACCOUNT_URL}/log/log/export-csv/`, data);
};
