import service from './service';
import endpoint from './constantUrl';

export const getDataModuleGroup = (param) => {
  const { search, limit, offset } = param;
  return service.get(
    `${endpoint.ACCOUNT_URL}/role/role/?search=${search}&limit=${limit}&offset=${offset}`
  );
};

export const getDataModuleGroupById = (id) => {
  return service.get(`${endpoint.ACCOUNT_URL}/role/role/${id}/`);
};

export const addDataModuleGroup = (data) => {
  return service.post(`${endpoint.ACCOUNT_URL}/role/role/`, data);
};

export const updateDataModuleGroup = (data, id) => {
  return service.put(`${endpoint.ACCOUNT_URL}/role/role/${id}/`, data);
};

export const deleteDataModuleGroup = (id) => {
  return service.delete(`${endpoint.ACCOUNT_URL}/role/role/${id}/`);
};
